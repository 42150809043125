import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import {
  TagWrapper,
  Tag,
  Wrapper
} from './styled';
import { SubTitle, SectionTitle } from '../../styled';

const NotAddedTags = ({ removeTag, newAddedTags }) => {
  const intl = useIntl();
  const hasAddedTags = !isEmpty(newAddedTags);

  if (hasAddedTags) {
    return (
      <Wrapper>
        <SectionTitle children={ intl.formatMessage({ id: 'manage.tags.inselevuln' }) } />
        <TagWrapper>
          { newAddedTags.map((tag) => (<Tag key={ `vtag_${tag}` } onClick={ () => removeTag(tag) }>{tag}</Tag>)) }
        </TagWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SectionTitle children={ intl.formatMessage({ id: 'manage.tags.inselevuln' }) } />
      <SubTitle children={ intl.formatMessage({ id: 'manage.tags.vuln.empty' }) } />
    </Wrapper>
  );
};

export default NotAddedTags;
