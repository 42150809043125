import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_TASKS_DELETE } from 'store/modals/modals';
import Wrapper from 'Common/Components/ReduxModal/styled';
import { Default, Accept } from 'Common/Components/Button/styled';
import { selectTaskToDelete } from 'store/Tasks/selectors';
import { markTaskToDelete, deleteTask, closeTaskDetail } from 'store/Tasks/actions';
import get from 'lodash/get';
import {
  ModalWrapper, Title, Message, ButtonsWrapper
} from './styled';

const DeleteTask = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const taskToDelete = useSelector(selectTaskToDelete);
  const projectId = get(taskToDelete, 'projectId', 0);
  const taskId = get(taskToDelete, 'id', 0);
  const taskName = get(taskToDelete, 'name', '');
  const title = intl.formatMessage({ id: 'planner.task.delete.title' });
  const message = intl.formatMessage({ id: 'planner.task.delete.message' }, { taskName });
  const cancel = intl.formatMessage({ id: 'planner.task.delete.cancelBtn' });
  const done = intl.formatMessage({ id: 'planner.task.delete.deleteBtn' });

  const onDone = () => {
    dispatch(deleteTask(projectId, taskId));
    dispatch(closeModal(MODAL_TASKS_DELETE));
    dispatch(closeTaskDetail());
  };

  const onCancel = () => {
    dispatch(markTaskToDelete(null));
    dispatch(closeModal(MODAL_TASKS_DELETE));
  };

  return (
    <Wrapper>
      <ModalWrapper>
        <Title>{ title }</Title>
        <Message>{ message }</Message>
        <ButtonsWrapper>
          <Default onClick={ onCancel }>{ cancel }</Default>
          <Accept onClick={ onDone }>{ done }</Accept>
        </ButtonsWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default DeleteTask;
