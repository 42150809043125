import { FEATURE_HOSTS, PERMISSION_TYPES, FEATURE_VULNS } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEmpty from 'lodash/isEmpty';
import { tableMaxPages } from 'Screens/Constants';
import TABS from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/TabSelector/tabs';

export const selectImagePreview = (state) => get(state, 'manage.imagePreview', '');
export const selectSelectedComment = (state) => get(state, 'manage.selectedComment', null);
export const selectComments = (state) => get(state, 'manage.comments', []);
export const selectError = (state) => get(state, 'manage.error', false);
export const selectErrorMessage = (state) => get(state, 'manage.errorMessage', '');
export const selectHasVulns = (state) => get(state, 'manage.vulnsList', []).length > 0;
export const selectVulnsList = (state) => get(state, 'manage.vulnsList', []);
export const selectVulnsGroupedData = (state, index) => get(state, `manage.vulnsList.${index}.groupData`, []);
export const selectVulnsCount = (state) => get(state, 'manage.vulnsCount', 0);
export const selectVulnsSelected = (state) => get(state, 'manage.vulnsSelected', []);
export const selectVulnDetail = (state) => get(state, 'manage.vulnDetail', false);
export const selectVulnDetailTags = (state) => get(state, 'manage.vulnDetail.tags', []);
export const selectVulnDetailId = (state) => get(state, 'manage.vulnDetail._id', 0);
export const selectVulnDetailSelectedTabId = (state) => get(state, 'manage.vulnDetailSelectedTab', 'general');
export const selectVulnDetailSelectedTab = (state) => {
  const selectedTabId = selectVulnDetailSelectedTabId(state);
  const defaultTab = TABS.find((tab) => tab.id === 'general');
  const selectedTab = TABS.find((tab) => tab.id === selectedTabId) || defaultTab;
  return selectedTab;
};
export const selectShowDetail = (state) => get(state, 'manage.showVulnDetail', false);
export const selectLastSelected = (state) => get(state, 'manage.lastSelected', -1);
export const selectLastIndexForRange = (state) => get(state, 'manage.lastIndexForRange', 1);
export const selectReadOnly = (state) => get(state, 'manage.readonly', false);
export const selectPermissions = (state) => get(state, 'faraday.permissions.vulns', []);
export const selectShowManageLeftFilters = (state) => get(state, 'manage.showLeftFilters', false);

export const selectSelectedMainVulns = (state) => {
  const selectedVulns = selectVulnsSelected(state);
  const mainVulns = selectedVulns.filter((v) => !isEmpty(v.duplicates_associated)).map((v) => ({ id: v.id, name: v.name }));
  const duplicatedVulns = selectedVulns.filter((v) => !isEmpty(v.duplicates_main));
  const relativeMains = duplicatedVulns.map((v) => ({ id: v.duplicates_main.id, name: v.duplicates_main.name }));

  const mainsInGroup = [...mainVulns, ...relativeMains];
  const set = new Set();
  const uniqueMains = mainsInGroup.filter((vuln) => (set.has(vuln.id) ? false : set.add(vuln.id)));

  return uniqueMains;
};

export const selectIsFetching = (state) => get(state, 'manage.isFetching', false);
export const selectIsFetchingExpandedData = (state) => get(state, 'manage.isFetchingExpandedData', false);

export const selectVisiblePages = (state) => {
  const pagesMap = get(state, 'manage.pagesMap', {});
  const currentPage = get(state, 'manage.page', 1);
  const group_by = get(state, 'manage.group_by', '');
  const vulnsList = get(state, 'manage.vulnsList', []);
  let arrayResponse = [];

  if (!group_by || group_by.length === 0) {
    const initialPage = currentPage <= tableMaxPages ? 1 : (currentPage - tableMaxPages) + 1;

    for (let i = initialPage; i <= currentPage; i += 1) {
      if (pagesMap[i]) arrayResponse = arrayResponse.concat(pagesMap[i]);
    }
    return arrayResponse;
  }
  return vulnsList;
};

export const selectRoutes = (state) => {
  const canReadVulns = selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.READ);
  const canReadHosts = selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.READ);
  const routeList = [];

  if (canReadVulns) routeList.push({ name: '/manage', desc: 'Vulnerabilities', requireWorkspace: true });
  if (canReadHosts) routeList.push({ name: '/host', desc: 'Assets', requireWorkspace: true });

  return routeList;
};

export const selectCurrentExternalId = (state) => {
  const detail = selectVulnDetail(state);
  return get(detail, 'external_id', '');
};
export const selectCurrentReferences = (state) => {
  const detail = selectVulnDetail(state);
  return sortBy(get(detail, 'refs', []));
};
export const selectCurrentPolicies = (state) => {
  const detail = selectVulnDetail(state);
  return get(detail, 'policyviolations', []);
};
export const selectCurrentImpacts = (state) => {
  const detail = selectVulnDetail(state);
  return get(detail, 'impact', []);
};

export const selectPagesMap = (state) => get(state, 'manage.pagesMap', {});
export const selectUsesMockVulns = (state) => get(state, 'manage.usesMockVulns', false);

export const allVulnsAreSelected = (state) => {
  const vulns = selectVulnsList(state);
  const vulnList = selectVulnsSelected(state);
  return vulns.every((testVuln) => vulnList.some((vuln) => vuln._id === testVuln._id)) && vulnList.length > 0;
};

export const someVulnIsSelected = (state) => (selectVulnsSelected(state).length > 0);

export const selectAttachments = (state) => get(state, 'manageEditCreate._attachments', {});

export const selectShowContextMenu = (state) => get(state, 'manage.showContextMenu', false);
export const selectContextMenuXPos = (state) => get(state, 'manage.contextMenuXPos', 0);
export const selectContextMenuYPos = (state) => get(state, 'manage.contextMenuYPos', 0);

export const selectVulnDetailDuplicates = (state) => get(state, 'manage.vulnDetailDuplicates', []);
export const selectUserMentions = (state) => get(state, 'manage.userMentions', []);

export const selectModalBulkUpdateField = (state) => get(state, 'manage.bulkUpdateField', '');
export const selectModalBulkUpdateValue = (state) => get(state, 'manage.bulkUpdateValue', '');

export const selectShowQuickPlanner = (state) => get(state, 'manage.showQuickPlanner', false);

export const selectTemplatesCreated = (state) => get(state, 'manage.templatesCreated', []);
export const selectFailedTemplates = (state) => get(state, 'manage.failedTemplates', []);

export const selectStatusTemplateMessage = (state) => {
  const templatesCreated = selectTemplatesCreated(state);
  const failedTemplates = selectFailedTemplates(state);

  const createdSuccessfully = (templatesCreated.length > 0) && (failedTemplates.length === 0);
  const singlePartialSuccess = (templatesCreated.length > 0) && (failedTemplates.length === 1);
  const multiplePartialSuccess = (templatesCreated.length > 0) && (failedTemplates.length > 0);
  const singleFailure = (templatesCreated.length === 0) && (failedTemplates.length === 1);
  const multipleFailure = (templatesCreated.length === 0) && (failedTemplates.length > 0);

  const type = (createdSuccessfully && 'success') ||
    (singlePartialSuccess && 'singlePartialSuccess') ||
    (multiplePartialSuccess && 'multiplePartialSuccess') ||
    (singleFailure && 'singleFailure') ||
    (multipleFailure && 'multipleFailure');

  return type;
};

export const modalData = {
  success: {
    title: 'success',
    subtitle: 'success'
  },
  singlePartialSuccess: {
    title: 'partialSuccess'
  },
  multiplePartialSuccess: {
    title: 'partialSuccess',
    subtitle: 'multiFail'
  },
  singleFailure: {
    title: 'failure'
  },
  multipleFailure: {
    title: 'failure',
    subtitle: 'multiFail'
  }
};

export const selecListTemplates = (state, status) => {
  const templatesCreated = selectTemplatesCreated(state);
  const failedTemplates = selectFailedTemplates(state);
  let list = [];
  if (status === 'success') {
    list = templatesCreated.map((t) => t.name);
  } else if (status === 'multiplePartialSuccess' || status === 'multipleFailure') {
    list = failedTemplates.map((t) => t.name);
  } else {
    list = [];
  }

  return list;
};
