import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { markNotification } from 'store/Notifications/actions';
import DateFormatter from 'Common/Components/DateFormatter';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import parseNotifications from './parseNotifications';
import {
  Text, TimeStamp, ListItem, Details, Delete
} from './styled';
import notificationActions from './notificationAction';

const NotificationItem = ({
  data, creationTime, id, event
}) => {
  const dispatch = useDispatch();
  const wsSelected = useSelector(selectCurrentWorkspace);
  return (data.message &&
    (
    <ListItem onClick={ notificationActions(event, data.data, dispatch, wsSelected) }>
      <Text>
        {parseNotifications(event, data.data, data.message)}
      </Text>
      <Details>
        <TimeStamp>
          <DateFormatter date={ creationTime } />
        </TimeStamp>
        <Delete onClick={ () => dispatch(markNotification(id)) }>Delete</Delete>
      </Details>
    </ListItem>
    )
  );
};

export default NotificationItem;
