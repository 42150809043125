import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalWrapper from 'Common/Components/ModalWrapper';
import isValidUrl from 'Common/Helpers/Common';
import {
  selectUrl, selectConsumerKey, selectPublicKey
} from 'store/Settings/jiraSelectors';
import {
  setField, saveJiraSettings, getJiraPublicKey
} from 'store/Settings/jiraActions';
import { MODAL_JIRA } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import {
  ModalContent, Url, ConfirmButton, CancelButton, Title, SubTitle, ButtonContainer, Content, Code, CodeWrapper, Flex, Section
} from './styled';

const FlexModal = ({ children }) => (
  <ModalWrapper>
    <ModalContent>
      <Content>
        {children}
      </Content>
    </ModalContent>
  </ModalWrapper>
);

const JiraURL = () => {
  const dispatch = useDispatch();
  const storedUrl = useSelector(selectUrl);
  const [url, setUrl] = useState(storedUrl);
  const storeUrl = (value) => dispatch(setField('url', value));

  return (
    <Section>
      <SubTitle>Enter your JIRA server URL</SubTitle>
      <Url title="URL" placeholder="http://domain.com:8080" onChange={ setUrl } value={ url } defaultValue={ url } />
      <ConfirmButton margin="15px 0 0 0" disabled={ !isValidUrl(url) } onClick={ () => storeUrl(url) }>Save</ConfirmButton>
    </Section>
  );
};

const JiraConsumerKey = () => {
  const dispatch = useDispatch();
  const consumerKey = useSelector(selectConsumerKey);
  const storedUrl = useSelector(selectUrl);
  const publicKey = useSelector(selectPublicKey);
  const setConsumerKey = (value) => dispatch(setField('consumerKey', value));
  const [key, setKey] = useState(consumerKey);
  useEffect(() => {
    // dispatch(getJiraPublicKey())
  }, []);
  const handlePublicKey = () => {
    dispatch(getJiraPublicKey());
  };

  return (
    <Section>
      <Flex>
        <Url title="Consumer key" placeholder="OAuthKey" onChange={ setKey } value={ key } defaultValue={ key } />
        <ConfirmButton margin="0 0 0 15px" disabled={ !key } onClick={ () => setConsumerKey(key) }>Save</ConfirmButton>
      </Flex>
      <SubTitle>Copy this public key on your Jira account</SubTitle>
      <ConfirmButton margin="15px 0 15px 0" disabled={ !consumerKey || !storedUrl } onClick={ handlePublicKey }>Generate Key</ConfirmButton>
      {publicKey && (
      <CodeWrapper>
        <Code>
          {publicKey}
        </Code>
      </CodeWrapper>
      )}
    </Section>
  );
};

const Buttons = () => {
  const dispatch = useDispatch();
  const close = () => dispatch(closeModal(MODAL_JIRA));
  const accept = () => {
    dispatch(saveJiraSettings());
    close();
  };

  return (
    <ButtonContainer>
      <CancelButton onClick={ close }>Cancel</CancelButton>
      <ConfirmButton margin="0 0 0 15px" onClick={ accept }>Done</ConfirmButton>
    </ButtonContainer>
  );
};

const ConfigurationModal = () => (
  <FlexModal>
    <Title>Jira Configuration</Title>
    <JiraURL />
    <JiraConsumerKey />
    <Buttons />
  </FlexModal>
);

export default ConfigurationModal;
