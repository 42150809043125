import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100px;
  margin-top: 15px;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
