import React from 'react';
import { useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_TAGS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import AddButton from 'Common/Components/AddButton';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useIntl } from 'react-intl';
import Wrapper from './styled';
import SubMenu from '../SubMenu';

const AddTagButton = () => {
  const intl = useIntl();
  const addLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.add' });
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_TAGS, PERMISSION_TYPES.CREATE));

  if (!canUpdate) return null;
  return (
    <Wrapper>
      <ContextMenuTrigger id="preview_add_tag_context_menu" holdToDisplay={ 0 }>
        <AddButton text={ addLabel } />
      </ContextMenuTrigger>
      <SubMenu />
    </Wrapper>
  );
};

export default AddTagButton;
