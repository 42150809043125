import styled from 'styled-components';
import { ReactComponent as SendIcon } from 'Images/icon-action-bar-send.svg';

export const Send = styled(SendIcon)`
  height: 17px;
`;

Send.displayName = 'Send';

export default Send;
