import React from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { selectJobsList } from 'store/Pipelines/selectors';
import {
  Wrapper, Label, RuleWrapper, AttributeWrapper, Attribute, Tag,
  AndLabel, ThenLabel, TaskWrapper, Action, Field
} from './styled';

const Rule = ({ rule }) => {
  const attributes = get(rule, 'attributes', []);
  const lastElement = attributes.length - 1;
  const intl = useIntl();

  return (
    <RuleWrapper>
      {
        attributes.map((a, index) => (
          <AttributeWrapper key={ `${JSON.stringify(a)}_wrapper` }>
            <Attribute>
              <Tag type="key">{ intl.formatMessage({ id: `model.field.${a.field}`, defaultMessage: a.field }) }</Tag>
              <Tag type="op">{ intl.formatMessage({ id: `model.operator.${a.operator}`, defaultMessage: a.operator }) }</Tag>
              <Tag type="val">{ intl.formatMessage({ id: `model.valid.${a.data}`, defaultMessage: a.data }) }</Tag>
            </Attribute>
            { (index !== lastElement) && <AndLabel>{ intl.formatMessage({ id: 'jobs.attributes.concat' }) }</AndLabel>}
          </AttributeWrapper>
        ))
      }
    </RuleWrapper>
  );
};

const Task = ({ task }) => {
  const intl = useIntl();
  return (
    <TaskWrapper>
      <Action>{ intl.formatMessage({ id: `model.field.${task.command}`, defaultMessage: task.command }) }</Action>
      {task.command !== 'DELETE' && <Field>{ intl.formatMessage({ id: `model.field.${task.field}`, defaultMessage: task.field }) }</Field>}
    </TaskWrapper>
  );
};

const JobsDetails = ({ tasks, id }) => {
  const jobs = useSelector((state) => selectJobsList(state));
  const jobSelected = jobs.filter((job) => job.id === id);
  const rules = jobSelected ? jobSelected[0].rules : [];
  const lastElement = rules.length - 1;
  const intl = useIntl();

  return (
    <Wrapper>
      <Label>{ intl.formatMessage({ id: 'pipelines.jobs.detail.label.if' }) }</Label>
      { rules.map((r, index) => (
        <div key={ `${JSON.stringify(r)}_rule` }>
          <Rule rule={ r } />
          { (index !== lastElement) && <Label>{ intl.formatMessage({ id: 'jobs.rules.concat' }) }</Label> }
        </div>
      )) }

      <ThenLabel>{ intl.formatMessage({ id: 'pipelines.jobs.detail.label.then' }) }</ThenLabel>
      { tasks.map((task) => <Task key={ task.id } task={ task } />)}
    </Wrapper>
  );
};
export default JobsDetails;
