import React from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { Wrapper, Text } from './styled';
import { workspaceSelectedFromTable } from 'Screens/Workspaces/actions/Actions';
import Skeleton from 'Common/Components/Skeleton';

const Name = ({ isFetching, ws }) => {
  const dispatch = useDispatch();
  const name = get(ws, 'name', '');
  const totalVulns = get(ws.total_vulns, 'stats.total_vulns', 0);
  const isWsActive = get(ws, 'active', false);

  const handleClick = (e) => isWsActive && dispatch(workspaceSelectedFromTable(name, totalVulns));

  if (isFetching) return <Skeleton />;
  return (
    <Wrapper>
      <Text onClick={ handleClick } children={ name } />
    </Wrapper>
  );
};

export default Name;
