import styled from 'styled-components';
import colors from 'Styles/colors';
import { FaPlus } from 'react-icons/fa';

export const CreateNewText = styled.div`
  color: ${colors.darkBlueGrey};
  cursor: pointer;
  padding-left: ${(props) => (props.actionBar ? 'unset' : '15px')};
  padding-bottom: 8px;
  padding-top: 2px;
  -webkit-appearance: none;
  font-size: 13.5px;
  font-weight: 400;
  margin: ${(props) => (props.actionBar ? '10px 0 10px 20px' : '10px 0')};
  span {
    padding-left: 15px;
  }
`;
CreateNewText.displayName = 'CreateNewText';

export const Plus = styled(FaPlus)`
  color: #90a9c0;
`;
Plus.displayName = 'PLus';
