import styled from 'styled-components';
import { MenuItem } from 'react-contextmenu';
import colors from 'Styles/colors';
import { tagStyles } from 'Styles/styles';
import { FaTimes } from 'react-icons/fa';

export const Wrapper = styled.div`
  flex: 1 1 auto;
  user-select: none;
  max-height: 157px;
  overflow: auto;
  & > :not(:last-child) {
    margin-bottom: 7px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Item = styled(MenuItem)` 
  display: flex;
  flex-direction: row;
  background-color: transparent !important;
  text-decoration: none;
  outline: none !important;
  width: 100%;
  cursor: default;
  padding: 0px;
  height: 29px;
`;
Item.displayName = 'Item';

export const AddedTag = styled.div`
  flex: 0 1 auto;
  border-radius: 3px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.06);
  background-color: ${colors.black3};
  margin-right: 2px;
  text-align: left;
  padding-top: 3px;
  padding-right: 11px;
  padding-left: 11px;
  color: white;
  font-weight: 300;
  font-size: 14px;
  ${tagStyles};
`;
AddedTag.displayName = 'AddedTag';

export const IconWrapper = styled.div`
  display: flex;
  flex: 0 0 29px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;

  &:hover{
    background-color: #eeeeee;
    border-radius: 50%;
  }
`;
IconWrapper.displayName = 'IconWrapper';

export const Close = styled(FaTimes)`
  color: #515a65;
`;
Close.displayName = 'Close';
