import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ContextMenu, MenuItem } from 'react-contextmenu';
import get from 'lodash/get';
import { useIntl } from 'react-intl';
import { resetWarning } from 'store/Settings/settingsActions';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE, MODAL_JIRA_TEMPLATE, MODAL_SEND_TO_JIRA } from 'store/modals/modals';
import {
  PERMISSION_TYPES, FEATURE_TICKETING_GITLAB, FEATURE_TICKETING_JIRA, FEATURE_TICKETING_SERVICENOW, FEATURE_TICKETING_WHD
} from 'Hooks/usePermission/permissions';
import sendToToolLicenseImage from 'Images/sendtotool-license.png';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { ACTIONS } from 'tracking/GA/constants';
import selectModal from 'store/modals/selectors';
import { selectVulnsSelected } from 'store/Manage/selectors';
import { getServiceNowSettings } from 'store/Settings/serviceNowActions';
import JiraTemplateModal from 'Common/Components/JiraTemplateModal';
import { Wrapper, Blue, ConfirmedIcon } from './styled';
import useModal from './hooks/useModal';
import Warning from './components/Warning';
import Jira from './components/Jira';
import ServiceNow from './components/ServiceNow';
import GitLab from './components/GitLab';
import SolarWinds from './components/SolarWinds';
import ReduxModal from '../ReduxModal';
import UpgradeLicense from '../UpgradeLicense';
import SetProject from './components/Jira/SetProject';

const SendToTool = ({ trigger }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [ModalVulnsNotConfirmed, showModalVulnsNotConfirmed, toggleModalVulnsNotConfirmed] = useModal(Warning);
  const [ModalJira, showModalJira, toggleModalJira] = useModal(Jira);
  const [ModalServiceNow, showModalServiceNow, toggleModalServiceNow] = useModal(ServiceNow);
  const [ModalGitLab, showModalGitLab, toggleModalGitLab] = useModal(GitLab);
  const [ModalSolarWinds, showModalSolarWinds, toggleModalSolarWinds] = useModal(SolarWinds);

  const vulnsSelected = useSelector(selectVulnsSelected);
  const error = useSelector((state) => get(state, 'settings.sendVulnToTool.payload.error', false));
  const message = useSelector((state) => get(state, 'settings.sendVulnToTool.payload.message', ''));
  const isEnabledJira = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledGitlab = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledServiceNow = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledWhd = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));

  const isEnabledByRoleJira = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleGitlab = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleServiceNow = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledByRoleWhd = useSelector((state) => selectAllowedByRole(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));
  const showJiraTemplateModal = useSelector((state) => selectModal(state, MODAL_JIRA_TEMPLATE));

  const showSendToJiraModal = useSelector((state) => selectModal(state, MODAL_SEND_TO_JIRA));

  const showModal = useSelector((state) => selectModal(state, MODAL_UPGRADE_LICENSE));

  const vulnsNotConfirmed = () => (vulnsSelected.some((e) => e.confirmed === false));

  const validate = (isEnabled, byRole) => {
    if (!isEnabled) {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
      return false;
    }
    if (!byRole) return false;
    if (vulnsNotConfirmed()) {
      toggleModalVulnsNotConfirmed(true);
      return false;
    }
    return true;
  };

  const sendVulnToJira = () => {
    if (validate(isEnabledJira, isEnabledByRoleJira)) dispatch(openModal(MODAL_SEND_TO_JIRA));
  };

  const sendVulnToServicenow = () => {
    if (validate(isEnabledServiceNow, isEnabledByRoleServiceNow)) {
      dispatch(getServiceNowSettings());
      toggleModalServiceNow(true);
    }
  };
  const sendVulnToGitlab = () => {
    if (validate(isEnabledGitlab, isEnabledByRoleGitlab)) toggleModalGitLab(true);
  };
  const sendVulnToSolarWinds = () => {
    if (validate(isEnabledWhd, isEnabledByRoleWhd)) toggleModalSolarWinds(true);
  };

  return (
    <Wrapper>
      <ContextMenu id={ trigger }>
        <MenuItem onClick={ sendVulnToJira } data={ { item: 'jira' } }>{ intl.formatMessage({ id: 'sendToTool.menu.jira.title' }) }</MenuItem>
        <MenuItem onClick={ sendVulnToServicenow } data={ { item: 'servicenow' } }>{ intl.formatMessage({ id: 'sendToTool.menu.serviceNow.title' }) }</MenuItem>
        <MenuItem onClick={ sendVulnToGitlab } data={ { item: 'gitlab' } }>{ intl.formatMessage({ id: 'sendToTool.menu.gitLab.title' }) }</MenuItem>
        <MenuItem onClick={ sendVulnToSolarWinds } data={ { item: 'solarwinds' } }>{ intl.formatMessage({ id: 'sendToTool.menu.solarWinds.title' }) }</MenuItem>
      </ContextMenu>

      {showModalJira && <ModalJira onClose={ toggleModalJira } />}
      {showModalServiceNow && <ModalServiceNow onClose={ toggleModalServiceNow } />}
      {showModalGitLab && <ModalGitLab onClose={ toggleModalGitLab } />}
      {showModalSolarWinds && <ModalSolarWinds onClose={ toggleModalSolarWinds } />}

      {showModalVulnsNotConfirmed && (
      <ModalVulnsNotConfirmed title={ intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.title' }) } onClose={ () => toggleModalVulnsNotConfirmed(false) }>
        { intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.onlyConfirmedVulns' }) }
        <ConfirmedIcon />
        <Blue>{ intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.confirmed' }) }</Blue>
        { intl.formatMessage({ id: 'sendToTool.warnings.vulnsNotConfirmed.message.tryAgain' }) }
      </ModalVulnsNotConfirmed>
      )}
      { error && message && <Warning title={ intl.formatMessage({ id: 'sendToTool.warnings.oops.title' }) } onClose={ () => dispatch(resetWarning()) }>{message}</Warning>}
      { showModal && (
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ sendToToolLicenseImage } descriptionIntlId="upgradeLicense.sendToTool.description" trackingShow={ ACTIONS.showSendToTool } trackingClick={ ACTIONS.clickSendToTool } />
      </ReduxModal>
      ) }
      {showJiraTemplateModal && <JiraTemplateModal />}
      {showSendToJiraModal && <SetProject />}
    </Wrapper>
  );
};

SendToTool.propTypes = {
  trigger: PropTypes.string.isRequired
};

export default SendToTool;
