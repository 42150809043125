import React, { useEffect } from 'react';
import { useFilePicker } from 'use-file-picker';
import { useDispatch } from 'react-redux';
import { setAttachments } from 'Screens/ManageEditCreate/actions/Actions';
import { addEvidences } from 'store/Manage/actions';
import { ImageWrapper, EvidenceDefault, BlueText } from './styled';
import { isEmpty } from 'lodash';

const UploadEvidence = ({ getEvidences, isEditing }) => {
  const dispatch = useDispatch();
  const [openFileSelector, { plainFiles, clear }] = useFilePicker({ multiple: true, readFilesContent: false });

  useEffect(() => {
    const onUpload = (plainFiles) => {
      if (isEditing) dispatch(setAttachments(plainFiles));
      else dispatch(addEvidences(plainFiles, getEvidences));
      clear();
    };

    if (!isEmpty(plainFiles)) onUpload(plainFiles);
  }, [getEvidences, plainFiles, dispatch, isEditing, clear]);

  return (
    <div onClick={ openFileSelector }>
      <ImageWrapper>
        <EvidenceDefault />
      </ImageWrapper>
      <BlueText>Add Evidence</BlueText>
    </div>
  );
};

export default UploadEvidence;
