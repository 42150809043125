import styled from 'styled-components';
import colors from 'Styles/colors';

const Input = styled.input`
  align-items: center;
  background-color: ${colors.paleGrey};
  border-bottom: solid 1px ${colors.grey12};
  border-radius: 0px 2px 2px 0px;
  border-top: solid 1px ${colors.grey12};
  border: solid 1px ${colors.grey12};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  color: #63758d;
  cursor: default;
  display:flex;
  flex-direction: row;
  font-size: 11.5px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  height: 24px;
  letter-spacing: normal;
  line-height: normal;
  margin-right: 5px;
  padding: 6px 7px 5.5px 7px;
  text-align: center;
  white-space: nowrap;
  width: 92px;
  
  :-ms-input-placeholder {
    color: rgb(222,229,236);
  }
  ::-webkit-input-placeholder {
    color: rgb(222,229,236);
  }
  ::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

export default Input;
