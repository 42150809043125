import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Tag } from 'Common/Components/SearchBar/components/BasicFilter/components/FilterTags';
import { useDispatch, useSelector } from 'react-redux';
import { selectJobModel } from 'store/Jobs/selectors';
import isEmpty from 'lodash/isEmpty';
import { addAttrToSelectedRule } from 'store/Jobs/actions';
import { selectFieldNames, selectFieldType, selectFieldOperators, selectFieldValidValues } from 'store/Filters/selectors';
import { useIntl } from 'react-intl';
import NewAttributeWrapper from './styled';
import AddButton from './components/AddButton';
import Dropdown from './components/Dropdown';
import AutocompleteInput from './components/AutocompleteInput';
import DateInput from './components/DateInput';

const NewAttribute = ({ rule }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [keyDropdown, setKeyDropdown] = useState(false);
  const [opDropdown, setOpDropdown] = useState(false);
  const [valDropdown, setValDropdown] = useState(false);
  const [selectedField, setSelectedField] = useState('');
  const [selectedOp, setSelectedOp] = useState('');
  const [inputVal, setInputVal] = useState('');

  const model = useSelector(selectJobModel);
  const fields = useSelector((state) => selectFieldNames(state, model));
  const fieldType = useSelector((state) => selectFieldType(state, model, selectedField));
  const fieldOperators = useSelector((state) => selectFieldOperators(state, model, selectedField));
  const fieldAutocomplete = useSelector((state) => selectFieldValidValues(state, model, selectedField));

  const isDateType = fieldType.includes('date');
  const showInput = !isEmpty(selectedOp) && fieldAutocomplete.length === 0;
  const showDateInput = showInput && isDateType;
  const showAutocompleteInput = showInput && !isDateType;

  const autocompleteOffset = fieldAutocomplete.length > 0 ? 0 : -185;

  const autocomplete = (fieldAutocomplete.length === 0 && inputVal) ? [inputVal] : fieldAutocomplete;

  const selectKey = (key) => {
    setSelectedField(key);
    setKeyDropdown(false);
    setOpDropdown(true);
  };

  const selectOp = (op) => {
    setSelectedOp(op);
    setOpDropdown(false);
    setValDropdown(true);
  };

  const selectVal = (selectedVal) => {
    const data = selectedOp === 'ilike' ? `%${selectedVal}%` : selectedVal;
    const attribute = {
      field: selectedField,
      operator: selectedOp,
      data
    };

    setSelectedField('');
    setSelectedOp('');
    setInputVal('');
    setValDropdown(false);
    dispatch(addAttrToSelectedRule(rule, attribute));
  };

  const onHide = () => {
    setSelectedField('');
    setSelectedOp('');
    setInputVal('');
    setValDropdown(false);
    setKeyDropdown(false);
    setOpDropdown(false);
  };

  return (
    <NewAttributeWrapper>
      <AddButton show={ isEmpty(selectedField) } onAdd={ () => setKeyDropdown(true) } />
      <Tag type="key" value={ selectedField && intl.formatMessage({ id: `model.field.${selectedField}`, defaultMessage: selectedField }) } />
      <Tag type="op" value={ selectedOp && intl.formatMessage({ id: `model.operator.${selectedOp}`, defaultMessage: selectedOp }) } valSelected={ showInput } />
      { showDateInput && <DateInput type='date' onValueSelect={ setInputVal } /> }
      { showAutocompleteInput && <AutocompleteInput inputVal={ inputVal } setInputVal={ setInputVal } /> }
      <Dropdown show={ keyDropdown } intlId="model.field" onHide={ onHide } options={ fields } onSelect={ selectKey } isFirst />
      <Dropdown show={ opDropdown } intlId="model.operator" onHide={ onHide } options={ fieldOperators } onSelect={ selectOp } />
      <Dropdown show={ valDropdown } intlId="model.valid" onHide={ onHide } options={ autocomplete } onSelect={ selectVal } offset={ autocompleteOffset } isDateType={ isDateType } />
    </NewAttributeWrapper>
  );
};

NewAttribute.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rule: PropTypes.object.isRequired
};

export default NewAttribute;
