/* eslint-disable react/jsx-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { selectTableFromat, selectShowTable } from 'store/Analytics/selectors';
import { Title, Wrapper } from './styled';

const Table = ({ name, type, data }) => {
  const formatedData = selectTableFromat(type);
  const showTable = useSelector(selectShowTable);

  if (!data || !showTable) return null;
  return (
    <Wrapper>
      <Title>{name}</Title>
      <table>
        <thead>
          <tr>
            {formatedData.map((column, i) => (
              <th id={ (i === 0 || i === formatedData.length - 1) && 'black' }>{column.name}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          { data.map((d, index) => (
            <tr>
              {formatedData.map((cell, i) => {
                if (cell.selector === 'label') return <td id={ i === 0 && 'light' }>{d[cell.selector]}</td>;
                if (cell.selector === 'rank') return <td id={ i === 0 && 'light' }>{index + 1}</td>;
                return <td id={ (i === formatedData.length - 1) && 'light' }>{d.data[cell.selector]}</td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default Table;
