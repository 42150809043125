import styled from 'styled-components';
import { ReactComponent as ArrowSVG } from 'Images/arrow-right.svg';

export const menuItemHeight = 36;

export const StyledMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  user-select: none;
  align-items: center;
  flex: 1 1 auto;
  background: 0 0;
  border: 0;
  cursor: pointer;
  font-weight: ${({ isSelected }) => (isSelected ? 500 : 300)};
  line-height: 1.5;
  text-align: inherit;
  white-space: nowrap;
  height: ${`${menuItemHeight}px`};
  padding: 7px 15px;
  outline: none;
  font-size: 14px;
  color: ${({ color }) => color || '#1c2243'};
  &:hover {
    background-color: #f1f4f7
  }
`;
StyledMenuItem.displayName = 'StyledMenuItem';

export const ArrowIcon = styled(ArrowSVG)`
    margin: auto 0px auto auto;
`;
ArrowIcon.displayName = 'ArrowIcon';
