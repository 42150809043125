import api from 'services/api';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { clearFilters } from 'store/Filters/actions';
import { selectQueryParam, selectFilters } from 'store/Filters/selectors';
import {
  REPORT_FILTERS, eq, createFilter
} from 'store/Filters/constants';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import {
  selectAdvancedFilterQueryParam, selectInputField, selectID, selectSelectedTemplate
} from 'store/ExecutiveReportEditCreate/selectors';
import { selectTags } from 'store/Tags/selectors';
import * as types from './types';

export function resetState () {
  return (dispatch) => {
    dispatch({
      type: types.RESET_STATE_EXECUTVE_REPORT_CREATE_UPDATE
    });
  };
}

export function hideWarnings () {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_WARNINGS_EXECUTIVE_REPORT_CREATE_UPDATE
    });
  };
}

export function createReport (isUpdating) {
  return async (dispatch, getState) => {
    const state = getState();
    const workspaceSelected = selectCurrentWorkspace(state);
    const filter = { filters: selectFilters('reports', state) };
    const title = selectInputField(state, 'title');
    const enterprise = selectInputField(state, 'enterprise');
    const selectedTemplate = selectSelectedTemplate(state);
    const advancedFilter = selectInputField(state, 'advancedFilter');
    const summary = selectInputField(state, 'summary');
    const conclusions = selectInputField(state, 'conclusions');
    const recommendations = selectInputField(state, 'recommendations');
    const objectives = selectInputField(state, 'objectives');
    const scope = selectInputField(state, 'scope');
    const id = selectID(state);

    const parsedAdvancedFilter = advancedFilter ? JSON.parse(`{"filters":[${advancedFilter}]}`) : JSON.parse('{"filters":[]}');
    const stringAdvancedFilter = parsedAdvancedFilter.filters.length > 0 ? JSON.stringify(parsedAdvancedFilter) : '';
    const stringStandardFilter = filter.filters.length > 0 ? JSON.stringify(filter) : '{}';
    const stringFilter = stringAdvancedFilter || stringStandardFilter;

    dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_START });

    try {
      let reportPayload = {
        name: `${workspaceSelected}_${title}`,
        title,
        enterprise,
        summary,
        conclusions,
        recommendations,
        scope,
        objectives,
        filter: stringFilter,
        advanced_filter: !!advancedFilter,
        advanced_filter_parsed: advancedFilter,
        grouped: selectedTemplate[0],
        template_name: selectedTemplate[1]
      };

      if (isUpdating) reportPayload = { ...reportPayload, id };

      if (isUpdating) await api.report.updateReport(workspaceSelected, reportPayload);
      else await api.report.createReport(workspaceSelected, reportPayload);
      dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_SUCCESS });
      dispatch(trackEvent(CATEGORIES.report, ACTIONS.createReport.name, ACTIONS.createReport.label));
    } catch (e) {
      dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_VALIDATION_FAIL, validationError: ['Invalid filter.'] });
    }
  };
}

function parseStringToFilterArray (filterString) {
  return async (dispatch, getState) => {
    const state = getState();
    const tags = selectTags(state);
    const assetsInFilter = filterString.match(/{"name":"target","op":"==","val":"(.*?)"}/g);
    dispatch(clearFilters('reports'));

    let assetsPremadeFilters = [];
    if (assetsInFilter) assetsPremadeFilters = assetsInFilter.map((f) => createFilter('target', eq.name.value, JSON.parse(f).val));

    const stringPremadeFilters = REPORT_FILTERS.map((elem) => ({
      name: elem.name.value,
      filter: elem.filter,
      parsedFilter: JSON.stringify(elem.filter)
    }));

    tags.forEach((tag) => {
      const tagFilter = { name: 'tags__name', filter: createFilter('tags__name', eq.name.value, tag.name) };
      const tagPremadeFilter = {
        name: tagFilter.name,
        filter: tagFilter.filter,
        parsedFilter: JSON.stringify(tagFilter.filter)
      };
      stringPremadeFilters.push(tagPremadeFilter);
    });

    let appliedFilters = [];
    appliedFilters = stringPremadeFilters
      .filter((filter) => filterString.includes(filter.parsedFilter))
      .map((filter) => ({ ...filter.filter }));

    appliedFilters = [
      ...appliedFilters,
      ...assetsPremadeFilters
    ];

    dispatch({ type: types.SET_FILTER_ARRAY, filterArray: appliedFilters });
  };
}

export function setInputField (label, value) {
  return (dispatch) => {
    dispatch({ type: types.SET_INPUT_FIELD, label, value });
  };
}

export function clearParseError () {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_PARSE_ADVANCED_FILTER_ERROR
    });
  };
}

export function setParseError (error) {
  return (dispatch) => {
    dispatch({
      type: types.SET_PARSE_ADVANCED_FILTER_ERROR, error
    });
  };
}

export function getVulnsPreview () {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const workspaceSelected = selectCurrentWorkspace(state);
      let advancedFilterQueryParam = [];
      try {
        advancedFilterQueryParam = selectAdvancedFilterQueryParam(state);
      } catch (e) {
        return dispatch(setParseError('Filter syntax error'));
      }
      const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
      const standardQueryParam = selectQueryParam('reports', state);
      const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;
      dispatch({ type: types.GET_PREVIEW_DATA_START_REPORT });
      const response = await api.manage.newGetVulns(workspaceSelected, queryParam);
      if (!response.status) {
        const result = response.vulnerabilities.map((vuln) => vuln.value);
        return dispatch({
          type: types.GET_PREVIEW_DATA_SUCCESS_REPORT,
          data: result,
          count: response.count
        });
      }
      return dispatch({ type: types.GET_PREVIEW_DATA_FAIL_REPORT, error: 'Failed to obtain vulnerabilities.' });
    } catch (e) {
      if (e.message.includes('filter')) return dispatch(setParseError(e.message));
      return dispatch({ type: types.GET_PREVIEW_DATA_FAIL_REPORT, error: 'Failed to obtain vulnerabilities.' });
    }
  };
}
// Summary: Get report for detail
export function getById (id) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const workspaceSelected = selectCurrentWorkspace(state);
      dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_START });
      const report = await api.report.getReportDetail(workspaceSelected, id);
      if (!report.advanced_filter) dispatch(parseStringToFilterArray(report.filter));
      dispatch({ type: types.SET_REPORT_CREATE_UPDATE, report });
    } catch (error) {
      dispatch({ type: types.EXECUTIVE_REPORT_CREATE_UPDATE_FAIL, error });
    }
  };
}
