import React, { useEffect, useState } from 'react';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import 'react-table-6/react-table.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  getData, removeReport, cloneReport, setOrderBy, updateReportsStatus
} from 'store/ExecutiveReport/actions';
import {
  selectReportList, selectIsFetching, selectRowsPerPage, selectReportsBeingProcessed
} from 'store/ExecutiveReport/selectors';
import { redirect } from 'store/Router/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import getColumns from './components/cells/Columns';
import { Wrapper, StyledTable } from './styled';
import EmptyReports from './components/EmptyReports';

const Table = () => {
  const [showModal, setModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector(selectReportList);
  const isFetching = useSelector(selectIsFetching);
  const rowsPerPage = useSelector(selectRowsPerPage);
  const mockData = [...Array(rowsPerPage).keys()];
  const ws = useSelector(selectCurrentWorkspace);
  const reportsBeingProcessed = useSelector(selectReportsBeingProcessed);
  const noReports = data.length === 0;
  const showTable = !(noReports && !isFetching);
  const action = {
    clone: (id, isRetry) => dispatch(cloneReport(id, isRetry)),
    edit: (id) => dispatch(redirect(`/report/${ws}/edit/${id}`)),
    delete: (id) => {
      setModal(true);
      setSelectedId(id);
    }
  };

  useEffect(() => {
    dispatch(getData());
  }, [dispatch, ws]);

  useEffect(() => {
    if (reportsBeingProcessed.length > 0) {
      setTimeout(async () => {
        await dispatch(updateReportsStatus());
      }, 6000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data]);

  const onFetchData = ({ sorted }) => {
    if (!isFetching) {
      dispatch(setOrderBy(sorted));
    }
  };

  const handleSubmit = () => {
    dispatch(removeReport(selectedId));
    setModal(false);
  };

  const handleClose = () => {
    setModal(false);
    setSelectedId(null);
  };

  return (
    <>
      <Wrapper>
        <EmptyReports show={ !showTable } />
        {showTable && (
          <StyledTable
            data={ isFetching ? mockData : data }
            columns={ getColumns(action, isFetching) }
            minRows={ 0 }
            defaultPageSize={ rowsPerPage }
            manual
            onFetchData={ onFetchData }
            showPagination={ false }
          />
        )}
      </Wrapper>
      <ModalConfirmation
        show={ showModal }
        handleSubmit={ handleSubmit }
        handleClose={ handleClose }
        entity="Report"
        message="This operation cannot be undone. Are you sure you want to proceed?"
      />
    </>
  );
};

export default Table;
