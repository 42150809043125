/* eslint-disable no-unused-vars */
import api from 'services/api';
import {
  GRAPH_RESET_ERROR, GRAPH_RESET_STATE, GRAPH_TOGGLE_VIEW, GRAPHS_FAIL, GRAPHS_FETCHING,
  GRAPHS_GET_ALL, GRAPH_SELECTED, GRAPH_SET_TYPE, GRAPH_SET_NAME, GRAPH_SET_DESCRIPTION,
  GRAPH_SET_NOT_CONFIRMED, GRAPH_SET_CLOSED, GRAPH_SET_WORKSPACES, GRAPH_SET_ID, GRAPH_SET_FROM,
  GRAPH_SET_TO, GRAPH_REFRESH_SUCCESS, GRAPH_GET_HOSTS, GRAPH_SET_HOSTS, GRAPH_CLOSE_NOTIFICATION,
  GRAPH_UPDATED, GRAPH_SET_SHOW_TABLE, GRAPH_REFRESH_FAIL
} from 'store/Analytics/constants';
import {
  selectClosed, selectDescription, selectFrom, selectGraphUpdated, selectHosts, selectId, selectName, selectNotConfirmed, selectTo, selectType, selectWorkspaces, selectData, selectAllowHosts
} from 'store/Analytics/selectors';
import { redirect } from 'store/Router/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_ANALYTICS_CONFIRM_NAVIGATION } from 'store/modals/modals';
import { isEmpty, now } from 'lodash';

export function somethingWentWrong (error) {
  return async (dispatch) => {
    dispatch({ type: GRAPHS_FAIL, errorMessage: error || 'There was an error, please try again.' });
  };
}

export function fetching () {
  return async (dispatch) => {
    dispatch({ type: GRAPHS_FETCHING });
  };
}

export function resetError () {
  return async (dispatch) => {
    dispatch({ type: GRAPH_RESET_ERROR });
  };
}

export function toggleView (viewMode) {
  return (dispatch) => {
    dispatch({ type: GRAPH_TOGGLE_VIEW, viewMode });
  };
}

export function resetState () {
  return (dispatch) => {
    dispatch({ type: GRAPH_RESET_STATE });
  };
}

export function getGraphs () {
  return async (dispatch) => {
    try {
      dispatch(fetching());
      const graphs = await api.analytics.fetchGraphs();
      return dispatch({ type: GRAPHS_GET_ALL, graphs });
    } catch (e) {
      return dispatch(somethingWentWrong(e.message));
    }
  };
}

export function confirmNavigation () {
  return (dispatch, getState) => {
    const state = getState();
    const graphUpdated = selectGraphUpdated(state);
    if (graphUpdated) {
      dispatch(openModal(MODAL_ANALYTICS_CONFIRM_NAVIGATION));
    } else {
      dispatch(resetState());
      dispatch(redirect('/analytics'));
    }
  };
}

export function viewDetail (id) {
  return async (dispatch) => {
    try {
      const graph = await api.analytics.fetchGraph(id);
      return dispatch({ type: GRAPH_SELECTED, graph });
    } catch (error) {
      return dispatch(somethingWentWrong(error.message));
    }
  };
}

export function saveGraph () {
  return async (dispatch, getState) => {
    try {
      const id = selectId(getState());
      const name = selectName(getState());
      const description = selectDescription(getState());
      const workspaces = selectWorkspaces(getState());
      const hosts = selectHosts(getState());
      const notConfirmed = selectNotConfirmed(getState());
      const closed = selectClosed(getState());
      const type = selectType(getState());
      const date_from = selectFrom(getState());
      const date_to = selectTo(getState());
      const data = selectData(getState());

      dispatch(fetching());
      const body = {
        name,
        description,
        filters: {
          workspaces,
          hosts,
          only_confirmed: notConfirmed,
          include_closed: closed,
          date_from,
          date_to
        },
        data,
        type
      };

      if (id === 0) {
        await api.analytics.createGraph(body);
      } else {
        await api.analytics.editGraph(id, body);
      }
      dispatch(redirect('/analytics'));
    } catch (e) {
      dispatch(somethingWentWrong(e.message));
    }
  };
}

export function deleteGraph (id) {
  return async (dispatch) => {
    try {
      await api.analytics.removeGraph(id);
      return dispatch(getGraphs());
    } catch (error) {
      return dispatch(somethingWentWrong(error.message));
    }
  };
}

export function copyGraph (id) {
  return async (dispatch) => {
    try {
      await api.analytics.copyGraph(id);
      return dispatch(getGraphs());
    } catch (error) {
      return dispatch(somethingWentWrong(error.message));
    }
  };
}

export function setGraphType (graphType, allowMultipleWs, allowHosts) {
  return (dispatch) => {
    dispatch({
      type: GRAPH_SET_TYPE, graphType, allowMultipleWs, allowHosts
    });

    dispatch(redirect('/analytics/0'));
  };
}

export function graphUpdated () {
  return (dispatch, getState) => {
    const id = selectId(getState());
    if (id) dispatch({ type: GRAPH_UPDATED });
  };
}

export function setGraphName (name) {
  return (dispatch) => {
    dispatch(graphUpdated());
    dispatch({ type: GRAPH_SET_NAME, name });
  };
}

export function setGraphDescription (description) {
  return (dispatch) => {
    dispatch(graphUpdated());
    dispatch({ type: GRAPH_SET_DESCRIPTION, description });
  };
}

export function setGraphNotConfirmed (notConfirmed) {
  return (dispatch) => {
    dispatch(graphUpdated());
    dispatch({ type: GRAPH_SET_NOT_CONFIRMED, notConfirmed });
  };
}

export function setGraphClosed (closed) {
  return (dispatch) => {
    dispatch({ type: GRAPH_SET_CLOSED, closed });
    dispatch(graphUpdated());
  };
}

export function setGraphWorkspaces (workspaces) {
  return (dispatch) => {
    dispatch(graphUpdated());
    dispatch({ type: GRAPH_SET_WORKSPACES, workspaces });
  };
}

export function setGraphHosts (hosts) {
  return (dispatch) => {
    dispatch(graphUpdated());
    dispatch({ type: GRAPH_SET_HOSTS, hosts });
  };
}

export function setGraphFrom (from) {
  return (dispatch) => {
    dispatch(graphUpdated());
    dispatch({ type: GRAPH_SET_FROM, from });
  };
}

export function setGraphTo (to) {
  return (dispatch) => {
    dispatch(graphUpdated());
    dispatch({ type: GRAPH_SET_TO, to });
  };
}

export const showDates = (type) => !(type === 'monthly_evolution_by_status' || type === 'monthly_evolution_by_severity');

const canRefresh = (getState) => {
  const type = selectType(getState());
  const workspaces = selectWorkspaces(getState());
  const hosts = selectHosts(getState());
  const from = selectFrom(getState());
  const to = selectTo(getState());
  const allowHosts = selectAllowHosts(getState());

  if (
    workspaces.length > 0 &&
    !workspaces.includes('') &&
    ((from && to) || (!from && !to))
  ) {
    if (type === 'vulnerabilities_per_host') {
      return (
        allowHosts &&
        hosts.length > 0 &&
        !hosts.includes('')
      );
    }
    return true;
  }

  return false;
};

export function refreshGraph () {
  return async (dispatch, getState) => {
    try {
      if (canRefresh(getState)) {
        const type = selectType(getState());
        const workspaces = selectWorkspaces(getState());
        const hosts = selectHosts(getState());
        const from = selectFrom(getState());
        const to = selectTo(getState());
        const onlyConfirmed = !selectNotConfirmed(getState());
        const includeClosed = selectClosed(getState());
        const parsedWorkspaces = workspaces && workspaces.filter((ws) => ws).map((ws) => `&workspaces=${ws}`).join('');
        const parsedHosts = hosts && hosts.filter((host) => host).map((el) => `&hosts=${el}`).join('');

        let query = `type=${type}&only_confirmed=${onlyConfirmed}&include_closed=${includeClosed}${parsedWorkspaces}${parsedHosts}`;
        if (from && to) query += `&date_from=${from} 00:00:00&date_to=${to} 00:00:00`;

        const response = await api.analytics.refreshGraph(query);
        const wsErrors = response && !isEmpty(response.errors.workspaces);
        const hostsErrors = !isEmpty(response.errors.hosts);
        if (wsErrors || hostsErrors) {
          if (wsErrors) dispatch(setGraphWorkspaces(workspaces.filter((v) => response.errors.workspaces.indexOf(v) < 0)));
          if (hostsErrors) dispatch(setGraphHosts(hosts.filter((v) => response.errors.hosts.indexOf(v) < 0)));
          dispatch({ type: GRAPH_REFRESH_FAIL, errors: response.errors });
        } else {
          dispatch({ type: GRAPH_REFRESH_SUCCESS, data: response, refreshedAt: new Date() });
        }
      }
    } catch (error) {
      dispatch(somethingWentWrong(error.message));
    }
  };
}

export function setGraphId (id, name) {
  return (dispatch) => {
    dispatch({ type: GRAPH_SET_ID, id, name });
  };
}

export function fetchHosts (ws) {
  return async (dispatch) => {
    try {
      const hosts = await api.analytics.getHosts(ws);
      return dispatch({ type: GRAPH_GET_HOSTS, hosts });
    } catch (error) {
      return dispatch(somethingWentWrong(error.message));
    }
  };
}

export function closeNotification (dataType) {
  return (dispatch) => {
    dispatch({ type: GRAPH_CLOSE_NOTIFICATION, dataType });
  };
}

export function setShowTable (show) {
  return (dispatch) => {
    dispatch({ type: GRAPH_SET_SHOW_TABLE, show });
  };
}
