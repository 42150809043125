import styled from 'styled-components';
import colors from 'Styles/colors';
import { AddedTag } from '../AddedTags/styled';

const NotAddedTag = styled(AddedTag)`
  border: solid 1px #cbcbcb;
  background-color: ${({ marked }) => (marked ? colors.grey12 : colors.primaryColor)};
  cursor: pointer;
  color: ${colors.dark3};
  &:hover {
    background-color: ${colors.iceBlue} !important;
  }
`;
NotAddedTag.displayName = 'NotAddedTag';

export default NotAddedTag;
