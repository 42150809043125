import get from 'lodash/get';

const dispatchWSAction = (store, event, payload) => { // eslint-disable-line no-unused-vars
  // TODO: hacer funcion mas escalable (que maneje 100+ actions)
  switch (event) {
    case 'new_notification':
      store.dispatch(
        {
          type: 'NEW_NOTIFICATION',
          data: payload.data,
          count: payload.unread_notifications_count
        }
      );
      break;
    case 'unread_notifications':
      store.dispatch(
        {
          type: 'UPDATE_NOTIFICATIONS',
          data: get(payload, 'unread_notifications.notifications', []),
          count: get(payload, 'unread_notifications.count', 0)
        }
      );
      break;
    case 'my_response':
      break;
    default:
      break;
  }
};

export default dispatchWSAction;
