/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTag, removeTag, setVulnsTags, showContextMenu
} from 'store/Manage/actions';
import { useIntl } from 'react-intl';
import TagSubMenu from 'Common/Components/Menus/TagSubMenu';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import {
  FEATURE_VULNS, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { TagIcon } from './styled';
import { selectVulnsSelected } from 'store/Manage/selectors';
import { selectShowCreateTagModal } from 'store/Tags/selectors';
import { setCreationTagModal } from 'Screens/Tags/actions/Actions';

export const Tags = () => {
  const dispatch = useDispatch();
  const vulnsSelected = useSelector(selectVulnsSelected);
  const title = useIntl().formatMessage({ id: 'manage.cm.tag' });
  const showCreateTagModal = useSelector(selectShowCreateTagModal);

  return (
    <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
      <ContextSubMenu title={ title } icon={ <TagIcon /> }>
        <TagSubMenu
          addTag={ (tag) => dispatch(addTag(tag)) }
          entitiesSelected={ vulnsSelected }
          hideNewContextMenu={ () => dispatch(showContextMenu(false)) }
          keyPrefix="cm.tag"
          trigger="cm.tag"
          removeTag={ (tag) => dispatch(removeTag(tag)) }
          setTags={ (tagsToAdd, tagsToRemove) => dispatch(setVulnsTags(tagsToAdd, tagsToRemove)) }
          showCreateModal={ showCreateTagModal }
          setShowModal={ (show) => dispatch(setCreationTagModal(show)) }
        />
      </ContextSubMenu>
    </WithPermission>
  );
};
