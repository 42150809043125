import React from 'react';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectTags } from 'store/Tags/selectors';
import CreateVulnTagModal from '../CreateVulnTagModal';
import Wrapper from './styled';
import CreateTag from './components/CreateTag';
import TagList from './components/TagList';
import { selectIsFetching } from 'store/Manage/selectors';

const TagSubMenu = ({
  addTag, removeTag, entitiesSelected,
  keyPrefix, actionBar, trigger, setTags,
  className, hideNewContextMenu, showCreateModal, setShowModal, showAddedTags
}) => {
  const tags = useSelector(selectTags);
  const includedTags = entitiesSelected.map((e) => get(e, 'tags', []));
  const addedTags = intersection(...includedTags);
  const isLoading = useSelector(selectIsFetching);
  const notAddedTags = tags.map((t) => t.name).filter((tag) => addedTags.every((t) => t !== tag));

  const content = (
    <>
      <TagList
        showAddedTags={ showAddedTags }
        addTag={ addTag }
        addedTags={ addedTags }
        notAddedTags={ notAddedTags }
        removeTag={ removeTag }
        keyPrefix={ keyPrefix }
        entitiesSelected={ entitiesSelected }
      />
      <CreateTag setShowModal={ setShowModal } actionBar={ actionBar } hideNewContextMenu={ hideNewContextMenu } />
      { showCreateModal &&
        <CreateVulnTagModal
          setShowModal={ setShowModal }
          setTags={ setTags }
          addedTags={ addedTags }
          notAddedTags={ notAddedTags }
          isLoading={ isLoading }
        />
      }
    </>
  );

  if (!actionBar) return content;
  return (
    <Wrapper
      id={ trigger }
      className={ className }
      children={ content }
    />
  );
};

TagSubMenu.propTypes = {
  actionBar: PropTypes.bool,
  addTag: PropTypes.func.isRequired,
  className: PropTypes.string,
  entitiesSelected: PropTypes.arrayOf(PropTypes.object),
  hideNewContextMenu: PropTypes.func,
  keyPrefix: PropTypes.string.isRequired,
  removeTag: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  trigger: PropTypes.string,
  showAddedTags: PropTypes.bool
};

TagSubMenu.defaultProps = {
  actionBar: false,
  addedTags: [],
  className: '',
  entitiesSelected: [],
  hideNewContextMenu: () => {},
  trigger: '',
  showAddedTags: true
};

export default TagSubMenu;
