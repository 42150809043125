import styled from 'styled-components';

export const StyledCircle = styled.div`
    height: 37px;
    width: 37px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    display: inline-block;
    padding: 6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

StyledCircle.displayName = 'StyledCircle';

export const Label = styled.div`
    color: ${(props) => props.color};
    font-size: 12.5px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
`;

Label.displayName = 'Label';
