import React from 'react';
import { useSelector } from 'react-redux';
import filter from 'lodash/filter';
import head from 'lodash/head';
import some from 'lodash/some';
import { useIntl } from 'react-intl';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import SearchList from 'Common/Components/SearchList';
import WorkspaceList from 'Common/Components/WorkspaceList';
import {
  SelectWsSection, TextInput, WrapperField, Label
} from './styled';

const WorkspacesSelector = ({ scheduleWorkspaces, setScheduleWorkspaces }) => {
  const intl = useIntl();
  const allWorkspaces = useSelector(selectActiveWorkspaces);
  const getWorkspaceList = () => allWorkspaces.map((ws) => ({ primary: ws.name, secondary: `${ws.stats.total_vulns}`, id: ws._id }));

  const scheduleWsFull = allWorkspaces.filter((ws) => scheduleWorkspaces.some((scheduleWs) => (scheduleWs === ws.name) || (scheduleWs.name === ws.name)));

  const onChangeScheduleWorkspaces = (items) => {
    setScheduleWorkspaces(items);
  };

  const onRemoveWs = (ws) => {
    const items = scheduleWsFull.filter((w) => w.id !== ws.id);
    onChangeScheduleWorkspaces(items);
  };

  const onSelectWs = (ws) => {
    if (!some(scheduleWorkspaces, { id: ws.id })) {
      const newItem = head(filter(allWorkspaces, { id: ws.id }));
      const items = [...scheduleWorkspaces, newItem];
      onChangeScheduleWorkspaces(items);
    }
  };

  return (
    <SelectWsSection>
      <TextInput>{ intl.formatMessage({ id: 'schedule.createEditSchedule.ws.title' }) }</TextInput>
      <WrapperField>
        <Label>{ intl.formatMessage({ id: 'schedule.createEditSchedule.ws.label' }) }</Label>
        <SearchList
          onSelect={ onSelectWs }
          items={ getWorkspaceList() }
          placeholder="Workspace Name*"
        />
        { scheduleWsFull.length > 0 && <WorkspaceList workspaces={ scheduleWsFull } onRemove={ onRemoveWs } /> }
      </WrapperField>
    </SelectWsSection>
  );
};

export default WorkspacesSelector;
