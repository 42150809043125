import {
  UPDATE_NOTIFICATIONS,
  NEW_NOTIFICATION
} from './actions';

const initialState = {
  unreadNotifications: [],
  count: 0
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    // Update Notifications
    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        unreadNotifications: action.data,
        count: action.count
      };
    case NEW_NOTIFICATION:
      return {
        ...state,
        unreadNotifications: [...state.unreadNotifications, action.data],
        count: action.count
      };
      // New Notification
    default: {
      return state;
    }
  }
};

export default notifications;
