import styled from 'styled-components';
import { FaTimes as CloseIcon } from 'react-icons/fa';

export const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
`;
TagsWrapper.displayName = 'TagsWrapper';

export const Tag = styled.div`
  display: flex;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 6%);
  border: solid 1px #cbcbcb;
  background-color: #ffffff;
  border-radius: 2px;
  opacity: 0.6;
  padding: 0 6px;
  height: 27px;
  line-height: 27px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
`;
Tag.displayName = 'Tag';

export const TagTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #1c2243;
  margin-right: 5px;
`;
TagTitle.displayName = 'TagTitle';

export const TagClose = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;
TagClose.displayName = 'TagClose';

export const Close = styled(CloseIcon)`
  color: #6a6a6a;
`;
Close.displayName = 'Close';
