import React from 'react';
import ExpandableWrapper from '../ExpandableWrapper';
import { useIntl } from 'react-intl';
import {
  Wrapper, RefTag, Text, Line
} from './styled';
import { useSelector } from 'react-redux';
import { selectVulnDetail } from 'store/Manage/selectors';
import useExpandable from 'Hooks/useExpandable';

const TAB = 'general';
const ID = 'risk';

const Risk = () => {
  const intl = useIntl();
  const currentVuln = useSelector(selectVulnDetail);
  const isExpanded = useExpandable(TAB, ID, ['dommy msj']); // si no tiene data no se muestra directamente (poreso el dummy msj)

  const vulnCvss = () => {
    if (!currentVuln.cvss3) return null;
    if (currentVuln.cvss3.base_score) return currentVuln.cvss3;
    else if (currentVuln.cvss2.base_score) return currentVuln.cvss2;
    else return null;
  };

  const cvss = vulnCvss();

  if (!cvss && !currentVuln.cwe[0] && !currentVuln.owasp[0]) return null; // si no tiene data no se renderiza

  const hasExploitable = currentVuln.tags.includes('exploit-available');
  const hasPatch = currentVuln.tags.includes('patch-available');

  return (
    <ExpandableWrapper id={ ID } title={ intl.formatMessage({ id: 'manage.detail.tab.general.risk' }) } defaultValue={ isExpanded }>
      <Wrapper>
        <table>
        {cvss && <Line>
            <Text big bold pb>Risk Score</Text>
            <Text big pb color={ cvss.base_severity }>{cvss.base_score} ({cvss.base_severity})</Text>
                 </Line>}
        {cvss && <Line>
            <Text bold>CVSS v3.0 Base Score</Text>
            <Text>{cvss.base_score}</Text>
                 </Line>}
        {cvss && <Line>
            <Text pb bold>CVSS v3.0 Vector</Text>
            <Text pb>{cvss.vector_string}</Text>
                 </Line>}
        {currentVuln.cwe[0] && <Line >
            <Text pb bold>CWE Weakness Type</Text>
            <Text pb ><a target="_blank" href={ `https://cwe.mitre.org/data/definitions/${currentVuln.cwe[0].split('-')[1]}.html` } rel="noopener noreferrer">{currentVuln.cwe[0]}</a></Text>
                               </Line>}
        {currentVuln.owasp[0] && <Line>
            <Text bold>OWASP Category</Text>
            <Text>{currentVuln.owasp[0] || 'No owasp'}</Text>
                                 </Line>}
        </table>
      {hasExploitable && <RefTag>Exploitable</RefTag>}
      {hasPatch && <RefTag green>Patch</RefTag>}
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Risk;
