import styled from 'styled-components';

const Wrapper = styled.div`
  display: inherit;
  &:hover {
    &::after {
      opacity: 1;
      content: '${(props) => props.text}';
      margin-left: 15px;
      padding: 10px;
      border-radius: 10px;
      background: #000;
      color: #fff;
      text-align: center;
      position: fixed;
      font-size: 12.5px;
      font-weight: 300;
    }
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
