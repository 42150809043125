/* eslint-disable import/prefer-default-export */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { setSamlField } from 'store/Settings/saml/actions';
import { selectUserRole, selectIsEnabled } from 'store/Settings/saml/selectors';
import StyledDropdown from './styled';

export const UserRole = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userRole = intl.formatMessage({ id: 'preferences.saml.userRole' });
  const enabled = useSelector(selectIsEnabled);
  const value = useSelector(selectUserRole);
  const roles = [
    { label: 'Administrator', value: 'admin' },
    { label: 'Pentester', value: 'pentester' },
    { label: 'Client', value: 'client' },
    { label: 'Asset Owner', value: 'asset_owner' }
  ];
  const selectedOption = roles.find((r) => r.value === value);
  const onChange = (v) => dispatch(setSamlField('userRole', v.value));

  return (
    <StyledDropdown
      value={ selectedOption }
      options={ roles }
      title={ userRole }
      onChange={ onChange }
      isClearable={ false }
      isDisabled={ !enabled }
      required
    />
  );
};
