import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useIntl } from 'react-intl';
import TagItems from './components/TagItems';
import AddTagButton from './components/AddTagButton';
import { Wrapper, Title, Subtitle } from './styled';
import { useDispatch } from 'react-redux';
import { getTags } from 'Screens/Tags/actions/Actions';

const Tags = ({ tags, setTags }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const hasTags = !isEmpty(tags);
  const hasTagsLabel = intl.formatMessage({ id: 'importFileCreationModal.tags.hasTagsSubtitle' });
  const noTagsLabel = intl.formatMessage({ id: 'importFileCreationModal.tags.noTagsSubtitle' });

  const addTag = (t) => setTags([...tags.filter((tag) => tag !== t), t]);
  const removeTag = (t) => setTags([...tags.filter((tag) => tag !== t)]);

  const replaceTags = (tagsToAdd, tagsToRemove) => {
    const addedTags = uniq([...tags, ...tagsToAdd]);
    const newTags = addedTags.filter((actualTag) => tagsToRemove.every((t) => actualTag !== t));
    setTags(newTags);
  };

  useEffect(() => {
    dispatch(getTags());
  }, [dispatch]);

  return (
    <Wrapper>
      <Title children={ intl.formatMessage({ id: 'importFileCreationModal.tags.title' }) } />
      <Subtitle children={ hasTags ? hasTagsLabel : noTagsLabel } />
      <TagItems tags={ tags } removeTag={ removeTag } />
      <AddTagButton tags={ tags } addTag={ addTag } removeTag={ removeTag } setTags={ replaceTags } />
    </Wrapper>
  );
};

export default Tags;
