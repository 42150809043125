import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Wrapper from 'Common/Components/ReduxModal/styled';
import { Accept } from 'Common/Components/Button/styled';
import { selectErrorMessage } from 'store/Projects/selectors';
import { clearError } from 'store/Projects/actions';
import {
  ModalWrapper, Title, Message, ButtonsWrapper
} from './styled';

const ErrorModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const title = intl.formatMessage({ id: 'error' });
  const errorMessage = useSelector(selectErrorMessage);
  const done = intl.formatMessage({ id: 'done' });

  const onDone = () => dispatch(clearError());

  return (
    <Wrapper>
      <ModalWrapper>
        <Title>{ title }</Title>
        <Message>{ errorMessage }</Message>
        <ButtonsWrapper>
          <Accept onClick={ onDone }>{ done }</Accept>
        </ButtonsWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default ErrorModal;
