/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withBreakpoints } from 'react-breakpoints';
import { connect } from 'react-redux';
import ActionBar from 'Common/Components/ActionBar';
import { selectKnowledgeBaseProps } from 'store/KnowledgeBase/selectors';
import { resetFilters, setSelectedEntity } from 'store/Filters/actions';
import { getSelectedEntity } from 'store/Filters/selectors';
import {
  deleteVulnTemplateSelected, hideVulnTemplateModalDelete,
  showVulnTemplateModalDelete, setVisibilityCreateModal,
  showVulnTemplateModalEdit, setPage, getVulnsTemplateCount
} from 'Screens/KnowledgeBase/actions/Actions';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { FEATURE_VULN_TEMPLATE, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import IconButton from 'Common/Components/IconButton';
import { ACTIONS } from 'tracking/GA/constants';
import get from 'lodash/get';
import {
  Edit, Trash, TableWrapper, Wrapper
} from './styled';
import Table from './Table';
import CreateActions from './CreateActions';

class KnowledgeBase extends Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.actionBar = React.createRef();
    this.getTableContainerHeight = this.getTableContainerHeight.bind(this);
  }

  componentDidMount () {
    const { selectedEntity, clearSelectedEntity, getVulnsTemplateCount } = this.props;
    if (selectedEntity !== 'knowledgeBase') clearSelectedEntity();
    getVulnsTemplateCount();
  }

  componentWillUnmount () {
    const { resetFilters } = this.props;
    resetFilters();
  }

  getTableContainerHeight () {
    const actionBarHeight = get(this.actionBar, 'current.clientHeight', 34);
    const tableContainerTopMargin = 30;
    return `calc(100% - ${actionBarHeight + tableContainerTopMargin}px)`;
  }

  render () {
    const {
      showDeleteConfirmation,
      templatesSelected,
      readonly,
      showModalCreate,
      totalVulnTemplate,
      rowsPerPage,
      page
    } = this.props;
    let message = '';

    if (showDeleteConfirmation) {
      if (templatesSelected.length === 1) { message = 'A template will be deleted. This operation cannot be undone. Are you sure you want to proceed?'; } else { message = `${templatesSelected.length} templates will be deleted. This operation cannot be undone. Are you sure you want to proceed?`; }
    }

    const showButton = (!readonly && templatesSelected.length > 0);
    const createButton = (
      <WithPermission permission={ FEATURE_VULN_TEMPLATE } type={ PERMISSION_TYPES.CREATE }>
        <CreateActions setVisibilityCreateModal={ this.props.setVisibilityCreateModal } showModalCreate={ showModalCreate } />
      </WithPermission>
    );

    const deleteButton = showButton
      ? (
      <WithPermission permission={ FEATURE_VULN_TEMPLATE } type={ PERMISSION_TYPES.DELETE }>
        <IconButton icon={ <Trash /> } title="Delete" onClick={ this.props.showVulnTemplateModalDelete } />
      </WithPermission>
        )
      : null;

    const editButton = showButton
      ? (
      <WithPermission permission={ FEATURE_VULN_TEMPLATE } type={ PERMISSION_TYPES.UPDATE }>
        <IconButton icon={ <Edit /> } title="Edit" onClick={ this.props.showVulnTemplateModalEdit } />
      </WithPermission>
        )
      : null;

    return (
      <Wrapper>
        <div ref={ this.actionBar }>
          <ActionBar
            showDeleteConfirmation={ showDeleteConfirmation }
            messageDeleteConfirmation={ message }
            handleSubmitDelete={ this.props.deleteVulnTemplateSelected }
            handleCloseDelete={ this.props.hideVulnTemplateModalDelete }
            workspaceSelected={ templatesSelected }
            createActions={ createButton }
            deleteButton={ deleteButton }
            editButton={ editButton }
            moreOptionsButton={ null }
            routeList={ this.props.routeList }
            entity="template"
            count={ templatesSelected.length }
            totalRows={ totalVulnTemplate }
            page={ page }
            rowsPerPage={ rowsPerPage }
            onPrev={ () => this.props.setPage(page - 1) }
            onNext={ () => this.props.setPage(page + 1) }
          />
        </div>
        <TableWrapper height={ this.getTableContainerHeight() }>
          <Table />
        </TableWrapper>
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showKnowledgeBase } trackingClick={ ACTIONS.clickKnowledgeBase } />
        </ReduxModal>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  ...selectKnowledgeBaseProps(state),
  selectedEntity: getSelectedEntity(state)
});

const mapDispatchToProps = (dispatch) => ({
  deleteVulnTemplateSelected: () => {
    dispatch(deleteVulnTemplateSelected());
  },
  hideVulnTemplateModalDelete: () => {
    dispatch(hideVulnTemplateModalDelete());
  },
  showVulnTemplateModalDelete: () => {
    dispatch(showVulnTemplateModalDelete());
  },
  setVisibilityCreateModal: (value) => {
    dispatch(setVisibilityCreateModal(value));
  },
  showVulnTemplateModalEdit: () => {
    dispatch(showVulnTemplateModalEdit());
  },
  setPage: (page) => {
    dispatch(setPage(page));
  },
  resetFilters: () => {
    dispatch(resetFilters('knowledgeBase'));
  },
  clearSelectedEntity: () => {
    dispatch(setSelectedEntity(''));
  },
  getVulnsTemplateCount: () => {
    dispatch(getVulnsTemplateCount());
  }
});

KnowledgeBase.propTypes = {
  deleteVulnTemplateSelected: PropTypes.func.isRequired,
  hideVulnTemplateModalDelete: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  readonly: PropTypes.bool.isRequired,
  routeList: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setVisibilityCreateModal: PropTypes.func.isRequired,
  showDeleteConfirmation: PropTypes.bool.isRequired,
  showModalCreate: PropTypes.bool.isRequired,
  showVulnTemplateModalDelete: PropTypes.func.isRequired,
  showVulnTemplateModalEdit: PropTypes.func.isRequired,
  templatesSelected: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalVulnTemplate: PropTypes.number.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withBreakpoints(KnowledgeBase));
