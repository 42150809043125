import React from 'react';
import PropTypes from 'prop-types';
import DateFormatter from 'Common/Components/DateFormatter';
import { FiTrash2 as Remove, FiEdit3 as Edit /* , FiMessageSquare as MessageIcon */ } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown';
import { FEATURE_COMMENTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import {
  CommentsItem,
  CommentsAuthor,
  CommentsDate,
  CommentsContent,
  CommentRemove,
  CommentEdit,
  CommentContainer,
  ButtonsContainer
} from './styled';

const parseNewLines = (text) => text.replace(/\n/gi, '\n &nbsp;');

const UserComment = ({
  comment, isCreator, onRemove, onEdit
}) => {
  const props = useSelector((state) => ({
    canEdit: selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.UPDATE),
    canDelete: selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.DELETE)
  }));

  return (
    <CommentsItem>
      <CommentContainer>
        <CommentsDate><DateFormatter date={ comment.create_date } /></CommentsDate>
        <CommentsAuthor>{`${comment.creator}: `}</CommentsAuthor>
        <CommentsContent><ReactMarkdown>{ parseNewLines(comment.text) }</ReactMarkdown></CommentsContent>
      </CommentContainer>
      {isCreator &&
    (
    <ButtonsContainer>
      {props.canDelete && (
      <CommentRemove onClick={ () => onRemove(comment) }>
        <Remove color="#bec8d2" size="1.2em" />
      </CommentRemove>
      )}
      {props.canEdit && (
      <CommentEdit onClick={ () => onEdit(comment) }>
        <Edit color="#bec8d2" size="1.2em" />
      </CommentEdit>
      )}
    </ButtonsContainer>
    )}
    </CommentsItem>
  );
};

UserComment.propTypes = {
  comment: PropTypes.shape({
    create_date: PropTypes.string.isRequired,
    creator: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  isCreator: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default UserComment;
