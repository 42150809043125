/* eslint-disable react/no-array-index-key */
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import React, { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteJob, copyJob, viewJob } from 'store/Jobs/actions';
import { selectJobId, selectJobsFiltered } from 'store/Jobs/selectors';
import ConfirmNavigationModal from 'Common/Components/ConfirmNavigationModal';
import selectModal from 'store/modals/selectors';
import { openModal, closeModal } from 'store/modals/actions';
import { MODAL_JOBS_DELETE } from 'store/modals/modals';
import { redirect } from 'store/Router/actions';
import ActionBar from '../ActionBar';
import DeleteMessage from './DeleteMessage';
import {
  Wrapper, WrapperItem, Title, Description, Id
} from './styled';

const Job = memo(({
  name, description, id, handleJobSelected, selected, handleDeleteJob, handleCopyJob
}) => {
  const [showActionBar, toggleActionBar] = useState();
  const handleOnMouseEnter = () => toggleActionBar(true);
  const handleOnMouseLeave = () => toggleActionBar(false);

  return (
    <WrapperItem selected={ selected } onMouseEnter={ handleOnMouseEnter } onMouseLeave={ handleOnMouseLeave }>
      <Title onClick={ handleJobSelected }>{ name }</Title>
      <Description>{ description }</Description>
      <Id>{ id }</Id>
      { showActionBar && <ActionBar handleCopyJob={ handleCopyJob } handleDeleteJob={ handleDeleteJob } handleJobSelected={ handleJobSelected } /> }
    </WrapperItem>
  );
});

Job.displayName = 'Job';

Job.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  handleJobSelected: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  handleDeleteJob: PropTypes.func.isRequired,
  handleCopyJob: PropTypes.func.isRequired
};

const Items = () => {
  const jobs = useSelector(selectJobsFiltered);
  const jobId = useSelector(selectJobId);
  const [jobToDelete, setJobToDelete] = useState();
  const dispatch = useDispatch();
  const showModalWarning = useSelector((state) => selectModal(state, MODAL_JOBS_DELETE));

  const intl = useIntl();
  const pipelinesCount = jobToDelete ? jobToDelete.pipelines.length : 0;
  const pipelinesTextLabel = pipelinesCount > 1 ? 'pipelines' : 'pipeline';
  const jobName = jobToDelete && `"${jobToDelete.name}"`;

  const title = intl.formatMessage({ id: 'jobs.delete.title' }, { jobName });
  const closeLabel = intl.formatMessage({ id: 'jobs.delete.close' });
  const acceptLabel = pipelinesCount ? intl.formatMessage({ id: 'jobs.delete.accept.withPipelines' }, { pipelinesCount, pipelinesTextLabel }) : intl.formatMessage({ id: 'jobs.delete.accept.withoutPipelines' });

  const handleJobSelected = (job) => {
    dispatch(viewJob(job));
    dispatch(redirect(`/automation/jobs/${job.id}`));
  };

  const handleDeleteJob = (e, job) => {
    e.stopPropagation();
    setJobToDelete(job);
    dispatch(openModal(MODAL_JOBS_DELETE));
  };

  const handleCopyJob = (e, job) => {
    e.stopPropagation();
    dispatch(copyJob(job));
  };

  const handleCloseConfirmation = () => {
    setJobToDelete(null);
    dispatch(closeModal(MODAL_JOBS_DELETE));
  };

  const handleSubmitConfirmation = () => {
    setJobToDelete(null);
    dispatch(closeModal(MODAL_JOBS_DELETE));
    dispatch(deleteJob(jobToDelete));
  };

  useEffect(() => () => {
    dispatch(closeModal(MODAL_JOBS_DELETE));
  }, [dispatch]);

  return (jobs.length > 0
    ? (
    <Wrapper>
      { jobs.map((job) => <Job key={ job.id } handleCopyJob={ (e) => handleCopyJob(e, job) } handleDeleteJob={ (e) => handleDeleteJob(e, job) } handleJobSelected={ () => handleJobSelected(job) } name={ job.name } description={ job.description } id={ job.id } selected={ isEqual(jobId, job.id) } />) }
      { showModalWarning && (
        <ConfirmNavigationModal
          onDone={ handleSubmitConfirmation }
          onCancel={ handleCloseConfirmation }
          title={ title }
          cancel={ closeLabel }
          done={ acceptLabel }
          message={ <DeleteMessage pipelinesCount={ pipelinesCount } /> }
        />
      )}
    </Wrapper>
      )
    : null);
};
export default Items;
