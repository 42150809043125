import React from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { resetState } from 'store/Analytics/actions';
import { redirect } from 'store/Router/actions';
import { TitleWrapper, Title, Subtitle } from 'Screens/Analytics/components/ActionBar/styled';
import { Wrapper, CancelButton } from './styled';

const Cancel = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const cancelLabel = intl.formatMessage({ id: 'analytics.createEdit.cancel' });

  const onClick = () => {
    dispatch(resetState());
    dispatch(redirect('/analytics'));
  };
  return (
    <CancelButton onClick={ onClick }>{ cancelLabel }</CancelButton>
  );
};

const ActionBar = () => (
  <Wrapper>
    <TitleWrapper>
      <Title>Analytics</Title>
      <Subtitle>- Create new graph</Subtitle>
    </TitleWrapper>
    <Cancel />
  </Wrapper>
);

export default ActionBar;
