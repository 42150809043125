import React from 'react';
import { useDispatch } from 'react-redux';
import AddButton from 'Common/Components/AddButton';
import { WithPermission } from 'Common/Components/WithPermission';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { setVisibilityCreateModal } from 'Screens/Workspaces/actions/Actions';

// eslint-disable-next-line import/prefer-default-export
export const WsAddButton = () => {
  const dispatch = useDispatch();
  return (
    <WithPermission permission={ FEATURE_WORKSPACES } type={ PERMISSION_TYPES.CREATE }>
      <AddButton text="Add Workspace" onClick={ () => dispatch(setVisibilityCreateModal(true)) } />
    </WithPermission>
  );
};
