import get from 'lodash/get';
import filter from 'lodash/filter';
import api from 'services/api';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';

import {
  SCHEDULE_FETCHING, SCHEDULE_TABLE_GET_DATA, SCHEDULE_TABLE_SELECT_ROW, SCHEDULE_FAIL, SCHEDULE_TABLE_PAGING_PREVIOUS, SCHEDULE_TABLE_PAGING_NEXT, SCHEDULE_TABLE_SORT_DATA, SCHEDULE_TABLE_RESET, GET_TOTAL_SCHEDULES,
  RUN_SCHEDULE
} from './constants';
import { selectIsFetching } from './selectors';

export function fetching () {
  return async (dispatch, getState) => {
    const isFetching = selectIsFetching(getState());
    if (!isFetching) dispatch({ type: SCHEDULE_FETCHING, isFetching: true });
  };
}

export function somethingWentWrong () {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_FAIL, message: 'There was an error, please try again.' });
  };
}

export function selectScheduleRow (rowSelectedInfo) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_SELECT_ROW, rowSelectedInfo });
  };
}

export function resetTable () {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_RESET });
  };
}

export function getTotalSchedules () {
  return async (dispatch) => {
    const schedules = await api.schedule.fetchTotalSchedules(); // obtengo el total de assets.
    const totalSchedules = filter(schedules.rows, (s) => s.value.active);
    dispatch({ type: GET_TOTAL_SCHEDULES, totalSchedules: get(totalSchedules, 'length', 0) });
  };
}

export function getSchedules () {
  return async (dispatch, getState) => {
    try {
      dispatch(fetching());
      const {
        page, pageSize, sortBy, sortDirection
      } = getState().schedule.table.list;
      const response = await api.schedule.fetchSchedules(page, pageSize, sortBy, sortDirection);
      dispatch(getTotalSchedules()); // calcula el total de schedules
      dispatch({ type: SCHEDULE_TABLE_GET_DATA, data: response });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function nextPage () {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_PAGING_NEXT });
    dispatch(getSchedules());
  };
}

export function previousPage () {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_PAGING_PREVIOUS });
    dispatch(getSchedules());
  };
}

export function sortBy (field, direction) {
  return async (dispatch) => {
    dispatch({ type: SCHEDULE_TABLE_SORT_DATA, field, direction });
    dispatch(getSchedules());
  };
}

export function removeSchedule () {
  return async (dispatch, getState) => {
    try {
      dispatch(fetching());
      const { rowsSelected } = getState().schedule.table.selected;

      await Promise.all(rowsSelected.map(async (row) => {
        await api.schedule.removeSchedule(row.id);
        dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.deleteSchedule.name, ACTIONS.deleteSchedule.label, row.id));
      }));

      dispatch(getSchedules());
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function saveSchedule (scheduleId, agentId, crontabName, active, crontab, description, executorId, parameters, workspaces) {
  return async (dispatch) => {
    try {
      dispatch(resetTable());

      dispatch(fetching());
      if (scheduleId === 0) {
        const response = await api.schedule.createSchedule(agentId, crontabName, active, crontab, description, executorId, parameters, workspaces);
        dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.createSchedule.name, ACTIONS.createSchedule.label, response.id));
        dispatch(getTotalSchedules());
      } else {
        await api.schedule.updateSchedule(scheduleId, agentId, crontabName, active, crontab, description, executorId, parameters, workspaces);
        dispatch(trackEvent(CATEGORIES.schedule, ACTIONS.editSchedule.name, ACTIONS.editSchedule.label, scheduleId));
      }
      dispatch(getSchedules());
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function changeStatus (scheduleId, status) {
  return async (dispatch) => {
    try {
      // if (status && selectLimitReached(getState())) { comento esto por ahora porque no deberia validar el limite cuando se activa/desactiva
      // dispatch(openModal(MODAL_UPGRADE_LICENSE));
      // } else {
      dispatch(fetching());
      await api.schedule.changeStatus(scheduleId, status);

      dispatch(getTotalSchedules());
      dispatch(getSchedules());
      // }
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function runSchedule (id) {
  return async (dispatch) => {
    try {
      await api.schedule.runSchedule(id);
      dispatch({ type: RUN_SCHEDULE, id });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}
