import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleModalEditCreate
} from 'store/Host/actions';
import { FEATURE_HOSTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { Wrapper, HostIp, Edit } from './styled';

const Ip = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => ({
    host: state.hostDetail.host,
    canEdit: selectAllowedByRole(state, FEATURE_HOSTS, PERMISSION_TYPES.UPDATE)
  }));

  return (
    <Wrapper>
      <HostIp>{props.host.ip}</HostIp>
      {props.canEdit && <Edit onClick={ () => dispatch(toggleModalEditCreate(true, props.host)) } />}
    </Wrapper>
  );
};

Ip.propTypes = {
  host: PropTypes.instanceOf(Object).isRequired
};

export default Ip;
