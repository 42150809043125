import get from 'lodash/get';

export const selectPermissions = (state) => get(state, 'faraday.permissions', {});
export const selectWorkspaces = (state) => get(state, 'faraday.workspaces', []);
export const selectWorkspaceById = (id, state) => {
  const workspaces = selectWorkspaces(state);
  const wsId = typeof id === 'string' ? Number(id) : id;
  return workspaces.find((ws) => ws._id === wsId);
};
export const selectWorkspaceSelected = (state) => get(state, 'faraday.workspaceSelected', {});
export const selectCurrentWorkspaceID = (state) => {
  const workspaces = selectWorkspaces(state);
  const currentWsName = selectWorkspaceSelected(state);
  const currentWs = workspaces.find((ws) => ws.name === currentWsName);
  return currentWs ? currentWs.id : null;
};
export const selectIsReadOnly = (state) => get(state, 'faraday.readonly', true);
export const selectCurrentWorkspace = (state) => get(state, 'faraday.workspaceSelected', '');

export const selectIsTourFinished = (state) => get(state, 'faraday.preferences.tourFinished', true);
export const selectShowWorkspaceValidator = (state) => get(state, 'faraday.showWorkspaceValidator', false);
export const selectEmail = (state) => get(state, 'faraday.email', '');
export const selectUserRole = (state) => get(state, 'faraday.role', '');
export const selectNotificationsEnabled = (state) => get(state, 'faraday.notificationsEnabled', false);
export const selectUserName = (state) => get(state, 'faraday.userName', '');
export const selectCurrentUserId = (state) => get(state, 'faraday.userId', '');
export const selectUserList = (state) => get(state, 'users.userList', []);
export const selectIsAdmin = (state) => get(state, 'faraday.role', '') === 'admin';
export const selectUrlReturn = (state) => get(state, 'faraday.urlReturn', ''); // url que determina a donde ir luego de loguearse.
export const selectShowUserFeedback = (state) => get(state, 'faraday.showUserFeedback', false);
export const selectWsPort = (state) => get(state, 'faraday.wsPort', 0);
