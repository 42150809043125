import React from 'react';
import { useSelector } from 'react-redux';
import {
  Wrapper, Group, LeftGroup, RightGroup, Separator
} from './styled';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import isEmpty from 'lodash/isEmpty';
import { WsAddButton, WsEditButton, WsDeleteButton, MoreOptionsButton } from './components/LeftGroup';
import { ShowInactive, GridViewButton, ListViewButton } from './components/RightGroup';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';

const WSActionBar = () => {
  const selectedWs = useSelector(selectWorkspacesSelected);
  const hasSelectedWs = !isEmpty(selectedWs);

  return (
    <Wrapper>
      <DeleteConfirmationModal />
      <LeftGroup>
        <WsAddButton />
        { hasSelectedWs && (
          <>
            <Separator />
            <Group>
              <WsEditButton />
              <WsDeleteButton />
              <MoreOptionsButton />
            </Group>
          </>
        )}
      </LeftGroup>
      <RightGroup>
        <ShowInactive />
        <GridViewButton />
        <ListViewButton />
      </RightGroup>
    </Wrapper>
  );
};

export default WSActionBar;
