import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectCustomAttributes } from 'store/CustomAttributes/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import CustomField from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/components/CustomField/index';
import { Wrapper, NoFieldsText } from './styled';
import ToggleButtons from 'Screens/Manage/components/ToggleButtons';
import ExpandableWrapper from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/GeneralTab/components/ExpandableWrapper';
import getCustomFieldValueFromVuln from 'Screens/Manage/components/VulnerabilityDetail/components/Tabs/components/CustomFieldsTab/functions';
import { selectVulnDetail } from 'store/Manage/selectors';
import { selectField } from 'store/ManageEditCreate/selectors';
import useExpandable from 'Hooks/useExpandable';

const ID = 'customAttributes';

const CustomFieldWrapper = ({ field }) => {
  const vuln = useSelector(selectVulnDetail);
  const editValue = useSelector((state) => selectField(state, field));
  const initialValue = vuln ? getCustomFieldValueFromVuln(vuln, field) : editValue;
  const isExpanded = useExpandable(ID, field.field_name, initialValue);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } key={ `expandible_custom_${field.id}` } id={ field.field_name } title={ field.field_display_name }>
      <CustomField field={ field } key={ `Custom_Field_${field.id}` } />
    </ExpandableWrapper>
  );
};

const CustomFieldsTab = () => {
  const intl = useIntl();
  const customFields = useSelector(selectCustomAttributes);
  const hasCustomFields = customFields.length > 0;
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));

  return (
    <Wrapper canUpdate={ canUpdate }>
      <ToggleButtons id={ ID } />
      {customFields.map((field) => (
        <CustomFieldWrapper key={ `custom_field_wrapper_${field.id}` } field={ field } />
      ))}
      {!hasCustomFields && <NoFieldsText>{intl.formatMessage({ id: 'customAttributes.empty' })}</NoFieldsText>}
    </Wrapper>
  );
};

export default CustomFieldsTab;
