/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnDetail, selectVulnDetailDuplicates } from 'store/Manage/selectors';
import { useIntl } from 'react-intl';
import { getDuplicates } from 'store/Manage/duplicateActions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_DUPLICATES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { Wrapper, ListWrapper, Subtitle } from './styled';
import DuplicateItem from './DuplicateItem';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';

const ID = 'general';

const Duplicates = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { id, name } = useSelector(selectVulnDetail);
  const duplicates = useSelector(selectVulnDetailDuplicates);
  const canUseDuplicates = useSelector((state) => selectAllowedByFeature(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ) && selectAllowedByRole(state, FEATURE_DUPLICATES, PERMISSION_TYPES.READ));
  const isExpanded = useExpandable(ID, 'duplicates', duplicates);

  useEffect(() => {
    if (canUseDuplicates) dispatch(getDuplicates(id));
  }, [dispatch, canUseDuplicates, id, name]);

  if (duplicates.length === 0) return null;
  return canUseDuplicates && (
    <ExpandableWrapper
      title={ intl.formatMessage({ id: 'vuln.detail.duplicates.title' }) }
      count={ duplicates.length }
      defaultValue={ isExpanded }
    >
      <Wrapper>
        <Subtitle children={ intl.formatMessage({ id: 'vuln.detail.duplicates.subtitle' }) } />
        <ListWrapper>
          { duplicates.map((vuln) => <DuplicateItem key={ vuln.id } vuln={ vuln } isDetailVuln={ vuln.id === id } />)}
        </ListWrapper>
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Duplicates;
