import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { selectJobsList, selectId } from 'store/Pipelines/selectors';
import { setJob, getJobs, setRedirectTo } from 'store/Pipelines/actions';
import TinyCircle from 'Common/Components/TinyCircle';
import StandardDropdown from 'Common/Components/StandarDropdown';
import BlueButton from 'Common/Components/BlueButton';
import colors from 'Styles/colors';
import { newJob } from 'store/Jobs/actions';
import Step from '../../Step';
import {
  Wrapper, StepContainer, ActionsWrapper, Text, dropdownStyle
} from './styled';

const NewJob = () => {
  const dispatch = useDispatch();
  const jobs = useSelector((state) => selectJobsList(state));
  const pipelineId = useSelector(selectId);
  const smallCircle = <TinyCircle color={ colors.blueCerulean } />;
  const intl = useIntl();

  useEffect(() => {
    dispatch(getJobs());
  }, [dispatch]);

  const createNewJob = () => {
    if (pipelineId === 0) dispatch(setRedirectTo('/automation/pipelines/new'));
    else dispatch(setRedirectTo(`/automation/pipelines/${pipelineId}`));
    dispatch(newJob());
  };

  return (
    <Wrapper>
      <StepContainer>
        <Step icon={ smallCircle } select />
      </StepContainer>
      <ActionsWrapper>
        <StandardDropdown customJobs={ dropdownStyle } field="jobs" placeholder="Select Job" options={ isEmpty(jobs) ? [] : jobs.map((el) => ({ name: el.name, desc: el.name })) } updateValue={ (field, value) => dispatch(setJob(value)) } />
        <Text>{ intl.formatMessage({ id: 'pipelines.detail.jobSequence.newJob.text' }) }</Text>
        <BlueButton className="create-button" onClick={ () => createNewJob() } label={ intl.formatMessage({ id: 'pipelines.detail.jobSequence.newJob.createButton' }) } />
      </ActionsWrapper>
    </Wrapper>
  );
};

export default NewJob;
