import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;
Wrapper.displayName = 'Wrapper';

export const Separator = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  flex: 0 0 2px;
  width: 100%;
  border: solid 1px #d8e0e7;
`;
Separator.displayName = 'Separator';
