import {
  PERMISSION_TYPES, FEATURE_USERS, FEATURE_VULN_TEMPLATE, FEATURE_WORKSPACES
} from 'Hooks/usePermission/permissions';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { selectMaxWorkspacesLimit } from 'store/Config/selector';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';

import { selectCurrentWorkspace } from 'store/Faraday/selectors';

const selectRoutes = (state) => {
  const routeList = [];

  const canReadWorkspaces = selectAllowedByRole(state, FEATURE_WORKSPACES, PERMISSION_TYPES.READ);
  const canReadUsers = selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.READ);
  const canReadTemplates = selectAllowedByRole(state, FEATURE_VULN_TEMPLATE, PERMISSION_TYPES.READ);
  if (canReadWorkspaces) routeList.push({ name: '/workspaces', desc: 'Workspaces' });
  if (canReadUsers) routeList.push({ name: '/users', desc: 'Users' });
  if (canReadTemplates) routeList.push({ name: '/knowledge_base', desc: 'Knowledge Base' });
  return routeList;
};
export default selectRoutes;

export const selectActiveWorkspaces = (state) => {
  const workspaces = get(state, 'faraday.workspaces');
  return filter(workspaces, { active: true });
};

export const selectTotalVulns = (state) => {
  const currentWorkspace = selectCurrentWorkspace(state);
  const activeWorkspaces = selectActiveWorkspaces(state);
  const ws = find(activeWorkspaces, { name: currentWorkspace });
  return get(ws, 'stats.total_vulns', 0);
};

export const selectLimitReached = (state) => {
  const activeWorskpaces = selectActiveWorkspaces(state);
  return (get(activeWorskpaces, 'length', 0) >= selectMaxWorkspacesLimit(state));
};

export const getCurrentWorkspace = (state) => get(state, 'faraday.workspaceSelected', '');
export const selectWorkspacesSelected = (state) => get(state, 'workspace.workspacesSelected', []);
export const selectCurrentlySelectedWorkspace = (state) => get(state, 'workspace.workspaceSelected', []);
export const selectWorkspacesCount = (state) => get(state, 'workspace.workspaceCount', 0);
export const selectLastWorkspaceSelected = (state) => get(state, 'workspace.lastWorkspaceSelected', {});
export const selectLastIndexForRange = (state) => get(state, 'workspace.lastIndexForRange', -1);
export const selectLastSelected = (state) => get(state, 'workspace.lastSelected', -1);
export const selectShowGridView = (state) => get(state, 'workspace.showGridView', true);
export const selectWorkspaceList = (state) => get(state, 'workspace.workspaceList', []);
export const selectRedirect = (state) => get(state, 'workspace.redirect', false);
export const selectShowDeleteConfirmation = (state) => get(state, 'workspace.showDeleteConfirmation', false);
export const selectMoreOptionsOpened = (state) => get(state, 'workspace.moreOptionsOpened', false);
export const selectIsFetching = (state) => get(state, 'workspace.isFetching', false);
export const selectShowInactive = (state) => get(state, 'workspace.showInactive', false);
export const selectShowModalAssignUsers = (state) => get(state, 'workspace.showModalAssignUsers', false);
export const selectWorkspaces = (state) => get(state, 'workspace.workspaces', []);
