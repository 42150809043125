/* eslint-disable import/prefer-default-export */
import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { saveTemplateFromVuln, showContextMenu } from 'store/Manage/actions';
import { useIntl } from 'react-intl';
import { selectUserRole } from 'store/Faraday/selectors';
import { SaveTemplateIcon } from './styled';

export const SaveAsTemplate = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);

  if (role === 'client' || role === 'asset_owner') return null;

  return (
    <MenuItem
      title={ intl.formatMessage({ id: 'manage.cm.saveAsTemplate' }) }
      icon={ <SaveTemplateIcon /> }
      onClick={ () => {
        dispatch(saveTemplateFromVuln());
        dispatch(showContextMenu(false));
      } }
    />
  );
};
