import React, { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setLdapField } from 'store/Settings/ldap/actions';
import {
  CustomInput, InputTitle, Wrapper
} from './styled';

const Input = ({
  inputValue, field, type, isRequired, disabled, tooltip
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(inputValue);
  const intl = useIntl();
  const title = intl.formatMessage({ id: `preferences.ldap.${field}` });
  const formattedTitle = isRequired ? `${title}*` : title;
  const placeholder = intl.formatMessage({ id: `preferences.ldap.${field}.placeholder` });

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  const onChangeDebounce = debounce((value) => {
    dispatch(setLdapField(field, value));
  }, 300);

  const onChangeCallBack = useCallback((value) => onChangeDebounce(value), [onChangeDebounce]);

  const onChange = (e) => {
    setValue(e.target.value);
    onChangeCallBack(e.target.value);
  };

  return (
    <Wrapper>
      <InputTitle disabled={ disabled }>
        { formattedTitle }
      </InputTitle>
      <CustomInput
        placeholder={ placeholder }
        onChange={ onChange }
        value={ value }
        type={ type }
        disabled={ disabled }
        title={ tooltip }
        isEmpty={ !value }
      />
    </Wrapper>
  );
};

Input.propTypes = {
  inputValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  field: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string
};

Input.defaultProps = {
  tooltip: '',
  disabled: false
};

export default Input;
