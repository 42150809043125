import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as confirmedIcon } from 'Images/confirmed.svg';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const Blue = styled.div`
  color: ${colors.blueCerulean};
  display: inline-block;
  margin: 0px 5px;
`;
Blue.displayName = 'Blue';

export const ConfirmedIcon = styled(confirmedIcon)`
  width: 20px;
  height: 12px;
  margin: 0 5px 0 0px;
`;
ConfirmedIcon.displayName = 'ConfirmedIcon';
