import {
  PIPELINES_FETCHING, PIPELINES_FAIL, PIPELINES_TABLE_GET_DATA,
  FILTER_PIPELINES, PIPELINE_SELECTED, PIPELINE_ENABLE_DISABLE,
  PIPELINE_UPDATE_FAIL, RUN_PIPELINE, RUN_PIPELINE_FAIL, SET_PIPELINE_INPUT_FIELD, PIPELINE_CREATE_UPDATE_START,
  PIPELINE_CREATE_UPDATE_SUCCESS, RESET_STATE_PIPELINE_CREATE_UPDATE, SET_JOB,
  DELETE_JOB_FROM_PIPELINE, SET_JOBS_NEW_ORDER, SET_PIPELINE_REDIRECT_TO, SET_TARGET, GET_JOBS, OPEN_NEW_PIPELINE,
  PIPELINES_RESET_ERROR
} from './constants';

export const initialState = {
  isFetching: false,
  error: false,
  success: false,
  errorMessage: '',
  pipelinesList: [],
  searchQuery: [],
  pipelineSelected: {
    id: 0,
    name: '',
    description: '',
    jobs: [],
    workspace_id: undefined,
    enabled: false
  },
  jobsList: [],
  isRunning: false,
  isCreating: false,
  isEditing: false,
  redirectTo: '',
  redirectFromPipelines: false
};

function pipelines (state = initialState, action) {
  switch (action.type) {
    case PIPELINES_FETCHING: {
      return {
        ...state,
        isFetching: true
      };
    }
    case PIPELINES_TABLE_GET_DATA: {
      return {
        ...state,
        pipelinesList: action.data.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
      };
    }
    case PIPELINES_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage
      };
    }
    case FILTER_PIPELINES: {
      return {
        ...state,
        searchQuery: action.query
      };
    }
    case PIPELINE_SELECTED: {
      return {
        ...state,
        pipelineSelected: action.pipeline,
        isCreating: action.isCreating,
        isEditing: action.isEditing
      };
    }
    case PIPELINE_ENABLE_DISABLE: {
      return {
        ...state
      };
    }
    case PIPELINE_UPDATE_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }
    case RUN_PIPELINE: {
      return {
        ...state,
        isRunning: action.isRunning,
        id: action.id
      };
    }
    case RUN_PIPELINE_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }
    case SET_PIPELINE_INPUT_FIELD: {
      return {
        ...state,
        pipelineSelected: {
          ...state.pipelineSelected,
          [action.label]: action.value
        }

      };
    }
    case SET_JOB: {
      const newJobs = [...state.pipelineSelected.jobs];
      newJobs.push(action.value);

      return {
        ...state,
        pipelineSelected: {
          ...state.pipelineSelected,
          jobs: newJobs
        }
      };
    }
    case PIPELINE_CREATE_UPDATE_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case PIPELINE_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false
      };
    }
    case RESET_STATE_PIPELINE_CREATE_UPDATE: {
      return {
        ...state,
        pipelineSelected: {
          ...initialState.pipelineSelected
        },
        isCreating: action.isCreating
      };
    }
    case DELETE_JOB_FROM_PIPELINE: {
      return {
        ...state,
        pipelineSelected: {
          ...state.pipelineSelected,
          jobs: action.value
        }
      };
    }
    case SET_JOBS_NEW_ORDER: {
      return {
        ...state,
        pipelineSelected: {
          ...state.pipelineSelected,
          jobs: action.jobs
        }
      };
    }
    case SET_TARGET: {
      return {
        ...state,
        pipelineSelected: {
          ...state.pipelineSelected,
          workspace_id: action.value
        }
      };
    }
    case SET_PIPELINE_REDIRECT_TO: {
      return {
        ...state,
        redirectTo: action.redirectTo
      };
    }
    case GET_JOBS: {
      return {
        ...state,
        jobsList: action.jobs
      };
    }
    case OPEN_NEW_PIPELINE: {
      return {
        ...state,
        pipelineSelected: action.value ? { ...state.pipelineSelected } : { ...initialState.pipelineSelected },
        isCreating: action.isCreating
      };
    }
    case PIPELINES_RESET_ERROR: {
      return {
        ...state,
        error: false,
        errorMessage: ''
      };
    }
    default: {
      return state;
    }
  }
}

export default pipelines;
