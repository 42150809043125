import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateActions from 'Screens/Users/components/CreateActions';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/users-license.png';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { ACTIONS } from 'tracking/GA/constants';
import NotFound from 'Screens/Errores/NotFound';
import { resetFilters } from 'store/Filters/actions';
import { selectWorkspaceSelected, selectIsReadOnly } from 'store/Faraday/selectors';
import selectRoutes from 'store/Workspace/selectors';
import {
  selectUserSelected, selectShowDeleteConfirmation,
  selectCurrentBreakpoint, selectUserCount
} from 'store/Users/selectors';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import {
  getData, deleteUserSelected, hideUserModalDelete, setPage
} from './actions/Actions';
import { TableWrapper } from './components/Table/styled';
import Table from './components/Table';
import ActionBar from '../../Common/Components/ActionBar';
import Edit from './components/Edit';
import Delete from './components/Delete';
import { MoreOptionsButton } from './components/MoreOptionsButton';

const Users = () => {
  const userSelected = useSelector(selectUserSelected);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const currentBreakpoint = useSelector(selectCurrentBreakpoint);
  const totalUsersCount = useSelector(selectUserCount);
  const page = useSelector((state) => selectPage('users', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('users', state));
  const isAllowedByFeature = useSelector((state) => selectAllowedByFeature(state, FEATURE_USERS, PERMISSION_TYPES.READ));
  const isAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.READ));
  const workspaceSelected = useSelector(selectWorkspaceSelected);
  const readonly = useSelector(selectIsReadOnly);
  const routeList = useSelector(selectRoutes);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
  }, [dispatch]);

  useEffect(() => () => dispatch(resetFilters('users')), [dispatch]);

  let message = '';

  if (showDeleteConfirmation) {
    if (userSelected.length === 1) { message = 'A user will be deleted. This operation cannot be undone. Are you sure you want to proceed?'; } else { message = `${userSelected.length} users will be deleted. This operation cannot be undone. Are you sure you want to proceed?`; }
  }

  const isAllowed = isAllowedByFeature && isAllowedByRole;

  if (!isAllowed) return <NotFound />;
  return (
    <div className="h-100">
      <ActionBar
        showDeleteConfirmation={ showDeleteConfirmation }
        messageDeleteConfirmation={ message }
        handleSubmitDelete={ () => dispatch(deleteUserSelected()) }
        handleCloseDelete={ () => dispatch(hideUserModalDelete()) }
        workspaceSelected={ workspaceSelected }
        createActions={ <CreateActions /> }
        deleteButton={ <Delete readonly={ readonly } /> }
        editButton={ <Edit currentBreakpoint={ currentBreakpoint } userSelected={ userSelected } /> }
        moreOptionsButton={ <MoreOptionsButton currentBreakpoint={ currentBreakpoint } userSelected={ userSelected } /> }
        routeList={ routeList }
        entity="user"
        count={ userSelected.length }
        totalRows={ totalUsersCount }
        page={ page }
        rowsPerPage={ rowsPerPage }
        onPrev={ () => dispatch(setPage(page - 1)) }
        onNext={ () => dispatch(setPage(page + 1)) }
      />
      <TableWrapper>
        <Table />
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.user.description" trackingShow={ ACTIONS.showUser } trackingClick={ ACTIONS.clickUser } />
        </ReduxModal>
      </TableWrapper>
    </div>
  );
};

export default Users;
