import React from 'react';
import AddButton from 'Common/Components/AddButton';
import { ContextMenuTrigger } from 'react-contextmenu';
import { useIntl } from 'react-intl';
import Wrapper from './styled';
import SubMenu from './SubMenu';

const AddTagButton = ({ tags, addTag, removeTag, setTags }) => {
  const intl = useIntl();
  const addLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.add' });

  return (
    <Wrapper>
      <ContextMenuTrigger id="import_file_add_tag_context_menu" holdToDisplay={ 0 }>
        <AddButton text={ addLabel } />
      </ContextMenuTrigger>
      <SubMenu setTags={ setTags } tags={ tags } addTag={ addTag } removeTag={ removeTag } />
    </Wrapper>
  );
};

export default AddTagButton;
