import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeTag, addTag, setVulnsTags
} from 'store/Manage/actions';
import { selectVulnDetail } from 'store/Manage/selectors';
import StyledSubMenu from './styled';
import { selectShowCreateTagModal } from 'store/Tags/selectors';
import { setCreationTagModal } from 'Screens/Tags/actions/Actions';

const SubMenu = () => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);
  const showCreateTagModal = useSelector(selectShowCreateTagModal);

  return (
    <StyledSubMenu
      actionBar
      addTag={ (tag) => dispatch(addTag(tag)) }
      entitiesSelected={ [vuln] }
      keyPrefix="preview_add_tag_context_menu"
      removeTag={ (tag) => dispatch(removeTag(tag)) }
      setTags={ (tagsToAdd, tagsToRemove) => dispatch(setVulnsTags(tagsToAdd, tagsToRemove)) }
      trigger="preview_add_tag_context_menu"
      showCreateModal={ showCreateTagModal }
      setShowModal={ (show) => dispatch(setCreationTagModal(show)) }
      showAddedTags={ false }
    />
  );
};

export default SubMenu;
