import { OPEN_MODAL, CLOSE_MODAL } from './actions';
import * as m from './modals';

const initialState = { // estas props deben coincidir con los valores de las constantes del archivo modals.js
  [m.MODAL_MANAGE_BULK_UPDATE]: false,
  [m.MODAL_MANAGE_BULK_UPDATE_CONFIRMATION]: false,
  [m.MODAL_RUN_USER_AGENT]: false,
  [m.MODAL_RUN_CLOUD_AGENT]: false,
  [m.MODAL_UPGRADE_LICENSE]: false,
  [m.MODAL_AGENT_TOKEN]: false,
  [m.MODAL_AGENT_CREATE]: false,
  [m.MODAL_SCHEDULE_CREATE_EDIT]: false,
  [m.MODAL_WORKSPACE_CREATE_EDIT]: false,
  [m.MODAL_EVIDENCE]: false,
  [m.MODAL_JIRA]: false,
  [m.MODAL_JIRA_TEMPLATE]: false,
  [m.MODAL_SEND_TO_JIRA]: false,
  [m.MODAL_PROJECTS_CREATE_EDIT]: false,
  [m.MODAL_PROJECTS_DELETE]: false,
  [m.MODAL_PROJECTS_SHOW_ERROR]: false,
  [m.MODAL_REDIRECT_CONFIRMATION]: false,
  [m.MODAL_APPLY_TEMPLATE]: false
};

function modals (state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        [action.payload]: true
      };
    }

    case CLOSE_MODAL: {
      return {
        ...state,
        [action.payload]: false
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}

export default modals;
