import React, { useEffect } from 'react';
import { getSaml, resetSaml } from 'store/Settings/saml/actions';
import { getLdap, resetLdap } from 'store/Settings/ldap/actions';
import { useDispatch } from 'react-redux';
import {
  TextAreas, FormWrapper, Column
} from './styled';

import {
  UserRole,
  DisplayName,
  AttributeIdentifier,
  SpCert,
  IdpCert,
  IID,
  SsoUrl,
  SpPrivateKey
} from './components';

const Form = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSaml());
    dispatch(getLdap());
  }, [dispatch]);

  useEffect(() => () => {
    dispatch(resetSaml());
    dispatch(resetLdap());
  }, [dispatch]);

  return (
    <FormWrapper>
      <Column>
        <UserRole />
        <DisplayName />
        <AttributeIdentifier />
        <TextAreas>
          <SpCert />
          <IdpCert />
        </TextAreas>
      </Column>
      <Column>
        <IID />
        <SsoUrl />
        <SpPrivateKey />
      </Column>
    </FormWrapper>
  );
};

export default Form;
