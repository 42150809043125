import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Wrapper, Item } from '../AddedTags/styled';
import NotAddedTag from './styled';

const NotAddedTags = ({ addTag, notAddedTags, keyPrefix, entitiesSelected }) => {
  if (isEmpty(notAddedTags)) return null;
  return (
    <Wrapper>
    { notAddedTags.map((t) => (
        <Item preventClose key={ `${keyPrefix}_${t}_` }>
          <NotAddedTag
            onClick={ () => addTag(t) }
            marked={ entitiesSelected.filter((entitie) => entitie.tags && entitie.tags.includes(t)).length > 0 }
            children={ t }
          />
        </Item>
    ))}
    </Wrapper>
  );
};

export default NotAddedTags;
