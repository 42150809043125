import React from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'Common/Components/Skeleton';
import { Status } from './styled';

export default function getColumns (fields, isFetching) {
  const alias = 'host.detail.services.table.columns';
  const columns = [{
    Header: () => (
      <FormattedMessage id={ `${alias}.id` } />
    ),
    maxWidth: 150,
    minWidth: 150,
    id: 'id',
    show: fields.id,
    Cell: ({ original }) => (
      isFetching ? <Skeleton /> : original.id
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.name` } />
    ),
    accessor: 'name',
    maxWidth: 150,
    minWidth: 150,
    id: 'name',
    show: fields.name,
    Cell: ({ original }) => (
      isFetching ? <Skeleton /> : original.name
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.version` } />
    ),
    accessor: 'version',
    maxWidth: 80,
    minWidth: 80,
    id: 'version',
    show: fields.version,
    Cell: ({ original }) => (
      isFetching ? <Skeleton /> : original.version
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.port` } />
    ),
    accessor: 'port',
    maxWidth: 50,
    minWidth: 50,
    id: 'port',
    show: fields.port,
    Cell: ({ original }) => (
      isFetching ? <Skeleton /> : original.port
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.protocol` } />
    ),
    accessor: 'protocol',
    maxWidth: 80,
    minWidth: 80,
    id: 'protocol',
    show: fields.protocol,
    Cell: ({ original }) => (
      isFetching ? <Skeleton /> : original.protocol
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.status` } />
    ),
    accessor: 'status',
    id: 'status',
    show: fields.status,
    Cell: ({ original }) => (
      isFetching ? <Skeleton /> : <Status value={ original.status }>{original.status}</Status>
    ),
    maxWidth: 130
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.vulns` } />
    ),
    accessor: 'vulns',
    maxWidth: 80,
    minWidth: 80,
    id: 'vulns',
    show: fields.vulns,
    Cell: ({ original }) => (
      isFetching ? <Skeleton /> : original.vulns
    )
  }, {
    Header: () => (
      <FormattedMessage id={ `${alias}.owned` } />
    ),
    accessor: 'owned',
    maxWidth: 120,
    minWidth: 120,
    id: 'owned',
    show: fields.owned,
    Cell: ({ original }) => {
      if (isFetching) return <Skeleton />;
      if (original.owned) return <FormattedMessage id={ `${alias}.owned.yes` } />;
      return <FormattedMessage id={ `${alias}.owned.no` } />;
    }
  }];

  return columns;
}
