import React from 'react';
import PropTypes from 'prop-types';
import { Title, Wrapper, Item } from './styled';

const display = {
  severity: [{
    name: 'Critical',
    type: 'C'
  },
  {
    name: 'High',
    type: 'H'
  },
  {
    name: 'Medium',
    type: 'M'
  },
  {
    name: 'Low',
    type: 'L'
  }
  ],
  status: [{
    name: 'Open',
    type: 'C'
  },
  {
    name: 'Closed',
    type: 'disable'
  },
  {
    name: 'Re-Opened',
    type: 'H'
  },
  {
    name: 'Risk-Accepted',
    type: 'L'
  }
  ]
};

const SideNote = ({ type }) => {
  if (!type) return null;
  return (
    <Wrapper>
      <Title>{type === 'severity' ? 'Vulnerability Severity' : 'Vulnerability Status'}</Title>
      {display[type].map((vuln) => <Item type={ vuln.type } key={ vuln.type }>{vuln.name}</Item>)}
    </Wrapper>
  );
};

SideNote.propTypes = {
  type: PropTypes.string.isRequired
};

export default SideNote;
