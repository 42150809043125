import React, { useState, useRef } from 'react';
import useClickOutside from 'Hooks/useClickOutside';
import useModal from 'Hooks/useModal';
import { useSelector } from 'react-redux';
import { selectUserName } from 'store/Faraday/selectors';
import Settings from 'Screens/Settings';
import Extras from './Modals/Extras';
import About from './Modals/About';
import Help from './Modals/Help';
import User from '.';

const UserContainer = () => {
  const [ModalPreferences, showModalPreferences, toggleModalPreferences] = useModal(Settings);
  const [ModalExtras, showModalExtras, toggleModalExtras] = useModal(Extras);
  const [ModalAbout, showModalAbout, toggleModalAbout] = useModal(About);
  const [ModalHelp, showModalHelp, toggleModalHelp] = useModal(Help);

  const [dropdown, setDropdown] = useState(false);
  const dataRef = useRef();
  useClickOutside(dataRef, () => setDropdown(false));

  const userRole = useSelector((s) => s.faraday.role);
  const userName = useSelector(selectUserName);

  const modals = {
    Extras: {
      Modal: ModalExtras,
      showModal: showModalExtras,
      toggleModal: toggleModalExtras
    },
    About: {
      Modal: ModalAbout,
      showModal: showModalAbout,
      toggleModal: toggleModalAbout
    },
    Help: {
      Modal: ModalHelp,
      showModal: showModalHelp,
      toggleModal: toggleModalHelp
    },
    Preferences: {
      Modal: ModalPreferences,
      showModal: showModalPreferences,
      toggleModal: toggleModalPreferences
    }
  };

  return (
    <div ref={ dataRef }>
      <User modals={ modals } userName={ userName } userRole={ userRole } dropdown={ dropdown } onClick={ () => setDropdown(!dropdown) } />
    </div>
  );
};

export default UserContainer;
