import React from 'react';
import Title from './components/Title';
import ActionBar from './components/ActionBar';
import { HostData } from './components/HostData';
import Description from './components/Description';
import Resolution from './components/Resolution';
import References from './components/References';
import Policies from './components/Policies';
import ImpactsTab from './components/ImpactsTab';
import Owner from './components/Owner';
import ExternalId from './components/ExternalId';
import ToggleButtons from 'Screens/Manage/components/ToggleButtons';
import { Wrapper, Fields } from './styled';
import Duplicates from './components/Duplicates';
import Tags from './components/Tags';
import CVE from './components/CVE';
import Risk from './components/Risk';

const GeneralTab = () => (
  <Wrapper>
    <Title />
    <ActionBar />
    <HostData />
    <ToggleButtons id="general" />
     <Fields>
      <CVE />
      <Risk />
      <References />
      <Duplicates />
      <Tags />
      <Description />
      <Resolution />
      <ImpactsTab />
      <Policies />
      <ExternalId />
      <Owner />
     </Fields>
  </Wrapper>
);

export default GeneralTab;
