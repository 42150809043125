import React from 'react';
import PropTypes from 'prop-types';
import parseTemplateName from 'store/ExecutiveReportEditCreate/mapping';
import {
  ActionItemWrapper, ItemTitle, WordIcon, PDFIcon
} from './styled';

const ActionItem = ({ template, onSelection }) => {
  const parsedTemplate = parseTemplateName(template);
  const Icon = () => (parsedTemplate.toLowerCase().includes('.doc') ? <WordIcon /> : <PDFIcon />);

  return (
    <ActionItemWrapper onClick={ () => onSelection(template) }>
      <Icon />
      <ItemTitle>{parsedTemplate}</ItemTitle>
    </ActionItemWrapper>
  );
};

ActionItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.array.isRequired,
  onSelection: PropTypes.func.isRequired
};

export default ActionItem;
