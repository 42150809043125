import React from 'react';
import Empty from './components/Empty';
import ActionBar from './components/ActionBar';
import { Wrapper, WrapperContent } from './styled';
import SelectType from './components/SelectType';

const NewGraph = () => (
  <Wrapper>
    <ActionBar />
    <WrapperContent>
      <SelectType />
      <Empty />
    </WrapperContent>
  </Wrapper>
);

export default NewGraph;
