import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Title, Text, Br } from './styled';

export const manageTourConfig = (action) => [
  {
    selector: 'first-tour-step',
    content: ''
  },
  {
    selector: '[data-tour="Add Vulnerability"]',
    position: 'bottom',
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.manage.addVuln.title" /> } />
        <Text children={ <FormattedMessage id="tour.manage.addVuln.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '.test-table-selector',
    position: 'bottom',
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.manage.table.title" /> } />
        <Text children={ <FormattedMessage id="tour.manage.table.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Assets"]',
    position: 'right',
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.manage.assets.title" /> } />
        <Text children={ <FormattedMessage id="tour.manage.assets.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Analytics"]',
    position: 'right',
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.manage.analytics.title" /> } />
        <Text children={ <FormattedMessage id="tour.manage.analytics.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Planner"]',
    position: 'right',
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.manage.planner.title" /> } />
        <Text children={ <FormattedMessage id="tour.manage.planner.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Reports"]',
    position: 'right',
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.manage.reports.title" /> } />
        <Text children={ <FormattedMessage id="tour.manage.reports.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '[data-tour="Automation"]',
    position: 'right',
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.manage.automation.title" /> } />
        <Text children={ <FormattedMessage id="tour.manage.automation.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '',
    content: '',
    action
  }
];

export const dashboardTourConfig = (redirectToManage) => ([
  {
    selector: 'first-tour-step',
    content: ''
  },
  {
    selector: '[data-tour="Activity Dashboard"]',
    position: 'right',
    style: { boxShadow: '0 2px 50px 0 rgba(28, 34, 67, 0.15)' },
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.dashboard.title" /> } />
        <Text children={ <FormattedMessage id="tour.dashboard.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '',
    content: '',
    action: redirectToManage
  }
]);

export const agentsTourConfig = (showEnjoyPopup) => ([
  {
    selector: 'first-tour-step',
    content: ' '
  },
  {
    selector: '[data-tour="agent_firstscan"]',
    position: 'right',
    content: () => (
      <Wrapper>
        <Title children={ <FormattedMessage id="tour.manage.firstScan.title" /> } />
        <Text children={ <FormattedMessage id="tour.manage.firstScan.description" values={ { b: (chunks) => <Br children={ chunks } /> } } /> } />
      </Wrapper>
    )
  },
  {
    selector: '',
    content: '',
    action: showEnjoyPopup
  }
]);
