import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { saveLoginSettings } from 'store/Settings/loginSettingsActions';
import { selectUserName } from 'store/Faraday/selectors';
import {
  Wrapper,
  Title,
  Username
} from './styled';
import ChangePassword from './components/ChangePassword';
import LoginSettings from './components/LoginSettings';
import Header from '../Header';
import TwoFactor from './components/TwoFactor';

const Account = ({ onClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userName = useSelector(selectUserName);

  const close = () => {
    onClose();
  };

  const accept = () => {
    dispatch(saveLoginSettings());
    close();
  };

  return (
    <Wrapper>
      <Header onAccept={ accept } onClose={ close } />
      <Title>
        {intl.formatMessage({ id: 'preferences.account.title' }) }
        <Username>{ userName }</Username>
      </Title>
      <ChangePassword />
      <LoginSettings />
      <TwoFactor />
    </Wrapper>
  );
};

Account.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Account;
