import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_TASKS_COMMENT_DELETE } from 'store/modals/modals';
import Wrapper from 'Common/Components/ReduxModal/styled';
import { Default, Accept } from 'Common/Components/Button/styled';
import { selectTaskComment } from 'store/Tasks/selectors';
import { deleteComment } from 'store/Tasks/actions';
import {
  ModalWrapper, Title, Message, ButtonsWrapper
} from './styled';

const DeleteTaskComment = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const comment = useSelector(selectTaskComment);
  const title = intl.formatMessage({ id: 'planner.task.taskComment.delete.title' });
  const message = intl.formatMessage({ id: 'planner.task.taskComment.message' });
  const cancel = intl.formatMessage({ id: 'planner.task.taskComment.cancelBtn' });
  const done = intl.formatMessage({ id: 'planner.task.taskComment.deleteBtn' });

  const onDone = () => {
    dispatch(deleteComment(comment.id));
    dispatch(closeModal(MODAL_TASKS_COMMENT_DELETE));
  };

  const onCancel = () => {
    dispatch(closeModal(MODAL_TASKS_COMMENT_DELETE));
  };

  return (
    <Wrapper>
      <ModalWrapper>
        <Title>{ title }</Title>
        <Message>{ message }</Message>
        <ButtonsWrapper>
          <Default onClick={ onCancel }>{ cancel }</Default>
          <Accept onClick={ onDone }>{ done }</Accept>
        </ButtonsWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default DeleteTaskComment;
