import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  updateVuln
} from 'store/Manage/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnDetail } from 'store/Manage/selectors';
import ExpandableWrapper from '../ExpandableWrapper';
import {
  Wrapper,
  DataContainer,
  DataItem,
  Placeholder,
  InputComp,
  Trash,
  TrashWrapper,
  Error,
  InputWrapper,
  ErrorIcon,
  Plus
} from './styled';
import useExpandable from 'Hooks/useExpandable';

const TAB = 'general';
const ID = 'cve';

const useVulnFieldPatch = (key) => {
  const dispatch = useDispatch();
  const currentVuln = useSelector(selectVulnDetail);
  const [list, setList] = useState(currentVuln[key]);

  useEffect(() => {
    setList(currentVuln[key]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVuln._id, key]);

  const onAdd = (name) => {
    const newList = [...list, name];
    setList(newList);
    dispatch(updateVuln(currentVuln, key, newList));
  };

  const onRemove = (name) => {
    const filterList = list.filter((key) => key !== name);
    setList(filterList);
    dispatch(updateVuln(currentVuln, key, filterList));
  };

  return [list, onAdd, onRemove];
};

const CVE = () => {
  const intl = useIntl();
  const currentVuln = useSelector(selectVulnDetail);
  const isExpanded = useExpandable(TAB, ID, currentVuln.cve);

  return (
    <ExpandableWrapper id={ ID } title={ intl.formatMessage({ id: 'manage.detail.tab.general.cve' }) } defaultValue={ isExpanded }>
      <Wrapper>
        <Input
          placeholder="Add CVE"
          errorMessage="CVE not valid"
          validator={ /^(CVE-(1999|2\d{3})-(\d{4,7}))$/ }
        />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default CVE;

const Input = ({ validator, placeholder, errorMessage }) => {
  const [listData, onAdd, onRemove] = useVulnFieldPatch('cve');
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const isOdd = (n) => n % 2 !== 0;

  const isValid = (value) => {
    if (!validator) return true;
    return validator.test(value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!isValid(value)) {
        setError(true);
        return;
      }
      onAdd(value);
      setValue('');
    }
  };

  const handleChange = (e) => {
    setError(false);
    setValue(e.target.value);
  };

  return (
    <>
      <InputWrapper>
        <InputComp
          value={ value }
          placeholder={ placeholder }
          onChange={ handleChange }
          onKeyPress={ handleKeyPress }
        />
        <Plus />
        {error && (
        <Error>
          <ErrorIcon />
          {errorMessage}
        </Error>
        )}
      </InputWrapper>
      <DataContainer>
        { listData && listData.map((name, i) => (
          <DataItem
            key={ `di_${Math.random().toString(36).substr(2, 9)}` }
            isOdd={ isOdd(i) }
          >
            <Placeholder href={ `https://www.cve.org/CVERecord?id=${name.toUpperCase()}` }>{name}</Placeholder>
            <TrashWrapper
              onClick={ () => onRemove(name) }
            >
              <Trash />
            </TrashWrapper>
          </DataItem>
        )) }
      </DataContainer>
    </>
  );
};
