import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as PlusImage } from 'Images/icon-action-bar-plus.svg';

export const Button = styled.div`
  white-space: nowrap;
  align-items: center;
  background-color: transparent;
  border-radius: 2px;
  border: solid ${(props) => (props.border === 0 || props.border > 0 ? props.border : 1)}px rgba(144, 169, 192, 0.3);
  cursor: pointer;
  display: flex;
  font-size: 11.5px;
  font-weight: 500;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  padding: 0 10px;

  & > svg {
    margin-right: 10px;
     & > path {
      fill: ${colors.dark2};
    }
  }

  &:hover {
    background-color: #f1f4f7;
    border-radius: 3px;
    border: solid ${(props) => (props.border === 0 || props.border > 0 ? props.border : 1)}px #d8dee5;
  }
  
  ${({ disabled }) => {
    if (!disabled) return null;
    return `
      pointer-events: none;
      background-color: ${colors.white};
      & > svg {
        & > path {
          fill: ${colors.grey26};
        }
      }
      `;
  }
}
`;
Button.displayName = 'Button';

export const Text = styled.div`
  user-select: none;
  color: ${({ disabled }) => `${disabled ? colors.grey26 : colors.black}`};
  font-size: 11.5px;
  font-weight: 500;
  line-height: 34px;
`;
Text.displayName = 'Text';

export const PlusIcon = styled(PlusImage)`
  height: 11px;
`;
Text.displayName = 'Text';
