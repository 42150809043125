import React from 'react';
import { PropTypes } from 'prop-types';
import {
  Wrapper, ModalWrapper, Title, Message, ButtonsWrapper, Cancel, Done
} from './styled';

const ConfirmNavigationModal = ({
  title, message, onCancel, onDone, cancel, done
}) => (
  <Wrapper>
    <ModalWrapper>
      <Title>{ title }</Title>
      <Message>{ message }</Message>
      <ButtonsWrapper cancel={ cancel }>
        {cancel ? <Cancel onClick={ onCancel }>{ cancel }</Cancel> : null}
        <Done onClick={ onDone }>{ done }</Done>
      </ButtonsWrapper>
    </ModalWrapper>
  </Wrapper>
);

ConfirmNavigationModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  onDone: PropTypes.func.isRequired,
  cancel: PropTypes.string,
  done: PropTypes.string.isRequired
};

ConfirmNavigationModal.defaultProps = {
  onCancel: null,
  cancel: ''
};

export default ConfirmNavigationModal;
