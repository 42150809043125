/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import {
  ContextMenuTrigger
} from 'react-contextmenu';
import { useDispatch, useSelector } from 'react-redux';
import {
  sortData, setShowTableContextMenu, set2fa,
  selectUser, resetState
} from 'Screens/Users/actions/Actions';
import {
  selectUsersList, selectIsFetching, selectUserSelected,
  selectUserCount, selectLastUsersSelected, selectLastIndexForRange, selectLastSelected
} from 'store/Users/selectors';
import { selectRowsPerPage } from 'store/Filters/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import getColumns from './Columns';
import ContextMenuUsersMassiveUpdate from '../ContextMenuUsersMassiveUpdate';
import getTrProps from './Functions';

const Table = () => {
  const table = useRef(null);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(selectUsersList);
  const isFetching = useSelector(selectIsFetching);
  const userSelected = useSelector(selectUserSelected);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_USERS, PERMISSION_TYPES.UPDATE));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('users', state));
  const userCount = useSelector(selectUserCount);
  const lastUsersSelected = useSelector(selectLastUsersSelected);
  const lastIndexForRange = useSelector(selectLastIndexForRange);
  const lastSelected = useSelector(selectLastSelected);
  const selectUserAction = (user) => dispatch(selectUser(user));

  const change2fa = (id, value) => {
    dispatch(set2fa(id, value ? 'disable2fa' : 'request2fa'));
  };

  const fetchData = (state) => {
    if (!isFetching) dispatch(sortData(state.sorted));
  };

  const onShow = () => dispatch(setShowTableContextMenu(true));
  const onHide = () => dispatch(setShowTableContextMenu(false));

  const mockData = [...Array(13).keys()];

  useEffect(() => () => dispatch(resetState()), [dispatch]);

  return (
    <div id="users-table" className="h-100 w-100" ref={ table }>
      <ContextMenuTrigger onContextMenu={ (e) => e.preventDefault() } id="massive_update_users_table" holdToDisplay={ 1000000 } disable={ !canUpdate }>
        <CustomReactTable
          data={ isFetching ? mockData : data }
          columns={ getColumns(change2fa, isFetching) }
          minRows={ 0 }
          pages={ Math.ceil(userCount / rowsPerPage) }
          page={ 1 }
          defaultPageSize={ rowsPerPage }
          manual
          onFetchData={ fetchData }
          showPagination={ false }
          style={ { height: '100%', boxShadow: '-8px 14px 20px 0px rgba(0, 0, 0, 0.03)' } }
          getTrProps={ (_, rowInfo) => getTrProps(rowInfo, data, setShowContextMenu, userSelected, lastUsersSelected, lastIndexForRange, lastSelected, selectUserAction) }
        />
      </ContextMenuTrigger>
      <ContextMenuUsersMassiveUpdate
        trigger="massive_update_users_table"
        disabled={ !showContextMenu }
        onShow={ onShow }
        onHide={ onHide }
      />
    </div>
  );
};

export default Table;
