import React from 'react';
import { selectCurrentWorkspace, selectNotificationsEnabled } from 'store/Faraday/selectors';
import { selectIsLoggedIn } from 'store/Sesion/selectors';
import { useSelector } from 'react-redux';
import Searcher from 'Common/Components/Searcher';
import WorkspaceSelector from './WorkspaceMenuSelector';
import Notifications from './Notifications';
import User from './User/userContainer';
import { Header, Wrapper } from './styled';

const NavMenu = () => {
  const loggedIn = useSelector((state) => selectIsLoggedIn(state));
  const currentWorkspace = useSelector((state) => selectCurrentWorkspace(state));
  const notificationsEnabled = useSelector((state) => selectNotificationsEnabled(state));
  const showNavigation = !!currentWorkspace;

  return loggedIn && (
    <Header>
      <WorkspaceSelector />
      <Searcher />
      <Wrapper>
        {showNavigation && notificationsEnabled && <Notifications />}
        <User />
      </Wrapper>
    </Header>
  );
};

export default NavMenu;
