import { combineReducers } from 'redux';
import breakpoints from 'store/Reducers/breakpoints/reducer';
import dashboard from 'store/Dashboard/reducer';
import locale from 'store/locale/reducer';
import host from 'store/Host/reducer';
import manage from 'store/Manage/reducer';
import preferences from 'store/Preferences/reducer';
import hostDetail from 'store/HostDetail/reducer';
import hostEditCreate from 'store/HostEditCreate/reducer';
import fileUploaderContext from 'store/FileUploaderContext/reducer';
import knowledgeBase from 'store/KnowledgeBase';
import knowledgeBaseEditCreate from 'store/KnowledgeBaseEditCreate';
import users from 'store/Users/reducer';
import settings from 'store/Settings/reducer';
import customAttributes from 'store/CustomAttributes/reducer';
import agents from 'store/Agents/reducer';
import schedule from 'store/Schedule/reducer';
import errors from 'store/errors/reducer';
import faraday from 'store/Faraday/Faraday';
import filters from 'store/Filters/reducer';
import modals from 'store/modals/reducer';
import config from 'store/Config/reducer';
import executiveReport from 'store/ExecutiveReport/reducer';
import executiveReportEditCreate from 'store/ExecutiveReportEditCreate/reducer';
import license from 'store/License/reducer';
import sideBar from 'store/SideBar/reducer';
import global from 'store/Global/reducer';
import { connectRouter } from 'connected-react-router';
import pipelines from 'store/Pipelines/reducer';
import jobs from 'store/Jobs/reducer';
import analytics from 'store/Analytics/reducer';
import tasks from 'store/Tasks/reducer';
import projects from 'store/Projects/reducer';
import sesion from '../Sesion/Sesion';
import manageEditCreate from './ManageEditCreate';
import workspace from './Workspace';
import workspaceEditCreate from './WorkspaceEditCreate';
import tags from './Tags';
import notifications from '../Notifications/reducer';
import wizard from './Wizard';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  sesion,
  faraday,
  knowledgeBase,
  customAttributes,
  filters,
  manage,
  manageEditCreate,
  host,
  hostDetail,
  hostEditCreate,
  knowledgeBaseEditCreate,
  workspace,
  workspaceEditCreate,
  users,
  executiveReport,
  tags,
  executiveReportEditCreate,
  settings,
  notifications,
  dashboard,
  breakpoints,
  locale,
  wizard,
  preferences,
  fileUploaderContext,
  agents,
  schedule,
  errors,
  modals,
  config,
  license,
  sideBar,
  pipelines,
  jobs,
  analytics,
  projects,
  tasks,
  global
});
export default createRootReducer;
