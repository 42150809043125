import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
`;
Wrapper.displayName = 'Wrapper';

export const TitleWrapper = styled.div`
  color: ${colors.dark2};
  display: flex;
  align-items: center;
  & > * {
    margin-bottom: 0;
  }
`;
TitleWrapper.displayName = 'TitleWrapper';

export const Title = styled.h2`
  font-size: 17px;
  font-weight: 500;
  margin-right: 5px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.h3`
  font-size: 13.5px;
  font-weight: bold;
  margin-top: 4px;
`;
Subtitle.displayName = 'Subtitle';

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  & > :not(:last-child) {
    margin-right: 15px;
  }
`;
WrapperButtons.displayName = 'WrapperButtons';
