/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  SubTitle,
  BackButton,
  ButtonsContainer,
  ButtonWithoutBackground,
  PullLeft
} from 'Common/Components/MainContainer/styled';
import DragFile from 'Common/Components/DragFile';
import FileUploader from 'Common/Components/FileUploader';
import {
  resetFileState,
  createManually,
  cancelRequest,
  uploadFile,
  rootFeed
} from 'Screens/Wizard/actions/Actions';
import { WORKSPACE_SELECTION } from 'Screens/Wizard/constants';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import {
  Wrapper, CustomBlueButton, Title, CsvIcon, LoginLogoContainer
} from './styled';

class StepFileSelector extends Component {
  constructor (props) {
    super(props);
    this.state = { files: [] };
    this.handleDrop = this.handleDrop.bind(this);
    this.uploadFileWizard = this.uploadFileWizard.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
  }

  componentDidMount () {
    this.props.resetFileState();
  }

  componentDidUpdate () {
    const { currentUploadState } = this.props;

    if (currentUploadState === 'Canceled') {
      this.setState({ files: [] });
      this.props.resetFileState();
    }
  }

  handleDrop (files) {
    const fileList = [];
    for (let i = 0; i < files.length; i += 1) {
      if (!files[i].name) return;
      fileList.push(files[i]);
    }
    this.setState({ files: fileList });
  }

  uploadFileWizard () {
    this.props.uploadFile(this.state.files[0]);
  }

  onBackClick () {
    const { onStep } = this.props;
    onStep(WORKSPACE_SELECTION);
  }

  render () {
    const {
      workspaceName, currentUploadState, currentUploadPercent
    } = this.props;

    return (
      <Wrapper>
        <LoginLogoContainer>
          <CsvIcon />
        </LoginLogoContainer>

        <PullLeft>
          <Title>
            Workspace &nbsp;
            {workspaceName}
          </Title>
        </PullLeft>
        <SubTitle style={ { fontSize: '12.5px', lineHeight: '18px' } }>
          <FormattedMessage id="wizard.StepFileSelector.subTitle2" />
        </SubTitle>

        {this.state.files.length === 0 ? <DragFile handleDrop={ this.handleDrop } /> : null}
        {this.state.files.length > 0
          ? (
          <FileUploader
            files={ this.state.files }
            uploadFile={ this.uploadFileWizard }
            currentUploadState={ this.props.currentUploadState }
            currentUploadPercent={ this.props.currentUploadPercent }
            currentUploadError={ this.props.currentUploadError }
            filenames={ this.state.files[0].name }
            height="280px"
          />
            )
          : null}
        <ButtonsContainer>
          {currentUploadState === 'Error' ? <ButtonWithoutBackground onClick={ () => { this.props.resetFileState(); this.setState({ files: [] }); } }><FormattedMessage id="wizard.StepFileSelector.buttonContainer.upload" /></ButtonWithoutBackground> : null}
          {!(this.state.files.length > 0) && currentUploadState === '' ? <BackButton onClick={ this.onBackClick } top="47px"><FormattedMessage id="wizard.StepFileSelector.buttonContainer.back" /></BackButton> : null }
          {currentUploadState === 'Uploading' && currentUploadPercent !== 100 ? <BackButton onClick={ () => { this.props.cancelRequest(); } }><FormattedMessage id="wizard.StepFileSelector.buttonContainer.cancel" /></BackButton> : null }
          {currentUploadState === ''
            ? (
            <CustomBlueButton onClick={ () => this.props.createManually() } label={ <FormattedMessage id="wizard.StepFileSelector.skip" /> } />
              )
            : null }

        </ButtonsContainer>
      </Wrapper>
    );
  }
}

StepFileSelector.propTypes = {
  workspaceName: PropTypes.string,
  uploadFile: PropTypes.func.isRequired
};

StepFileSelector.defaultProps = {
  workspaceName: ''
};

const mapStateToProps = (state) => ({
  user: state.sesion.user,
  workspaceName: selectCurrentWorkspace(state),
  currentUploadState: state.wizard.currentUploadState,
  currentUploadPercent: state.wizard.currentUploadPercent,
  currentUploadError: state.wizard.currentUploadError,
  firstLogin: state.sesion.firstLogin
});

const mapDispatchToProps = (dispatch) => ({
  resetFileState: () => {
    dispatch(resetFileState());
  },
  createManually: () => {
    dispatch(createManually());
  },
  cancelRequest: () => {
    dispatch(cancelRequest());
  },
  uploadFile: (files) => {
    dispatch(uploadFile(files));
  },
  rootFeed: () => {
    dispatch(rootFeed());
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepFileSelector));
