import styled from 'styled-components';
import colors from 'Styles/colors';
import StringField from 'Common/Components/StringField';
import { Title as InputTitle } from 'Common/Components/StringField/styled';
import { Title } from 'Screens/Settings/common-styled';
import Link from 'Common/Components/Link/styled';

export const Wrapper = styled.div`
  padding: 0px 0 20px 0;
  ${Title} {
    margin-bottom: 20px;
    font-size: 14.5px;
    font-weight: 500;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Content = styled.div`
  display: flex;
  flex-flow: wrap;
  & > * {
    width: calc(92% / 2);
  }
`;
Content.displayName = 'Content';

export const CustomInput = styled(StringField)`
  margin-bottom: 16px;
  ${InputTitle} {
    font-weight: 400;
    color: #afb1bc;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-right: 0px;
  }
`;
CustomInput.displayName = 'CustomInput';

export const Url = styled(CustomInput)`
  margin-right: 10px;
  max-width: 100%;
`;
Url.displayName = 'Url';

export const Project = styled(CustomInput)`
  margin-right: 10px;
  max-width: 100%;
`;
Project.displayName = 'Project';

export const AccessToken = styled(CustomInput)`
  margin-right: 10px;
  max-width: 100%;
`;
AccessToken.displayName = 'AccessToken';

export const WrapperCustomField = styled.div`
  margin-right: 10px;
  padding: 20px 0;
`;
WrapperCustomField.displayName = 'WrapperCustomField';

export const Test = styled(Link)`
  padding: 20px 0;
  display: block;
`;
Test.displayName = 'Test';

export const Label = styled.div`
  font-size: 12.5px;
  line-height: 1.12;
  font-weight: 400;
  color: ${colors.grey17};
  padding-bottom: 20px;
`;
Label.displayName = 'Label';
