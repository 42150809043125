/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import upgradeLicenseImage from 'Images/reporting-license.png';
import { useIntl } from 'react-intl';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { setInputField } from 'store/ExecutiveReportEditCreate/actions';
import { selectSelectedTemplate, selectParsedTemplateName } from 'store/ExecutiveReportEditCreate/selectors';
import { getTemplates } from 'store/ExecutiveReport/actions';
import get from 'lodash/get';
import { ACTIONS } from 'tracking/GA/constants';
import {
  Wrapper, ExpandArrow, SelectedTemplate, InputTitle, DropdownContainer
} from './styled';
import Dropdown from './components/Dropdown';

const TemplateDropdown = () => {
  const [isExpanded, setExpand] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const isAllowed = useSelector((state) => selectAllowedByFeature(state, FEATURE_REPORTING, PERMISSION_TYPES.CREATE));
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const isTemplateSelected = selectedTemplate.length > 0;
  const parsedTemplate = useSelector(selectParsedTemplateName);
  const selectedTemplateLabel = isTemplateSelected ? parsedTemplate : intl.formatMessage({ id: 'reports.editCreate.template.noSelection' });

  const onSelection = (selectedTemplate) => {
    const isTemplateAllowed = get(selectedTemplate, '[2]', false);
    if (isAllowed && isTemplateAllowed) {
      dispatch(setInputField('selectedTemplate', selectedTemplate));
    } else {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
    }
  };
  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  return (
    <DropdownContainer>
      <InputTitle>
        Report type
      </InputTitle>
      <Wrapper onClick={ () => setExpand(!isExpanded) }>
        <SelectedTemplate children={ selectedTemplateLabel } />
        <ExpandArrow />
        {isExpanded && <Dropdown onSelection={ onSelection } hideDropdown={ () => setExpand(false) } /> }
      </Wrapper>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.reporting.description" trackingShow={ ACTIONS.showReport } trackingClick={ ACTIONS.clickReport } />
      </ReduxModal>
    </DropdownContainer>
  );
};

export default TemplateDropdown;
