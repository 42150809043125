import get from 'lodash/get';

export const mappedTemplates = {
  'generic_default.docx (generic) (Word)': { orderPonderation: 9, label: 'Vulnerabilities - Legacy (.docx)' },
  'generic_default.html (generic) (PDF)': { orderPonderation: 8, label: 'Vulnerabilities - Legacy (.pdf)' },
  'generic_vulnerabilities.docx (generic) (Word)': { orderPonderation: 7, label: 'Vulnerabilities (.docx)' },
  'generic_vulnerabilities_light.docx (generic) (Word)': { orderPonderation: 6, label: 'Vulnerabilities - Light (.docx)' },
  'generic_vulnerabilities_dark.docx (generic) (Word)': { orderPonderation: 5, label: 'Vulnerabilities - Dark (.docx)' },
  'group_default.docx (grouped) (Word)': { orderPonderation: 4, label: 'Vulnerabilities - Grouped (.docx)' },
  'group_default.html (grouped) (PDF)': { orderPonderation: 3, label: 'Vulnerabilities - Grouped (.pdf)' },
  'generic_assets.docx (generic) (Word)': { orderPonderation: 2, label: 'Assets (.docx)' },
  'generic_assets_light.docx (generic) (Word)': { orderPonderation: 1, label: 'Assets - Light (.docx)' },
  'generic_assets_dark.docx (generic) (Word)': { orderPonderation: 0, label: 'Assets - Dark (.docx)' }
};

const capitalize = (str) => str.toString().charAt(0).toUpperCase() + str.toString().slice(1);

const parseTemplateName = (template) => {
  const templateName = get(template, '[1]', '');
  const parsedTemplate = get(mappedTemplates, `${templateName}`, templateName);
  const templateLabel = get(parsedTemplate, 'label', templateName);
  if (templateLabel === templateName) {
    templateLabel.replace('.html', '');
    templateLabel.replace('.pdf', '');
    templateLabel.replace('.docx', '');
    templateLabel.replace('(generic)', '');
    templateLabel.replace('(Word)', '(.docx)');
  }
  return capitalize(templateLabel);
};

export default parseTemplateName;
