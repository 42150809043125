import styled from 'styled-components';
import { Dropdown } from 'Common/Components/Inputs';

const StyledDropdown = styled(Dropdown)`
  ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'};
  margin-bottom: 16px;
`;
StyledDropdown.displayName = 'StyledDropdown';

export default StyledDropdown;
