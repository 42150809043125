import sortBy from 'lodash/sortBy';
import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const parseData = (data) => {
  const parsed = data.map((d) => {
    const date = moment(`01-${d.label}`, 'DD-MM-YYYY');

    return {
      ...d,
      date,
      label: date.format('MMM')
    };
  });
  return sortBy(parsed, ['date'], ['asc']);
};
