import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import {
  Label, StyledCheckbox, WrapperField
} from './styled';

const Checkbox = ({
  disabled,
  onChange,
  state,
  theme,
  partialChecked,
  text
}) => {
  const [check, setCheck] = useState(state);
  useEffect(() => {
    setCheck(state);
  }, [state]);
  const handleChange = (e) => {
    onChange && onChange(e);// eslint-disable-line
    setCheck(!check);
  };
  const getCheckBox = () => {
    // Since DOM rejects boolean values of not recognized props, added $ on '$partialChecked' to convert it to a transient prop to the styled component
    return <StyledCheckbox readOnly disabled={ disabled } $partialChecked={ partialChecked } checked={ check } theme={ theme } onClick={ disabled ? null : handleChange } />;
  };

  if (text) {
    return (
      <WrapperField>
        {getCheckBox()}
        <Label>{text}</Label>
      </WrapperField>
    );
  }

  return (getCheckBox());
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  state: PropTypes.bool,
  theme: PropTypes.string,
  partialChecked: PropTypes.bool,
  text: PropTypes.string
};

Checkbox.defaultProps = {
  onChange: null,
  disabled: false,
  state: false,
  theme: 'green',
  partialChecked: false,
  text: ''
};

export default Checkbox;
