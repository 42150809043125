/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import RunIcon from './components/RunIcon';
import ActionBar from './components/ActionBar';
import LastExecution from './components/LastExecution';
import AvailableTools from './components/AvailableTools';
import {
  Wrapper, TitleWrapper, Title, Status, TextWrapper
} from './styled';

const UserAgent = memo(({ agent, markToDelete }) => {
  const intl = useIntl();
  const name = get(agent, 'name', '');
  const lastExecution = get(agent, 'last_run', null);
  const tools = get(agent, 'executors', []);
  const status = get(agent, 'status', 'offline');
  const statusLabel = intl.formatMessage({ id: `agents.item.status.${status}` });

  return (
    <Wrapper data-tour={ name } >
      <RunIcon agent={ agent } />
      <TextWrapper>
        <TitleWrapper>
          <Title children={ name } />
          <Status value={ status } children={ statusLabel } />
        </TitleWrapper>
        <LastExecution lastExecution={ lastExecution } />
        <AvailableTools tools={ tools } />
      </TextWrapper>
      <ActionBar markToDelete={ () => markToDelete(agent) } />
    </Wrapper>
  );
});

UserAgent.displayName = 'UserAgent';

UserAgent.propTypes = {
  agent: PropTypes.object.isRequired,
  markToDelete: PropTypes.func.isRequired
};

UserAgent.displayName = 'UserAgent';

export default UserAgent;
