import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  width: 256px;
  max-height: 319px;
  border-radius: 2px;
  box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #edf2f7;
  background-color: #ffffff;
  top: 43px;
  right: 0px;
  z-index: 999;
`;

export const Footer = styled.div`
  height: 37px;
  margin: 0;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & p {
    color: #0082ed;
    font-size: 10px;
    font-weight: 800;
    cursor: pointer;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  min-height: 70px;
  margin: 0;
  padding: 0 13px;
  padding-right: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #d9e4ef;
  cursor: pointer;

  &:hover {
    background-color: #edf2f7;
  }

  &:hover button{
    display:block;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  color: #1c2243;
  text-align: initial;
  padding: 10px 0;
`;

export const TimeStamp = styled.p`
  margin: 0;
  font-size: 9px;
  color: #63758d;
`;

export const NotificationList = styled.ul`
  width: 100%;
  max-height: 282px;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  //display: flex;
  //flex-direction: column-reverse;

  /* width */
  &::-webkit-scrollbar {
    width: 7px;
    margin: 5px 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #e0dfe0;
    border-radius: 3px;
    margin: 9px 0;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #918e8e;
    border-radius: 3px;
    margin: 9px 0;
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

export const Delete = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  display:none;

  color: #ea3158;
  font-size: 9px;
  font-weight: 600;
`;
