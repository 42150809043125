import styled from 'styled-components';
import { ReactComponent as Jira } from 'Images/jira_logo.svg';
import { ReactComponent as ServiceNow } from 'Images/servicenow-logo.svg';
import { ReactComponent as Gitlab } from 'Images/preferences_gitlab_logo.svg';
import { Wrapper as WrapperUpgradeLicense } from 'Common/Components/UpgradeLicense/styled';

export const JiraLogo = styled(Jira)``;

export const ServicenowLogo = styled(ServiceNow)`
  margin-top: -4px;
`;

export const GitLabLogo = styled(Gitlab)``;

export const SolarwindsLogo = styled.img`
  margin-top: -13px;
`;

export const Wrapper = styled.div`
  font-size: 21.5px;
  * > ${WrapperUpgradeLicense} {
    flex-direction: column;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
`;
Title.displayName = 'Title';

export const CustomImage = styled.img`
  display: block;
`;
CustomImage.displayName = 'CustomImage';

export const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
Content.displayName = 'Content';

export const Options = styled.div`
  width: 138px;
`;
Options.displayName = 'Options';

export const View = styled.div`
  padding-left: 34px;
  width: 100%;
`;
View.displayName = 'View';
