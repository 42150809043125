import styled from 'styled-components';

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 4;
  max-height: 170px;
  top: 32px;
  left: 0px;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  box-shadow: 0 2px 50px 0 rgba(28, 34, 67, 0.1);
`;
DropdownContainer.displayName = 'DropdownContainer';

export default DropdownContainer;
