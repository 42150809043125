import React from 'react';
import {
  ModalWrapper, Wrapper, Title, Footer, Close, WarningIcon
} from './styled';

const Warning = ({ title, children, onClose }) => (
  <ModalWrapper>
    <Wrapper>
      <Title>
        <WarningIcon />
        {title}
      </Title>
      { children }
      <Footer>
        <Close onClick={ onClose }>Close</Close>
      </Footer>
    </Wrapper>
  </ModalWrapper>
);

export default Warning;
