import styled from 'styled-components';
import StringField from 'Common/Components/StringField';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    overflow-y: auto;
    padding-right: 5px;
    user-select: none;
    
    > :not(:last-child){
    margin-bottom: 26px;
   }
`;
Wrapper.displayName = 'Wrapper';

export const Row = styled.div`
    display: flex;
`;
Row.displayName = 'Row';

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;
Column.displayName = 'Column';

export const ExternalId = styled(StringField)`

`;

ExternalId.displayName = 'ExternalId';

export const RightColumn = styled(Column)`
    & > :first-child{
        margin-bottom: 10px;
    }
`;
RightColumn.displayName = 'RightColumn';

export const PolicyColumn = styled(Column)`
    margin-right: 20px;
    width: 48%;
`;
PolicyColumn.displayName = 'PolicyColumn';

export const AditionalText = styled.div`
    width: 220px;
    height: 36px;    
    font-size: 13.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: 0.1px;
    color: #afb1bc;
    margin-left: 20px;
`;
AditionalText.displayName = 'AditionalText';
