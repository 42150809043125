import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import get from 'lodash/get';
import { selectReadOnly, selectVulnDetail } from 'store/Manage/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { updateVuln } from 'store/Manage/actions';
import MarkdownEditor from 'Common/Components/v2/MarkdownEditor';
import ExpandableWrapper from '../ExpandableWrapper';
import useExpandable from 'Hooks/useExpandable';

const TAB = 'general';
const ID = 'description';

const Description = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const readOnly = useSelector(selectReadOnly);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const disabled = readOnly || !canUpdate;

  const currentVuln = useSelector(selectVulnDetail);
  const description = get(currentVuln, ID, '');
  const updateValue = (field, value) => dispatch(updateVuln(currentVuln, field, value));
  const isExpanded = useExpandable(TAB, ID, description);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } title={ intl.formatMessage({ id: 'manage.detail.tab.general.description' }) }>
      <MarkdownEditor
        value={ description }
        onBlur={ (value) => updateValue('desc', value) }
        disabled={ disabled }
      />
    </ExpandableWrapper>
  );
};

export default Description;
