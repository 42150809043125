import get from 'lodash/get';
import selectRoutes from 'store/Workspace/selectors';

export const selectKnowledgeBaseProps = (state) => {
  const showDeleteConfirmation = get(state, 'knowledgeBase.showDeleteConfirmation', {});
  const templatesSelected = get(state, 'knowledgeBase.templatesSelected', {});
  const readonly = get(state, 'faraday.readonly', false);
  const showModalCreate = get(state, 'knowledgeBase.showModalCreate', false);
  const routeList = selectRoutes(state);
  const totalVulnTemplate = get(state, 'knowledgeBase.totalVulnTemplate', 0);
  const rowsPerPage = get(state, 'filters.knowledgeBase.rowsPerPage', 0);
  const page = get(state, 'filters.knowledgeBase.page', 0);
  const updated = get(state, 'knowledgeBase.updated', false);
  return {
    showDeleteConfirmation,
    templatesSelected,
    readonly,
    showModalCreate,
    routeList,
    totalVulnTemplate,
    rowsPerPage,
    page,
    updated
  };
};

export const selectKnowledgeBaseTableProps = (state) => {
  const templatesSelected = get(state, 'knowledgeBase.templatesSelected', {});
  const data = get(state, 'knowledgeBase.vulnTemplateList', []);
  const lastTemplateSelected = get(state, 'knowledgeBase.vulnTemplateList', {});
  const lastIndexForRange = get(state, 'knowledgeBase.lastIndexForRange', -1);
  const lastSelected = get(state, 'knowledgeBase.lastSelected', -1);
  const isFetching = get(state, 'knowledgeBase.isFetching', false);
  return {
    templatesSelected, data, lastTemplateSelected, lastIndexForRange, lastSelected, isFetching
  };
};

export const selectTemplateEditCreate = (state) => {
  const name = get(state, 'knowledgeBaseEditCreate.name', '');
  const description = get(state, 'knowledgeBaseEditCreate.description', '');
  const exploitation = get(state, 'knowledgeBaseEditCreate.exploitation', '');
  const policyviolations = get(state, 'knowledgeBaseEditCreate.policyviolations', []);
  const accountability = get(state, 'knowledgeBaseEditCreate.accountability', false);
  const availability = get(state, 'knowledgeBaseEditCreate.availability', false);
  const confidentiality = get(state, 'knowledgeBaseEditCreate.confidentiality', false);
  const integrity = get(state, 'knowledgeBaseEditCreate.integrity', false);
  const errorMessage = get(state, 'knowledgeBaseEditCreate.errorMessage', false);
  const external_id = get(state, 'knowledgeBaseEditCreate.external_id', false);
  const references = get(state, 'knowledgeBaseEditCreate.references', false);
  const resolution = get(state, 'knowledgeBaseEditCreate.resolution', false);
  const easeofresolution = get(state, 'knowledgeBaseEditCreate.easeofresolution', '');
  const data = get(state, 'knowledgeBaseEditCreate.data', false);
  const id = get(state, 'knowledgeBaseEditCreate.id', false);

  return {
    name,
    description,
    exploitation,
    policyviolations,
    accountability,
    availability,
    confidentiality,
    integrity,
    errorMessage,
    external_id,
    references,
    resolution,
    easeofresolution,
    data,
    id
  };
};

export const selectReadonly = (state) => {
  const readonly = get(state, 'faraday.readonly', false);
  return { readonly };
};

export const selectRowsPerPage = (state) => get(state, 'knowledgeBase.rowsPerPage', 0);

export const selectFilters = (state) => get(state, 'knowledgeBase.filters', '');
