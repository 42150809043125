import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { viewDetail } from 'store/Analytics/actions';
import Preview from 'Screens/Analytics/components/CreateEdit/components/Preview';
import Modals from 'Screens/Analytics/components/Modals';
import useDownload from 'Hooks/useDownload';
import ActionBar from './components/ActionBar';
import Settings from './components/Settings';
import { Wrapper, WrapperContent } from './styled';

const EditGraph = () => {
  const dispatch = useDispatch();
  const [nodeRef, download] = useDownload();

  const { id } = useParams();

  useEffect(() => {
    if (id > 0) {
      dispatch(viewDetail(id));
    }
  }, [dispatch, id]);

  return (
    <Wrapper>
      <ActionBar download={ download } />
      <WrapperContent>
        <Settings />
        <Preview ref={ nodeRef } />
      </WrapperContent>
      <Modals />
    </Wrapper>
  );
};

export default EditGraph;
