import React, { useState } from 'react';
import uniq from 'lodash/uniq';
import union from 'lodash/union';
import difference from 'lodash/difference';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import {
  SubTitle,
  BodyWrapper
} from './styled';
import Header from './components/Header';
import AddTag from './components/AddTag';
import AddedTags from './components/AddedTags';
import NotAddedTags from './components/NotAddedTags';

const CreateVulnTagModal = ({ setTags, addedTags, notAddedTags, isLoading, setShowModal }) => {
  const intl = useIntl();
  const [tagsToAdd, setTagsToAdd] = useState([]);
  const [tagsToRemove, setTagsToRemove] = useState([]);
  const onClose = () => setShowModal(false);

  const addTag = (tag) => {
    setTagsToAdd(uniq([...tagsToAdd, tag]));
    setTagsToRemove([...tagsToRemove.filter((t) => t !== tag)]);
  };

  const removeTag = (tag) => {
    setTagsToRemove(uniq([...tagsToRemove, tag]));
    setTagsToAdd([...tagsToAdd.filter((t) => t !== tag)]);
  };

  const newAddedTags = difference(union(addedTags, tagsToAdd), tagsToRemove);
  const newNotAddedTags = difference(union(notAddedTags, tagsToRemove), tagsToAdd);

  return (
    <Modal show id="modal_creation_tag" onHide={ onClose } size="lg" centered dialogClassName="ct-modal" backdrop="static">
      <Header
        tagsToAdd={ difference(tagsToAdd, addedTags) }
        tagsToRemove={ difference(tagsToRemove, notAddedTags) }
        onHandleClose={ onClose }
        isLoading={ isLoading }
        setTags={ setTags }
      />
      <SubTitle children={ intl.formatMessage({ id: 'manage.tags.create_and_organize' }) } />
      <BodyWrapper>
        <AddTag addTag={ addTag } />
        <NotAddedTags
          addTag={ addTag }
          newAddedTags={ newAddedTags }
          newNotAddedTags={ newNotAddedTags }
          notAddedTags={ notAddedTags }
        />
        <AddedTags removeTag={ removeTag } newAddedTags={ newAddedTags } />
      </BodyWrapper>
    </Modal>
  );
};

export default CreateVulnTagModal;
