import fetchApi from 'services/api/connector';

export const fetchSchedules = (page, pageSize, sortBy, sortDirection) => fetchApi(`agents_schedule?page=${page}&page_size=${pageSize}&sort=${sortBy}&sort_dir=${sortDirection}`, { method: 'GET' });

export const removeSchedule = (id) => fetchApi(`agents_schedule/${id}`, { method: 'DELETE' });

export const createSchedule = (agentId, crontabName, active, crontab, description, executorId, parameters, workspaces_names) => fetchApi('agents_schedule', {
  method: 'POST',
  data: {
    id: 0,
    description,
    crontab,
    crontab_name: crontabName,
    timezone: '',
    agent_id: agentId,
    executor_id: executorId,
    parameters,
    active,
    workspaces_names
  }
});

export const updateSchedule = (scheduleId, agentId, crontabName, active, crontab, description, executorId, parameters, workspaces_names) => fetchApi(`agents_schedule/${scheduleId}`, {
  method: 'PATCH',
  data: {
    id: scheduleId,
    description,
    crontab,
    crontab_name: crontabName,
    timezone: '',
    agent_id: agentId,
    executor_id: executorId,
    parameters,
    active,
    workspaces_names
  }
});

export const getSchedule = (id) => fetchApi(`agents_schedule/${id}`, { method: 'GET' });

export const changeStatus = (id, active) => fetchApi(`agents_schedule/${id}`, { version: 'v3', method: 'PATCH', data: { active } });

export const fetchTotalSchedules = () => fetchApi('agents_schedule?page=1&page_size=100', { method: 'GET' });

export const runSchedule = (id) => fetchApi(`agents_schedule/${id}/run`, { method: 'POST' });
