import React from 'react';
import { TableHeader } from '../../styled';
import {
  IconCell, NameCell, VulnsCountCell,
  CreatorCell, LastActivityCell, DownloadCell,
  CloneAndUpdateCell, CloneAndEditCell, DeleteCell
} from '.';

export default function getColumns (action, isFetching) {
  const columns = [{
    Header: () => <div />,
    width: 40,
    headerClassName: 'no-border-right',
    className: 'icon',
    resizable: false,
    sortable: false,
    Cell: (rowInfo) => <IconCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <TableHeader>NAME</TableHeader>,
    id: 'name',
    resizable: false,
    headerClassName: 'no-border-left',
    Cell: (rowInfo) => <NameCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <TableHeader>VULNS</TableHeader>,
    id: 'vuln_count',
    key: 'vuln_count',
    maxWidth: 81,
    resizable: false,
    Cell: (rowInfo) => <VulnsCountCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <TableHeader>CREATOR</TableHeader>,
    id: 'creator',
    key: 'creator',
    maxWidth: 102,
    resizable: false,
    Cell: (rowInfo) => <CreatorCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <TableHeader>LAST ACTIVITY</TableHeader>,
    resizable: false,
    id: 'create_time',
    maxWidth: 141,
    Cell: (rowInfo) => <LastActivityCell rowInfo={ rowInfo } isFetching={ isFetching } cloneAction={ action.clone } />
  }, {
    Header: () => <></>,
    id: 'export',
    key: 'export',
    width: 60,
    resizable: false,
    sortable: false,
    headerClassName: 'no-border-right',
    className: 'actionButton',
    Cell: (rowInfo) => <DownloadCell rowInfo={ rowInfo } isFetching={ isFetching } />
  }, {
    Header: () => <></>,
    resizable: false,
    sortable: false,
    width: 60,
    id: 'clone',
    className: 'actionButton',
    headerClassName: 'no-border-right',
    Cell: (rowInfo) => <CloneAndUpdateCell rowInfo={ rowInfo } isFetching={ isFetching } cloneAction={ action.clone } />
  }, {
    Header: () => <></>,
    resizable: false,
    sortable: false,
    width: 60,
    id: 'edit',
    className: 'actionButton',
    headerClassName: 'no-border-right',
    Cell: (rowInfo) => <CloneAndEditCell rowInfo={ rowInfo } isFetching={ isFetching } editAction={ action.edit } />
  }, {
    Header: () => <></>,
    resizable: false,
    sortable: false,
    width: 60,
    id: 'delete',
    className: 'actionButton',
    headerClassName: 'no-border-right',
    Cell: (rowInfo) => <DeleteCell rowInfo={ rowInfo } isFetching={ isFetching } deleteAction={ action.delete } />
  }];

  return columns;
}
