import styled from 'styled-components';
import colors from 'Styles/colors';
import { fadeIn } from 'Styles/effects';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(250 251 252 / 0.7);
  z-index: 500;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  & > :first-child {
    box-shadow: 18px 43px 50px 0 rgba(167, 167, 167, 0.5);
    border-top: 10px solid ${colors.softBlue};
    border-radius: 2px 2px 0 0;
    padding: 36px 40px;
    overflow: scroll;
    max-height: 85%;
    height: auto;
  }
  ${fadeIn(0, 1)}
`;
ModalWrapper.displayName = 'ModalWrapper';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  background: white;
  height: 100%;
  max-height: 500px;
  max-width: 550px;
  width: 100%;
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  color: ${colors.dark2};
  padding-bottom: 34px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 13.5px;
  font-weight: normal;
  line-height: 1.03;
  color: ${colors.grey19};
  margin-bottom: 20px;
`;
Subtitle.displayName = 'Subtitle';

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 21px 0 0;
  margin-top: auto;
  & > *:not(:last-child) {
    margin-right: 13px;
  }
`;
WrapperButtons.displayName = 'WrapperButtons';
