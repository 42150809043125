import React from 'react';
import ContextMenu from 'Common/Components/ContextMenu';
import { Separator } from 'Common/Components/ContextMenu/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShowContextMenu, selectContextMenuXPos, selectContextMenuYPos, selectVulnsSelected, selectReadOnly
} from 'store/Manage/selectors';
import { showContextMenu } from 'store/Manage/actions';
import { selectShowCreateTagModal } from 'store/Tags/selectors';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { selectUserRole } from 'store/Faraday/selectors';
import {
  AddNewEvidence, Delete, Edit, Filter, Severity, Status, Tags, AddComment, CopyLink, CopyApiLink, SaveAsTemplate
} from './components';
import ApplyTemplate from './components/ApplyTemplate';

const VulnsContextMenu = () => {
  const role = useSelector(selectUserRole);
  const dispatch = useDispatch();
  const displayContextMenu = useSelector(selectShowContextMenu);
  const xPos = useSelector(selectContextMenuXPos);
  const yPos = useSelector(selectContextMenuYPos);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const showCreateTagModal = useSelector(selectShowCreateTagModal);
  const isBulk = vulnsSelected.length > 1;
  const areVulnsSelected = vulnsSelected.length > 0;
  const readOnly = useSelector(selectReadOnly);
  const showLine = !(role === 'client' || role === 'asset_owner');

  if (readOnly || !displayContextMenu || !areVulnsSelected) return null;
  return (
    <ContextMenu
      show={ displayContextMenu }
      xPos={ xPos }
      yPos={ yPos }
      displayFunction={ (show, x, y) => dispatch(showContextMenu(show, x, y)) }
      disableOutsideClick={ showCreateTagModal }
    >
      <Filter />
      <Separator show={ !isBulk && areVulnsSelected } />
      <CopyLink />
      <CopyApiLink />
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
        <Edit />
      </WithPermission>
      <Tags />
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
        <Severity />
        <Status />
      </WithPermission>
      <AddComment />
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
        <AddNewEvidence />
        <Separator show />
        <SaveAsTemplate />
        <ApplyTemplate />
      </WithPermission>
      <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.DELETE }>
        <Separator show={ showLine } />
        <Delete />
      </WithPermission>
    </ContextMenu>
  );
};

export default VulnsContextMenu;
