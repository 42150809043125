/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeVulnsTags } from 'store/Manage/actions';
import { selectVulnDetail, selectVulnDetailTags } from 'store/Manage/selectors';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import {
  TagsWrapper,
  Tag,
  TagTitle,
  TagClose,
  Close
} from './styled';

const Tags = () => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);
  const vulnTags = useSelector(selectVulnDetailTags);
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const removeTag = (tagToRemove) => dispatch(removeVulnsTags(vuln, tagToRemove));

  return (
    <TagsWrapper>
      {
        vulnTags.map((tag, i) => (
          <Tag key={ `${tag}_${i}` }>
            <TagTitle children={ tag } />
            <TagClose show={ canUpdate } onClick={ () => removeTag(tag) }>
              <Close />
            </TagClose>
          </Tag>
        ))
      }
    </TagsWrapper>
  );
};

export default Tags;
