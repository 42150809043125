import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { resetFilters } from 'store/Filters/actions';
import { getById, resetState } from '../../store/ExecutiveReportEditCreate/actions';
import { Wrapper, ContentWrapper } from './styled';
import ReportHeader from './components/Header';
import ReportForm from './components/ReportForm';
import NewReportPreview from './components/ReportPreview';

const ExecutiveReportEditCreate = ({ edit, match }) => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);

  useEffect(() => {
    if (edit) {
      const reportId = get(match, 'params.id', -1);
      const parsedId = parseInt(reportId, 10);
      dispatch(getById(parsedId));
    }
    return () => {
      dispatch(resetState());
      dispatch(resetFilters('reports'));
    };
  }, [match, edit, dispatch, workspaceSelected]);

  return (
    <Wrapper>
      <ReportHeader isEditing={ edit } />
      <ContentWrapper>
        <ReportForm isEditing={ edit } />
        <NewReportPreview />
      </ContentWrapper>
    </Wrapper>
  );
};

ExecutiveReportEditCreate.propTypes = {
  edit: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired
};

ExecutiveReportEditCreate.defaultProps = {
  edit: false
};

export default ExecutiveReportEditCreate;
