export const PERMISSION_TYPES = {
  CREATE: 'create',
  DELETE: 'delete',
  READ: 'read',
  UPDATE: 'update',
  PATCH: 'patch'
};

export const FEATURE_2FA = '2fa';
export const FEATURE_AGENTS = 'agents';
export const FEATURE_CLOUD_AGENTS = 'cloud_agents';
export const FEATURE_COMMENTS = 'comment';
export const FEATURE_CUSTOM_FIELDS = 'custom_fields';
export const FEATURE_DUPLICATES = 'duplicates';
export const FEATURE_HOSTS = 'hosts';
export const FEATURE_JOBS = 'jobs';
export const FEATURE_MY_SUBSCRIPTION = 'my_subscription';
export const FEATURE_PIPELINES = 'pipelines';
export const FEATURE_REPORTING = 'executive_reports';
export const FEATURE_SCHEDULER = 'scheduler';
export const FEATURE_SETTINGS_REPORTING = 'settings_executive_reports';
export const FEATURE_SETTINGS_SMTP = 'settings_smtp';
export const FEATURE_SETTINGS_LDAP = 'settings_ldap';
export const FEATURE_SETTINGS_SAML = 'settings_saml';
export const FEATURE_TAGS = 'tags';
export const FEATURE_TICKETING_GITLAB = 'ticketing_gitlab';
export const FEATURE_TICKETING_JIRA = 'ticketing_jira';
export const FEATURE_TICKETING_SERVICENOW = 'ticketing_servicenow';
export const FEATURE_TICKETING_WHD = 'ticketing_whd';
export const FEATURE_USERS = 'users';
export const FEATURE_VULN_TEMPLATE = 'vulnerability_template';
export const FEATURE_VULNS = 'vulns';
export const FEATURE_WORKSPACES = 'workspaces';
export const FEATURE_PROJECTS = 'projects';
export const FEATURE_TASKS = 'tasks';
export const FEATURE_ANALYTICS = 'analytics';
