import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addFilter, removeFilter, storeDashboardFilter } from 'store/Filters/actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import { CLOSED_FILTERS, CONFIRMED_FLAG_FILTERS, DASHBOARD_FILTERS } from 'store/Filters/constants';
import Checkbox from 'Common/Components/Checkbox';
import { isFilteringBy, selectStoredDashboardFilter } from 'store/Filters/selectors';
import api from 'services/api';
import {
  WrapperSeverity, Title, Wrapper, Empty, Severity, Header, FilterIcon, FilterWrapper, Dropdown, Selector, Option, ArrowDown
} from './styled';

const Filter = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;
  const storeFilters = useSelector(selectStoredDashboardFilter);

  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));

  useEffect(() => {
    if (storeFilters.confirmed)dispatch(addFilter('vulns', confirmed));
    if (storeFilters.closed)dispatch(addFilter('vulns', closed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmedFilterToggle = () => {
    dispatch(storeDashboardFilter('confirmed', !isFilteringByConfirm));
    if (isFilteringByConfirm) dispatch(removeFilter('vulns', confirmed));
    else dispatch(addFilter('vulns', confirmed));
  };
  const closedFilterToggle = () => {
    dispatch(storeDashboardFilter('closed', !isFilteringByClosed));
    if (isFilteringByClosed) dispatch(removeFilter('vulns', closed));
    else dispatch(addFilter('vulns', closed));
  };

  const content = () => {
    if (isFilteringByConfirm && isFilteringByClosed) return 'Confirmed, Not Closed';
    if (isFilteringByConfirm) return 'Confirmed';
    if (isFilteringByClosed) return 'Not Closed';
    return 'Showing All';
  };

  return (
    <FilterWrapper>
      <FilterIcon onClick={ () => setIsOpen(!isOpen) } $active={ isFilteringByConfirm || isFilteringByClosed } />
      <Dropdown onClick={ () => setIsOpen(!isOpen) } >
        {content()}
        <ArrowDown />
      </Dropdown>
      {isOpen && (
      <Selector>
        <Option onClick={ confirmedFilterToggle }>
          <Checkbox state={ isFilteringByConfirm } />
          Confirmed
        </Option>
        <Option onClick={ closedFilterToggle } >
          <Checkbox state={ isFilteringByClosed } />
          Not Closed
        </Option>
      </Selector>
      ) }
    </FilterWrapper>
  );
};

const Vulnerabilities = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const workspaceSelected = useSelector(selectCurrentWorkspace);

  const types = ['critical', 'high', 'medium', 'low', 'informational'];
  const groups = types.map((type) => {
    if (!data || !data.groups) return null;
    let newType = data.groups.find((el) => el.severity === type);

    if (!newType) newType = { count: 0, name: type, severity: type };
    // eslint-disable-next-line consistent-return
    return newType;
  });

  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;

  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));

  const filterArr = useMemo(() => {
    const confirmed = '{"name":"confirmed","op":"eq","val":"true"}';
    const closed = '{"name":"status","op":"neq","val":"closed"}';
    const arr = [];

    if (isFilteringByConfirm) arr.push(confirmed);
    if (isFilteringByClosed) arr.push(closed);
    return `[${arr.toString()}]`;
  }, [isFilteringByConfirm, isFilteringByClosed]);

  const rootManage = (severity) => {
    const filterBySeverity = DASHBOARD_FILTERS.vulnerabilitiesBySeverity(severity);
    const filterByConfirmed = isFilteringByConfirm ? CONFIRMED_FLAG_FILTERS.confirmed : CONFIRMED_FLAG_FILTERS.notConfirmed;

    dispatch(addFilter('vulns', filterBySeverity));
    dispatch(addFilter('vulns', filterByConfirmed));
    if (isFilteringByClosed) dispatch(addFilter('vulns', CLOSED_FILTERS.closed));
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  useEffect(() => {
    const call = async () => {
      const filter = `{"filters":${filterArr},"group_by":[{"field":"severity"}]}`;
      const res = await api.manage.getVulns(workspaceSelected, filter);

      const arr = res.vulnerabilities.map((sev) => sev.value).filter(vuln => vuln.severity !== 'unclassified');
      setData({ total_count: arr.reduce((prev, curr) => prev + curr.count, 0), groups: arr });
    };
    call();
  }, [filterArr, workspaceSelected]);
  return (
    <Wrapper>
      <Header>
        <Title>Vulnerabilities by Severity</Title>
        <Filter />
      </Header>
      {data && data.groups
        ? (
          <WrapperSeverity>
            {groups.map((group) => <Severity type={ group.severity } onClick={ () => { rootManage(group.severity); } } width={ data.total_count ? group.count / data.total_count : 1000 } key={ group.severity }>{group.count}</Severity>)}
          </WrapperSeverity>
          )
        : <Empty>No vulnerabilities has been found yet.</Empty>}
    </Wrapper>
  );
};

export default Vulnerabilities;
