import React from 'react';
import PropTypes from 'prop-types';
import { TitleWrapper, Title, Subtitle } from 'Screens/Analytics/components/ActionBar/styled';
import Export from './components/Export';
import Cancel from './components/Cancel';
import Save from './components/Save';
import { Wrapper, WrapperButtons } from './styled';

const ActionBar = ({ download }) => (
  <Wrapper>
    <TitleWrapper>
      <Title>Analytics</Title>
      <Subtitle>- Graph data</Subtitle>
    </TitleWrapper>
    <WrapperButtons>
      <Save />
      <Export download={ download } />
      <Cancel />
    </WrapperButtons>
  </Wrapper>
);

ActionBar.propTypes = {
  download: PropTypes.func.isRequired
};

export default ActionBar;
