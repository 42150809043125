import styled from 'styled-components';
import { ReactComponent as _Close } from 'Images/close-feedback.svg';
import { ReactComponent as _Message } from 'Images/user-feedback.svg';
import { ReactComponent as _Success } from 'Images/check.svg';

export const Text = styled.div`
  margin-left: 10px;
  width: 140px;
  display: none;
  user-select: none;
  color: #fff;
  font-size: 20px;
  margin-top: -3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  padding-top: 12px;
`;
Text.displayName = 'Text';

export const Close = styled(_Close)`
`;
Close.displayName = 'Close';

export const CloseForm = styled(_Close)`
  width: 8px;
  height: 8px;
  path {
    stroke: #63758d;
    stroke-width: 7;
  }
`;
CloseForm.displayName = 'CloseForm';

export const MessageWrapper = styled.div`
  background-color: #0082ED;
  border-radius: 50%;
  position: absolute;
  right: 0px;
`;
MessageWrapper.displayName = 'MessageWrapper';

export const Message = styled(_Message)`
`;
Message.displayName = 'Message';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .btn-form {
    padding: 7px 25px !important;
    position: fixed !important;
    bottom: 71px !important;
    right: 83px !important;
  }
`;
ContentWrapper.displayName = 'ContentWrapper';

export const SuccessWrapper = styled.div`
  display: ${(props) => (props.success ? 'flex' : 'none')};
  justify-content: center;
  width: 100%;
  margin-top: 50px;
`;
SuccessWrapper.displayName = 'SuccessWrapper';

export const Success = styled(_Success)`
  width: 86px;
  height: 86px;
  color: #90a9c0;
  cursor: default;
`;
Success.displayName = 'Success';

export const CloseWrapper = styled.div`
  margin-left: 13px;
  cursor: pointer;
  background-color: #fafbfc;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
      background-color: #eff1f3;
  }
`;
CloseWrapper.displayName = 'CloseWrapper';

export const FormCloseWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  background-color: #fafbfc;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover{
      background-color: #eff1f3;
  }
`;
FormCloseWrapper.displayName = 'FormCloseWrapper';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
  z-index: 2;
  background: #0082ED;
  border-radius: 50px;
  width: auto;
  transition: all .5s ease-out;
  width: 55px;
  cursor: pointer;
  height: 55px;

  &:hover{
    width: 252px;
  }

  &:hover ${Text}{
    display: block;
  }

  & ${CloseWrapper}{
    background-color: #8ac4ff;
    margin-top: -3px;
  }

  &:not(:hover) ${CloseWrapper}{
    display: none;
  }

`;
Wrapper.displayName = 'Wrapper';

export const ButtonWrapper = styled.div`
  position: relative;
  display:flex;
  align-items: center;
  height: 55px;
`;
ButtonWrapper.displayName = 'ButtonWrapper';

export const FormWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;
  width: 400px;
  height: 461px;
  padding: 13px 33px;
  border-radius: 4px;
  box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
  z-index: 2;
  
  & .ff-button-bar {
    display: flex !important;
    justify-content: flex-end !important;

    button{
      min-width: auto !important;
      min-height: auto !important;
      line-height: 1 !important;
      padding: 9px 27px !important;
      & img{
        display: none !important;
      }
    }
    .btn-primary{
      color: #fff !important;
      background-color: #0082ed !important;
      border-color: #0082ed !important;
      border-radius: 2px !important;
      height: min-content !important;
      font-size: 14px !important;
    }
  }

  & .ff-powered-img {
    height: 0px !important;
    width: 0px !important;
    visibility: hidden !important;
  }

  & .ff-partial {
    display: none !important;
  }

  & .text-center input{
      visibility: visible !important;
      position: inherit !important;
  }

  & .ff-title{
    font-size: 21px !important;
    font-weight: 600 !important;
    color: #0082ed !important;
  }

  & .ff-title{
    font-size: 21px !important;
    font-weight: 600 !important;
    color: #0082ed !important;
  }

  & .form-group {
      & label {
        font-size: 14px !important;
        font-weight: normal !important;
        color: #1c2243 !important;
      }
      & textarea {
        background: #edf2f7 !important;
        resize: none !important;
        height: 137px !important;
        padding: 7px !important;
      }
  }

  & #ff-success {
    min-height: 100% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    text-align: center !important;

    & + div button {
        display: none !important;
    }

    & h3 {
        font-size: 34px !important;
        font-weight: 600 !important;
        color: #0082ed !important;
        margin-bottom: 18px !important;
    }

    & p {
        font-size: 14px !important;
        text-align: center !important;
        font-weight: normal !important;
        color: #1c2243 !important;
    }
  }
`;
FormWrapper.displayName = 'FormWrapper';
