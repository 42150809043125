// --- Aux functions to create keys
export const createNameKey = (label, value) => ({
  name: {
    label,
    value
  }
});
const searchByFilterKey = (name, autocomplete, operators) => ({
  name,
  autocomplete,
  operators
});
const groupByKey = (label, field) => ({
  label,
  value: [
    {
      field
    }
  ]
});
export const createFilter = (name, op, val) => ({
  name,
  op,
  val
});
export const createLabelName = (label, name) => ({
  label,
  name
});

const createLabelValue = (label, value) => ({
  label,
  value
});

const FILTER_OPERATORS = {
  eq: createNameKey('=', '=='),
  neq: createNameKey('!=', '!='),
  like: createNameKey('contains', 'like'),
  ilike: createNameKey('contains', 'ilike'),
  greaterOrEqual: createNameKey('>=', '>='),
  greater: createNameKey('>', '>'),
  lessOrEqual: createNameKey('<=', '<='),
  less: createNameKey('<', '<'),
  has: createNameKey('has', 'has'),
  any: createNameKey('any', 'any'),
  in: createNameKey('in', 'in'),
  notIn: createNameKey('not in', 'not_in'),
  isNull: createNameKey('is null', 'is_null'),
  isNotNull: createNameKey('is not null', 'is_not_null'),
  and: createNameKey('&', 'and'),
  or: createNameKey('||', 'or')
};

export const {
  eq, neq, like, ilike, greaterOrEqual, greater, lessOrEqual,
  less, has, any, notIn, isNull, isNotNull, and, or
} = FILTER_OPERATORS;

const commandIdFilter = (id) => ({
  name: 'creator_command_id',
  op: eq.name.value,
  val: id
});

const vulnerabilitiesBySeverity = (severityType) => ({
  name: 'severity',
  op: eq.name.value,
  val: severityType
});

const vulnerabilitiesByStatus = (statusType) => ({
  name: 'status',
  op: eq.name.value,
  val: statusType
});

const vulnerabilityById = (id) => ({
  name: 'id',
  op: eq.name.value,
  val: id
});

const vulnerabilitiesByType = (type) => ({
  name: 'type',
  op: eq.name.value,
  val: type
});

const assetsByIp = (ip) => ({
  name: 'ip',
  op: eq.name.value,
  val: ip
});

const assetsByServiceName = (serviceName) => ({
  name: 'services',
  op: 'any',
  val: {
    name: 'name',
    op: eq.name.value,
    val: serviceName
  }
});

const vulnsByTag = (tag) => ({
  name: 'tags__name',
  op: eq.name.value,
  val: tag
});

const assetsById = (id) => ({
  name: 'id',
  op: eq.name.value,
  val: id
});

// ------------------------------------

const SEARCH_BY_AUTOCOMPLETE_KEYS = {
  critical: createNameKey('Critical', 'critical'),
  high: createNameKey('High', 'high'),
  medium: createNameKey('Medium', 'medium'),
  low: createNameKey('Low', 'low'),
  informational: createNameKey('Informational', 'informational'),
  unclassified: createNameKey('Unclassified', 'unclassified'),
  true: createNameKey('True', 'true'),
  false: createNameKey('False', 'false'),
  trivial: createNameKey('Trivial', 'trivial'),
  simple: createNameKey('Simple', 'simple'),
  moderate: createNameKey('Moderate', 'moderate'),
  difficult: createNameKey('Difficult', 'difficult'),
  infeasible: createNameKey('Infeasible', 'infeasible'),
  vulnerability: createNameKey('Vulnerability', 'vulnerability'),
  vulnerability_web: createNameKey('Vulnerability Web', 'vulnerability_web'),
  open: createNameKey('Open', 'open'),
  closed: createNameKey('Closed', 'closed'),
  filtered: createNameKey('Filtered', 'filtered'),
  reOpened: createNameKey('Re-Opened', 're-opened'),
  riskAccepted: createNameKey('Risk Accepted', 'risk-accepted')
};

const SEVERITY_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.critical,
  SEARCH_BY_AUTOCOMPLETE_KEYS.high,
  SEARCH_BY_AUTOCOMPLETE_KEYS.medium,
  SEARCH_BY_AUTOCOMPLETE_KEYS.low,
  SEARCH_BY_AUTOCOMPLETE_KEYS.informational,
  SEARCH_BY_AUTOCOMPLETE_KEYS.unclassified
];

const BOOL_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.true,
  SEARCH_BY_AUTOCOMPLETE_KEYS.false
];

const EASE_OF_RESOLUTION_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.trivial,
  SEARCH_BY_AUTOCOMPLETE_KEYS.simple,
  SEARCH_BY_AUTOCOMPLETE_KEYS.moderate,
  SEARCH_BY_AUTOCOMPLETE_KEYS.difficult,
  SEARCH_BY_AUTOCOMPLETE_KEYS.infeasible
];

const VULN_TYPE_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.vulnerability,
  SEARCH_BY_AUTOCOMPLETE_KEYS.vulnerability_web
];

const STATUS_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.open,
  SEARCH_BY_AUTOCOMPLETE_KEYS.closed,
  SEARCH_BY_AUTOCOMPLETE_KEYS.reOpened,
  SEARCH_BY_AUTOCOMPLETE_KEYS.riskAccepted
];

const SERVICES_STATUS_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.open,
  SEARCH_BY_AUTOCOMPLETE_KEYS.closed,
  SEARCH_BY_AUTOCOMPLETE_KEYS.filtered
];

export const SEARCH_BY_FILTER_KEYS = {
  asset: searchByFilterKey({ label: 'Asset', value: 'target' }, [], [eq, neq, ilike]),
  confirmed: searchByFilterKey({ label: 'Confirmed', value: 'confirmed' }, BOOL_AUTOCOMPLETE, [eq]),
  createDate: searchByFilterKey({ label: 'Creation Date', value: 'create_date' }, [], [eq, lessOrEqual, greaterOrEqual, less, greater]),
  creatorCommandId: searchByFilterKey({ label: 'Creator command ID', value: 'creator_command_id' }, [], [eq, neq]),
  data: searchByFilterKey({ label: 'Data', value: 'data' }, [], [eq, neq, ilike]),
  description: searchByFilterKey({ label: 'Description', value: 'description' }, [], [eq, neq, ilike]),
  easeOfResolution: searchByFilterKey({ label: 'Ease of Resolution', value: 'ease_of_resolution' }, EASE_OF_RESOLUTION_AUTOCOMPLETE, [eq, neq]),
  externalId: searchByFilterKey({ label: 'External ID', value: 'external_id' }, [], [eq, neq, ilike]),
  hostnames__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [ilike]),
  hostNames: searchByFilterKey({ label: 'Hostnames', value: 'hostnames__name' }, [], [any]),
  id: searchByFilterKey({ label: 'ID', value: 'id' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  ip: searchByFilterKey({ label: 'Asset', value: 'ip' }, [], [eq, neq, ilike]),
  method: searchByFilterKey({ label: 'Method', value: 'method' }, [], [eq, neq, ilike]),
  name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [eq, neq, ilike]),
  os: searchByFilterKey({ label: 'OS', value: 'os' }, [], [eq, neq, ilike]),
  owned: searchByFilterKey({ label: 'Owned', value: 'owned' }, BOOL_AUTOCOMPLETE, [eq]),
  path: searchByFilterKey({ label: 'Path', value: 'path' }, [], [eq, neq, ilike]),
  request: searchByFilterKey({ label: 'Request', value: 'request' }, [], [eq, neq, ilike]),
  resolution: searchByFilterKey({ label: 'Resolution', value: 'resolution' }, [], [eq, neq, ilike]),
  response: searchByFilterKey({ label: 'Response', value: 'response' }, [], [eq, neq, ilike]),
  service__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [eq, neq, ilike]),
  service__port: searchByFilterKey({ label: 'Port', value: 'port' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  serviceName: searchByFilterKey({ label: 'Service name', value: 'service__name' }, [], [has]),
  servicePort: searchByFilterKey({ label: 'Service port', value: 'service__port' }, [], [has]),
  services__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [eq, neq, ilike]),
  services__port: searchByFilterKey({ label: 'Port', value: 'port' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  services__status: searchByFilterKey({ label: 'Status', value: 'status' }, SERVICES_STATUS_AUTOCOMPLETE, [eq, neq]),
  servicesName: searchByFilterKey({ label: 'Services name', value: 'services__name' }, [], [any]),
  servicesPort: searchByFilterKey({ label: 'Services port', value: 'services__port' }, [], [any]),
  servicesStatus: searchByFilterKey({ label: 'Services status', value: 'services__status' }, [], [any]),
  severity: searchByFilterKey({ label: 'Severity', value: 'severity' }, SEVERITY_AUTOCOMPLETE, [eq, neq]),
  status: searchByFilterKey({ label: 'Status', value: 'status' }, STATUS_AUTOCOMPLETE, [eq, neq]),
  tag: searchByFilterKey({ label: 'Tag', value: 'tags__name' }, [], [eq, neq]),
  tool: searchByFilterKey({ label: 'Tool', value: 'tool' }, [], [eq, neq, ilike]),
  type: searchByFilterKey({ label: 'Type', value: 'type' }, VULN_TYPE_AUTOCOMPLETE, [eq, neq])
};

export const GROUP_BY = {
  none: {
    key: groupByKey('None', ''),
    filter: null
  },
  name: {
    key: groupByKey('Name', 'name'),
    filter: createFilter('name', ilike.name.value, '')
  },
  severity: {
    key: groupByKey('Severity', 'severity'),
    filter: createFilter('severity', eq.name.value, '')
  },
  services__name: {
    key: groupByKey('Service name', 'services__name'),
    filter: createFilter('services', any.name.value, '')
  },
  status: {
    key: groupByKey('Status', 'status'),
    filter: createFilter('status', eq.name.value, '')
  },
  hostnames__name: {
    key: groupByKey('Hostnames', 'hostnames__name'),
    filter: createFilter('hostnames', any.name.value, '')
  },
  target_host_ip: {
    key: groupByKey('Asset', 'target_host_ip'),
    filter: createFilter('target_host_ip', ilike.name.value, '')
  },
  os: {
    key: groupByKey('OS', 'os'),
    filter: createFilter('os', eq.name.value, '')
  },
  cveAndTarget: {
    key: {
      label: 'CVE & Target',
      value: [
        {
          field: 'cve_instances__name'
        },
        {
          field: 'target'
        }
      ]
    },
    filter: createFilter('cve_instances__name', eq.name.value, '')
  }
};

export const CONTEXT_MENU_FILTER_KEYS = {
  asset: {
    key: createLabelName('Asset', 'target'),
    filter: createFilter('target', eq.name.value, '')
  },
  date: {
    key: createLabelName('Date', 'create_date'),
    filter: createFilter('create_date', eq.name.value, '')
  },
  name: {
    key: createLabelName('Name', 'name'),
    filter: createFilter('name', ilike.name.value, '')
  },
  service: {
    key: createLabelName('Service name', 'service'),
    filter: createFilter('service', has.name.value, '')
  },
  status: {
    key: createLabelName('Status', 'status'),
    filter: createFilter('status', eq.name.value, '')
  },
  tags: {
    key: createLabelName('Tags', 'tags'),
    filter: createFilter('tags', has.value, '')
  }
};

// --- VULN KEYS ---

export const VULNS_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.asset,
  SEARCH_BY_FILTER_KEYS.confirmed,
  SEARCH_BY_FILTER_KEYS.createDate,
  SEARCH_BY_FILTER_KEYS.creatorCommandId,
  SEARCH_BY_FILTER_KEYS.data,
  SEARCH_BY_FILTER_KEYS.description,
  SEARCH_BY_FILTER_KEYS.easeOfResolution,
  SEARCH_BY_FILTER_KEYS.externalId,
  SEARCH_BY_FILTER_KEYS.id,
  SEARCH_BY_FILTER_KEYS.method,
  SEARCH_BY_FILTER_KEYS.name,
  SEARCH_BY_FILTER_KEYS.path,
  SEARCH_BY_FILTER_KEYS.request,
  SEARCH_BY_FILTER_KEYS.resolution,
  SEARCH_BY_FILTER_KEYS.response,
  SEARCH_BY_FILTER_KEYS.serviceName,
  SEARCH_BY_FILTER_KEYS.servicePort,
  SEARCH_BY_FILTER_KEYS.severity,
  SEARCH_BY_FILTER_KEYS.status,
  SEARCH_BY_FILTER_KEYS.tag,
  SEARCH_BY_FILTER_KEYS.tool,
  SEARCH_BY_FILTER_KEYS.type
];

export const VULNS_GROUP_BY_KEYS = [
  GROUP_BY.none.key,
  GROUP_BY.name.key,
  GROUP_BY.severity.key,
  GROUP_BY.status.key,
  GROUP_BY.target_host_ip.key,
  GROUP_BY.cveAndTarget.key
];

export const VULNS_CONTEXT_MENU_FILTER_KEYS = [
  CONTEXT_MENU_FILTER_KEYS.name.key,
  CONTEXT_MENU_FILTER_KEYS.service.key,
  CONTEXT_MENU_FILTER_KEYS.asset.key,
  CONTEXT_MENU_FILTER_KEYS.date.key,
  CONTEXT_MENU_FILTER_KEYS.status.key,
  CONTEXT_MENU_FILTER_KEYS.tags.key
];

// --- ASSETS KEYS ---

export const ASSETS_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.ip,
  SEARCH_BY_FILTER_KEYS.hostNames,
  SEARCH_BY_FILTER_KEYS.id,
  SEARCH_BY_FILTER_KEYS.os,
  SEARCH_BY_FILTER_KEYS.owned,
  SEARCH_BY_FILTER_KEYS.servicesName,
  SEARCH_BY_FILTER_KEYS.servicesPort,
  SEARCH_BY_FILTER_KEYS.servicesStatus
];

export const ASSETS_GROUP_BY_KEYS = [
  GROUP_BY.none.key,
  GROUP_BY.os.key,
  GROUP_BY.services__name.key,
  GROUP_BY.hostnames__name.key
];

export const REPORT_FILTERS = [
  {
    name: createLabelValue('Confirmed', 'confirmed'),
    filter: createFilter('confirmed', eq.name.value, 'true')
  },
  {
    name: createLabelValue('Not Confirmed', 'notConfirmed'),
    filter: createFilter('confirmed', eq.name.value, 'false')
  },
  {
    name: createLabelValue('Web', 'webVulnerability'),
    filter: createFilter('type', eq.name.value, 'vulnerability_web')
  },
  {
    name: createLabelValue('Vulnerability', 'vulnerability'),
    filter: createFilter('type', eq.name.value, 'vulnerability')
  },
  {
    name: createLabelValue('Critical', 'critical'),
    filter: createFilter('severity', eq.name.value, 'critical')
  },
  {
    name: createLabelValue('High', 'high'),
    filter: createFilter('severity', eq.name.value, 'high')
  },
  {
    name: createLabelValue('Medium', 'medium'),
    filter: createFilter('severity', eq.name.value, 'medium')
  },
  {
    name: createLabelValue('Low', 'low'),
    filter: createFilter('severity', eq.name.value, 'low')
  },
  {
    name: createLabelValue('Informational', 'informational'),
    filter: createFilter('severity', eq.name.value, 'informational')
  },
  {
    name: createLabelValue('Unclassified', 'unclassified'),
    filter: createFilter('severity', eq.name.value, 'unclassified')
  },
  {
    name: createLabelValue('Open', 'open'),
    filter: createFilter('status', eq.name.value, 'open')
  },
  {
    name: createLabelValue('Closed', 'closed'),
    filter: createFilter('status', eq.name.value, 'closed')
  },
  {
    name: createLabelValue('Re-Opened', 'reopened'),
    filter: createFilter('status', eq.name.value, 're-opened')
  },
  {
    name: createLabelValue('Risk-Accepted', 'riskAccepted'),
    filter: createFilter('status', eq.name.value, 'risk-accepted')
  }
];

// --- KB KEYS ---

export const KB_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.data,
  SEARCH_BY_FILTER_KEYS.description,
  SEARCH_BY_FILTER_KEYS.easeOfResolution,
  SEARCH_BY_FILTER_KEYS.externalId,
  SEARCH_BY_FILTER_KEYS.id,
  SEARCH_BY_FILTER_KEYS.name,
  SEARCH_BY_FILTER_KEYS.resolution,
  SEARCH_BY_FILTER_KEYS.severity
];

export const CONFIRMED_FLAG_FILTERS = {
  confirmed: {
    name: 'confirmed',
    op: eq.name.value,
    val: 'true'
  },
  notConfirmed: {
    name: 'confirmed',
    op: eq.name.value,
    val: 'false'
  }
};

export const CLOSED_FILTERS = {
  closed: {
    name: 'status',
    op: neq.name.value,
    val: 'closed'
  }
};

const kbByName = (name) => ({
  name: 'name',
  op: ilike.name.value,
  val: name
});

export const KB_FILTERS = {
  kbByName
};

export const DASHBOARD_FILTERS = {
  commandIdFilter,
  vulnerabilitiesBySeverity,
  vulnerabilitiesByStatus,
  vulnerabilityById,
  assetsByIp,
  assetsByServiceName,
  vulnerabilitiesByType,
  vulnsByTag,
  assetsById
};

export const KEY_LABELS_MAP = Object.entries(SEARCH_BY_FILTER_KEYS).map((key) => key[1].name);
export const OPERATOR_LABELS_MAP = Object.entries(FILTER_OPERATORS).map((operator) => operator[1].name);
export const AUTOCOMPLETE_LABELS_MAP = Object.entries(SEARCH_BY_AUTOCOMPLETE_KEYS).map((autocomplete) => autocomplete[1].name);
