import { ContextMenu } from 'react-contextmenu';
import styled from 'styled-components';

export const Wrapper = styled(ContextMenu)`
  display: inline-block;
  height: fit-content !important;
  width: 210px !important;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
