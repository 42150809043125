import {
  WORKSPACE_SELECTED_FOR_WORKING,
  GET_WORKSPACES_FOR_WORKING_STARTED,
  GET_WORKSPACES_FOR_WORKING_SUCCESS,
  GET_WORKSPACES_FOR_WORKING_FAILURE,
  STOP_REDIRECT,
  WORKSPACE_SELECTED,
  SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE,
  HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE,
  DELETE_WORKSPACES,
  HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED,
  GET_DATA_WORKSPACE_START,
  GET_DATA_WORKSPACE_FAIL,
  GET_DATA_WORKSPACE_SUCCESS,
  WORKSPACE_UPDATE_SUCCESS,
  SET_VISIBILITY_CREATE_MODAL_WORKSPACE,
  SET_USERS_WORKSPACES,
  WORKSPACE_MASSIVE_UPDATE_FAIL,
  WORKSPACE_MASSIVE_UPDATE_SUCCESS,
  MORE_OPTIONS_STATUS_CHANGE_WORKSPACE,
  SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE,
  TOGGLE_INACTIVE_FLAG,
  GET_DATA_WORKSPACES_SUCCESS,
  WORKSPACE_SHOW_GRID_VIEW,
  WORKSPACE_ENABLE_DISABLE
} from '../../Screens/Workspaces/actions/Actions';

const WS_KEY = 'workspaceSelected';

export const loadData = (key, defaultValue) => localStorage.getItem(key) || defaultValue;
export const saveData = (key, value) => {
  localStorage.setItem(key, value);
};

const initialState = {
  isFetching: false,
  workspaces: [],
  workspaceSelected: loadData(WS_KEY, ''),
  workspaceList: [],
  error: false,
  errorMessage: '',
  redirect: false,
  mode: '',
  showDeleteConfirmation: false,
  workspacesSelected: [],
  lastSelected: -1,
  lastWorkspaceSelected: {},
  lastIndexForRange: -1,
  showModalCreate: false,
  moreOptionsOpened: false,
  showModalAssignUsers: false,
  users: [],
  showInactive: false,
  workspaceCount: 0,
  showGridView: true
};

function faraday (state = initialState, action) {
  switch (action.type) {
    case GET_DATA_WORKSPACE_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_DATA_WORKSPACE_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }

    case GET_DATA_WORKSPACE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaces: action.data
      };
    }

    case WORKSPACE_SELECTED_FOR_WORKING: {
      saveData(WS_KEY, action.workspace);
      return { ...state, workspaceSelected: action.workspace /* redirect: action.redirect */ };
    }
    case STOP_REDIRECT:
      return { ...state, redirect: false };

    case GET_WORKSPACES_FOR_WORKING_STARTED:
      return { ...state, isFetching: true };

    case GET_WORKSPACES_FOR_WORKING_SUCCESS:
      return {
        ...state,
        workspaces: action.data,
        workspaceCount: action.data.length,
        isFetching: false
      };

    case GET_WORKSPACES_FOR_WORKING_FAILURE:
      return {
        ...state, error: true, errorMessage: action.data, isFetching: false
      };

    case WORKSPACE_SELECTED: {
      const {
        newRowsSelected, lastSelected, indexForRange, rowSelected
      } = action.vulnsSelectedInfo;

      return {
        ...state,
        lastWorkspaceSelected: rowSelected,
        workspacesSelected: newRowsSelected,
        lastSelected: lastSelected || -1,
        lastIndexForRange: indexForRange || -1
      };
    }

    case SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }

    case HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }

    case DELETE_WORKSPACES: {
      return {
        ...state,
        isFetching: true
      };
    }

    case HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED: {
      return {
        ...state,
        showDeleteConfirmation: false,
        workspacesSelected: initialState.workspacesSelected,
        lastWorkspaceSelected: initialState.lastWorkspaceSelected
      };
    }

    case WORKSPACE_UPDATE_SUCCESS: {
      const { workspaceList } = state;
      const indexWorkspaces = workspaceList.findIndex((wspace) => wspace.id === action.ws.id);
      workspaceList[indexWorkspaces] = { ...action.ws };

      return {
        ...state,
        workspaceList,
        isFetching: false
      };
    }

    case SET_VISIBILITY_CREATE_MODAL_WORKSPACE: {
      return {
        ...state,
        showModalCreate: action.value
      };
    }

    case SET_USERS_WORKSPACES: {
      return {
        ...state,
        users: action.users
      };
    }

    case WORKSPACE_MASSIVE_UPDATE_FAIL: {
      return {
        ...state,
        error: true,
        errorMessage: action.error
      };
    }

    case WORKSPACE_MASSIVE_UPDATE_SUCCESS: {
      return {
        ...state,
        workspaces: [...action.workspaces],
        showModalAssignUsers: false
      };
    }

    case MORE_OPTIONS_STATUS_CHANGE_WORKSPACE: {
      return {
        ...state,
        moreOptionsOpened: action.value
      };
    }

    case SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE: {
      return {
        ...state,
        showModalAssignUsers: action.value
      };
    }

    case TOGGLE_INACTIVE_FLAG: {
      return {
        ...state,
        showInactive: !state.showInactive
      };
    }

    case GET_DATA_WORKSPACES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        workspaceList: action.data,
        workspacesSelected: initialState.workspacesSelected
      };
    }

    case WORKSPACE_SHOW_GRID_VIEW: {
      return {
        ...state,
        showGridView: action.showGridView
      };
    }

    case WORKSPACE_ENABLE_DISABLE: {
      let readonly = state.readonly;

      if (action.ws === state.workspaceSelected) readonly = !readonly;

      return {
        ...state,
        isFetching: true,
        readonly
      };
    }

    default:
      return state;
  }
}

export default faraday;
