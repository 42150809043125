import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getJiraProjects, getJiraTemplate } from 'store/Settings/jiraActions';
import { selectAuthenticated, selectProjects } from 'store/Settings/jiraSelectors';
import {
  Title, Wrapper, Dropdown, Flex
} from './styled';

const ProjectsDropdown = ({ canShow }) => {
  const dispatch = useDispatch();
  const templates = useSelector(selectProjects);
  const authenticated = useSelector(selectAuthenticated);
  const [hasChanged, setHasChanged] = useState(false);
  const onChange = (e) => {
    dispatch(getJiraTemplate(e.value));
    // dispatch(setJiraTemplate(e));
  };
  useEffect(() => {
    if (authenticated && !hasChanged) {
      dispatch(getJiraProjects());
      setHasChanged(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, dispatch]);

  return canShow && (
    <Wrapper disabled={ !authenticated }>
      <Title>Projects</Title>
      <Flex>
        <Dropdown isDisabled={ !authenticated || !templates.length } options={ templates } onChange={ onChange } />
        {/* <BlueButton label="Get Projects" disabled={ !authenticated } onClick={ () => dispatch(getJiraProjects()) } /> */}
      </Flex>
    </Wrapper>
  );
};

export default ProjectsDropdown;
