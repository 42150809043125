/* eslint-disable react/no-children-prop */
import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { hideVulnModalDelete, deleteSelectedVulns } from 'store/Manage/actions';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectVulnsSelected } from 'store/Manage/selectors';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody, BoldText
} from './styled';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const showDeleteConfirmation = useSelector((state) => get(state, 'manage.showDeleteConfirmation', false));
  const selectedVulns = useSelector(selectVulnsSelected);
  const vulnsCount = selectedVulns.length;
  const isBulk = selectedVulns.length > 1;
  const hasDuplicates = selectedVulns.some((v) => get(v, 'duplicates_associated', []).length > 0);

  const bulk = `You’re deleting ${vulnsCount} Vulnerability(s)${hasDuplicates ? ', ' : ''}`;
  const single = `A vulnerability will be deleted${hasDuplicates ? ', ' : ''}`;
  const confirmationText = '. This action cannot be undone. Are you sure you want to proceed?';

  if (!showDeleteConfirmation) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>Delete Vulnerability(s)</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>
            <span children={ isBulk ? bulk : single } />
            <BoldText children={ hasDuplicates ? `including ${isBulk ? 'their' : 'its'} duplicates` : '' } />
            <span children={ confirmationText } />
          </BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ () => dispatch(hideVulnModalDelete()) }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ () => dispatch(deleteSelectedVulns()) }>
            Delete
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

export default DeleteConfirmationModal;
