import React from 'react';
import { useSelector } from 'react-redux';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import ReduxModal from 'Common/Components/ReduxModal';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import NotFound from 'Screens/Errores/NotFound';
import { PERMISSION_TYPES, FEATURE_AGENTS } from 'Hooks/usePermission/permissions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import Wrapper from './styled';
import ActionBar from './components/ActionBar';
import Grid from './components/Grid';
import { AgentsTour } from 'Common/Components/Tour';

const Agents = () => {
  const isAllowedByFeature = useSelector((state) => selectAllowedByFeature(state, FEATURE_AGENTS, PERMISSION_TYPES.READ));
  const isAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_AGENTS, PERMISSION_TYPES.READ));
  const isAllowed = isAllowedByFeature && isAllowedByRole;

  if (!isAllowed) return <NotFound />;
  return (
    <>
      <AgentsTour />
      <Wrapper>
        <ActionBar />
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showAgents } trackingClick={ ACTIONS.clickAgents } />
        </ReduxModal>
        <Grid />
      </Wrapper>
    </>
  );
};
export default Agents;
