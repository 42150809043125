/* eslint-disable react/no-children-prop */
import React from 'react';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  Backdrop, DropdownContainer, OptionsContainer, Option, OptionLabel
} from './styled';

const Dropdown = ({
  show, onHide, onSelect, options, isFirst, offset, intlId, isDateType
}) => {
  const onSelectOption = (option) => onSelect(option);

  if (!show) return null;
  return (
    <>
      <Backdrop onClick={ onHide } />
      <DropdownContainer isFirst={ isFirst } offset={ offset }>
        <OptionsContainer>
          {options.map((option) => (
            <Option key={ option } onClick={ () => onSelectOption(option) }>
              <OptionLabel>
                { option && <FormattedMessage id={ `${intlId}.${option}` } defaultMessage={ isDateType ? moment(option).format('L') : option } /> }
              </OptionLabel>
            </Option>
          ))}
          {options.length === 0 && (
          <Option>
            <OptionLabel>
              Type your query
            </OptionLabel>
          </Option>
          )}
        </OptionsContainer>
      </DropdownContainer>
    </>
  );
};

Dropdown.propTypes = {
  offset: PropTypes.number,
  isFirst: PropTypes.bool,
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  intlId: PropTypes.string.isRequired
};

Dropdown.defaultProps = {
  offset: 0,
  isFirst: false,
  show: false,
  options: []
};

export default Dropdown;
