import styled from 'styled-components';
import colors from 'Styles/colors';
import { DataContainer } from 'Common/Components/InputFilter/styled';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  color: ${colors.darkBlueGrey};
  margin-bottom: 13px;
`;
Title.displayName = 'Title';

export const SubTitle = styled.div`
  font-size: 12.5px;
  color: ${colors.greyBlue};
  margin-bottom: 13px;
`;
SubTitle.displayName = 'SubTitle';

export const InputWrapper = styled.div`
  input {
    width: 100%;
  }

  ${DataContainer} {
    margin-top: 2px;
    width: 100%;
    max-height: 125px;
    overflow-x: hidden;
  }
`;
InputWrapper.displayName = 'InputWrapper';

export const List = styled.div`
  margin-top: 21px;
  >:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
  }
  ${({ show }) => show && `
    border: 1px solid #e8e8e8;
  `}

`;
List.displayName = 'List';
