import api from 'services/api';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import head from 'lodash/head';
import lowerCase from 'lodash/lowerCase';
import upperCase from 'lodash/upperCase';

import { redirect } from 'store/Router/actions';
import { closeModal } from 'store/modals/actions';
import { MODAL_SAVE_JOB } from 'store/modals/modals';
import { selectRedirectTo } from 'store/Pipelines/selectors';
import { setRedirectTo } from 'store/Pipelines/actions';
import * as types from './types';
import {
  selectJobDescription,
  selectJobId,
  selectJobModel,
  selectJobName,
  selectJobRules, selectJobSelected, selectJobTaskIds, selectJobTasks
} from './selectors';

export function somethingWentWrong (message) {
  return async (dispatch) => {
    dispatch({ type: types.JOBS_FAIL, errorMessage: message || 'There was an error, please try again.' });
  };
}

export const parseRules = (job) => {
  const rules = head(get(job, 'rules', []));
  let parsedRules = [];
  if (isEmpty(rules)) return { ...job, rules: [] };
  try {
    if (rules.type === 'or') {
      rules.children.forEach((rule) => {
        if (rule.type === 'leaf') {
          parsedRules = [
            ...parsedRules,
            {
              attributes: [{ field: rule.field, operator: rule.operator, data: rule.data }]
            }
          ];
        } else {
          parsedRules = [
            ...parsedRules,
            {
              attributes: rule.children.map((attr) => ({ field: attr.field, operator: attr.operator, data: attr.data }))
            }
          ];
        }
      });
    } else if (rules.type === 'and') {
      parsedRules = [
        {
          attributes: rules.children.map((attr) => ({ field: attr.field, operator: attr.operator, data: attr.data }))
        }
      ];
    } else {
      parsedRules = [
        {
          attributes: [{ field: rules.field, operator: rules.operator, data: rules.data }]
        }
      ];
    }
    return { ...job, rules: [...parsedRules] };
  } catch (e) {
    return e;
  }
};

export function existsEmptyRules (rules) {
  return isEmpty(rules) || rules.find((r) => isEmpty(r.attributes));
}

export function existsEmptyTasks (tasks) {
  return tasks.filter((t) => !t.deleted).find((t) => {
    const command = upperCase(t.command);
    return command === '' ||
  (['APPEND', 'UPDATE'].indexOf(command) >= 0 && (t.field === '' || t.value === ''));
  });
}

export function allowAddTasks (tasks) {
  const filtered = tasks.filter((t) => !t.deleted);
  return isEmpty(filtered) || !existsEmptyTasks(filtered);
}

export function getJobs () {
  return async (dispatch) => {
    try {
      const jobs = await api.jobs.fetchJobs();
      return dispatch({ type: types.JOBS_GET_DATA, jobs });
    } catch (e) {
      return dispatch(somethingWentWrong());
    }
  };
}

export function searchJob (name) {
  return async (dispatch) => {
    dispatch({ type: types.JOBS_SEARCH, searchQuery: name });
  };
}

export function removeRuleFromSelectedJob (rule) {
  return async (dispatch, getState) => {
    const state = getState();
    const rules = selectJobRules(state);
    const newRules = rules.filter((r) => !isEqual(r, rule));
    dispatch({ type: types.JOBS_SET_RULES, rules: newRules });
  };
}

export function addNewRuleToSelectedJob () {
  return async (dispatch, getState) => {
    const state = getState();
    const rules = selectJobRules(state);
    const newRules = [...rules, { attributes: [] }];

    dispatch({ type: types.JOBS_SET_RULES, rules: newRules });
  };
}

export function addAttrToSelectedRule (rule, attribute) {
  return async (dispatch, getState) => {
    const state = getState();
    const rules = selectJobRules(state);
    const selectedRule = rules.find((r) => isEqual(r, rule));
    const hasAttribute = selectedRule.attributes.find((attr) => isEqual(attr, attribute));
    if (selectedRule && !hasAttribute) selectedRule.attributes.push(attribute);
    dispatch({ type: types.JOBS_SET_RULES, rules });
  };
}

export function removeAttrFromSelectedRule (rule, attribute) {
  return async (dispatch, getState) => {
    const state = getState();
    const rules = selectJobRules(state);
    const selectedRule = rules.find((r) => isEqual(r, rule));
    const attributes = get(selectedRule, 'attributes', []).filter((a) => !isEqual(a, attribute));
    if (isEmpty(attributes)) dispatch(removeRuleFromSelectedJob(rule));
    else if (selectedRule) {
      selectedRule.attributes = attributes;
      dispatch({ type: types.JOBS_SET_RULES, rules });
    }
  };
}

export function viewJob (job) {
  return (dispatch) => {
    dispatch({ type: types.JOBS_VIEW, job: isEmpty(job) ? null : parseRules(job) });
  };
}

export function getFields () {
  return async (dispatch) => {
    try {
      const fields = await api.jobs.fetchFields();
      const vulnFields = get(fields, 'vulnerability', {});
      const hostFields = get(fields, 'host', {});
      return dispatch({ type: types.JOBS_GET_TASKS_FIELDS, vulnFields, hostFields });
    } catch (e) {
      return dispatch(somethingWentWrong());
    }
  };
}

export function deleteJob (job) {
  return async (dispatch, getState) => {
    try {
      const jobSelected = selectJobSelected(getState());
      await api.jobs.deleteJob(job.id);
      if (get(jobSelected, 'id', 0) === job.id) dispatch(viewJob(null));
      return dispatch(getJobs());
    } catch (e) {
      return dispatch(somethingWentWrong());
    }
  };
}

export function copyJob (job) {
  return async (dispatch) => {
    try {
      await api.jobs.copyJob(job.id);
      return dispatch(getJobs());
    } catch (e) {
      return dispatch(somethingWentWrong());
    }
  };
}

export function newJob () {
  return (dispatch) => {
    dispatch({ type: types.JOBS_NEW });
    dispatch(redirect('/automation/jobs/new'));
  };
}

export function addTask () {
  return (dispatch, getState) => {
    const tasks = selectJobTasks(getState());
    if (allowAddTasks(tasks)) {
      dispatch({ type: types.JOBS_ADD_TASK });
    }
  };
}

export function setName (name) {
  return (dispatch) => {
    dispatch({ type: types.JOBS_SET_NAME, name });
  };
}

export function setDescription (description) {
  return (dispatch) => {
    dispatch({ type: types.JOBS_SET_DESCRIPTION, description });
  };
}

export function setTasks (tasks) {
  return (dispatch) => {
    dispatch({ type: types.JOBS_SET_TASKS, tasks });
  };
}

export function setModel (model) {
  return (dispatch) => {
    dispatch({ type: types.JOBS_SET_MODEL, model });
  };
}

function getRulesParsed (rules) {
  const getChildren = (data, field, operator) => ({
    children: [],
    data,
    field,
    operator,
    type: 'leaf'
  });

  const parseAttributes = (attributes) => {
    const children = attributes.map((attr) => (getChildren(attr.data, attr.field, attr.operator)));
    if (attributes.length > 1) {
      return {
        children,
        type: 'and'
      };
    }
    return children[0];
  };

  const children = rules.map((rule) => (
    parseAttributes(rule.attributes)
  ));
  if (rules.length > 1) {
    return [{
      children,
      type: 'or'
    }];
  }
  return children;
}

async function saveTask (task, taskIds, newTaskIds) {
  let newTask = {
    id: task.id,
    command: upperCase(task.command)
  };
  if (lowerCase(task.command) !== 'delete') {
    newTask = {
      ...newTask,
      field: task.field,
      value: task.value
    };
  }

  if (task.id > 0 && task.deleted === true) {
    await api.jobs.deleteTask(task.id);
    const taskIndex = taskIds.indexOf(task.id);
    if (taskIndex !== -1) {
      newTaskIds.splice(taskIndex, 1);
    }
  } else if (task.id === 0) {
    if (!task.deleted) {
      const response = await api.jobs.createTask(newTask);
      newTaskIds.push(response.id);
    }
  } else {
    await api.jobs.editTask(task.id, newTask);
  }
}

export function saveJob (createNewJob) {
  return async (dispatch, getState) => {
    try {
      const id = selectJobId(getState());
      const tasks = selectJobTasks(getState());
      const rules = selectJobRules(getState());
      const model = selectJobModel(getState());
      const name = selectJobName(getState());
      const description = selectJobDescription(getState());
      const taskIds = selectJobTaskIds(getState());
      const redirectToPipeline = selectRedirectTo(getState());

      // TODO: validar
      let data = {
        name,
        description,
        enabled: true,
        model,
        rules_json: getRulesParsed(rules)
      };

      // tasks
      const newTaskIds = [
        ...taskIds
      ];
      await Promise.all(tasks.map(async (task) => {
        await saveTask(task, taskIds, newTaskIds);
      }));

      data = {
        ...data,
        tasks_ids: newTaskIds
      };

      if (createNewJob) { // create job
        await api.jobs.createJob(data);
      } else { // edit job
        await api.jobs.updateJob(id, data);
      }

      dispatch(viewJob(null));
      dispatch(getJobs());
      if (redirectToPipeline === '') {
        dispatch(redirect('/automation/jobs'));
      } else {
        dispatch(setRedirectTo(''));
        dispatch(redirect(redirectToPipeline));
      }
      dispatch(closeModal(MODAL_SAVE_JOB));
    } catch (e) {
      if (e?.message === 'Existing value') return dispatch(somethingWentWrong('Already existing job with same name'));
      return dispatch(somethingWentWrong());
    }
  };
}

export function resetError () {
  return async (dispatch) => {
    dispatch({ type: types.JOBS_RESET_ERROR });
  };
}
