import { newGetVulns } from 'store/Manage/filterActions';
import api from 'services/api';

// **
// settings section
// **
export const SET_SETTINGS_SHOW_MODAL = 'SET_SETTINGS_SHOW_MODAL';

export const GET_SETTINGS_TEMPLATES_START = 'GET_SETTINGS_TEMPLATES_START';
export const GET_SETTINGS_TEMPLATES_SUCCESS = 'GET_SETTINGS_TEMPLATES_SUCCESS';
export const GET_SETTINGS_TEMPLATES_FAIL = 'GET_SETTINGS_TEMPLATES_FAIL';

export const SEND_VULN_TO_TOOL = 'SEND_VULN_TO_TOOL';

// **
// settings section
// **

export function closeModal () {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_SHOW_MODAL, showModal: false });
  };
}

export function openModal () {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_SHOW_MODAL, showModal: true });
  };
}

export function getTemplates (tool) {
  return async (dispatch) => {
    dispatch({ type: GET_SETTINGS_TEMPLATES_START });
    try {
      const response = await api.settings.fetchTemplates(tool);
      return dispatch({ type: GET_SETTINGS_TEMPLATES_SUCCESS, templates: response.templates });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TEMPLATES_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function updateVulns (vulns, trackerKey) {
  return async (dispatch, getState) => {
    const { workspaceSelected } = getState().faraday;
    const results = [];

    Object.keys(vulns).forEach((key) => {
      const issuetracker = {};
      issuetracker[trackerKey] = {
        id: vulns[key].id,
        key: vulns[key].key,
        self: vulns[key].self,
        url: vulns[key].self ? vulns[key].self : vulns[key].url,
        vuln_id: vulns[key].vuln_id
      };
      const vulnData = {
        issuetracker
      };
      results.push(api.settings.updateVuln(vulns[key].vuln_id, workspaceSelected, vulnData));
    });
    await Promise.all(results);
    dispatch(newGetVulns());
  };
}

export function resetWarning () {
  return async (dispatch) => {
    // hago reset del error.
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { error: false, message: '' } });
  };
}
