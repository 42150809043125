import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { selectNotifications } from 'store/Notifications/selectors';
import { NotificationList, Wrapper } from './styled';
import NotificationItem from './notificationItem';
import Footer from './notificationsFooter';

const NotificationsDropdown = () => {
  const { unreadNotifications } = useSelector((s) => ({ unreadNotifications: selectNotifications(s) }), shallowEqual);
  const sortByDate = (a, b) => Date.parse(b.create_date) - Date.parse(a.create_date);
  return (
    <Wrapper>
      <NotificationList>
        {unreadNotifications.sort((a, b) => sortByDate(a, b)).map((notification) => (
          <NotificationItem
            key={ notification.notification_id }
            id={ notification.notification_id }
            data={ notification.notification_data }
            event={ notification.event }
            creationTime={ notification.create_date }
          />
        ))}
      </NotificationList>
      <Footer />
    </Wrapper>
  );
};

export default NotificationsDropdown;
