import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text } from './styled';

const IconButton = ({
  onClick, title, icon, text, id
}) => (
  <Wrapper title={ title } onClick={ onClick } id={ id }>
    {icon}
    { text && <Text>{ text }</Text> }
  </Wrapper>
);

IconButton.defaultProps = {
  onClick: null,
  id: ''
};

IconButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  id: PropTypes.string
};

export default IconButton;
