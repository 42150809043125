/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper, Item
} from './styled';

const ElementList = ({ elements, onClickCallback }) => (
  <Wrapper>
    {elements.map((element, index) => <Item key={ `${element.name}-${index}` } onClick={ () => onClickCallback(element.name) }>{element.name}</Item>)}
  </Wrapper>
);

ElementList.defaultProps = {
  elements: []
};

ElementList.propTypes = {
  elements: PropTypes.instanceOf(Array),
  onClickCallback: PropTypes.func.isRequired
};

export default ElementList;
