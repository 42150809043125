/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import {
  DrageableZone,
  DragableZoneActive,
  DragableZoneActiveContent,
  DragableZoneInActiveContent,
  Text,
  TextInactive,
  FileSelectorButton,
  SubTextInactive,
  DropInput,
  Cloud
} from './styled';

const dropRef = React.createRef();

function handleDrag (e) {
  e.preventDefault();
  e.stopPropagation();
}

class DragFile extends Component {
  constructor (props) {
    super(props);
    this.state = {
      dragging: false
    };

    this.handleDragIn = this.handleDragIn.bind(this);
    this.handleDragOut = this.handleDragOut.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount () {
    const div = dropRef.current;
    this.dragCounter = 0;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', handleDrag);
    div.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount () {
    const div = dropRef.current;
    div.removeEventListener('dragenter', this.handleDragIn);
    div.removeEventListener('dragleave', this.handleDragOut);
    div.removeEventListener('dragover', handleDrag);
    div.removeEventListener('drop', this.handleDrop);
  }

  onChange (e) {
    this.props.handleDrop(e.target.files);
  }

  handleDragIn (e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter += 1;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  }

  handleDragOut (e) {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter -= 1;
    if (this.dragCounter === 0) this.setState({ dragging: false });
  }

  handleDrop (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  }

  render () {
    const { onManage } = this.props;
    const { dragging } = this.state;
    const styles = {
      cover: {
        position: 'absolute',
        width: 450,
        height: onManage ? 425 : 550,
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        top: 0,
        bottom: 0,
        marginTop: 'auto',
        marginBottom: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    };

    return (
      <DrageableZone onManage={ onManage } style={ dragging ? styles.cover : {} } ref={ dropRef }>
        {dragging &&
          (
          <DragableZoneActive onManage={ onManage }>
            <DragableZoneActiveContent>
              <Cloud color="#0082ed" />
              <Text>Drop your files here</Text>
            </DragableZoneActiveContent>
          </DragableZoneActive>
          )}
        <DragableZoneInActiveContent>
          <Cloud />
          <TextInactive>Drag and drop your files here</TextInactive>
          <SubTextInactive>or</SubTextInactive>
          <FileSelectorButton htmlFor="upload">Browse</FileSelectorButton>
          <DropInput multiple type="file" id="upload" onChange={ this.onChange } />
        </DragableZoneInActiveContent>
      </DrageableZone>
    );
  }
}
export default DragFile;
