import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: right;
  justify-content: space-between;
  margin-bottom: 19px;
`;
Wrapper.displayName = 'Wrapper';

export const WrapperButtons = styled.div`
  display: flex;
  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
WrapperButtons.displayName = 'WrapperButtons';
