import fetchApi from 'services/api/connector';

export const newGetVulns = (ws, queryParam) => fetchApi(`ws/${ws}/vulns/filter?q=${encodeURI(JSON.stringify(queryParam))}`);

export const getVulns = (ws, queryParam) => fetchApi(`ws/${ws}/vulns/filter?q=${queryParam}`);

export const getVulnDetail = (ws, id) => fetchApi(`ws/${ws}/vulns/${id}`);

export const getVulnDuplicates = (ws, id) => fetchApi(`ws/${ws}/vulns/${id}/duplicates`);

export const markDuplicates = (ws, data) => fetchApi(`ws/${ws}/vulns/duplicates/associate`, { method: 'POST', data });

export const removeDuplicates = (ws, data) => fetchApi(`ws/${ws}/vulns/duplicates/disassociate`, { method: 'POST', data });

export const markAsMainDuplicate = (ws, data) => fetchApi(`ws/${ws}/vulns/duplicates/mark_as_main`, { method: 'POST', data });

export const setVulnsTags = (ws, data) => fetchApi(`ws/${ws}/vulns/set_tags`, { method: 'POST', data, version: 'v3' });

export const updateVuln = (ws, vuln) => fetchApi(`ws/${ws}/vulns/${vuln._id}`, { method: 'PATCH', data: vuln, version: 'v3' });

export const exportCSV = (ws, queryParam) => fetchApi(`ws/${ws}/vulns/filter?export_csv=true&q=${encodeURI(JSON.stringify(queryParam))}`);

export const fetchManifest = () => fetchApi('agents/get_manifests');

export const getSearchFilter = () => fetchApi('searchfilter');

export const getTemplates = () => fetchApi('vulnerability_template');

export const fetchById = (ws, id) => fetchApi(`ws/${ws}/vulns/${id}`);

export const getComments = (ws, id) => fetchApi(`ws/${ws}/comment/vulnerability/${id}`);

export const newComment = (ws, data) => fetchApi(`ws/${ws}/comment`, { method: 'POST', data, version: 'v3' });

export const removeComment = (ws, id) => fetchApi(`ws/${ws}/comment/${id}`, { method: 'DELETE', version: 'v3' });

export const saveComment = (ws, id, data) => fetchApi(`ws/${ws}/comment/${id}`, { method: 'PATCH', data, version: 'v3' });

export const deleteVulns = (ws, ids) => fetchApi(`ws/${ws}/vulns`, { method: 'DELETE', data: { ids }, version: 'v3' });

export const createVuln = (ws, data) => fetchApi(`ws/${ws}/vulns`, { method: 'POST', data, version: 'v3' });

export const updateVulns = (ws, data) => fetchApi(`ws/${ws}/vulns`, { method: 'PATCH', data, version: 'v3' });
