import styled from 'styled-components';

export const BodyWrapper = styled.div`
  
`;
BodyWrapper.displayName = 'BodyWrapper';

export const SubTitle = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63758d;
  margin-top: 21px;
`;
SubTitle.displayName = 'SubTitle';

export const SectionTitle = styled.div`
  font-size: 14.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
`;
SectionTitle.displayName = 'SectionTitle';
