/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown as ChevronDown } from 'react-icons/fa';
import {
  Wrapper,
  DataContainer,
  DataItem,
  RedAster
} from './styled';
import './styles.scss';

class InputFilter extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);

    this.state = {
      value: this.props.value,
      showData: false
    };
  }

  componentDidMount () {
    this.props.getObjects();
  }

  onChangeHandler (v) {
    const { showData } = this.state;
    this.setState({ value: v });
    this.setState({ showData: !showData });
    this.props.onChange('name', v);
  }

  onSelectHandler (item) {
    if (this.props.cleanOnSelect) this.setState({ value: '' });
    else this.setState({ value: item.name });

    this.setState({ showData: false });
    this.props.onSelect(item);
  }

  render () {
    const { value, showData } = this.state;
    const {
      type, data, placeholder, id, required
    } = this.props;
    const filteredData = data.filter((it) => value === '' || it.name.toLowerCase().includes(value.toLowerCase()));
    return (
      <Wrapper onBlur={ () => { this.setState({ showData: false }); } }>
        <input id={ id } className={ `input-filter ${value.length > 0 ? 'full' : ''}` } value={ value } placeholder={ placeholder } type={ type } onChange={ (e) => this.onChangeHandler(e.target.value) } />
        {required ? <RedAster>*</RedAster> : null }
        <ChevronDown color="#515a65" className="input-filter-arrow" onClick={ () => { this.onChangeHandler(this.state.value); } } />
        {
          filteredData.length > 0 && showData &&
          (
            <DataContainer>
              { filteredData.map((item) => <DataItem className="data-item" key={ item.id } onMouseDown={ (e) => { this.onSelectHandler(item); e.stopPropagation(); e.preventDefault(); } }>{item.name}</DataItem>) }
            </DataContainer>
          )
        }
      </Wrapper>
    );
  }
}

InputFilter.propTypes = {
  data: PropTypes.array.isRequired,
  getObjects: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string
};

InputFilter.defaultProps = {
  type: 'text',
  placeholder: 'Enter value',
  required: false,
  value: ''
};

export default InputFilter;
