
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  text-align: left;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1c2243;
`;
Title.displayName = 'Title';

export const Subtitle = styled(Title)`
  margin-top: 10px;
  font-size: 12px;
  font-weight: normal;
  color: #63758d;
`;
Subtitle.displayName = 'Subtitle';
