import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { Title, Subtitle } from 'Screens/Settings/common-styled';
import {
  selectCategories, selectSubcategories, selectTemplate, selectCategory, selectSubcategory
} from 'store/Settings/serviceNowSelectors';
import { setField } from 'store/Settings/serviceNowActions';
import { selectTemplates } from 'store/Settings/settingsSelectors';
import {
  Wrapper, Content, Label, WrapperField, WrapperCategory, WrapperSubcategory, WrapperTemplate
} from './styled';

const IncidentConfiguration = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const props = useSelector((state) => ({
    templates: selectTemplates(state),
    selectedTemplate: selectTemplate(state),
    selectedCategory: selectCategory(state),
    selectedSubcategory: selectSubcategory(state)
  }));

  useEffect(() => {
    setCategories(selectCategories());
  }, []);

  useEffect(() => {
    setSubcategories(selectSubcategories(props.selectedCategory));
  }, [props.selectedCategory]);

  const onChange = (field, value) => {
    dispatch(setField(field, value));
  };

  const onChangeCategory = (field, value) => {
    setSubcategories(selectSubcategories(value));
    onChange(field, value);
  };

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.incidentConfiguration.title' }) }</Title>
      <Subtitle>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.incidentConfiguration.subtitle' }) }</Subtitle>
      <Content>
        <WrapperField>
          <WrapperCategory>
            <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.incidentConfiguration.category.title' }) }</Label>
            <StandardDropdown field="category" options={ categories } updateValue={ onChangeCategory } value={ props.selectedCategory } defaultValue={ props.selectedCategory } width="207px" />
          </WrapperCategory>
          <WrapperSubcategory>
            <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.incidentConfiguration.subcategory.title' }) }</Label>
            <StandardDropdown field="subcategory" options={ subcategories } updateValue={ onChange } value={ props.selectedSubcategory } defaultValue={ props.selectedSubcategory } width="190px" />
          </WrapperSubcategory>
          <WrapperTemplate>
            <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.incidentConfiguration.template.title' }) }</Label>
            <StandardDropdown field="template" options={ props.templates } updateValue={ onChange } value={ props.selectedTemplate } defaultValue={ props.selectedTemplate } width="425px" />
          </WrapperTemplate>
        </WrapperField>
      </Content>
    </Wrapper>
  );
};
export default IncidentConfiguration;
