import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import session from 'redux-persist/lib/storage/session';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import { supported, subscribe } from 'utils/breakpoints/mediaListener';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import socketsMiddleware from '../services/websocket/socketsMiddleware';
import createRootReducer from './Reducers/index';

export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: 'faraday',
  storage: session,
  blacklist: [
    'filters',
    'manage',
    'manageEditCreate',
    'host',
    'hostDetail',
    'hostEditCreate',
    'template',
    'knowledgeBaseEditCreate',
    'workspaceEditCreate',
    'executiveReport',
    'executiveReportEditCreate',
    'tags',
    'schedulingEditCreate',
    'settings',
    'dashboard',
    'knowledgeBase',
    'errors',
    'router',
    'global'
  ],
  stateReconciler: autoMergeLevel1
};

const pReducer = persistReducer(persistConfig, createRootReducer(history));
const routeMiddleware = routerMiddleware(history);
const middlewares = [routeMiddleware, socketsMiddleware, thunkMiddleware];
export const store = createStore(pReducer, composeEnhancers(applyMiddleware(...middlewares)));

if (supported()) subscribe(store);

export const persistor = persistStore(store);
