import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { showQuickPlanner, redirectToManage } from 'store/Manage/actions';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_PROJECTS, FEATURE_TASKS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { Icon, Wrapper } from './styled';

const QuickPlannerButton = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isProjectEnabled = useSelector((state) => selectAllowedByFeature(state, FEATURE_PROJECTS, PERMISSION_TYPES.UPDATE) && selectAllowedByRole(state, FEATURE_PROJECTS, PERMISSION_TYPES.UPDATE));
  const isTaskEnabled = useSelector((state) => selectAllowedByFeature(state, FEATURE_TASKS, PERMISSION_TYPES.UPDATE) && selectAllowedByRole(state, FEATURE_TASKS, PERMISSION_TYPES.UPDATE));

  const open = () => {
    dispatch(redirectToManage());
    dispatch(showQuickPlanner(true));
  };

  const title = intl.formatMessage({ id: 'quickPlanner.title' });
  if (!isProjectEnabled || !isTaskEnabled) return null;
  return (
    <Wrapper onClick={ open } title={ title }>
      <Icon />
    </Wrapper>
  );
};

export default QuickPlannerButton;
