import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import { agentsTourConfig, manageTourConfig, dashboardTourConfig } from './tourConfig';
import { openModal } from 'store/modals/actions';
import { MODAL_TOUR_FINISHED } from 'store/modals/modals';
import EnjoyPopup from './EnjoyPopup';
import CommonTour from './CommonTour';
import { endTour } from 'store/Manage/actions';

import { selectUserAgents } from 'store/Agents/selectors';

export const DashboardTour = () => {
  const [isTourOpen, setTour] = useState(false);
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);

  const redirectToManage = () => {
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  return (
    <CommonTour
      steps={ dashboardTourConfig(redirectToManage) }
      isTourOpen={ isTourOpen }
      setTour={ setTour }
    />
  );
};

export const ManageTour = () => {
  const [isTourOpen, setTour] = useState(false);
  const dispatch = useDispatch();
  const userAgents = useSelector(selectUserAgents);
  const hasFirstScan = userAgents.some((agent) => agent.name === 'agent_firstscan' && agent.executors.some((ex) => ex.name === 'discovery'));

  const action = () => {
    if (hasFirstScan) dispatch(redirect('/automation/agents'));
    else {
      dispatch(openModal(MODAL_TOUR_FINISHED));
      setTour(false);
      dispatch(endTour());
    }
  };

  return (
    <>
      <CommonTour steps={ manageTourConfig(action) } isTourOpen={ isTourOpen } setTour={ setTour } />
      <EnjoyPopup />
    </>
  );
};

export const AgentsTour = () => {
  const [isTourOpen, setTour] = useState(false);
  const dispatch = useDispatch();

  const showEnjoyPopup = () => {
    dispatch(openModal(MODAL_TOUR_FINISHED));
    setTour(false);
    dispatch(endTour());
  };

  return (
    <>
      <CommonTour steps={ agentsTourConfig(showEnjoyPopup) } isTourOpen={ isTourOpen } setTour={ setTour } />
      <EnjoyPopup />
    </>
  );
};
