import get from 'lodash/get';

const parseNotifications = (type, data, message) => {
  switch (type) {
    // case "new_agent": {
    //   return;
    // }
    // case 'new_user': {
    //   return `User ${data.username.unchanged_value} has been created as ${data.roles.unchanged_value}`;
    // }
    // case "new_agentexecution": {
    //   return;
    // }
    // case 'new_vulnerability': {
    //   return `A new vulnerability ${data.name.unchanged_value.toUpperCase()} has been created by ${data.event_created_by}`;
    // }
    // case "update_workspace": {
    //   return;
    // }
    // case "update_agent": {
    //   return;
    // }
    // case 'update_user': {
    //   if (data.username.new_value) {
    //     return `${data.username.new_value} information has been updated`;
    //   }
    //   return `${data.username.unchanged_value} information has been updated`;
    // }
    // case "update_agent_scan": {
    //   return;
    // }
    // case 'update_executivereport': {
    //   return `Your report ${data.name.unchanged_value} has been created.`;
    // }
    // case 'update_vulnerability': {
    //   if (data.severity.new_value) {
    //     return `${data.event_created_by} changed ${data.name.unchanged_value} from ${data.severity.old_value} to ${data.severity.new_value}`;
    //   } if (data.status.new_value) {
    //     return `${data.event_created_by} changed ${data.name.unchanged_value} status from ${data.severity.old_value} to ${data.severity.new_value}`;
    //   }
    //   return message;
    // }
    // case 'update_vulnerabilityweb': {
    //   if (data.severity.new_value) {
    //     return `${data.event_created_by} changed ${data.name.unchanged_value} from ${data.severity.old_value} to ${data.severity.new_value}`;
    //   } if (data.status.new_value) {
    //     return `${data.event_created_by} changed ${data.name.unchanged_value} status from ${data.severity.old_value} to ${data.severity.new_value}`;
    //   }
    //   return message;
    // }
    // case "update_comment": {
    //   return;
    // }
    // case "delete_workspace": {
    //   return;
    // }
    // case "delete_agent": {
    //   return;
    // }
    // case 'delete_user': {
    //   return message;
    // }
    // case "delete_executivereport": {
    //   return;
    // }
    // case 'delete_vulnerability': {
    //   return `${data.event_created_by} deleted ${data.name.unchanged_value}`;
    // }
    // case 'delete_vulnerabilityweb': {
    //   return `${data.event_created_by} deleted ${data.name.unchanged_value}`;
    // }
    // case "delete_comment": {
    //   return;
    // }
    case 'new_workspace': {
      const name = get(data, 'name.new_value', false);
      if (name) {
        return `Workspace ${name} has been created.`;
      } return message;
    }
    case 'update_executivereport': {
      const name = get(data, 'name.new_value', false);
      if (name) {
        return `Your report ${name} has been created.`;
      } return message;
    }
    case 'new_command': {
      const vulns = get(data, 'sum_created_vulnerabilities.new_value', false) ?? 0;
      const host = get(data, 'sum_created_hosts.new_value', false) ?? 0;
      const services = get(data, 'sum_created_services.new_value', false) ?? 0;
      const name = get(data, 'tool.new_value', false);
      const workspace = get(data, 'workspace.new_value', false);
      const importSource = get(data, 'import_source.new_value', false);
      if (importSource) {
        if (importSource === 'agent') {
          return `Agent scan ${name} has been completed. ${vulns} vulnerabilities, ${host} assets and ${services} services were added to ${workspace}.`;
        } return `The file ${name} has been successfully uploaded. ${vulns} vulnerabilities, ${host} assets and ${services} services were added to ${workspace}.`;
      }
      return message;
    }
    case 'new_comment': {
      const userName = get(data, 'event_created_by', false);
      const objectName = get(data, 'obj_name.new_value', false);
      if (userName && objectName) {
        return `${userName} mentioned you in ${objectName}`;
      } return message;
    }
    default: {
      return message;
    }
  }
};

export default parseNotifications;
