import { max } from 'lodash';
import React from 'react';
import {
  VictoryBar, VictoryChart, VictoryAxis, VictoryLabel
} from 'victory';
import { Wrapper } from './styled';

const TopTenMostRepeated = ({ data }) => {
  const height = 250 + (data.length * 25);
  const maxTicks = max(data.map((item) => item.data.total));
  const tickCount = maxTicks < 5 ? maxTicks : 5;

  const styles = {
    colorScale: ['#a4247a', '#ea3158', '#f59220', '#88cc3f'],
    VictoryTooltip: { fontSize: '10px', fill: '#63758d', fontFamily: 'Sequel Sans' },
    flyoutStyle: { stroke: '#d9e4ef', strokeWidth: 1, fill: '#fff' },
    none: { display: 'none' },
    VictoryAxis: {
      grid: {
        fill: '#bec8d2',
        stroke: '#bec8d2',
        strokeDasharray: '10,5',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        pointerEvents: 'painted',
        strokeWidth: tickCount ? '2' : '0'
      },
      tickLabels: {
        fontFamily: 'Sequel Sans',
        fill: '#63758d',
        fontSize: 12
      }
    },
    font1: {
      fontFamily: 'Sequel Sans',
      fill: '#63758d',
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0
    },
    font2: {
      tickLabels: {
        fontFamily: 'Sequel Sans',
        fill: '#63758d',
        fontSize: 8,
        stroke: 'transparent',
        strokeWidth: 0
      }

    },
    font3: {
      fontFamily: 'Sequel Sans',
      fill: '#63758d',
      fontSize: 12,
      stroke: '#63758d',
      strokeWidth: 0
    }

  };

  return (
    <Wrapper>
      <VictoryChart
        height={ height }
        domainPadding={ 40 }
        padding={ {
          left: 250, top: 10, right: 0, bottom: 80
        } }
      >
        <VictoryAxis
          axisLabelComponent={ (
            <VictoryLabel
              dy={ -60 }
              style={ styles.font1 }
            />
          ) }
          style={ styles.font2 }
        />
        <VictoryAxis
          dependentAxis
          tickCount={ tickCount || 1 }
          tickFormat={ (t) => `${Math.round(t)}` }
          label="Number of Vulnerabilities"
          axisLabelComponent={ (
            <VictoryLabel
              dy={ 20 }
              style={ styles.font3 }
            />
          ) }
          style={ styles.VictoryAxis }
        />
        <VictoryBar
          horizontal
          sortKey="data.total"
          style={ { data: { width: 20, fill: '#0082ed' } } }
          labelComponent={ <VictoryLabel style={ styles.none } /> }
          data={ data }
          x="label"
          y="data.total"
        />
      </VictoryChart>
    </Wrapper>
  );
};

export default TopTenMostRepeated;
