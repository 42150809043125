import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ListViewImage } from 'Images/list_view.svg';
import { ReactComponent as GridViewImage } from 'Images/grid_view.svg';
import AddButton from 'Common/Components/AddButton';
import { selectCurrentViewMode, selectIsEmpty } from 'store/Analytics/selectors';
import { GRAPH_GRID_VIEW, GRAPH_LIST_VIEW } from 'store/Analytics/viewMode';
import { redirect } from 'store/Router/actions';
import { toggleView } from 'store/Analytics/actions';
import {
  Wrapper, TitleWrapper, Title, Subtitle, WrapperButtons
} from './styled';

const NewGraph = () => {
  const dispatch = useDispatch();
  return <AddButton text="Create New Graph" onClick={ () => dispatch(redirect('/analytics/new')) } />;
};

const ListView = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectCurrentViewMode);
  const isEmpty = useSelector(selectIsEmpty);

  if (isEmpty) return null;

  const toggle = () => dispatch(toggleView(GRAPH_LIST_VIEW));

  if (viewMode !== GRAPH_LIST_VIEW) return null;
  return <AddButton onClick={ toggle } text="List View" icon={ <ListViewImage /> } />;
};

const GridView = () => {
  const dispatch = useDispatch();
  const viewMode = useSelector(selectCurrentViewMode);
  const isEmpty = useSelector(selectIsEmpty);

  if (isEmpty) return null;

  const toggle = () => dispatch(toggleView(GRAPH_GRID_VIEW));

  if (viewMode !== GRAPH_LIST_VIEW) return null;
  return <AddButton onClick={ toggle } text="Grid View" icon={ <GridViewImage /> } />;
};

// create new graph, list view, grid view
const ActionBar = () => (
  <Wrapper>
    <TitleWrapper>
      <Title>Analytics</Title>
      <Subtitle>- My graphs</Subtitle>
    </TitleWrapper>
    <WrapperButtons>
      <NewGraph />
      <ListView />
      <GridView />
    </WrapperButtons>
  </Wrapper>
);

export default ActionBar;
