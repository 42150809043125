import styled from 'styled-components';
import BlueButton from 'Common/Components/BlueButton/index';
import OutlinedButton from 'Common/Components/OutlinedButton/index';

export const Wrapper = styled.div`
  display: inline-flex;  
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.span`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0082ed;
`;
Title.displayName = 'Title';

export const BtnsWrapper = styled.div`
  display: inline-flex;
  margin-left: auto;
`;
BtnsWrapper.displayName = 'BtnsWrapper';

export const Cancel = styled(OutlinedButton)`
  width: 89px;
  height: 34px;
`;

Cancel.displayName = 'CancelButton';

export const Create = styled(BlueButton)`
  width: 89px;
  height: 34px;
  margin-left: 12px;
`;
Create.displayName = 'CreateBtn';

export const TagIcon = styled.div`
  margin-left: 19px;
`;
TagIcon.displayName = 'TagIcon';
