import RadioButton from 'Common/Components/RadioButton/index';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Subtitle, Title } from 'Screens/Settings/common-styled';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { saveServiceNowSettings } from 'store/Settings/serviceNowActions';
import { saveGitLabSettings } from 'store/Settings/gitLabActions';
import { saveSolarWindsSettings } from 'store/Settings/solarWindsActions';
import { selectAllowedByFeature } from 'Hooks/usePermission/selector';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import upgradeLicenseImage from 'Images/ticketing-license.png';
import {
  FEATURE_TICKETING_GITLAB, FEATURE_TICKETING_JIRA, FEATURE_TICKETING_SERVICENOW, FEATURE_TICKETING_WHD, PERMISSION_TYPES
} from 'Hooks/usePermission/permissions';
import { ACTIONS } from 'tracking/GA/constants';
import Header from '../Header';
import Jira from './components/Jira';
import ServiceNow from './components/ServiceNow';
import {
  GitLabLogo, JiraLogo, ServicenowLogo, SolarwindsLogo, Wrapper, Content, Options, View
} from './styled';
import GitLab from './components/GitLab';
import SolarWinds from './components/SolarWinds';
import UpgradeLicense from './components/UpgradeLicense';
import Solarwinds from 'Images/preferences_solarwinds_logo.jpeg';

const TicketingTools = ({ onClose }) => {
  const [radioValue, setRadio] = useState(0);
  const dispatch = useDispatch();
  const intl = useIntl();

  const isEnabledJira = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_JIRA, PERMISSION_TYPES.CREATE));
  const isEnabledGitlab = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_GITLAB, PERMISSION_TYPES.CREATE));
  const isEnabledServiceNow = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_SERVICENOW, PERMISSION_TYPES.CREATE));
  const isEnabledWhd = useSelector((state) => selectAllowedByFeature(state, FEATURE_TICKETING_WHD, PERMISSION_TYPES.CREATE));

  const onChange = ({ target }) => {
    setRadio(Number(target.value));
  };

  const close = () => {
    onClose();
  };

  const accept = () => {
    if (radioValue === 2) dispatch(saveServiceNowSettings());
    if (radioValue === 3) dispatch(saveSolarWindsSettings());
    if (radioValue === 4) dispatch(saveGitLabSettings());

    close();
  };

  const featureEnabled = () => isEnabledJira || isEnabledGitlab || isEnabledServiceNow || isEnabledWhd;

  return (
    <Wrapper>
      <Header onAccept={ accept } onClose={ close } />
      { featureEnabled()
        ? (
            <>
              <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.title' }) }</Title>
              <Subtitle>{ intl.formatMessage({ id: 'preferences.ticketingTools.subtitle' }) }</Subtitle>
              <Content>
                <Options>
                  <RadioButton label="None" name="radio" value={ 0 } checked={ radioValue === 0 } onChange={ onChange } />
                  { isEnabledJira && <RadioButton label={ <JiraLogo /> } name="radio" value={ 1 } checked={ radioValue === 1 } onChange={ onChange } /> }
                  { isEnabledServiceNow && <RadioButton label={ <ServicenowLogo /> } name="radio" value={ 2 } checked={ radioValue === 2 } onChange={ onChange } /> }
                  { isEnabledWhd && <RadioButton label={ <SolarwindsLogo src={ Solarwinds } /> } name="radio" value={ 3 } checked={ radioValue === 3 } onChange={ onChange } /> }
                  { isEnabledGitlab && <RadioButton label={ <GitLabLogo /> } name="radio" value={ 4 } checked={ radioValue === 4 } onChange={ onChange } /> }
                </Options>
                <View>
                  { radioValue === 1 && <Jira /> }
                  { radioValue === 2 && <ServiceNow /> }
                  { radioValue === 3 && <SolarWinds /> }
                  { radioValue === 4 && <GitLab /> }
                </View>
              </Content>
            </ >
          )
        : <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.ticketing.description" trackingShow={ ACTIONS.showSettings } trackingClick={ ACTIONS.clickSettings } />}
    </Wrapper>
  );
};

TicketingTools.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default TicketingTools;
