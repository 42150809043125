import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  Wrapper, Item, AddedTag, IconWrapper, Close
} from './styled';

const AddedTags = ({ addedTags, removeTag, keyPrefix }) => {
  if (isEmpty(addedTags)) return null;
  return (
    <Wrapper>
      { addedTags.map((t) => (
          <Item preventClose key={ `${keyPrefix}_${t}_` }>
            <AddedTag key="tag_manage_context_" children={ t } />
            <IconWrapper onClick={ () => removeTag(t) } children={ <Close /> } />
          </Item>
      ))}
    </Wrapper>
  );
};

export default AddedTags;
