import get from 'lodash/get';
import { selectEntity, selectOrderBy } from 'store/Filters/selectors';
import parseTemplateName, { mappedTemplates } from 'store/ExecutiveReportEditCreate/mapping';
// eslint-disable-next-line import/prefer-default-export
export const selectIsFetching = (state) => get(state, 'executiveReportEditCreate.isFetching', false);
export const selectVulnsCount = (state) => get(state, 'executiveReportEditCreate.total_vulns', 0);
export const selectPreviewData = (state) => get(state, 'executiveReportEditCreate.previewData', []);
export const selectInputField = (state, inputType) => get(state, `executiveReportEditCreate.${inputType}`, '');
export const selectSelectedTemplate = (state) => get(state, 'executiveReportEditCreate.selectedTemplate', []);
export const selectTemplates = (state) => get(state, 'executiveReport.templates', []);
export const selectID = (state) => get(state, 'executiveReportEditCreate.id', '');
export const selectFilterArray = (state) => get(state, 'executiveReportEditCreate.filterArray', []);
export const selectParseError = (state) => get(state, 'executiveReportEditCreate.parseError', false);
export const selectAdvancedFilter = (state) => get(state, 'executiveReportEditCreate.advancedFilter', '');
export const selectAdvancedFilterQueryParam = (state) => {
  const { page, rowsPerPage } = selectEntity('reports', state);
  const advancedFilter = selectAdvancedFilter(state);
  const orderBy = JSON.stringify(selectOrderBy('reports', state));
  const offset = (page - 1) * rowsPerPage;
  const queryToParse = `{"offset":${offset},"limit":${rowsPerPage},"order_by":${orderBy},"filters":[${advancedFilter}]}`;
  const parsedQuery = JSON.parse(queryToParse);
  return parsedQuery;
};
export const selectParsedTemplateName = (state) => {
  const selectedTemplate = selectSelectedTemplate(state);
  return parseTemplateName(selectedTemplate);
};

export const selectOrderedTemplates = (state) => {
  const templates = selectTemplates(state);

  const sortFunction = (ta, tb) => {
    const taName = get(ta, '[1]', '');
    const tbName = get(tb, '[1]', '');
    const parsedTa = get(mappedTemplates, `${taName}`, {});
    const parsedTb = get(mappedTemplates, `${tbName}`, {});
    const pondA = get(parsedTa, 'orderPonderation', -1);
    const pondB = get(parsedTb, 'orderPonderation', -1);

    if (pondA < pondB) return 1;
    if (pondA > pondB) return -1;
    return 0;
  };

  return templates.sort(sortFunction);
};
