import styled from 'styled-components';
import colors from 'Styles/colors';

const sevColor = {
  critical: colors.warmPurple,
  high: colors.redPink,
  medium: colors.tangerine,
  low: colors.apple,
  informational: colors.grey2,
  disable: '#d6d6d6'
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Text = styled.td`
  font-weight: ${({ bold }) => bold ? 'bold' : 'normal'};
  font-size: ${({ big }) => big ? '18px' : '13px'};
  padding-bottom: ${({ pb }) => pb ? '5px' : '0px'};
  color: ${({ color }) => color ? sevColor[color] : 'normal'};

`;
Text.displayName = 'Text';

export const Line = styled.tr`
font-size: 13px;
`;
Line.displayName = 'Line';

export const RefTag = styled.div`
  margin-top: 10px;
  color: #fff;
  background-color: ${({ green }) => green ? colors.green1 : colors.red1};
  width: 88px;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  padding: 2px 0;
  cursor:pointer;
`;
