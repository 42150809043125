import styled from 'styled-components';

export const Content = styled.div`
    padding: 0 19px 18px 20px;
    grid-row: 2/3;
    overflow: auto;
`;
Content.displayName = 'Content';

export const Wrapper = styled.div`
   max-width: 1920px;
   min-width: 1250px;
   overflow: hidden;
   height: 100%;
   margin: 0 auto;
   display: grid;
   grid-template-rows: min-content 1fr;
   grid-template-columns: min-content minmax(0, 1fr);
   background-color: #fafbfc;
`;
Wrapper.displayName = 'Wrapper';
