import styled from 'styled-components';
import { Dropdown as _Dropdown } from 'Common/Components/Inputs';

export const Wrapper = styled.div`
  opacity:${({ disabled }) => (disabled ? '.5' : '1')};
  //border-top: 1px solid #E9E9E9;
  margin-top: 32px;
  //padding-top: 20px;

  & button {
    margin-left: 25px;
  }
`;

export const Title = styled.div`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const Dropdown = styled(_Dropdown)`
  //margin-right: 25px !important;
  width: 300px;
}
`;

export const Flex = styled.div`
  display: flex;
`;
