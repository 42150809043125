import fetchApi from 'services/api/connector';

export const fetchHosts = (ws, queryParam) => fetchApi(`ws/${ws}/hosts/filter?q=${escape(JSON.stringify(queryParam))}`);

export const getHosts = (ws) => fetchApi(`ws/${ws}/hosts`);

export const createHost = (ws, data) => fetchApi(`ws/${ws}/hosts`, { method: 'POST', data });

export const updateHost = (ws, data) => fetchApi(`ws/${ws}/hosts/${data.id}`, { method: 'PATCH', data });

export const deleteHost = (ws, id) => fetchApi(`ws/${ws}/hosts/${id}`, { method: 'DELETE', body: {}, headers: { 'Content-type': 'application/json' } });

export const fetchTotalHosts = (workspace) => fetchApi(`ws/${workspace}/hosts/filter?q=${escape(JSON.stringify({ limit: 1, offset: 0, filters: [] }))}`);
