import styled from 'styled-components';
import { ReactComponent as DownArrow } from 'Images/arrow_down_drop.svg';

export const Wrapper = styled.div`
  height: 58px;
  flex: 1 1 auto;
  cursor: pointer;
  display: flex;
  overflow: visible;
  position: relative;
  padding-left: 13px;
  padding-right: 16px;
  box-shadow: 2px 2px 5px 0 rgba(28, 34, 67, 0.06), inset 0 -2px 0 0 #63758d;
  border: solid 1px #d6d6d6;
  background-color: #ffffff;
  `;
Wrapper.displayName = 'Wrapper';

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 58px;
  margin-left: 33px;
  `;
DropdownContainer.displayName = 'DropdownContainer';

export const InputTitle = styled.div`
  height: 17px;
  text-align: left;
  user-select: none;
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8f94b2;
  `;
InputTitle.displayName = 'InputTitle';

export const SelectedTemplate = styled.div`
  color: #1c2243;
  font-size: 14.5px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: normal;
  margin: auto auto auto 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`;
SelectedTemplate.displayName = 'SelectedTemplate';

export const ExpandArrow = styled(DownArrow)`
  margin: auto 0px auto auto;
  width: 25px;
`;
ExpandArrow.displayName = 'ExpandArrow';
