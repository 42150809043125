import Checkbox from 'Common/Components/Checkbox/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Title,
  Input as ClientId,
  Input as Username
} from 'Screens/Settings/common-styled';
import {
  authenticate,
  deauthenticate
} from 'store/Settings/serviceNowActions';
import {
  selectAuthenticated, selectError, selectIsFetching, selectMessage
} from 'store/Settings/serviceNowSelectors';
import { useIntl } from 'react-intl';

import BlueButton from 'Common/Components/BlueButton';
import Error from 'Common/Components/Error';
import Success from 'Common/Components/Success';
import {
  Content,
  Wrapper,
  Password,
  PleaseWait,
  WrapperTitle
} from './styled';

const Oauth = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectAuthenticated);
  const [authenticated, setAuthenticated] = useState(isAuthenticated);
  const [showParams, setShowParams] = useState(false);
  const [clientId, setClientId] = useState();
  const [secretKey, setSecretKey] = useState();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const isFetching = useSelector(selectIsFetching);
  const error = useSelector(selectError);
  const message = useSelector(selectMessage);

  useEffect(() => {
    if (authenticated) setShowParams(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const oAuthChange = (e) => {
    setAuthenticated(e.target.checked);
    setShowParams(e.target.checked);
    if (isAuthenticated && !e.target.checked) dispatch(deauthenticate());
  };

  const save = () => {
    dispatch(authenticate(clientId, secretKey, username, password));
  };

  if (isFetching) return <PleaseWait>{ intl.formatMessage({ id: 'pleaseWait' }) }</PleaseWait>;
  return (
    <Wrapper disabled={ isFetching }>
      <WrapperTitle>
        <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.title' }) }</Title>
        <Checkbox defaultChecked={ isAuthenticated } onChange={ (e) => oAuthChange(e) } disabled={ isFetching } />
      </WrapperTitle>
      { showParams && (
      <Content>
        <ClientId title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.clientId' }) } value={ clientId } defaultValue={ clientId } onChange={ (v) => setClientId(v) } />
        <Password title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.secretKey' }) } value={ secretKey } defaultValue={ secretKey } onChange={ (v) => setSecretKey(v) } />
        <Username title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.username' }) } value={ username } defaultValue={ username } onChange={ (v) => setUserName(v) } />
        <Password title={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.password' }) } value={ password } defaultValue={ password } onChange={ (v) => setPassword(v) } />
        <BlueButton onClick={ save } label={ intl.formatMessage({ id: 'preferences.ticketingTools.serviceNow.oauth.save' }) } isLoading={ isFetching } />
      </Content>
      ) }
      {error && message ? <Error>{message}</Error> : null}
      {!error && message ? <Success>{message}</Success> : null}
    </Wrapper>
  );
};
export default Oauth;
