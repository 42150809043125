import React from 'react';
import { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';
import { useDispatch } from 'react-redux';
import { newJob } from 'store/Jobs/actions';
import { Wrapper, Title, Button } from './styled';

const Header = () => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(newJob());
  return (
    <Wrapper>
      <Title>Jobs</Title>
      <Button title="Create" onClick={ onClick }>
        <PlusIcon />
        Create New Job
      </Button>
    </Wrapper>
  );
};
export default Header;
