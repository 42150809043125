import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedTemplate, selectVulnsCount } from 'store/ExecutiveReportEditCreate/selectors';
import { setPageNumber } from 'store/Filters/actions';
import { getVulnsPreview } from 'store/ExecutiveReportEditCreate/actions';
import { selectFilterHistory } from 'store/Filters/selectors';
import Pagination from './components/Pagination';
import Wrapper from './styled';
import ThemePreview from './components/ReportCovers/ThemePreview';
import Table from './components/Table';
import NoReportsPreview from './components/ReportCovers/NoReportsPreview';

const Cover = ({ show }) => {
  const selectedTemplate = useSelector(selectSelectedTemplate);
  if (show) {
    const templateName = selectedTemplate[1];
    if (templateName.includes('dark')) return <ThemePreview theme="dark" />;
    if (templateName.includes('light')) return <ThemePreview theme="light" />;
    return <ThemePreview theme="generic" />;
  }
  return null;
};

Cover.propTypes = {
  show: PropTypes.bool.isRequired
};

const ReportPreview = () => {
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const isTemplateSelected = useSelector(selectSelectedTemplate).length > 0;

  const vulnsCount = useSelector((state) => selectVulnsCount(state));
  const filters = useSelector((state) => selectFilterHistory('reports', state));
  const noResultsFilter = vulnsCount === 0 && filters.length > 0;
  const showNoReports = !isTemplateSelected || noResultsFilter;

  const setNewPage = (newPageNumber) => {
    setPage(newPageNumber);
    if (newPageNumber > 0) {
      dispatch(setPageNumber('reports', newPageNumber));
      dispatch(getVulnsPreview());
    }
  };

  return (
    <Wrapper>
      <Pagination page={ page } setPage={ setNewPage } />
      <Cover show={ page === 0 && !showNoReports } />
      <NoReportsPreview show={ page === 0 && showNoReports } />
      <Table show={ page > 0 } />
    </Wrapper>
  );
};

export default ReportPreview;
