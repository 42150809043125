import React from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { removeFilter } from 'store/Filters/actions';
import { getVulnsPreview } from 'store/ExecutiveReportEditCreate/actions';
import {
  ItemWrapper, AssetIP, Wrapper, RemoveIcon
} from './styled';

const AssetItem = ({ filter, onDelete }) => (
  <ItemWrapper>
    <AssetIP>
      { filter.val }
    </AssetIP>
    <RemoveIcon onClick={ () => onDelete(filter) } />
  </ItemWrapper>
);

AssetItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  filter: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
};

const AssetsList = ({ list }) => {
  const dispatch = useDispatch();
  const hasAssetsFilters = list.length > 0;

  const onDelete = (filter) => {
    dispatch(removeFilter('reports', filter));
    dispatch(getVulnsPreview());
  };

  return hasAssetsFilters
    ? (
    <Wrapper>
      {list.map((f) => <AssetItem key={ f.val } filter={ f } onDelete={ onDelete } />)}
    </Wrapper>
      )
    : null;
};

AssetsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  list: PropTypes.array.isRequired
};

export default AssetsList;
