/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './App.scss';
import './Styles/bootstrap.min.css';
import './Styles/styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Error from 'Screens/Error';
import { validateSession } from 'Screens/Login/actions/Actions';
import { selectLocation } from 'store/Router/selectors';
import { selectIsValidatingSession } from 'store/Sesion/selectors';
import { setUrlReturn } from 'Screens/Faraday/actions/Actions';
import NavMenu from 'Screens/NavMenu';
import SideBar from 'Screens/Sidebar';
import UserFeedback from 'Common/Components/UserFeedback';
import errorHandler from './Common/Handlers/ErrorHandler';
import FileUploadContext from './Common/Components/FileUploadContext';
import CommandValidator from './Common/Components/CommandValidator';
import LicenseValidator from './Common/Components/LicenseValidator';
import RedirectModal from './Common/Components/RedirectModal';
import { Content, Wrapper } from './styled';

import { initialize, trackPageView } from './tracking/GA';
import { selectIsProcessingFiles } from 'store/FileUploaderContext/selector';

const App = ({ logOut, children }) => {
  const gaKey = useSelector(() => (process.env.REACT_APP_GA_KEY));
  const location = useSelector(selectLocation);
  const isValidatingSession = useSelector(selectIsValidatingSession);
  const showImportModal = useSelector(selectIsProcessingFiles);

  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    dispatch(initialize(gaKey));
    if (location.hash.startsWith('#/status/ws/')) dispatch(setUrlReturn(location.hash.replace('#/status/ws/', 'manage/')));
    else dispatch(setUrlReturn(location.pathname));
    dispatch(validateSession());
    errorHandler(logOut);
    setMounted(true);
  }, [dispatch]);

  useEffect(() => {
    dispatch(trackPageView(`${location.pathname}${location.search}`));
  }, [dispatch, location]);

  if (!mounted || isValidatingSession) return null;

  return (
    <Wrapper>
      <NavMenu />
      <SideBar />
      <Content>
        {
          children
        }
        <UserFeedback />
      </Content>
      <LicenseValidator />
      <RedirectModal />
      <Error />
      { showImportModal && <FileUploadContext /> }
      <CommandValidator />
    </Wrapper>
  );
};

export default App;
