import styled from 'styled-components';

import { ReactComponent as MoreSVG } from 'Images/icon-action-bar-more.svg';

export const Wrapper = styled.div`
`;
Wrapper.displayName = 'Wrapper';

export const MoreIcon = styled(MoreSVG)`
    height: 17px;
    width: 20px;
`;
