import get from 'lodash/get';
import api from 'services/api';
import { SEND_VULN_TO_TOOL, updateVulns } from './settingsActions';

export const TOOL_NAME = 'whd';

// obtiene datos de ticketing tools
export const GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START = 'GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START';
export const GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL = 'GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL';
export const GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS = 'GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS';

// guarda los datos
export const SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START = 'SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START';
export const SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS = 'SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS';
export const SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL = 'SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL';

export const SET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FIELD = 'SET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FIELD'; // acutaliza el estado de un campo

export function getSolarWindsSettings () {
  return async (dispatch) => {
    dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START });
    try {
      const response = await api.settings.fetchTicketingTools(TOOL_NAME);
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: GET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function saveSolarWindsSettings () {
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_START });
    try {
      const {
        url, apiKey, template
      } = getState().settings.ticketingTools.solarWinds;
      const response = await api.settings.saveTicketingToolsSolarWinds(TOOL_NAME, url, apiKey, template);
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_SUCCESS, response });
    } catch (e) {
      return dispatch({ type: SAVE_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FAIL, error: true, message: 'There was an error, please try again.' });
    }
  };
}

export function setField (field, value) {
  return async (dispatch) => {
    dispatch({ type: SET_SETTINGS_TICKETING_TOOLS_SOLARWINDS_FIELD, field, value });
  };
}

export function sendVulnToSolarWinds (clientId, categoryId, locationId) {
  return async (dispatch, getState) => {
    dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: true, error: false, message: '' } });
    try {
      const { vulnsSelected } = getState().manage;
      const data = { vulns: vulnsSelected };
      if (clientId) data.client_id = Number(clientId);
      if (categoryId) data.category = Number(categoryId);
      if (locationId) data.location = Number(locationId);

      const vulns = await api.settings.sendVulnToSolarWinds(data);
      await dispatch(updateVulns(vulns, 'whd'));

      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: false, message: 'Operation was executed successfully.' } });
    } catch (e) {
      return dispatch({ type: SEND_VULN_TO_TOOL, payload: { isFetching: false, error: true, message: get(e, 'message', 'There was an error, please try again.') } });
    }
  };
}
