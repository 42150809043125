import React from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import 'Common/styles/commonStyles.scss';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import Pagination from 'Common/Components/Pagination';
import { selectIsAdmin } from 'store/Faraday/selectors';
import {
  Wrapper, Group, LeftGroup, RightGroup, Separator
} from './styled';

const ActionBar = ({
  tagButton, duplicateButton, createActions, groupFieldSelector, editButton, deleteButton,
  addRemoveColumns, downloadButton, moreOptionsButton, showDeleteConfirmation,
  handleSubmitDelete, handleCloseDelete, entity, count, sendButton, totalRows, vulnFilter,
  page, rowsPerPage, onPrev, onNext, sidebarFilters, deleteModalText, extraPreviousActions, quickPlannerButton, isGrouping, refreshButton
}) => {
  const showCreateActions = !!createActions;
  const showActions = count > 0 && (editButton || sendButton || deleteButton || moreOptionsButton || duplicateButton || tagButton);
  const showRightActions = downloadButton || sidebarFilters || addRemoveColumns || extraPreviousActions || refreshButton;
  const showWsRightActions = !!extraPreviousActions;
  const hasVulns = totalRows > 0;
  const isAdmin = useSelector(selectIsAdmin);

  return (
    <Wrapper>
      <ModalConfirmation
        show={ showDeleteConfirmation }
        handleSubmit={ handleSubmitDelete }
        handleClose={ handleCloseDelete }
        entity={ entity }
        count={ count }
        message={ deleteModalText }
      />
      <LeftGroup>
        <Group show={ showCreateActions }>
          {createActions }
        </Group>

        <Separator show={ showActions } />

        <Group show={ showActions }>
          { editButton }
          { sendButton }
          { tagButton }
          { deleteButton }
          { duplicateButton }
          { moreOptionsButton }
        </Group>
        <Separator show />
        <Group show={ isAdmin }>
          { quickPlannerButton }
        </Group>
      </LeftGroup>

      <RightGroup show>
        <Group show={ hasVulns && !isGrouping }>
          {vulnFilter }
        </Group>

        <Group show={ (hasVulns || isGrouping) && !!groupFieldSelector }>
          { groupFieldSelector }
        </Group>

        { ((hasVulns && !isGrouping) || showWsRightActions) && rowsPerPage && <Pagination offset={ page } limit={ rowsPerPage } total={ totalRows } onPrev={ onPrev } onNext={ onNext } />}

        <Separator show={ hasVulns && showWsRightActions && showRightActions } />

        <Group show={ hasVulns && showRightActions }>
          { downloadButton }
          { refreshButton }
          { sidebarFilters }
        </Group>

        <Group show={ showRightActions }>
          { addRemoveColumns }
        </Group>

        <Group show={ showWsRightActions }>
          {extraPreviousActions}
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

ActionBar.propTypes = {
  showDeleteConfirmation: PropTypes.bool,
  isGrouping: PropTypes.bool,
  handleSubmitDelete: PropTypes.func,
  handleCloseDelete: PropTypes.func,
  groupFieldSelector: PropTypes.instanceOf(Object),
  createActions: PropTypes.instanceOf(Object),
  deleteButton: PropTypes.instanceOf(Object),
  editButton: PropTypes.instanceOf(Object),
  moreOptionsButton: PropTypes.instanceOf(Object),
  addRemoveColumns: PropTypes.instanceOf(Object),
  sendButton: PropTypes.instanceOf(Object),
  downloadButton: PropTypes.instanceOf(Object),
  vulnFilter: PropTypes.instanceOf(Object),
  sidebarFilters: PropTypes.instanceOf(Object),
  entity: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  tagButton: PropTypes.instanceOf(Object),
  duplicateButton: PropTypes.instanceOf(Object),
  deleteModalText: PropTypes.string,
  refreshButton: PropTypes.instanceOf(Object)
};

ActionBar.defaultProps = {
  isGrouping: false,
  showDeleteConfirmation: false,
  handleSubmitDelete: () => {},
  handleCloseDelete: () => {},
  deleteButton: null,
  editButton: null,
  moreOptionsButton: null,
  addRemoveColumns: null,
  sendButton: null,
  groupFieldSelector: null,
  createActions: null,
  downloadButton: null,
  vulnFilter: null,
  sidebarFilters: null,
  tagButton: null,
  duplicateButton: null,
  deleteModalText: '',
  refreshButton: null
};

export default ActionBar;
