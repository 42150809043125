import BlueButton from 'Common/Components/BlueButton';
import styled from 'styled-components';
import colors from 'Styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
    
`;
Wrapper.displayName = 'Wrapper';

export const OptionsContainer = styled.div`
  margin-top: 14px;
`;
OptionsContainer.displayName = 'OptionsContainer';

export const ForgotPassword = styled.div`
  user-select: none;
  color: ${colors.blue9};
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  float:left;
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
`;
ForgotPassword.displayName = 'ForgotPassword';

export const RememberMe = styled.div`
  user-select: none;
  font-weight: 300;

  .checkbox-container {
    display: inline;
    float: left;
    margin-left: 22px;
    margin-right: 9px;
    margin-bottom: 0px;
    margin-top: 2px;
  }
`;
RememberMe.displayName = 'RememberMe';

export const RememberMeLabel = styled.div`
  color: ${colors.grey11};
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  display: inline;
  float: left;
  margin-top: 1px;
`;
RememberMe.displayName = 'RememberMe';

export const InputTitle = styled.p`
  user-select: none;
  color: ${(props) => (props.error ? colors.redPink : colors.grey11)};
  font-weight: 300 !important;
`;
InputTitle.displayName = 'InputTitle';

export const ErrorMessage = styled.p.attrs({
  id: 'login_error_message'
})`
    font-size: 10.5px;
    font-weight: normal;
    text-align: left;
    color: ${colors.redPink};
    float: left;
    width: 245px;
    margin-bottom: 8px !important;
    margin-top: 8px;
`;
ErrorMessage.displayName = 'ErrorMessage';

export const WrapperField = styled.div`
  ${InputTitle} {
    width: 348px;
    display: flex;
    justify-content: left;
    height: 14px;
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: left;
    padding-left: 5px;
  }
`;
WrapperField.displayName = 'WrapperField';

export const AcceptButton = styled(BlueButton)`
  margin-top: 34px;
  width: 100%;
  `;
AcceptButton.displayName = 'AcceptButton';
