import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { markDuplicates } from 'store/Manage/duplicateActions';
import IconButton from 'Common/Components/IconButton';
import { selectVulnsSelected, selectShowDetail, selectSelectedMainVulns } from 'store/Manage/selectors';
import { selectIsReadOnly } from 'store/Faraday/selectors';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { unSelectAll } from 'store/Manage/actions';
import get from 'lodash/get';
import DuplicateIcon from './styled';

const DuplicateButton = ({ showModal }) => {
  const dispatch = useDispatch();
  const readonly = useSelector(selectIsReadOnly);
  const selectedVulns = useSelector(selectVulnsSelected);
  const multipleVulnsSelected = selectedVulns.length > 1;
  const showVulnDetail = useSelector(selectShowDetail);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'vuln.detail.duplicates.markAsDuplicates' });
  const selectedVulnsIDs = selectedVulns.map((v) => v.id);
  const mainsRelatedToSelection = useSelector(selectSelectedMainVulns);
  const mergingGroups = mainsRelatedToSelection.length > 1;

  const onClick = () => {
    if (mergingGroups) showModal();
    else {
      const mainID = get(mainsRelatedToSelection, '[0].id', null);
      dispatch(markDuplicates(selectedVulnsIDs, mainID));
      dispatch(unSelectAll());
    }
  };

  if (readonly || !multipleVulnsSelected || showVulnDetail) return null;
  return (
    <IconButton icon={ <DuplicateIcon /> } title={ title } onClick={ onClick } />
  );
};

DuplicateButton.propTypes = {
  showModal: PropTypes.func.isRequired
};

export default DuplicateButton;
