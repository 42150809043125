import Skeleton from 'Common/Components/Skeleton';
import React from 'react';
import TimeAgo from 'react-timeago';
import { Wrapper, Content, Data, DateTime, Duration, Label, RunBy, Target } from './styled';

const Card = ({ isSkeleton, target, user, duration, date }) => {
  return (
    <Wrapper>
      <Target title={ target }>{isSkeleton ? <Skeleton /> : target}</Target>
      <Content>
        <RunBy>
          <Label>Run by:</Label>
          <Data>{isSkeleton ? <Skeleton /> : user}</Data>
        </RunBy>
        <Duration>
          <Label>Duration</Label>
          <Data>{isSkeleton ? <Skeleton /> : duration}</Data>
        </Duration>
      </Content>
      <DateTime>{isSkeleton ? <Skeleton /> : (date && <TimeAgo date={ date } />)}</DateTime>
    </Wrapper>
  );
};
export default Card;
