import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ElementSearch from 'Screens/Analytics/components/CreateEdit/components/EditGraph/components/Settings/components/ElementSearch';
import { selectWorkspaces } from 'store/Faraday/selectors';
import { useIntl } from 'react-intl';
import {
  Wrapper, Title, Label, AddWorkspace
} from './styled';

const Workspaces = ({
  onAddCallback, onRemoveCallback, onSelectCallback, workspacesSelected
}) => {
  const intl = useIntl();
  const workspaces = useSelector(selectWorkspaces);
  const isAddWorkspaceDisabled = workspacesSelected.some((ws) => ws === '');

  return (
    <Wrapper>
      <Title>Workspace</Title>
      <Label>Add one or more Workspaces to render their data</Label>
      <AddWorkspace disabled={ isAddWorkspaceDisabled } label="+ Add Workspace" onClick={ onAddCallback } />
      { workspacesSelected.map((value, index) => <ElementSearch placeholder={ intl.formatMessage({ id: 'analytics.graph.name' }) } key={ `workspaces-${value}` } elementIndex={ index } elementName={ value } elements={ workspaces } onSelectCallback={ onSelectCallback } onRemoveCallback={ onRemoveCallback } />)}
    </Wrapper>
  );
};

Workspaces.defaultProps = {
  workspacesSelected: []
};

Workspaces.propTypes = {
  onAddCallback: PropTypes.func.isRequired,
  onRemoveCallback: PropTypes.func.isRequired,
  onSelectCallback: PropTypes.func.isRequired,
  workspacesSelected: PropTypes.instanceOf(Object)
};

export default Workspaces;
