import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTags } from 'Screens/Tags/actions/Actions';
import { useIntl } from 'react-intl';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import ReportInput from './components/Input';
import TemplateDropdown from './components/TemplateDropdown';
import Sections from './components/Sections';
import { Wrapper, EnterpriseWrapper, TitleWrapper } from './styled';

const ReportForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'reports.editCreate.inputTitle.title' });
  const enterprise = intl.formatMessage({ id: 'reports.editCreate.inputTitle.enterprise' });
  const workspaceSelected = useSelector(selectCurrentWorkspace);

  useEffect(() => {
    dispatch(getTags());
  }, [dispatch, workspaceSelected]);

  return (
    <Wrapper>
      <TitleWrapper>
        <ReportInput inputType="title" inputTitle={ title } />
      </TitleWrapper>
      <EnterpriseWrapper>
        <ReportInput inputType="enterprise" inputTitle={ enterprise } />
        <TemplateDropdown />
      </EnterpriseWrapper>
      <Sections />
    </Wrapper>
  );
};

export default ReportForm;
