import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetLogin, login2fa, hideErrorMessage } from 'Screens/Login/actions/Actions';
import MainContainer from 'Common/Components/MainContainer';
import {
  Title, SubTitle, Button, BackButton
} from 'Common/Components/MainContainer/styled';
import SecretCode from 'Screens/Settings/components/Account/components/TwoFactor/components/SecretCode';
import { selectErrorMessage, selectActiveUser } from 'store/Sesion/selectors';
import {
  Wrapper, DigitsContainer, ButtonsContainer, WrapperError
} from './styled';

class SecondFactor extends Component {
  constructor (props) {
    super(props);
    this.onSecretCodeChange = this.onSecretCodeChange.bind(this);
    this.state = { secretCode: '' };
  }

  onSecretCodeChange (secretCode) {
    this.setState({ secretCode });
  }

  componentDidUpdate () {
    const { errorMessage, hideErrorMessage } = this.props;
    if (errorMessage.length > 0) {
      setTimeout(() => {
        hideErrorMessage();
      }, 4000);
    }
  }

  submit () {
    const { login2fa, user } = this.props;
    const { secretCode } = this.state;
    login2fa(secretCode, user);
  }

  render () {
    const { errorMessage, user, resetLogin } = this.props;
    const { secretCode } = this.state;

    const disabled = secretCode.length < 6;

    return (
      <Wrapper>
        <MainContainer width="450px" height="415px">
          <Title>{`Welcome, ${user}!`}</Title>
          <SubTitle>Two-factor authentication</SubTitle>

          <DigitsContainer>
            <SecretCode onChange={ this.onSecretCodeChange } />
          </DigitsContainer>

          <SubTitle>Enter the code from your authenticator app</SubTitle>
          <ButtonsContainer>
            <BackButton id="cancel" onClick={ () => { resetLogin(); } }>Cancel</BackButton>
            <Button id="singIn" onClick={ () => { this.submit(secretCode, user); } } disabled={ disabled }>Sign In</Button>
          </ButtonsContainer>
          { errorMessage && <WrapperError>{ errorMessage }</WrapperError> }
        </MainContainer>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: selectActiveUser(state),
  errorMessage: selectErrorMessage(state)
});

const mapDispatchToProps = (dispatch) => ({
  login2fa: (secretCode, user) => {
    dispatch(login2fa(secretCode, user));
  },
  resetLogin: () => {
    dispatch(resetLogin());
  },
  hideErrorMessage: () => {
    dispatch(hideErrorMessage());
  }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SecondFactor));
