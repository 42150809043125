import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { WithPermission } from 'Common/Components/WithPermission';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import DeleteButton from 'Common/Components/DeleteButton';
import { showUserModalDelete } from 'Screens/Users/actions/Actions';
import { selectActiveUser } from 'store/Sesion/selectors';
import { selectUserSelected } from 'store/Users/selectors';

const Delete = ({ readonly }) => {
  const dispatch = useDispatch();
  const selectedUser = useSelector(selectUserSelected);
  const activeUser = useSelector(selectActiveUser);
  const display = !isEmpty(selectedUser) && !selectedUser.some((u) => u.username === activeUser);

  return (
    <WithPermission permission={ FEATURE_USERS } type={ PERMISSION_TYPES.DELETE }>
      <DeleteButton readonly={ readonly } entity="manage" selectedEntities={ selectedUser } display={ display } openConfirmationModal={ () => dispatch(showUserModalDelete()) } />
    </WithPermission>
  );
};

Delete.propTypes = {
  readonly: PropTypes.bool.isRequired
  // eslint-disable-next-line react/forbid-prop-types
};

export default Delete;
