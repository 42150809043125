/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { useDispatch } from 'react-redux';
import { redirectToVulnDetail, unSelectAll } from 'store/Manage/actions';
import { markAsMainDuplicate, removeDuplicate } from 'store/Manage/duplicateActions';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Wrapper, Label, LinkButton, RemoveIcon, ThisVulnLabel
} from './styled';
import DuplicateLabel from './DuplicateIcon';

const DuplicateItem = ({ isDetailVuln, vuln }) => {
  const { id, name, is_main } = vuln;
  const intl = useIntl();
  const dispatch = useDispatch();

  const markAsMainLabel = intl.formatMessage({ id: 'vuln.detail.duplicates.markAsMain' });
  const thisVulnLabel = `(${intl.formatMessage({ id: 'vuln.detail.duplicates.thisVuln' })})`;

  const redirectToDetail = () => {
    dispatch(unSelectAll());
    dispatch(redirectToVulnDetail(id));
  };

  const onLabelClick = isDetailVuln ? null : redirectToDetail;

  const onRemoveClick = () => dispatch(removeDuplicate(id));

  return (
    <Wrapper>
      <DuplicateLabel isMain={ is_main } />
      <Label children={ name } onClick={ onLabelClick } />
      <ThisVulnLabel $show={ isDetailVuln } children={ thisVulnLabel } />
      <LinkButton $show={ isDetailVuln && !is_main } onClick={ () => dispatch(markAsMainDuplicate(id)) } children={ markAsMainLabel } />
      <RemoveIcon $show={ !is_main } onClick={ onRemoveClick } />
    </Wrapper>
  );
};

DuplicateItem.propTypes = {
  isDetailVuln: PropTypes.bool,
  vuln: PropTypes.object.isRequired
};

DuplicateItem.defaultProps = {
  isDetailVuln: false
};
export default DuplicateItem;
