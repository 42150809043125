import React, { useEffect } from 'react';
import NotFound from 'Screens/Errores/NotFound';
import { useSelector, useDispatch } from 'react-redux';
import { getGraphs, resetError } from 'store/Analytics/actions';
import { selectError, selectErrorMessage } from 'store/Analytics/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { WithPermission } from 'Common/Components/WithPermission/index';
import { FEATURE_ANALYTICS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import Empty from 'Screens/Analytics/components/Empty';
import Grid from 'Screens/Analytics/components/Grid';
import Table from 'Screens/Analytics/components/Table';
// import CreateEdit from 'Screens/Analytics/components/CreateEdit';
import ActionBar from 'Screens/Analytics/components/ActionBar';
import Modals from 'Screens/Analytics/components/Modals';
import { Wrapper } from './styled';

const Analytics = () => {
  const isEnabled = true; // TODO: implement feature flag & role permissions
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  useEffect(() => {
    dispatch(getGraphs());
  }, [dispatch]);

  if (!isEnabled) return <NotFound />;
  return (
    <Wrapper>
      <WithPermission permission={ FEATURE_ANALYTICS } type={ PERMISSION_TYPES.CREATE }>
        <ActionBar />
        <Table />
        <Grid />
        <Empty />
        <Modals />
        <ErrorDialog visible={ error } message={ errorMessage } resetError={ () => dispatch(resetError()) } />
      </WithPermission>
    </Wrapper>
  );
};

export default Analytics;
