/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useIntl } from 'react-intl';
import Wrapper from './styled';
import { SectionTitle } from '../../styled';
import InputTag from './InputTag';

const AddTag = ({ addTag }) => {
  const intl = useIntl();
  return (
    <Wrapper>
      <SectionTitle children={ intl.formatMessage({ id: 'manage.tags.add' }) } />
      <InputTag addTag={ addTag } />
    </Wrapper>
  );
};

export default AddTag;
