import styled from 'styled-components';
import colors from 'Styles/colors';
import { Modal } from 'react-bootstrap';
import { DataContainer } from 'Common/Components/InputFilter/styled';

const Row = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-stretch: normal;
  font-style: normal;
  font-size: 12.5px;
  padding-top: 8px;
`;
Row.displayName = 'Row';

export const CustomModal = styled(Modal)`
  width: 500px;
  height: 350px;
  overflow-x: inherit !important;
  overflow-y: inherit !important;
  position: absolute;
  left: calc(50% - 250px);
  top: calc(20%);
  padding: 0;

  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    padding: 34px 30px;
    height: 350px;
  }
`;
CustomModal.displayName = 'Wraper';

export const Header = styled.div`
`;
Header.displayName = 'Header';

export const Body = styled.div`
  margin-top: 25px;
`;
Body.displayName = 'Body';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  display: inline-block;
`;
Title.displayName = 'Title';

const Button = styled.button`
  width: 89px;
  height: 34px;
  border-radius: 2px;
  float: right;
  font-size: 14px;
  font-weight: 500;
`;

export const CancelButton = styled(Button)`
  border: solid 1px ${colors.dark6};
  color: ${colors.dark2};
  background-color: white;
  margin-right: 8px;
`;
CancelButton.displayName = 'CancelButton';

export const CreateButton = styled(Button)`
  background-color: ${(props) => (props.disabled ? colors.grey20 : colors.softBlue)};
  color: white;
  border: 0;
`;
CreateButton.displayName = 'CreateButton';

export const ErrorMessage = styled.label`
  color: ${colors.redPink};
`;
ErrorMessage.displayName = 'ErrorMessage';

export const SubTitle = styled.label`
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.grey19};
  display: inline-block;
`;
SubTitle.displayName = 'SubTitle';

export const SubTitleContainer = styled(SubTitle)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
SubTitleContainer.displayName = 'SubTitleContainer';

export const BlueText = styled.label`
  font-size: 12.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.blueCerulean};
  display: inline-block;
  margin-bottom: 0px;
`;
BlueText.displayName = 'BlueText';

export const UserSelectorWraper = styled.div`
  input {
    width: 427px;
  }

  ${DataContainer} {
    width: 440px;
    max-height: 125px;
    overflow-x: hidden;
  }
`;
UserSelectorWraper.displayName = 'UserSelectorWraper';

export const UserSelect = styled.div`
    height: 102px;
    border-radius: 3px;
    border: solid 1px #dddddd;
    background-color: #ffffff;
    margin-top: 21px;
    overflow: scroll;
`;
UserSelect.displayName = 'UserSelect';

export const UserRow = styled.div`
  height: 33px;
  width: 430px;

  &:hover {
    svg {
      color: ${colors.blue8};
    }
  }
`;
UserRow.displayName = 'UserRow';

export const NameRow = styled(Row)`
  width: 200px;
  font-weight: 300;
  color: ${colors.dark2};
  padding-left: 20px;
`;
NameRow.displayName = 'NameRow';

export const RoleRow = styled(Row)`
  width: 150px;
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  white-space: nowrap;
  color: ${({ role }) => {
    if (role === 'admin') return colors.warmPurple;
    if (role === 'pentester') return colors.redPink;
    if (role === 'client') return colors.blueCerulean;
    if (role === 'asset_owner') return colors.softBlue;
    return colors.softBlue;
  }};
`;
RoleRow.displayName = 'RoleRow';

export const IconRow = styled(Row)`
  display: inline-block;
  float: right;
  margin-right: 22px;
  padding-top: 7px;

  svg {
    height: 17px;
    width: 17px;
    color: ${colors.blueGrey};
    cursor: pointer;
  }
`;
IconRow.displayName = 'IconRow';
