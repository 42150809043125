/* eslint-disable react/no-children-prop */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {
  TagsWrapper,
  Tag,
  TagTitle,
  TagClose,
  Close
} from './styled';

const Tags = ({ tags, removeTag }) => {
  if (isEmpty(tags)) return null;
  return (
  <TagsWrapper>
    {
      tags.map((tag, i) => (
        <Tag key={ `${tag}_${i}` }>
          <TagTitle children={ tag } />
          <TagClose onClick={ () => removeTag(tag) } children={ <Close /> } />
        </Tag>
      ))
    }
  </TagsWrapper>
  );
};

export default Tags;
