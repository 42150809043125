/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import Buttons from 'Common/Components/JiraTemplateModal/Components/Buttons';
import FlexModal from 'Common/Components/JiraTemplateModal/Components/FlexModal';
import { Dropdown } from 'Common/Components/Inputs';
import { useSelector } from 'react-redux';
import { selectIsTemplate, selectProjectData } from 'store/Settings/jiraSelectors';
import { parseToLabel, parseIssueField, parseIssueType } from 'store/Settings/helpers';
import {
  Title, SubTitle, CustomInput, CustomTextArea
} from './styled';
import IssueType from './Components/IssueType';
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
const JiraTemplateModal = () => {
  const isTemplate = useSelector(selectIsTemplate);
  const projectData = useSelector(selectProjectData);
  const { name, issue_types, ticket_config } = projectData;

  const issueFieldInitialValue = (templateIssueType, issue_types) => {
    if (!templateIssueType) return null;
    const initialValue = issue_types.find((field) => field.key === templateIssueType.key);
    return initialValue;
  };

  const issueFieldDataInitialValue = (templateIssueType) => {
    if (!templateIssueType) return [];
    return templateIssueType.fields;
  };

  const templateIssueType = parseIssueType(ticket_config.issue_types);
  const issueFieldInitial = issueFieldInitialValue(templateIssueType, issue_types);
  const [issueFieldOptions, setIssueFieldOptions] = useState(issueFieldInitial);
  const [issueFieldData, setIssueFieldData] = useState(issueFieldDataInitialValue(templateIssueType, issueFieldInitial?.fields));
  const [ticketConfig, setTicketConfig] = useState(ticket_config);

  // issue fields state:
  const requiredFields = useMemo(() => () => (issueFieldOptions ? issueFieldOptions.fields.map((field) => (field.options.filter((option) => option.required))).reduce((prev, curr) => [...prev, ...curr], []) : []), [issueFieldOptions]);
  const fieldsDefault = useMemo(() => parseToLabel(issueFieldData.filter((issue) => !issue.required)), [issueFieldData]);
  const defaultOptionsUI = useMemo(() => [...parseToLabel(requiredFields()), ...fieldsDefault].map((op) => ({ ...op, value: op.key })), [requiredFields, fieldsDefault]);

  const [value, setValue] = useState(defaultOptionsUI);

  const onIssueTypeChange = (e) => {
    const unselectedIssueTypes = {};

    for (const iTypes in ticket_config.issue_types) {
      unselectedIssueTypes[iTypes] = {
        ...ticket_config.issue_types[iTypes],
        selected: false
      };
    }

    const issueTypes = {
      ...unselectedIssueTypes,
      [e.key]: {
        ...ticket_config.issue_types[e.key],
        selected: true
      }
    };

    setIssueFieldData(parseIssueType(issueTypes).fields);
    setIssueFieldOptions(e);
    setTicketConfig({
      ...ticketConfig,
      issue_types: {
        ...unselectedIssueTypes,
        [e.key]: {
          ...ticket_config.issue_types[e.key],
          selected: true
        }
      }
    });
  };

  const fieldDataWithoutLabel = (keySelected) => {
    // filter data to match with label
    const { label, ...fieldData } = issueFieldData.find((field) => field.key === keySelected);
    return fieldData;
  };

  const handleIssueTypeChange = (e, key, action) => {
    if (action && (action.action === 'pop-value' || action.action === 'remove-value')) {
      const { [key]: del, ...other } = ticketConfig.issue_types[issueFieldOptions.key].fields;
      setTicketConfig({
        ...ticketConfig,
        [ticketConfig.issue_types[issueFieldOptions.key].fields]: other
      });
    } else {
      setTicketConfig({
        ...ticketConfig,
        issue_types: {
          ...ticketConfig.issue_types,
          [issueFieldOptions.key]: {
            ...ticketConfig.issue_types[issueFieldOptions.key],
            fields: {
              ...ticketConfig.issue_types[issueFieldOptions.key].fields,
              [key]: {
                ...fieldDataWithoutLabel(key),
                value: e
              }
            }
          }
        }
      });
    }
  };

  const isDisable = () => {
    if (issueFieldOptions === null) return true;
    const requiredList = requiredFields();
    const ticketFields = ticketConfig.issue_types[issueFieldOptions.key].fields;

    const allRequiredFieldsAreCorrect = requiredList.every((field) => {
      if (!ticketFields[field.key]) return false;
      const type = ticketFields[field.key].schema.type;
      const val = ticketFields[field.key].value;
      if (type === 'array') {
        if (!ticketFields[field.key].value) return false;
        return true;
      }
      if (type === 'user' || type === 'version' || type === 'group' || type === 'date' || type === 'datetime' || type === 'string' || type === 'option' || type === 'project' || type === 'priority' || type === 'securitylevel' || type === 'option' || type === 'option') {
        if (val === '' || val === undefined) return false;
        return true;
      }

      if (type === 'number') {
        if (ticketFields[field.key].value === null) return false;
        return true;
      }
      return true;
    });
    return !allRequiredFieldsAreCorrect;
  };

  useEffect(() => {
    if (isTemplate) {
      let populatedTicket = {
        ...ticketConfig
      };
      if (!ticketConfig.summary) populatedTicket = { ...populatedTicket, summary: '{{vuln.name}} - {{vuln.severity}}' };
      if (!ticketConfig.description) populatedTicket = { ...populatedTicket, description: 'Description: {{vuln.description}}\n\n{% if vuln.data %}Data: {{vuln.data}}{% endif %}' };
      setTicketConfig(populatedTicket);
    }
  }, []);

  useEffect(() => {
    setValue(defaultOptionsUI);
  }, [defaultOptionsUI]);

  return (
    <FlexModal>
      <Buttons isTemplate={ isTemplate } ticketConfig={ ticketConfig } projectKey={ name } disabled={ isDisable() && !isTemplate } />
      <Title>
        Configure Jira Issue
        {isTemplate ? 'Template' : ''}
      </Title>
      <SubTitle>
        Issue’s configuration for
        {' '}
        { name }
      </SubTitle>
      <CustomInput
        title="Summary"
        placeholder="Add your summary"
        defaultValue={ ticketConfig.summary }
        onChange={ (summary) => setTicketConfig({ ...ticketConfig, summary }) }
      />
      <CustomTextArea
        title="Description"
        placeholder="Add your description"
        value={ ticketConfig.description }
        onChange={ (description) => setTicketConfig({ ...ticketConfig, description }) }
      />
      <Dropdown
        options={ parseToLabel(issue_types) }
        title="Issue Types"
        onChange={ onIssueTypeChange }
        defaultValue={ issueFieldOptions && { ...issueFieldOptions, label: issueFieldOptions.key } }
      />
      {issueFieldOptions && !!issueFieldOptions.fields.length &&
      (
      <IssueFieldDropdown
        ticketConfig={ ticketConfig }
        setTicketConfig={ setTicketConfig }
        issueFieldOptions={ issueFieldOptions }
        setIssueFieldData={ setIssueFieldData }
        templateIssueType={ templateIssueType }
        requiredFields={ requiredFields }
        value={ value }
        setValue={ setValue }
      />
      )}
      {!!issueFieldData.length && issueFieldData.map(({
        schema, name, options, key, ...data
      }) => (
        <IssueType
          onChange={ (e, action) => handleIssueTypeChange(e, key, action) }
          type={ schema.type }
          title={ name }
          options={ options }
          key={ `${issueFieldOptions.key}-${name}` }
          issueType={ issueFieldOptions.key }
          { ...data }
        />
      ))}
    </FlexModal>
  );
};

export default JiraTemplateModal;

const isPropValuesEqual = (subject, target, propNames) => propNames.every((propName) => subject[propName] === target[propName]);

const getUniqueItemsByProperties = (items, propNames) => items.filter((item, index, array) => index === array.findIndex((foundItem) => isPropValuesEqual(foundItem, item, propNames)));

const IssueFieldDropdown = ({
  issueFieldOptions, setIssueFieldData, ticketConfig, setTicketConfig, templateIssueType, requiredFields, value, setValue
}) => {
  const onIssueFieldChange = (e, action) => {
    if (action && (action.action === 'pop-value' || action.action === 'remove-value')) {
      if (action.removedValue.required) return;
      const issueType = { ...ticketConfig.issue_types[issueFieldOptions.key] };
      delete issueType.fields[action.removedValue.key];

      const issueTypes = {
        ...ticketConfig.issue_types,
        [issueFieldOptions.key]: issueType
      };
      setTicketConfig({
        ...ticketConfig,
        issue_types: issueTypes
      });
    }
    const newValue = e
      ? e.map((field) => {
        const { value, ...returnVal } = field;
        return returnVal;
      })
      : null;
    setValue(e ? getUniqueItemsByProperties(e, ['key']) : e);
    setIssueFieldData(newValue ? getUniqueItemsByProperties(newValue, ['key']) : []);
  };

  useEffect(() => {
    if (!templateIssueType?.fields) setIssueFieldData(parseToLabel(requiredFields()));
  }, [setIssueFieldData]);

  const options = parseIssueField(issueFieldOptions.fields).map((field) => ({
    ...field,
    options: field.options.map((op) => ({ ...op, value: op.key }))
  }));

  return (
    <Dropdown
      isMulti
      options={ options }
      title="Issue Fields"
      onChange={ onIssueFieldChange }
      value={ value }
      isClearable={ false }
    />
  );
};
