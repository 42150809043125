import React from 'react';
import { useDispatch } from 'react-redux';
import { showEditModal } from 'Screens/Workspaces/actions/Actions';
import IconButton from 'Common/Components/IconButton';
import { openModal } from 'store/modals/actions';
import { MODAL_WORKSPACE_CREATE_EDIT } from 'store/modals/modals';
import Edit from './styled';
import { WithPermission } from 'Common/Components/WithPermission';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

// eslint-disable-next-line import/prefer-default-export
export const WsEditButton = () => {
  const dispatch = useDispatch();

  const showEditionModal = () => {
    dispatch(showEditModal());
    dispatch(openModal(MODAL_WORKSPACE_CREATE_EDIT));
  };

  return (
    <WithPermission permission={ FEATURE_WORKSPACES } type={ PERMISSION_TYPES.UPDATE }>
      <IconButton icon={ <Edit /> } title="Edit" onClick={ showEditionModal } />
    </WithPermission>
  );
};
