import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showWorkspaceModalDelete } from 'Screens/Workspaces/actions/Actions';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import some from 'lodash/some';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import IconButton from 'Common/Components/IconButton';
import Trash from './styled';
import { WithPermission } from 'Common/Components/WithPermission';
import { FEATURE_WORKSPACES, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';

// eslint-disable-next-line import/prefer-default-export
export const WsDeleteButton = () => {
  const dispatch = useDispatch();
  const selectedWs = useSelector(selectWorkspacesSelected);
  const currentWs = useSelector(selectCurrentWorkspace);
  const isCurrentWorkspaceSelected = some(selectedWs, { name: currentWs });

  if (isCurrentWorkspaceSelected) return null;
  return (
    <WithPermission permission={ FEATURE_WORKSPACES } type={ PERMISSION_TYPES.DELETE }>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ () => dispatch(showWorkspaceModalDelete()) } />
    </WithPermission>
  );
};
