import { initialize as init, set, pageview, event } from 'react-ga';
import { selectCloudInstance } from 'store/Config/selector';

const selectIsEnabled = (state) => selectCloudInstance(state);

export function initialize (key) {
  return async (_, getState) => {
    const isEnabled = selectIsEnabled(getState());
    if (isEnabled) {
      init(key || 'developGaKey', {
        titleCase: false
      });
    }
  };
}

export function trackUser (email) {
  return async (_, getState) => {
    const isEnabled = selectIsEnabled(getState());
    if (isEnabled) set({ email });
  };
}

export function untrackUser () {
  return async (_, getState) => {
    const isEnabled = selectIsEnabled(getState());
    if (isEnabled) set({ email: '' });
  };
}

export function trackPageView (url) {
  return async (_, getState) => {
    const isEnabled = selectIsEnabled(getState());
    if (isEnabled) pageview(url);
  };
}

export function trackEvent (category, action, label, value) {
  return async (_, getState) => {
    const isEnabled = selectIsEnabled(getState());
    if (isEnabled) {
      event({
        category,
        action,
        label,
        value
      });
    }
  };
}
