import styled from 'styled-components';
import { tagStyles } from 'Styles/styles';

export const Wrapper = styled.div`
  margin-top: 34px;
`;
Wrapper.displayName = 'Wrapper';

export const TagWrapper = styled.div`
  margin-top: 21px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
`;
TagWrapper.displayName = 'TagWrapper';

export const Tag = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  background-color: #63758d;
  padding: 5px 8px;
  height: 25px;
  font-size: 11.5px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #FFF;
  margin-right: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  ${tagStyles};
`;
Tag.displayName = 'Tag';
