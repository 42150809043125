import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Wrapper, Separator } from './styled';
import AddedTags from './components/AddedTags';
import NotAddedTags from './components/NotAddedTags';

const TagList = ({
  addTag, addedTags, notAddedTags,
  removeTag, keyPrefix, entitiesSelected, showAddedTags
}) => {
  return (
    <Wrapper>
      { showAddedTags && <AddedTags addedTags={ addedTags } removeTag={ removeTag } keyPrefix={ keyPrefix } /> }
      { !isEmpty(addedTags) && !isEmpty(notAddedTags) && showAddedTags && <Separator /> }
      <NotAddedTags addTag={ addTag } notAddedTags={ notAddedTags } keyPrefix={ keyPrefix } entitiesSelected={ entitiesSelected } />
    </Wrapper>
  );
};

export default TagList;
