import styled from 'styled-components';
import { ReactComponent as Word } from 'Images/word.svg';
import { ReactComponent as PDF } from 'Images/pdf.svg';

export const ActionItemWrapper = styled.div`
  display: flex;  
  padding-left: 21px;
  height: 34px;
  :hover{
    background-color: #edf2f7;
  }
`;
ActionItemWrapper.displayName = 'ActionItemWrapper';

export const ItemTitle = styled.div`
  height: 34px;
  margin: auto 0px auto 0px;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.52;
  letter-spacing: normal;
  color: #1c2243;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`;
ItemTitle.displayName = 'ItemTitle';

export const WordIcon = styled(Word)`
  flex: 0 0 15px;
  height: 15px;
  margin: auto 14px auto 0px;
`;
WordIcon.displayName = 'WordIcon';

export const PDFIcon = styled(PDF)`
  flex: 0 0 15px;
  height: 15px;
  margin: auto 14px auto 0px;
`;
PDFIcon.displayName = 'PDFIcon';
