import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiMoreHorizontal as More } from 'react-icons/fi';
import { PropTypes } from 'prop-types';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import { moreStatusChange } from 'Screens/Users/actions/Actions';
import { selectMoreOptionsOpened } from 'store/Users/selectors';
import ContextMenuUsersMassiveUpdate from '../ContextMenuUsersMassiveUpdate';

export const ContextMenuMasiveUpdate = ({ currentBreakpoint }) => {
  const dispatch = useDispatch();
  const onShow = () => dispatch(moreStatusChange(true));
  const onHide = () => dispatch(moreStatusChange(false));
  return (
    <ContextMenuUsersMassiveUpdate
      breakpoint={ currentBreakpoint }
      trigger="massive_update_users_table_nav"
      onShow={ onShow }
      onHide={ onHide }
    />
  );
};

export const MoreOptionsButton = ({ currentBreakpoint, userSelected }) => {
  const moreOptionsOpened = useSelector(selectMoreOptionsOpened);
  return (
    <ContextMenuButton
      opened={ moreOptionsOpened }
      menu={ <ContextMenuMasiveUpdate currentBreakpoint={ currentBreakpoint } /> }
      trigger="massive_update_users_table_nav"
      display={ userSelected.length > 0 }
      icon={ <More color="#1c4566" className="icon-more" style={ { fontSize: '20px' } } onClick={ () => { } } id="action-button-more-options" /> }
    />
  );
};

ContextMenuMasiveUpdate.propTypes = {
  currentBreakpoint: PropTypes.bool.isRequired
};

MoreOptionsButton.propTypes = {
  currentBreakpoint: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userSelected: PropTypes.array.isRequired
};
