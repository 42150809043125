import React from 'react';
import PropTypes from 'prop-types';
import ElementSearch from 'Screens/Analytics/components/CreateEdit/components/EditGraph/components/Settings/components/ElementSearch';
import { useSelector } from 'react-redux';
import { selectHostsList, selectWorkspaces } from 'store/Analytics/selectors';
import { useIntl } from 'react-intl';
import { Wrapper, AddHost } from './styled';

const Hosts = ({
  onAddCallback, onRemoveCallback, onSelectCallback, hostsSelected
}) => {
  const intl = useIntl();
  const hosts = useSelector(selectHostsList);
  const isAddHostDisabled = hostsSelected.some((host) => host === '');
  const workspaces = useSelector(selectWorkspaces);
  const emptyWorkspaces = workspaces.length === 0;

  return (
    <Wrapper>
      <AddHost disabled={ isAddHostDisabled || emptyWorkspaces } label="+ Add Asset" onClick={ onAddCallback } />
      { hostsSelected.map((value, index) => <ElementSearch placeholder={ intl.formatMessage({ id: 'analytics.graph.searchHost' }) } key={ `hosts-${value}` } elementIndex={ index } elementName={ value } elements={ hosts } onSelectCallback={ onSelectCallback } onRemoveCallback={ onRemoveCallback } />)}
    </Wrapper>
  );
};

Hosts.defaultProps = {
  hostsSelected: []
};

Hosts.propTypes = {
  onAddCallback: PropTypes.func.isRequired,
  onRemoveCallback: PropTypes.func.isRequired,
  onSelectCallback: PropTypes.func.isRequired,
  hostsSelected: PropTypes.instanceOf(Object)
};

export default Hosts;
