/* eslint-disable import/prefer-default-export */
import React from 'react';
import ContextSubMenu from 'Common/Components/ContextMenu/components/ContextSubMenu';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectGroupByField } from 'store/Filters/selectors';
import { selectVulnsSelected } from 'store/Manage/selectors';
import {
  setBulkUpdateField, setBulkUpdateValue,
  showContextMenu, updateVuln
} from 'store/Manage/actions';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { WithPermission } from 'Common/Components/WithPermission';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import { StatusIcon } from './styled';

const StatusItem = ({ type }) => {
  const vulnsSelected = useSelector(selectVulnsSelected);
  const selectedVuln = vulnsSelected[0];
  const dispatch = useDispatch();
  const intl = useIntl();
  const oneVulnSelected = vulnsSelected.length === 1;
  const isBulk = vulnsSelected.length > 1;

  const applyStatus = (status) => {
    if (isBulk) {
      dispatch(setBulkUpdateField('status'));
      dispatch(setBulkUpdateValue(status));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVuln(selectedVuln, 'status', status));
    dispatch(showContextMenu(false));
  };

  return (
    <MenuItem
      isSelected={ oneVulnSelected && selectedVuln.status === type }
      title={ intl.formatMessage({ id: `manage.cm.status.${type}` }) }
      onClick={ () => applyStatus(type) }
    />
  );
};

StatusItem.propTypes = { type: PropTypes.string.isRequired };

export const Status = () => {
  const groupBy = useSelector((state) => selectGroupByField('vulns', state));
  const intl = useIntl();

  if (groupBy === 'status') return null;
  return (
    <WithPermission permission={ FEATURE_VULNS } type={ PERMISSION_TYPES.UPDATE }>
      <ContextSubMenu
        title={ intl.formatMessage({ id: 'manage.cm.status' }) }
        icon={ <StatusIcon /> }
      >
        <StatusItem type="open" />
        <StatusItem type="closed" />
        <StatusItem type="re-opened" />
        <StatusItem type="risk-accepted" />
      </ContextSubMenu>
    </WithPermission>
  );
};
