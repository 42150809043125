import React from 'react';
import { selectLimitReached } from 'store/Host/selectors';
import { useIntl } from 'react-intl';
import AddButton from 'Common/Components/AddButton';
import { useSelector } from 'react-redux';

const CreateActions = ({ setVisibilityCreateModal }) => {
  const intl = useIntl();
  const addText = intl.formatMessage({ id: 'host.create' });
  const limitReached = useSelector(selectLimitReached);
  const handleShow = () => !limitReached && setVisibilityCreateModal(true);

  return (
    <AddButton
      disabled={ limitReached }
      onClick={ handleShow }
      title={ addText }
      text={ addText }
    />
  );
};

export default CreateActions;
