import React from 'react';
import PropTypes from 'prop-types';
import { selectWorkspaces } from 'store/Faraday/selectors';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { standardDropdownStyle } from 'Common/styles/style';
import { Wrapper, Title, Subtitle } from './styled';

const SingleWorkspace = ({ defaultValue, onSelectWorkspaceCallback }) => {
  const workspaces = useSelector(selectWorkspaces);
  const intl = useIntl();

  if (workspaces.length === 0) return null;
  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'analytics.settings.ws.title' }) }</Title>
      <Subtitle>{ intl.formatMessage({ id: 'analytics.settings.ws.subtitle' }) }</Subtitle>
      <Select
        styles={ standardDropdownStyle }
        value={ defaultValue }
        onChange={ onSelectWorkspaceCallback }
        options={ workspaces.map((item) => ({ value: item.name, label: item.name })) }
        placeholder="Select One"
      />
    </Wrapper>
  );
};

SingleWorkspace.defaultProps = {
  defaultValue: {}
};

SingleWorkspace.propTypes = {
  onSelectWorkspaceCallback: PropTypes.func.isRequired,
  defaultValue: PropTypes.instanceOf(Object)
};
export default SingleWorkspace;
