import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: auto;
    margin-right: 19px;
    margin-top: 23px;
    max-width: 700px;
`;
Wrapper.displayName = 'FormWrapper';

export const TitleWrapper = styled.div`
    height: 58px;
`;
TitleWrapper.displayName = 'TitleWrapper';

export const EnterpriseWrapper = styled.div`
    height: 58px;
    overflow: visible;
    display: flex;
    flex-direction: row;
    margin-top: 14px;
    margin-bottom: 16px;
    >:nth-child(1) {
        width: 60%
    }
    >:nth-child(2) {
        width: 40%
    }
    @media (max-width: 1400px) {
        >:nth-child(1) {
            width: 50%;
        }
        >:nth-child(2) {
            width: 50%;
        }
    }
`;
EnterpriseWrapper.displayName = 'EnterpriseWrapper';
