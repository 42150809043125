import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getServiceNowSettings, TOOL_NAME } from 'store/Settings/serviceNowActions';
import { getTemplates } from 'store/Settings/settingsActions';
import IncidentConfiguration from './components/IncidentConfiguration';
import Oauth from './components/Oauth';
import Settings from './components/Settings';
import { Wrapper, Content } from './styled';

const ServiceNow = () => {
  const dispatch = useDispatch();

  // obtengo los datos del servidor
  useEffect(() => {
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getServiceNowSettings());
  }, [dispatch]);

  return (
    <Wrapper>
      <Content>
        <Settings />
        <Oauth />
        <IncidentConfiguration />
      </Content>
    </Wrapper>
  );
};
export default ServiceNow;
