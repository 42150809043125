import styled from 'styled-components';
import { tagStyles } from 'Styles/styles';

const Tag = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.06);
  border: solid 1px #bec8d2;
  background-color: #fafbfc;
  padding: 5px 8px;
  height: 25px;
  font-size: 11.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #63758d;
  margin-right: 12px;
  cursor: pointer;
  margin-bottom: 8px;
  ${tagStyles};
`;
Tag.displayName = 'Tag';

export default Tag;
