import styled from 'styled-components';

const StyledTinyCircle = styled.div`
    height: 12.5px;
    width: 12.5px;
    background-color: ${(props) => (props.outlined ? '' : props.color)};
    border: ${(props) => (props.outlined ? `2px solid ${props.color}` : 'none')};
    border-radius: 50%;
    display: inline-block;
`;

StyledTinyCircle.displayName = 'StyledTinyCircle';

export default StyledTinyCircle;
