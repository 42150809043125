import styled from 'styled-components';

const Wrapper = styled.div` 
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
