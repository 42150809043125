import fetchApi from 'services/api/connector';

export const getActiveWorkspaces = () => fetchApi('ws?filter&active=true');

export const addWorkspace = (data) => fetchApi('ws', { method: 'POST', data });

export const deleteWorkspace = (ws) => fetchApi(`ws/${ws}`, { method: 'DELETE' });

export const getReportStatus = (ws, commandId) => fetchApi(`ws/${ws}/commands/${commandId}`);

export const getWsStats = (ws) => fetchApi(`ws/${ws}`);

export const createWorkspace = (data) => fetchApi('ws', { method: 'POST', data });

export const updateWorkspace = (ws, data) => fetchApi(`ws/${ws}`, { method: 'PATCH', data });

export const fetchWorkspaces = (queryParam) => fetchApi(`ws/filter?q=${escape(JSON.stringify(queryParam))}&histogram=true`);
