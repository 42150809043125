import React from 'react';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import { DashboardTour } from 'Common/Components/Tour';
import DashboardLayout from '../DashboardLayout';

const Dashboard = () => (
  <>
    <DashboardLayout />
    <DashboardTour />
    <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
      <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showWorkspace } trackingClick={ ACTIONS.clickWorkspaces } />
    </ReduxModal>
  </>
);

export default Dashboard;
