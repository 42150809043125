import React, { useEffect } from 'react';
import {
  setVulnerabilityDetailTab, getComments, getUsersList
} from 'store/Manage/actions';
import { selectVulnDetailSelectedTab, selectVulnDetail } from 'store/Manage/selectors';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_COMMENTS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import TABS from './tabs';
import { TabSelectorWrapper, TabButton } from './styled';

const Tabs = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const selectedTab = useSelector(selectVulnDetailSelectedTab);

  const getLabel = (tab) => intl.formatMessage({ id: tab.intlId });
  const isActive = (tab) => tab.id === selectedTab.id;
  const vuln = useSelector(selectVulnDetail);
  const canReadComments = useSelector((state) => selectAllowedByRole(state, FEATURE_COMMENTS, PERMISSION_TYPES.READ));

  const enabledTabs = TABS.filter((tab) => {
    if (tab.id === 'comment') return canReadComments;
    return tab;
  });

  useEffect(() => {
    dispatch(getComments(vuln._id));
    dispatch(getUsersList());
  }, [dispatch, vuln._id]);

  return (
    <TabSelectorWrapper>
      { enabledTabs.map((tab) => (
        <TabButton
          active={ isActive(tab) }
          key={ tab.id }
          onClick={ () => dispatch(setVulnerabilityDetailTab(tab.id)) }
          children={ getLabel(tab) }
        />
      ))}
    </TabSelectorWrapper>
  );
};

export default Tabs;
