import styled from 'styled-components';
import colors from 'Styles/colors';
// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 13.5px;
  font-weight: 600;
  color: #264d6d;
  margin-bottom: 4px;
`;
Title.displayName = 'Title';

export const Subtitle = styled.div`
  font-size: 12.5px;
  color: ${colors.greyBlue};
  margin-bottom: 17px;
`;
Subtitle.displayName = 'Subtitle';
