import { setFilter } from 'store/Filters/actions';
import { redirect } from 'store/Router/actions';
import { DASHBOARD_FILTERS } from 'store/Filters/constants';
import get from 'lodash/get';
import { openTaskDetail } from 'store/Tasks/actions';

const parseNotifications = (type, data, dispatch) => {
  const ws = get(data, 'workspace.new_value', null);
  switch (type) {
    case 'new_workspace': {
      return () => {
        dispatch(redirect(`/feed/${data.name.unchanged_value}`));
      };
    }
    case 'update_executivereport': {
      return () => { dispatch(redirect(`/report/${ws}`)); };
    }
    case 'new_command': {
      return () => {
        dispatch(setFilter('vulns', DASHBOARD_FILTERS.commandIdFilter(data.id.new_value)));
        dispatch(redirect(`/manage/${ws}`));
      };
    }
    case 'new_comment': {
      return () => {
        if (data.obj_type === 'vulnerability') {
          dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnerabilityById(data.obj_id.new_value)));
          dispatch(redirect(`/manage/${ws}`));
        } else {
          const projectId = get(data, 'obj_task_project.new_value', false);
          const taskId = get(data, 'obj_id.new_value', false);
          dispatch(openTaskDetail(projectId, taskId, 'comments'));
        }
      };
    }
    default: {
      return () => {};
    }
  }
};

export default parseNotifications;
