import styled from 'styled-components';
import image from 'Images/empty_state_graph_graph.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
Wrapper.displayName = 'Wrapper';

export const EmptyImage = styled.img.attrs({
  src: image
})`
  width: 100%;
  max-width: 515px;
  margin-bottom: 30px;
  height: auto;
`;
EmptyImage.displayName = 'EmptyImage';
