import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { closeModal } from 'store/modals/actions';
import { MODAL_TASKS_CREATE_EDIT, MODAL_PROJECTS_DELETE } from 'store/modals/modals';
import Wrapper from 'Common/Components/ReduxModal/styled';
import { Default, Accept } from 'Common/Components/Button/styled';
import { selectProjectId, selectProjectName } from 'store/Projects/selectors';
import { setSelectedProject, deleteProject } from 'store/Projects/actions';
import { clearTasks } from 'store/Tasks/actions';
import {
  ModalWrapper, Title, Message, ButtonsWrapper
} from './styled';

const DeleteProject = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const projectName = useSelector(selectProjectName);
  const projectId = useSelector(selectProjectId);
  const title = intl.formatMessage({ id: 'planner.project.delete.title' }, { projectName });
  const message = intl.formatMessage({ id: 'planner.project.delete.message' });
  const cancel = intl.formatMessage({ id: 'cancel' });
  const done = intl.formatMessage({ id: 'done' });

  const onDone = () => {
    dispatch(deleteProject());
    dispatch(clearTasks(projectId));
    dispatch(setSelectedProject(null));
    dispatch(closeModal(MODAL_PROJECTS_DELETE));
    dispatch(closeModal(MODAL_TASKS_CREATE_EDIT));
  };

  const onCancel = () => {
    dispatch(setSelectedProject(null));
    dispatch(closeModal(MODAL_PROJECTS_DELETE));
  };

  return (
    <Wrapper>
      <ModalWrapper>
        <Title>{ title }</Title>
        <Message>{ message }</Message>
        <ButtonsWrapper>
          <Default onClick={ onCancel }>{ cancel }</Default>
          <Accept onClick={ onDone }>{ done }</Accept>
        </ButtonsWrapper>
      </ModalWrapper>
    </Wrapper>
  );
};

export default DeleteProject;
