import React from 'react';
import { PropTypes } from 'prop-types';
import { getVulnsPreview, setInputField } from 'store/ExecutiveReportEditCreate/actions';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { Tag, Label } from './styled';

const CustomFilterTag = ({ customFilter, disabled }) => {
  const dispatch = useDispatch();
  const query = get(customFilter, 'json_query', '');
  const name = get(customFilter, 'name', '');

  const applyFilter = () => {
    if (!disabled) {
      if (query) dispatch(setInputField('advancedFilter', query));
      dispatch(getVulnsPreview());
    }
  };

  return (
    <Tag
      disabled={ disabled }
      onClick={ applyFilter }
    >
      <Label>
        {name}
      </Label>
    </Tag>
  );
};
CustomFilterTag.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customFilter: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

CustomFilterTag.defaultProps = {
  disabled: false
};

export default CustomFilterTag;
