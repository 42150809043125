import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { Title, Subtitle } from 'Screens/Settings/common-styled';
import { selectTemplate } from 'store/Settings/solarWindsSelectors';
import { setField } from 'store/Settings/solarWindsActions';
import { selectTemplates } from 'store/Settings/settingsSelectors';
import {
  Wrapper, Content, Label, WrapperTemplate
} from './styled';

const IssuesConfiguration = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const props = useSelector((state) => ({
    templates: selectTemplates(state),
    selectedTemplate: selectTemplate(state)
  }));

  const onChange = (field, value) => {
    dispatch(setField(field, value));
  };

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.issuesConfiguration.title' }) }</Title>
      <Subtitle>{ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.issuesConfiguration.subtitle' }) }</Subtitle>
      <Content>
        <WrapperTemplate>
          <Label>{ intl.formatMessage({ id: 'preferences.ticketingTools.solarWinds.issuesConfiguration.template.title' }) }</Label>
          <StandardDropdown field="template" options={ props.templates } updateValue={ onChange } value={ props.selectedTemplate } defaultValue={ props.selectedTemplate } width="425px" />
        </WrapperTemplate>
      </Content>
    </Wrapper>
  );
};
export default IssuesConfiguration;
