import sendToWS from '../websocket/utils';

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';

// mark read notification
export const markNotification = (notification_id) => (dispatch) => {
  sendToWS(dispatch, 'mark_read_notification', { notification_id });
};
// mark read all notifications
export const markAllNotifications = () => (dispatch) => {
  sendToWS(dispatch, 'mark_read_all_notifications', {});
};

// Get Unread Notification
export const getUnreadNotifications = () => (dispatch) => {
  sendToWS(dispatch, 'get_unread_notifications');
};
