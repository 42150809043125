import React from 'react';
import VulnListSection from './VulnSection';
import PlainTextSection from './PlainTextSection';

const sectionsComponents = [
  {
    id: 0,
    added: true,
    component: (onDelete, onAdd, isAdded) => <VulnListSection key={ 0 } id={ 0 } onDelete={ onDelete } onAdd={ onAdd } isAdded={ isAdded } />
  },
  {
    id: 1,
    added: false,
    component: (onDelete, onAdd, isAdded) => <PlainTextSection inputType="summary" key={ 1 } id={ 1 } onDelete={ onDelete } onAdd={ onAdd } isAdded={ isAdded } />
  },
  {
    id: 2,
    added: false,
    component: (onDelete, onAdd, isAdded) => <PlainTextSection inputType="recommendations" key={ 2 } id={ 2 } onDelete={ onDelete } onAdd={ onAdd } isAdded={ isAdded } />
  },
  {
    id: 3,
    added: false,
    component: (onDelete, onAdd, isAdded) => <PlainTextSection inputType="scope" key={ 3 } id={ 3 } onDelete={ onDelete } onAdd={ onAdd } isAdded={ isAdded } />
  },
  {
    id: 4,
    added: false,
    component: (onDelete, onAdd, isAdded) => <PlainTextSection inputType="objectives" key={ 4 } id={ 4 } onDelete={ onDelete } onAdd={ onAdd } isAdded={ isAdded } />
  },
  {
    id: 5,
    added: false,
    component: (onDelete, onAdd, isAdded) => <PlainTextSection inputType="conclusions" key={ 5 } id={ 5 } onDelete={ onDelete } onAdd={ onAdd } isAdded={ isAdded } />
  }
];

export default sectionsComponents;
