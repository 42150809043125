import React from 'react';
import BulkMarkdownEditor from './BulkMarkdownEditor';
import NameInput from './NameInput';
import ItemList from './ItemList';
import Impact from './Impact';
import BulkComment from './BulkComment';

const BULK_UPDATE_COMPONENTS = {
  'description': <BulkMarkdownEditor />,
  'data': <BulkMarkdownEditor />,
  'resolution': <BulkMarkdownEditor />,
  'request': <BulkMarkdownEditor />,
  'response': <BulkMarkdownEditor />,
  'name': <NameInput />,
  'references': <ItemList />,
  'impact': <Impact />,
  'policy violations': <ItemList />,
  'comment': <BulkComment />
};

export default BULK_UPDATE_COMPONENTS;
