import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectRedirect, selectShowGridView, selectShowModalAssignUsers } from 'store/Workspace/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { stopRedirect, getData, getUsers } from 'Screens/Workspaces/actions/Actions';
import { setSelectedEntity, resetFilters } from 'store/Filters/actions';
import Table from './components/Table';
import WSActionBar from './components/WSActionBar';
import Wrapper from './styled';
import GridView from './components/GridView';
import ModalAssignUsers from './components/ModalAssignUsers';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { ACTIONS } from 'tracking/GA/constants';
import { FormattedMessage } from 'react-intl';
import { selectAllowGetUsers } from 'store/Session/selectors';

const Workspace = () => {
  const dispatch = useDispatch();
  const currentWorkspace = useSelector(selectCurrentWorkspace);
  const redirect = useSelector(selectRedirect);
  const showGridView = useSelector(selectShowGridView);
  const showModalAssignUsers = useSelector(selectShowModalAssignUsers);
  const allowGetUsers = useSelector(selectAllowGetUsers);

  useEffect(() => {
    if (allowGetUsers) dispatch(getUsers());
    dispatch(getData());
  }, [allowGetUsers, dispatch]);

  useEffect(() => () => {
    dispatch(resetFilters('workspaces'));
    dispatch(setSelectedEntity(''));
  }, [dispatch]);

  if (redirect) {
    dispatch(stopRedirect());
    return <Redirect to={ `/manage/${currentWorkspace}` } />;
  }

  return (
    <Wrapper>
      <WSActionBar />
      { showGridView ? <GridView /> : <Table /> }
      { showModalAssignUsers && <ModalAssignUsers /> }
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } description={ <FormattedMessage id="upgradeLicense.workspace.description" /> } trackingShow={ ACTIONS.showWorkspace } trackingClick={ ACTIONS.clickWorkspaces } />
      </ReduxModal>
    </Wrapper>
  );
};

export default Workspace;
