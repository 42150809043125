import React from 'react';

import {
  Label,
  Input,
  Indicator
} from './styled';

export default function RadioButton ({
  value,
  onChange,
  name,
  id,
  label,
  disabled,
  checked,
  className,
  defaultChecked
}) {
  return (
    <Label htmlFor={ id } disabled={ disabled } className={ className }>
      {label}
      <Input
        id={ id }
        type="radio"
        role="radio"
        name={ name }
        value={ value }
        disabled={ disabled }
        onChange={ onChange }
        checked={ checked }
        defaultChecked={ defaultChecked }
      />
      <Indicator />
    </Label>
  );
}
