import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FiPlus as Plus } from 'react-icons/fi';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Title,
  CustomInput,
  PlusIcon,
  InputWrapper
} from './styled';

function InputTag ({ addTag }) {
  const [value, setValue] = useState('');

  const addTagValue = () => {
    if (value !== '') {
      addTag(value);
      setValue('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      addTagValue();
    }
  };

  return (
    <Wrapper>
      <Title><FormattedMessage id="manage.tags.name" /></Title>
      <InputWrapper>
        <CustomInput value={ value } placeholder="Exploit" onKeyDown={ (e) => { handleKeyDown(e); } } onChange={ (e) => { setValue(e.target.value); } } />
        <PlusIcon onClick={ () => addTagValue() }><Plus color="#afb1bc" /></PlusIcon>
      </InputWrapper>
    </Wrapper>
  );
}

InputTag.propTypes = {
  addTag: PropTypes.func.isRequired
};

InputTag.defaultProps = {

};

export default InputTag;
