import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FaChevronDown as ChevronDown } from 'react-icons/fa';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';
import {
  Wrapper,
  DataContainer,
  DataItem,
  WorkspaceName,
  Stats
} from './styled';
import './styles.scss';

class SearchWorkspaces extends React.Component {
  constructor (props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSelectHandler = this.onSelectHandler.bind(this);
    this.parseTitle = this.parseTitle.bind(this);

    this.state = {
      value: '',
      showData: false
    };
  }

  componentDidMount () {
    const { getWorkspaces } = this.props;
    getWorkspaces();
  }

  onChangeHandler (v) {
    this.setState({ value: v });
    this.setState({ showData: true });
    // this.props.onChange(v);
  }

  onSelectHandler (item) {
    const { onSelect } = this.props;
    this.setState({ value: '' });
    this.setState({ showData: false });
    onSelect(item);
  }

  // eslint-disable-next-line class-methods-use-this
  parseTitle (item, raw) {
    if (item.type && item.type === 'Host') {
      const hostname = `${item.hostnames.length > 0 ? `(${item.hostnames[0]})` : ''}`;
      return `${item.name} ${hostname}`;
    }
    if (item.type && item.type === 'Service') {
      const hData = item.hostnameData.length > 0 ? `(${item.hostnameData})` : '';
      return `${item.hostname} ${hData} ${raw ? item.name : ''}`;
    }

    return item.name;
  }

  render () {
    const { value, showData } = this.state;
    // eslint-disable-next-line no-unused-vars
    const {
      type,
      workspaces,
      placeholder,
      id
    } = this.props;

    const filteredData = value === '' ? workspaces : workspaces.filter((it) => (it.name.toLowerCase().includes(value.toLowerCase())));
    return (
      <Wrapper onBlur={ () => { this.setState({ showData: false }); } }>
        <input className={ `ws-filter ${value.length > 0 ? 'full' : ''}` } value={ value } placeholder={ placeholder } type={ type } onChange={ (e) => this.onChangeHandler(e.target.value) } id={ id } />
        <ChevronDown color="#90a9c0" size="0.6em" className="input-chevron-icon" onClick={ () => { this.setState({ showData: true }); } } />
        {
          showData &&
          (
            <DataContainer>
              { filteredData.length > 0 && filteredData.map((item) => (
                <DataItem className="data-item" key={ `${item.type}_${item.id}` } onMouseDown={ (e) => { this.onSelectHandler(item); e.stopPropagation(); e.preventDefault(); } }>
                  <WorkspaceName>{ item.name }</WorkspaceName>
                  <Stats>{`${item.stats.total_vulns} vulnerabilities`}</Stats>
                </DataItem>
              )) }
            </DataContainer>
          )
        }
      </Wrapper>
    );
  }
}

SearchWorkspaces.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  workspaces: PropTypes.array.isRequired,
  getWorkspaces: PropTypes.func.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.string
};

SearchWorkspaces.defaultProps = {
  type: 'text',
  placeholder: 'Workspace Name',
  id: ''
};

const mapDispatchToProps = (dispatch) => ({
  getWorkspaces: () => {
    dispatch(getWorkspaces());
  }
});

const mapStateToProps = (state) => ({
  workspaces: state.workspace.workspaces
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchWorkspaces));
