import {
  GET_TAGS_LIST_START,
  GET_TAGS_LIST_SUCCESS,
  GET_TAGS_LIST_ERROR,
  SHOW_CREATE_TAG_MODAL
} from '../../Screens/Tags/actions/Actions';

const initialState = {
  isFetching: false,
  tagsList: [],
  error: false,
  errorMessage: '',
  showCreateTagModal: false
};

function tags (state = initialState, action) {
  switch (action.type) {
    case GET_TAGS_LIST_START: {
      return {
        ...state,
        isFetching: true
      };
    }

    case GET_TAGS_LIST_SUCCESS: {
      return {
        ...state,
        tagsList: action.data,
        isFetching: false
      };
    }

    case GET_TAGS_LIST_ERROR: {
      return {
        ...state,
        isFetching: false,
        errorMessage: 'An error has occurred',
        error: true
      };
    }

    case SHOW_CREATE_TAG_MODAL: {
      return {
        ...state,
        showCreateTagModal: action.show
      };
    }

    default: {
      return state;
    }
  }
}

export default tags;
