import React, { useState } from 'react';
import StyledSubMenu from './styled';

const SubMenu = ({ addTag, tags, removeTag, setTags }) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  return (
    <StyledSubMenu
      actionBar
      addTag={ addTag }
      entitiesSelected={ [{ tags }] }
      removeTag={ removeTag }
      trigger="import_file_add_tag_context_menu"
      keyPrefix="import_file_add_tag_context_menu"
      setTags={ setTags }
      showCreateModal={ showCreateModal }
      setShowModal={ (show) => setShowCreateModal(show) }
      showAddedTags={ false }
    />
  );
};

export default SubMenu;
