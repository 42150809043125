import styled from 'styled-components';

export const Wrapper = styled.div`
  
`;
Wrapper.displayName = 'Wrapper';

export const SearchAction = styled.div`
    font-size: 14.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1c2243;
    padding: 8px 20px;
    cursor: pointer;
    border-top: solid 1px #c7d6e4;
    margin-left: -20px;
    margin-right: -20px;

    &:hover{
        background-color: #e4ecf3;
        box-shadow: inset 0 -1px 0 0 #afafaf;
        font-weight: 600;
    }
`;
SearchAction.displayName = 'SearchAction';

export const DataContainer = styled.div`
    width: 430px;
    max-height: 350px;
    border-radius: 2px;
    box-shadow: 0 0 10px 10px rgba(222, 222, 222, 0.16);
    border: solid 1px #e8e8e8;
    background-color: #ffffff;
    overflow-y: auto;
    padding: 0 20px;
    position: absolute;
    z-index: 10;
    overflow-x: hidden;
    
    
`;
DataContainer.displayName = 'DataContainer';

export const DataItem = styled.div`
    font-size: 13px;
    color: #1c2243;
    cursor: pointer;
    padding: 0px 33px;
    border-radius: 3px;
    margin-left: -20px;
    margin-right: -20px;
    width: 420px;
    height: 33px;
    display: table;
}

    &:hover{
        background-color: #edf2f7;
        font-weight: 600;
    }
`;
DataItem.displayName = 'DataItem';

export const WorkspaceName = styled.span`
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.64;
    letter-spacing: normal;
    color: #1c2243;
    float: left;

`;
WorkspaceName.displayName = 'WorkspaceName';

export const Stats = styled.span`
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.72;
    letter-spacing: normal;
    color: #90a9c0;
    float: right;
`;
Stats.displayName = 'Stats';
