import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin-top: 12px;
  margin-bottom: 21px;
  >:nth-child(2){
    margin-right: 14px;
  }
  >:nth-child(3){
    width: 170px;
    margin-right: 7px;
  }
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
