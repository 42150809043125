import React from 'react';
import Select from 'react-select';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { updateVuln } from 'store/Manage/actions';
import { selectVulnDetail, selectReadOnly } from 'store/Manage/selectors';
import { statusSelect } from 'Common/styles/style';
import { capitalize } from 'utils/stringUtils';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { status as statusList } from 'Screens/Constants';
import Wrapper from './styled';

const StatusPicker = () => {
  const dispatch = useDispatch();
  const vuln = useSelector(selectVulnDetail);

  const readOnly = useSelector(selectReadOnly);
  const status = get(vuln, 'status', '');
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));

  const value = {
    label: capitalize(status),
    value: status
  };

  return (
    <Wrapper>
      <Select
        styles={ statusSelect }
        isSearchable={ false }
        disabled={ readOnly || !canUpdate }
        value={ value }
        onChange={ (e) => dispatch(updateVuln(vuln, 'status', e.value)) }
        options={ statusList.map((element) => ({ label: element.desc, value: element.name })) }
      />
    </Wrapper>
  );
};

export default StatusPicker;
