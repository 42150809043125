import styled from 'styled-components';
import { Title } from '../MarkdownInput/styled';

export const Wrapper = styled.div`
  overflow-y: auto;
  user-select: none;
  & >:first-child {
    margin-bottom: 12px;
  }
  >:nth-child(3), >:nth-child(4), >:nth-child(5) {
    margin-bottom: 20px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const AditionalText = styled.div`
  width: 220px;
  height: 36px;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: 0.1px;
  color: #afb1bc;
  margin-left: 20px;
`;
AditionalText.displayName = 'AditionalText';

export const Row = styled.div`
  display:flex;
  align-items: flex-start;
`;
Row.displayName = 'Row';

export const Field = styled.div`
  width: 50%;
`;
Field.displayName = 'Field';

export const MethodTitle = styled(Title)`
`;
MethodTitle.displayName = 'MethodTitle';
