/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { getCustomAttributes } from 'store/CustomAttributes/actions';
import {
  removeComment,
  resetErrorValue,
  resetState,
  showManageLeftFilters,
  hideVulnerabilityDetail,
  loadVulnerabilityDetail
} from 'store/Manage/actions';
import {
  selectSelectedComment, selectError, selectErrorMessage
  , selectShowDetail
} from 'store/Manage/selectors';
import { newGetVulns } from 'store/Manage/filterActions';
import {
  clearAdvancedFilter, getCustomFilters, resetFilters
} from 'store/Filters/actions';
import { getSelectedEntity } from 'store/Filters/selectors';
import { selectShowHostLeftFilters } from 'store/Host/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { getTags } from 'Screens/Tags/actions/Actions';
import get from 'lodash/get';
import selectTourFinished from 'store/Preferences/Tour/selectors';
import ConfirmationDialog from 'Common/Components/ConfirmationDialog';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';
import { selectTotalVulns } from 'store/Workspace/selectors';
import { ACTIONS } from 'tracking/GA/constants';
import selectModal from 'store/modals/selectors';
import { selectLocation } from 'store/Router/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { ManageTour } from 'Common/Components/Tour';
import QuickPlanner from 'Screens/Planner/QuickPlanner';
import CustomDragLayer from 'Common/Components/DragAndDrop/CustomDragLayer';
import { Wrapper, TableContainer } from './styled';
import 'Common/styles/markdownStyles.scss';
import ManageTable from './components/ManageTable';
import VulnerabilityDetail from './components/VulnerabilityDetail';
import CustomActionBar from './components/CustomActionBar';
import FilterSideBar from './components/FilterSideBar';
import TemplateModal from './components/ManageTable/components/ContextMenu/components/TemplateModal';
import 'Screens/Manage/styles/manageStyles.scss';

const Manage = ({ params }) => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const selectedEntity = useSelector(getSelectedEntity);
  const tourFinished = useSelector(selectTourFinished);
  const showHostFilters = useSelector(selectShowHostLeftFilters);
  const totalVulns = useSelector(selectTotalVulns);
  const location = useSelector(selectLocation);
  const showCommentDeletionModal = useSelector((state) => selectModal(state, 'commentDeletion'));
  const showModal = useSelector((state) => selectModal(state, MODAL_UPGRADE_LICENSE));
  const selectedComment = useSelector((state) => selectSelectedComment(state));
  const showDetail = useSelector(selectShowDetail);

  const handleParamId = () => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadVulnerabilityDetail(id));
    else dispatch(hideVulnerabilityDetail());
  };

  useEffect(() => {
    dispatch(getCustomAttributes());
    dispatch(getCustomFilters());

    if (showHostFilters) dispatch(showManageLeftFilters(true));
    else dispatch(showManageLeftFilters(false));

    handleParamId();
  }, [showHostFilters, selectedEntity, dispatch, handleParamId]);

  useEffect(() => () => {
    handleParamId();
    dispatch(resetState());
    dispatch(resetFilters('vulns'));
    dispatch(clearAdvancedFilter('vulns'));
  }, [dispatch]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId, location.pathname]);

  useEffect(() => {
    dispatch(newGetVulns());
    dispatch(getTags());
  }, [dispatch, workspaceSelected]);

  const emptyState = totalVulns === 0 && tourFinished;

  return (
    <Wrapper>
      { !emptyState && <CustomActionBar /> }
      <TableContainer>
        <CustomDragLayer />
        <ManageTable />
        <FilterSideBar entity="vulns" />
        { showDetail && <VulnerabilityDetail /> }
        <QuickPlanner />
        <TemplateModal />
      </TableContainer>
      <ErrorDialog visible={ error } message={ errorMessage } resetError={ () => dispatch(resetErrorValue()) } />
      <ManageTour />
      { showModal && (
        <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
          <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.workspace.description" trackingShow={ ACTIONS.showVulns } trackingClick={ ACTIONS.clickVulns } />
        </ReduxModal>
      ) }
      <ConfirmationDialog
        show={ showCommentDeletionModal }
        title="Delete Comment"
        submitText="Delete"
        message="A comment will be deleted. This action cannot be undone. Are you sure you want to proceed?"
        handleClose={ () => dispatch(closeModal('commentDeletion')) }
        handleSubmit={ () => {
          dispatch(removeComment(selectedComment.id));
          dispatch(closeModal('commentDeletion'));
        } }
      />
    </Wrapper>
  );
};

Manage.propTypes = {
  params: PropTypes.object.isRequired
};

export default Manage;
