import React from 'react';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { PropTypes } from 'prop-types';
import {
  ModalTitle, Title, BodyText, ModalContent, ButtonsContainer,
  ConfirmButton, CancelButton, Warning, ModalBody
} from './styled';

const ModalConfirmation = ({
  show, handleSubmit, handleClose, entity, count, message
}) => {
  const upperCaseTitle = entity.charAt(0).toUpperCase() + entity.slice(1);
  const modalText = message || `You’re deleting ${count} ${upperCaseTitle}${count > 1 ? '(s)' : ''}. This action cannot be undone. Are you sure you want to proceed?`;
  if (!show) return null;
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>
          <Warning />
          <Title>{`Delete ${upperCaseTitle}${count > 1 ? '(s)' : ''}`}</Title>
        </ModalTitle>
        <ModalBody>
          <BodyText>{modalText}</BodyText>
        </ModalBody>
        <ButtonsContainer>
          <CancelButton onClick={ handleClose }>
            Cancel
          </CancelButton>
          <ConfirmButton onClick={ handleSubmit }>
            Delete
          </ConfirmButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalWrapper>
  );
};

ModalConfirmation.propTypes = {
  show: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired,
  message: PropTypes.string,
  count: PropTypes.number
};

ModalConfirmation.defaultProps = {
  show: true,
  message: '',
  count: 0
};

export default ModalConfirmation;
