import api from 'services/api';
import get from 'lodash/get';
import { setError } from 'store/errors/actions';
import * as types from './types';
import {
  selectIsEnabled,
  selectDisplayName,
  selectIdentityId,
  selectUserRole,
  selectAttributeIdentifier,
  selectSsoUrl,
  selectSpCertificate,
  selectSpPrivateKey,
  selectIdpCertificate
} from './selectors';

const fetchStart = () => ({ type: types.SETTINGS_SAML_FETCH });
const success = (response) => ({ type: types.SETTINGS_SAML_SUCCESS, response });

export const setSamlField = (field, value) => ({ type: types.SET_SETTINGS_SAML_FIELD, field, value });
export const resetSaml = () => ({ type: types.SET_SETTINGS_SAML_RESET });

const error = (errorMessage) => (dispatch) => {
  dispatch(setError(errorMessage));
  dispatch({ type: types.SETTINGS_SAML_FAIL });
};

export function getSaml () {
  return async (dispatch) => {
    dispatch(fetchStart());
    try {
      const response = await api.settings.fetchSaml();
      const parsedResponse = {
        enabled: get(response, 'enabled', false),
        displayName: get(response, 'display_name', ''),
        identityId: get(response, 'identity_id', ''),
        userRole: get(response, 'user_role', ''),
        attributeIdentifier: get(response, 'attribute_identifier', ''),
        ssoUrl: get(response, 'sso_url', ''),
        spCertificate: get(response, 'sp_certificate', ''),
        spPrivateKey: get(response, 'sp_private_key', ''),
        idpCertificate: get(response, 'idp_certificate', '')
      };
      return dispatch(success(parsedResponse));
    } catch (e) {
      const errorMessage = get(e, 'messages.json.error', 'There was a problem, please try again');
      return dispatch(error(errorMessage));
    }
  };
}

export function saveSaml (onClose) {
  return async (dispatch, getState) => {
    dispatch(fetchStart());
    try {
      const state = getState();
      const enabled = selectIsEnabled(state);
      const display_name = selectDisplayName(state);
      const identity_id = selectIdentityId(state);
      const user_role = selectUserRole(state);
      const attribute_identifier = selectAttributeIdentifier(state);
      const sso_url = selectSsoUrl(state);
      const sp_certificate = selectSpCertificate(state);
      const sp_private_key = selectSpPrivateKey(state);
      const idp_certificate = selectIdpCertificate(state);

      const payload = {
        enabled,
        display_name,
        identity_id,
        user_role,
        attribute_identifier,
        sso_url,
        sp_certificate,
        sp_private_key,
        idp_certificate
      };

      const response = await api.settings.saveSaml(payload);
      onClose();
      return dispatch(success(response));
    } catch (e) {
      const errorMessage = get(e, 'messages.json.error', 'There was a problem, please try again');
      return dispatch(error(errorMessage));
    }
  };
}
