import styled from 'styled-components';
import TagSubMenu from 'Common/Components/Menus/TagSubMenu';

const StyledSubMenu = styled(TagSubMenu)`
  position: absolute !important;
  left: 105px !important;
  top: 0px !important;
`;

export default StyledSubMenu;
