import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWorkspaceSelected, hideWorkspaceModalDelete
} from 'Screens/Workspaces/actions/Actions';
import ModalConfirmation from 'Common/Components/ModalConfirmation';
import { selectShowDeleteConfirmation, selectWorkspacesSelected } from 'store/Workspace/selectors';
import { selectWorkspaceUsers } from 'store/Session/selectors';

const DeleteConfirmationModal = () => {
  const dispatch = useDispatch();
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const selectedWs = useSelector(selectWorkspacesSelected);
  const users = useSelector(selectWorkspaceUsers);

  const wsHaveAdminUsersOnly = () => {
    // Workspaces can be deleted if none of them has a non-admin user assigned to them
    const nonAdminUsers = users.filter((user) => !user.roles.includes('admin'));
    // return workspaces
    return selectedWs.every((ws) => nonAdminUsers.every((user) => !user.workspaces.includes(ws.name)));
  };

  const deleteModalText = wsHaveAdminUsersOnly() ? '' : 'You will not be able to recover it. Assigned users will no longer be able to access it.';

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ () => dispatch(deleteWorkspaceSelected()) }
      handleClose={ () => dispatch(hideWorkspaceModalDelete()) }
      entity="workspace"
      count={ selectedWs.length }
      message={ deleteModalText }
    />
  );
};

export default DeleteConfirmationModal;
