import React from 'react';
import { hideMenu } from 'react-contextmenu/modules/actions';
import { FEATURE_TAGS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import { useSelector } from 'react-redux';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { CreateNewText, Plus } from './styled';

const CreateTag = ({ actionBar, hideNewContextMenu, setShowModal }) => {
  const canCreate = useSelector((state) => selectAllowedByRole(state, FEATURE_TAGS, PERMISSION_TYPES.CREATE));

  const onCreate = () => {
    setShowModal(true);
    hideMenu();
    if (!actionBar) hideNewContextMenu();
  };

  if (!canCreate) return null;
  return (
    <CreateNewText onClick={ onCreate } actionBar={ actionBar }>
      <Plus />
      <span>Create Tag</span>
    </CreateNewText>
  );
};

export default CreateTag;
