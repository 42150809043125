/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import InputSearch from 'Common/Components/InputSearch';
import { FiTrash2 as Trash } from 'react-icons/fi';
import { AiFillExclamationCircle as Warning } from 'react-icons/ai';
import {
  getServices,
  getHosts,
  createHost,
  addAsset,
  removeAsset
} from 'Screens/ManageEditCreate/actions/Actions';
import { selectServicesAndHosts, selectManageEditCreate, selectIsWebVuln } from 'store/ManageEditCreate/selectors';
import TotalHostsUsed from 'Common/Components/TotalHostsUsed';
import { selectLimitReached } from 'store/Host/selectors';
import { ACTIONS } from 'tracking/GA/constants';
import AddServicePanel from './components/AddServicePanel';
import {
  Wrapper,
  Title,
  AddAssets,
  AddedAssets,
  AssetItem,
  RemoveAssetIcon,
  CreateAsset,
  AssetTitle,
  EmptyInfoText,
  AssetItemWrapper,
  AddServiceWarning,
  ServiceLink,
  Row
} from './styled';

class Assets extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      assets: [],
      actionValue: '',
      showAddServicePanel: false,
      selectedAsset: null
    };
    this.addAsset = this.addAsset.bind(this);
    this.removeAsset = this.removeAsset.bind(this);
    this.parseTitle = this.parseTitle.bind(this);
    this.onChangeActionValueHandler = this.onChangeActionValueHandler.bind(this);
  }

  componentDidMount () {
    this.props.getServices();
    this.props.getHosts();
  }

  componentDidUpdate () {
    if (this.props.manageEditCreate.targets !== this.state.assets) {
      this.setState({ assets: this.props.manageEditCreate.targets });
    }
  }

  onChangeActionValueHandler (v) {
    this.setState({ actionValue: v });
  }

  addAsset (value) {
    const temp = this.state.assets;
    const found = temp.find((item) => item.type === value.type && item.id === value.id);
    if (!found) this.props.addVulnAsset(value);
  }

  removeAsset (value) {
    const temp = this.state.assets;
    const index = temp.findIndex((item) => item.type === value.type && item.id === value.id);
    if (index > -1) {
      temp.splice(index, 1);
      this.setState({ assets: temp });
      this.props.removeVulnAsset(value);
    }
  }

  parseTitle (item, raw) {
    if (item.type && item.type === 'Host') {
      const hostname = `${item.hostnames.length > 0 ? `(${item.hostnames[0]})` : ''}`;
      return `${item.name} ${hostname}`;
    }
    if (item.type && item.type === 'Service') {
      const hData = item.hostnameData.length > 0 ? `(${item.hostnameData})` : '';
      return `${item.hostname} ${hData} ${raw ? item.name : ''}`;
    }

    return item.name;
  }

  render () {
    const { servicesAndHost, createHostAsAsset, manageEditCreate, isWebVuln } = this.props;
    const { actionValue, showAddServicePanel, selectedAsset } = this.state;
    // const isVulnWeb = manageEditCreate.request !== '' && manageEditCreate.response !== '';
    const searchAction = (
      <CreateAsset>
        <span className="mr-1">Create new Asset:</span>
        {actionValue}
      </CreateAsset>
    );
    return (
      <Wrapper>
        <Row className="ml-0 mr-0">
          <AddAssets className="add-assets">
            <Title>Add Assets</Title>
            <InputSearch onSelect={ this.addAsset } placeholder="Search or create Assets" data={ servicesAndHost } searchAction={ searchAction } functionAction={ createHostAsAsset } onChange={ this.onChangeActionValueHandler } isWebVuln={ isWebVuln } />
          </AddAssets>
          <AddedAssets className="added-assets">
            <Title>Added Assets</Title>
            { manageEditCreate.targets.length === 0
              ? <EmptyInfoText>No Assets added yet. Please search & select or create new Assets on the left.</EmptyInfoText>
              : manageEditCreate.targets.map((item) => (
                <AssetItemWrapper key={ item.id }>
                  <AssetItem className="asset">
                    <AssetTitle>{ this.parseTitle(item) }</AssetTitle>
                    <RemoveAssetIcon className="remove-icon" onClick={ () => this.removeAsset(item) }><Trash color="#1c4566" className="input-list-icon" /></RemoveAssetIcon>
                    {item.type && item.type === 'Service' && <span style={ { position: 'relative', top: '-25px' } } className="float-right">{item.name}</span> }
                  </AssetItem>
                  {
                    isWebVuln && item.type === 'Host' &&
                    (
                      showAddServicePanel && selectedAsset.id === item.id
                        ? <AddServicePanel cancelAction={ () => { this.setState({ showAddServicePanel: false }); } } hostId={ item.id } />
                        : (
                          <AddServiceWarning>
                            <Warning size="1.3em" className="mr-2" />
                            Web Vulns must be assigned to a
                            <ServiceLink onClick={ () => { this.setState({ selectedAsset: item }); this.setState({ showAddServicePanel: true }); } }>Service</ServiceLink>
                          </AddServiceWarning>
                          )
                    )
                  }
                </AssetItemWrapper>
              )) }
            { this.props.limitReached && <TotalHostsUsed trackingShow={ ACTIONS.showAddAssets } trackingClick={ ACTIONS.clickAddAssets } /> }
          </AddedAssets>
        </Row>
      </Wrapper>
    );
  }
}

Assets.propTypes = {
  getServices: PropTypes.func.isRequired,
  getHosts: PropTypes.func.isRequired,
  createHostAsAsset: PropTypes.func.isRequired,
  servicesAndHost: PropTypes.array.isRequired,
  addVulnAsset: PropTypes.func.isRequired,
  removeVulnAsset: PropTypes.func.isRequired,
  manageEditCreate: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  getHosts: () => {
    dispatch(getHosts());
  },

  getServices: () => {
    dispatch(getServices());
  },

  createHostAsAsset: (value) => {
    dispatch(createHost(value));
  },

  addVulnAsset: (target) => {
    dispatch(addAsset(target));
  },

  removeVulnAsset: (target) => {
    dispatch(removeAsset(target));
  }
});

const mapStateToProps = (state) => ({
  servicesAndHost: selectServicesAndHosts(state),
  manageEditCreate: selectManageEditCreate(state),
  isWebVuln: selectIsWebVuln(state),
  limitReached: selectLimitReached(state)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Assets));
