import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export const getFirstScanFileAmount = (state) => get(state, 'fileUploaderContext.firstScanFilesAmount', 0);

export const getFirstScanPercentage = (state) => get(state, 'fileUploaderContext.firstScanFilesPercentage', 0);

export const selectCommandError = (state) => get(state, 'fileUploaderContext.error', false);

export const selectCommands = (state) => get(state, 'fileUploaderContext.commandsInProcess', []);

export const selectUnfinishedCommands = (state) => selectCommands(state).filter((c) => !c.finished);
export const selectIsProcessingFiles = (state) => !isEmpty(selectCommands(state).filter((c) => c.fileType === 'file'));
