const initialState = {
  selectedComment: null,
  isFetching: false,
  isFetchingExpandedData: false,
  error: false,
  errorMessage: '',
  vulnsList: [],
  vulnsSelected: [],
  vulnsCount: 0,
  showDeleteConfirmation: false,
  showLeftFilters: false,
  showVulnDetail: false,
  vulnDetail: undefined,
  bulkUpdateField: '',
  bulkUpdateValue: '',
  lastSelected: -1,
  lastIndexForRange: -1,
  usesMockVulns: false,
  comments: [],
  vulnDetailSelectedTab: 'general',
  addColumnsOpened: false,
  userMentions: [],
  showContextMenu: false,
  contextMenuXPos: 0,
  contextMenuYPos: 0,
  showQuickPlanner: false,
  templatesCreated: [],
  failedTemplates: [],
  imagePreview: ''
};

export default initialState;
