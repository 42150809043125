export const AGENTS_GET_USER_AGENTS = 'AGENTS_GET_USER_AGENTS';
export const AGENTS_GET_CLOUD_AGENTS = 'AGENTS_GET_CLOUD_AGENTS';
export const AGENTS_FETCHING = 'AGENTS_FETCHING';
export const AGENTS_FAIL = 'AGENTS_FAIL';
export const AGENTS_SUCCESS = 'AGENTS_SUCCESS';
export const AGENTS_GET_TOKEN = 'AGENTS_GET_TOKEN';
export const AGENTS_GET_TOOLS = 'AGENTS_GET_TOOLS';
export const AGENTS_CREATE_WIZARD_RESET = 'AGENTS_CREATE_WIZARD_RESET';
export const AGENTS_CREATE_WIZARD_ADD_TOOL = 'AGENTS_CREATE_WIZARD_ADD_TOOL';
export const AGENTS_CREATE_WIZARD_REMOVE_TOOL = 'AGENTS_CREATE_WIZARD_REMOVE_TOOL';
export const AGENTS_CREATE_WIZARD_SET_FIELDS_TOOL = 'AGENTS_CREATE_WIZARD_SET_FIELDS_TOOL';
export const AGENTS_GET_MANIFEST = 'AGENTS_GET_MANIFEST';
export const RESET_NEW_AGENT = 'RESET_NEW_AGENT';
export const SET_NEW_AGENT = 'SET_NEW_AGENT';

export const AGENTS_SELECT_CLOUD_AGENT = 'AGENTS_SELECT_CLOUD_AGENT';
export const AGENTS_RUN_SET_WORKSPACES = 'AGENTS_RUN_SET_WORKSPACES';
export const AGENTS_RUN_SET_IGNORE_INFO = 'AGENTS_RUN_SET_IGNORE_INFO';
export const AGENTS_RUN_SET_RESOLVE_HOSTNAME = 'AGENTS_RUN_SET_RESOLVE_HOSTNAME';
