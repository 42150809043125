import styled from 'styled-components';

const Wrapper = styled.div`
  height: 132px;
  border-radius: 2px;
  background-color: #fafbfc;
  padding: 13px;
  margin-left: -13px;
  margin-bottom: 21px;
  margin-top: 21px;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;
