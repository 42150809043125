import styled from 'styled-components';
import Wrapper from '../../styled';

export const TextAreas = styled.div` 
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  width: 100%;
  overflow: hidden;
  &>:not(:last-child){
    padding-bottom: 17px;
  }
`;
TextAreas.displayName = 'TextAreas';

export const FormWrapper = styled(Wrapper)` 
  flex-direction: row;
  &>:not(:last-child) {
    margin-right: 20px;
  }
  &>:last-child{
    margin-top: 79px;
  }
`;
FormWrapper.displayName = 'FormWrapper';

export const Column = styled.div` 
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: hidden;
`;
