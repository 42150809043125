import { addFilter, removeFilter, setSaveEnabled } from 'store/Filters/actions';
import { isFilteringBy } from 'store/Filters/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { newGetVulns } from 'store/Manage/filterActions';
import { getData } from 'store/Host/actions';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { trackEvent } from 'tracking/GA';
import get from 'lodash/get';

const useFilterToggle = (filter, entityName) => {
  const dispatch = useDispatch();
  const isFilterActive = useSelector((state) => isFilteringBy(state, entityName, filter));

  const trackSearch = () => {
    const trackAction = get(ACTIONS, `search.${entityName}.preset`, '');
    if (trackAction) dispatch(trackEvent(CATEGORIES.search, trackAction.name, trackAction.label));
  };

  const toggleFilter = () => {
    if (isFilterActive) dispatch(removeFilter(entityName, filter));
    else dispatch(addFilter(entityName, filter));
    if (entityName === 'vulns') dispatch(newGetVulns());
    else if (entityName === 'assets') dispatch(getData());
    trackSearch();
    dispatch(setSaveEnabled(true));
  };

  return [isFilterActive, toggleFilter];
};

export default useFilterToggle;
