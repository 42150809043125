import React from 'react';
import { Wrapper, EmptyImage } from './styled';

// empty list/grid view
const Empty = () => (
  <Wrapper>
    <EmptyImage />
  </Wrapper>
);

export default Empty;
