import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionBar from 'Common/Components/ActionBar';
import CreateActions from 'Screens/Manage/components/CreateActions';
import EditColumnContext from 'Common/Components/EditColumnContext';
import DeleteConfirmationModal from 'Screens/Manage/components/CustomActionBar/components/DeleteConfirmationModal';
import MarkAsDuplicateModal from 'Screens/Manage/components/CustomActionBar/components/MarkAsDuplicateModal';
import IconButton from 'Common/Components/IconButton';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import selectModal from 'store/modals/selectors';
import { MODAL_CUSTOM_ATTRIBUTES } from 'store/modals/modals';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/manage/actions';
import {
  showVulnModalDelete,
  removeTag,
  addTag,
  downloadVulns,
  redirectToVulnDetail,
  setVulnsTags
} from 'store/Manage/actions';
import { selectPage, selectRowsPerPage, selectGroupByField } from 'store/Filters/selectors';
import { selectRoutes } from 'store/Manage/selectors';
import TagSubMenu from 'Common/Components/Menus/TagSubMenu';
import { FEATURE_DUPLICATES, FEATURE_VULNS, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import get from 'lodash/get';
import { selectManageFields } from 'store/Preferences/manage/selectors';
import { selectAllowedByFeature, selectAllowedByRole } from 'Hooks/usePermission/selector';
import VulnFlagFilter from 'Common/Components/VulnFlagFilter';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import QuickPlannerButton from 'Screens/Planner/QuickPlanner/QuickPlannerButton';
import CustomAttributesModal from 'Screens/Manage/components/CustomAttributesModal';
import { setPage } from 'store/Manage/filterActions';
import OpenContextMenuButton from './components/OpenContextMenu';
import GroupFieldSelector from './components/GroupFieldSelector';
import FilterSideBarIcon from './components/FilterSidebarIcon';
import DuplicateButton from './components/DuplicateButton';
import SendToToolsButton from './components/SendToToolsButton';
import {
  EditColumns, Trash, Edit, Tag, Download
} from './styled';
import { selectShowCreateTagModal } from 'store/Tags/selectors';
import { setCreationTagModal } from 'Screens/Tags/actions/Actions';
import RefreshButton from 'Screens/Manage/components/CustomActionBar/components/RefreshButton';

const CustomActionBar = () => {
  const [showContext, toggleContext] = useState(false);
  const [showDuplicateModal, toggleDuplicateModal] = useState(false);
  const dispatch = useDispatch();

  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const readonly = useSelector((state) => get(state, 'faraday.readonly', false));
  const fields = useSelector(selectManageFields);
  const routeList = useSelector(selectRoutes);
  const vulnsSelected = useSelector((state) => get(state, 'manage.vulnsSelected', []));
  const showVulnDetail = useSelector((state) => get(state, 'manage.showVulnDetail', false));
  const vulnsCount = useSelector((state) => get(state, 'manage.vulnsCount', 0));
  const page = useSelector((state) => selectPage('vulns', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('vulns', state));
  const canDelete = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.DELETE));
  const canUpdate = useSelector((state) => selectAllowedByRole(state, FEATURE_VULNS, PERMISSION_TYPES.UPDATE));
  const canUseDuplicates = useSelector((state) => selectAllowedByFeature(state, FEATURE_DUPLICATES, PERMISSION_TYPES.CREATE) && selectAllowedByRole(state, FEATURE_DUPLICATES, PERMISSION_TYPES.CREATE));
  const isGrouping = useSelector((state) => selectGroupByField('vulns', state));
  const showCustomAttributesModal = useSelector((state) => selectModal(state, MODAL_CUSTOM_ATTRIBUTES));
  const showCreateTagModal = useSelector(selectShowCreateTagModal);

  const tagMenu = (
    <TagSubMenu
      actionBar
      addTag={ (tag) => dispatch(addTag(tag)) }
      entitiesSelected={ vulnsSelected }
      keyPrefix="tag_menu_manage"
      removeTag={ (tag) => dispatch(removeTag(tag)) }
      setTags={ (tagsToAdd, tagsToRemove) => dispatch(setVulnsTags(tagsToAdd, tagsToRemove)) }
      trigger="tag_menu_manage"
      showCreateModal={ showCreateTagModal }
      setShowModal={ (show) => dispatch(setCreationTagModal(show)) }
    />
  );

  const addRemoveColumns = (
    <EditColumnContext
      trigger="action-button-column"
      fields={ fields }
      toggle={ () => toggleContext(!showContext) }
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      resetDefault={ () => dispatch(resetDefault()) }
    />
  );

  // TODO: Separate in components appart
  const showButton = (!readonly && vulnsSelected.length > 0 && !showVulnDetail);
  const deleteButton = showButton ? <IconButton icon={ <Trash /> } title="Delete" onClick={ () => dispatch(showVulnModalDelete()) } /> : null;
  const editButton = showButton ? <IconButton icon={ <Edit /> } title="Edit" onClick={ () => dispatch(redirectToVulnDetail(vulnsSelected[0]._id)) } /> : null;
  const downloadButton = <IconButton icon={ <Download /> } title="Download Filtered Workspace" onClick={ () => dispatch(downloadVulns()) } />;
  const moreOptionsButton = <OpenContextMenuButton display={ showButton && vulnsSelected.length > 0 && !showVulnDetail } />;

  const addRemoveColumnsButton = (
    <>
      { showCustomAttributesModal && <CustomAttributesModal /> }
      <ContextMenuButton
        opened={ showContext }
        menu={ addRemoveColumns }
        trigger="action-button-column"
        display
        icon={ <IconButton icon={ <EditColumns /> } title="Columns" /> }
      />
    </>
  );

  const tagButton = (
    <ContextMenuButton
      menu={ tagMenu }
      trigger="tag_menu_manage"
      display={ showButton }
      icon={ <IconButton icon={ <Tag /> } title="Tags" /> }
    />
  );

  const duplicateButton = canUseDuplicates && <DuplicateButton showModal={ () => toggleDuplicateModal(true) } />;

  return (
    <>
      <DeleteConfirmationModal />
      <MarkAsDuplicateModal show={ showDuplicateModal } hideModal={ () => toggleDuplicateModal(false) } />
      <ActionBar
        isGrouping={ !!isGrouping }
        workspaceSelected={ workspaceSelected }
        groupFieldSelector={ vulnsSelected.length === 0 && !showVulnDetail ? <GroupFieldSelector /> : null }
        createActions={ !readonly && <CreateActions /> }
        deleteButton={ canDelete ? deleteButton : null }
        editButton={ canUpdate ? editButton : null }
        moreOptionsButton={ moreOptionsButton }
        duplicateButton={ duplicateButton }
        addRemoveColumns={ addRemoveColumnsButton }
        downloadButton={ downloadButton }
        routeList={ routeList }
        entity="vulnerability"
        count={ vulnsSelected.length }
        tagButton={ canUpdate ? tagButton : null }
        sendButton={ <SendToToolsButton /> }
        totalRows={ vulnsCount }
        vulnFilter={ <VulnFlagFilter /> }
        page={ page }
        rowsPerPage={ rowsPerPage }
        onPrev={ () => dispatch(setPage(page - 1)) }
        onNext={ () => dispatch(setPage(page + 1)) }
        sidebarFilters={ <FilterSideBarIcon entity="vulns" /> }
        quickPlannerButton={ <QuickPlannerButton /> }
        refreshButton={ <RefreshButton /> }
      />
    </>

  );
};

export default CustomActionBar;
