import fetchApi from 'services/api/connector';

export const fetchGraphs = () => fetchApi('analytics', { method: 'GET' });
export const createGraph = (data) => fetchApi('analytics', { method: 'POST', data });
export const editGraph = (id, data) => fetchApi(`analytics/${id}`, { method: 'PATCH', data });
export const fetchGraph = (id) => fetchApi(`analytics/${id}`, { method: 'GET' });
export const removeGraph = (id) => fetchApi(`analytics/${id}`, { method: 'DELETE' });
export const copyGraph = (id) => fetchApi(`analytics/clone/${id}`, { method: 'POST' });
export const refreshGraph = (query) => fetchApi(`analytics/data_collection?${query}`, { method: 'GET' });
export const getHosts = (workspace) => fetchApi(`ws/${workspace}/hosts`);
