/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { clearFilters, setPageNumber } from 'store/Filters/actions';
import { getVulnsPreview, setInputField } from 'store/ExecutiveReportEditCreate/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnsCount } from 'store/ExecutiveReportEditCreate/selectors';
import { FormattedMessage, useIntl } from 'react-intl';
import Expander from '../Expander/index';
import { Wrapper, Title, StyledRadio } from './styled';
import AdvancedFilter from './components/AdvancedFilter';
import FieldsFilter from './components/FieldsFilter';

const VulnListSection = ({ id }) => {
  const [radioValue, setRadio] = useState(0);
  const dispatch = useDispatch();
  const vulnsCount = useSelector(selectVulnsCount);
  const allEnabled = radioValue === 0;
  const fieldsEnabled = radioValue === 1;
  const advancedEnabled = radioValue === 2;
  const intl = useIntl();
  const expanderTitle = `${intl.formatMessage({ id: 'reports.editCreate.vulnSection.expanderTitleStart' })} (${vulnsCount} ${intl.formatMessage({ id: 'reports.editCreate.vulnSection.expanderTitleEnd' })})`;
  const expanderSubtitle = intl.formatMessage({ id: 'reports.editCreate.vulnSection.expanderSubtitle' });
  const title = <FormattedMessage id="reports.editCreate.vulnSection.title" />;

  const allVulns = intl.formatMessage({ id: 'reports.editCreate.vulnSection.allVulnsRadio' });
  const filterByFields = intl.formatMessage({ id: 'reports.editCreate.vulnSection.filterByFieldsRadio' });
  const advancedFilter = intl.formatMessage({ id: 'reports.editCreate.vulnSection.advancedFilterRadio' });

  const reloadFilteringOption = () => {
    dispatch(setPageNumber('reports', 1));
    dispatch(clearFilters('reports'));
    dispatch(setInputField('advancedFilter', ''));
    dispatch(getVulnsPreview());
  };

  const onChange = ({ target }) => {
    setRadio(Number(target.value));
    reloadFilteringOption();
  };

  return (
    <Expander id={ id } title={ expanderTitle } subtitle={ expanderSubtitle } canExpand isRemovable={ false }>
      <Wrapper>
        <Title children={ title } />
        <StyledRadio label={ allVulns } value={ 0 } checked={ allEnabled } onChange={ onChange } />
        <StyledRadio label={ filterByFields } value={ 1 } checked={ fieldsEnabled } onChange={ onChange } />
        <FieldsFilter disabled={ !fieldsEnabled } enableSection={ () => setRadio(1) } />
        <StyledRadio label={ advancedFilter } value={ 2 } checked={ advancedEnabled } onChange={ onChange } />
        <AdvancedFilter disabled={ !advancedEnabled } enableSection={ () => setRadio(2) } />
      </Wrapper>
    </Expander>
  );
};

VulnListSection.propTypes = {
  id: PropTypes.number.isRequired
};

export default VulnListSection;
