import get from 'lodash/get';
import styled from 'styled-components';
import colors from 'Styles/colors';

const statusMapColors = {
  'opened': colors.warmPurple,
  'risk-accepted': colors.green1,
  're-opened': colors.grey1,
  'closed': colors.grey1
};

// eslint-disable-next-line import/prefer-default-export
export const Status = styled.div`
  color: ${(props) => get(statusMapColors, [props.value], colors.grey1)};
  font-weight: bold;
  font-size: 12.5px;
  text-transform: capitalize;
`;
Status.displayName = 'Status';
