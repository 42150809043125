/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useIntl } from 'react-intl';
import Tag from 'Images/tag.svg';
import {
  Wrapper, Title, BtnsWrapper,
  Cancel, Create, TagIcon
} from './styled';

const Header = ({ setTags, isLoading, onHandleClose, tagsToAdd, tagsToRemove }) => {
  const intl = useIntl();

  const onCreate = () => {
    setTags(tagsToAdd, tagsToRemove);
    onHandleClose();
  };

  return (
      <Wrapper>
        <Title children={ intl.formatMessage({ id: 'manage.tags' }) } />
        <BtnsWrapper>
          <Cancel onClick={ onHandleClose } label="Cancel" />
          <Create onClick={ onCreate } label="Save" isLoading={ isLoading } />
        </BtnsWrapper>
        <TagIcon><img alt="Tags" src={ Tag } /></TagIcon>
      </Wrapper>
  );
};

export default Header;
