import api from 'services/api';
import { refreshDashboard } from 'Screens/Dashboard/actions/Actions';
import { hasFinishedProcessingFirstScanCommand, isProcessingAFirstScan } from 'Common/Components/FileUploadContext/functions';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';
import { newGetVulns } from 'store/Manage/filterActions';
import isEmpty from 'lodash/isEmpty';
import { getFirstScanFileAmount, selectCommands, selectUnfinishedCommands } from 'store/FileUploaderContext/selector';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

export const HIDE_FILE_UPLOAD_CONTEXT = 'HIDE_FILE_UPLOAD_CONTEXT';
export const ADD_COMMAND = 'ADD_COMMAND';
export const COMMAND_ERROR = 'COMMAND_ERROR';
export const UPDATE_COMMAND_LIST_STATUS = 'UPDATE_COMMAND_LIST_STATUS';
export const UPDATE_FIRST_SCAN_FILE_AMOUNT = 'UPDATE_FIRST_SCAN_FILE_AMOUNT';
export const UPDATE_FIRST_SCAN_FILE_PERCENTAGE = 'UPDATE_FIRST_SCAN_FILE_PERCENTAGE';

export function hideUploadContextMenu () {
  return (dispatch) => {
    dispatch({ type: HIDE_FILE_UPLOAD_CONTEXT });
  };
}

export function updateFirstScanFileAmount (value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIRST_SCAN_FILE_AMOUNT, value });
  };
}

export function updateFirstScanFilePercentage (value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_FIRST_SCAN_FILE_PERCENTAGE, value });
  };
}

export function updateCommandsStatus () {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const commands = selectCommands(state);
      const workspaceSelected = selectCurrentWorkspace(state);
      const unfinishedCommands = selectUnfinishedCommands(state);
      const firstScanFileAmount = getFirstScanFileAmount(state);

      const promises = unfinishedCommands.map((c) => api.workspace.getReportStatus(workspaceSelected, c.command_id));
      const results = await Promise.all(promises);

      const commandListUpdated = commands.map((command) => {
        if (command.finished) return command;
        else {
          const commandStatus = results.find((updated) => updated._id === command.command_id);
          const finished = commandStatus.duration !== 'In progress';
          const error = commandStatus.duration === 'Error';
          return ({ ...command, finished, error });
        }
      });

      const updatedFirstScanCommands = commandListUpdated.filter((c) => c.fileType === 'firstScan');
      const updatedFSAmount = updatedFirstScanCommands.length;

      if (firstScanFileAmount < updatedFSAmount) {
        dispatch(updateFirstScanFileAmount(updatedFSAmount));
      }

      if (isProcessingAFirstScan(commandListUpdated)) {
        const finishedFirstScanAmount = firstScanFileAmount - updatedFSAmount;
        const percentage = (finishedFirstScanAmount * 100) / firstScanFileAmount;
        const finalValue = percentage || 0;
        dispatch(updateFirstScanFilePercentage(finalValue));
      }

      if (isEmpty(promises)) {
        if (window.location.href.includes('/manage')) dispatch(newGetVulns());
        else if (window.location.href.includes('/feed')) dispatch(refreshDashboard());
        dispatch(getWorkspaces());
        dispatch(updateFirstScanFileAmount(0));
      }

      if (window.location.href.includes('/manage') && hasFinishedProcessingFirstScanCommand(commandListUpdated)) {
        dispatch(newGetVulns());
      }

      return dispatch({ type: UPDATE_COMMAND_LIST_STATUS, data: commandListUpdated });
    } catch (error) {
      return dispatch({ type: COMMAND_ERROR, error });
    }
  };
}
