import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styled';

function Tooltip ({ text, children }) {
  return (
    <Wrapper text={ text }>
      {children}
    </Wrapper>
  );
}

Tooltip.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

export default Tooltip;
