/* eslint-disable react/no-children-prop */
import React, { useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectFilterArray } from 'store/ExecutiveReportEditCreate/selectors';
import { selectTags } from 'store/Tags/selectors';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  createFilter, createLabelName, eq, REPORT_FILTERS
} from 'store/Filters/constants';
import { selectIsFiltering } from 'store/Filters/selectors';
import { addFilter, clearFilters } from 'store/Filters/actions';
import { getVulnsPreview } from 'store/ExecutiveReportEditCreate/actions';

import {
  Wrapper, Title, TagsContainer, NoTags, NoTagsBold, NoTagsContainer
} from './styled';
import FilterTag from './components/FilterTag';
import AssetsFilter from './components/AssetsFilter';

const FieldsFilter = ({ enableSection, disabled }) => {
  const dispatch = useDispatch();
  const tags = useSelector(selectTags);
  const tagFilters = tags.map((t) => ({ name: createLabelName(t.name, 'tags__name'), filter: createFilter('tags__name', eq.name.value, t.name) }));
  const noTagsAvailable = tags.length === 0;
  const areStoredFilters = useSelector((state) => selectIsFiltering('reports', state));
  const filterArray = useSelector(selectFilterArray);
  const title = <FormattedMessage id="reports.editCreate.fieldFilter.title" />;
  const subtitle = <FormattedMessage id="reports.editCreate.fieldFilter.includeTags" />;

  const NoTagsSubtitle = () => {
    const intl = useIntl();
    const noTagsTitle = intl.formatMessage({ id: 'reports.editCreate.fieldFilter.noTags' });
    const noTagsPath = ` ${intl.formatMessage({ id: 'reports.editCreate.fieldFilter.vulnsPath' })}`;

    return (
      <NoTagsContainer>
        <NoTags children={ noTagsTitle } />
        <NoTagsBold children={ noTagsPath } />
      </NoTagsContainer>
    );
  };

  const updateStoredFilters = useCallback((newFilterArray) => {
    dispatch(clearFilters('reports'));
    newFilterArray.forEach((filter) => dispatch(addFilter('reports', filter)));
    dispatch(getVulnsPreview());
  }, [dispatch]);

  useEffect(() => {
    if (filterArray.length > 0) {
      updateStoredFilters(filterArray);
    }
  }, [filterArray, updateStoredFilters]);

  useEffect(() => {
    if (areStoredFilters) enableSection();
  }, [areStoredFilters, enableSection]);

  return (
    <Wrapper disabled={ disabled }>
      <Title children={ title } />
      <TagsContainer>
        { REPORT_FILTERS.map((f) => (<FilterTag key={ `${f.name.label}` } filterElement={ f } disabled={ disabled } />)) }
      </TagsContainer>
      <Title children={ subtitle } />
      <TagsContainer>
        {noTagsAvailable && <NoTagsSubtitle /> }
        { tagFilters.map((f) => (<FilterTag key={ `${f.name.label}` } filterElement={ f } disabled={ disabled } />)) }
      </TagsContainer>
      <AssetsFilter disabled={ disabled } />
    </Wrapper>
  );
};

FieldsFilter.propTypes = {
  disabled: PropTypes.bool.isRequired,
  enableSection: PropTypes.func.isRequired
};

export default FieldsFilter;
