import styled from 'styled-components';
import colors from 'Styles/colors';
import { FaChevronDown as ChevronDown } from 'react-icons/fa';

export const Wrapper = styled.div`
  height: 34px;
  width: 177px;
  align-self: center;
  margin-right: 30px;
  &:hover{
    background-color: ${colors.grey25};
    border-radius: 5px;
  }
`;
Wrapper.displayName = 'Wrapper';

export const Toggle = styled.div`
  padding: 0px;
  padding-left: 13px;
  padding-right: 13px;
  height: 34px;
  font-size: 11.5px;
  color: ${colors.dark2};
  width: 177px;
  display:flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
  box-shadow: 0 10px 15px 0 rgba(190, 200, 210, 0.24), inset 0 -1px 30px 0 rgba(99, 117, 141, 0.14);
  border: solid 3px rgba(190, 200, 210, 0.4);
  background-color: #edf2f7;
  &:after {
    content: none;
  }
`;
Toggle.displayName = 'Toggle';

export const Menu = styled.div`
  background:#fff;
  width: 339px !important;
  height: fit-content;
  padding-left: 8px;
  padding-right: 7px;
  padding-top: 23px;
  padding-bottom: 23px;
  position: absolute;
  z-index: 99;
  border-radius: 2px;
  box-shadow: 2px 2px 30px 0 rgba(0, 0, 0, 0.14);
  border: solid 1px #cdd5dd;

  a {
    text-decoration: none;
  }
`;
Menu.displayName = 'Menu';

export const Item = styled.div`
  cursor: pointer;
  padding-left: 13px;
  padding-right: 13px;
  height: 43px;
  border-bottom: ${(props) => (props.lastelement ? 'none' : `solid 1px ${colors.grey24}`)};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${colors.iceBlue} !important;
  }

  &:active {
    color: ${colors.dark2};
  }
`;
Item.displayName = 'Item';

export const NoItems = styled.div`
  padding-left: 13px;
  padding-right: 13px;
  height: 43px;
  display: flex;
  align-items: center;
  font-size: 13.5px;
  font-weight: 300;
  font-stretch: normal;
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
NoItems.displayName = 'NoItems';

export const WorkspaceSelectedName = styled.div`
  color: ${colors.dark2};
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
WorkspaceSelectedName.displayName = 'Item';

export const ArrowDown = styled(ChevronDown)`
  width: 10px;
  color: ${colors.grey5};
  margin-top: 4px;
`;
ChevronDown.displayName = 'ChevronDown';

export const WorkspaceOptionName = styled.span`
  font-size: 13.5px;
  font-weight: 400;
  font-stretch: normal;
  color: ${colors.dark2};
  max-width: 235px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
WorkspaceOptionName.displayName = 'WorkspaceOptionName';

export const ActionsContainer = styled.div`
  margin-top: 21px;
  padding-left: 13px;
  padding-right: 13px;
`;
ActionsContainer.displayName = 'ActionsContainer';

export const ViewAll = styled.span`
    font-size: 12.5px;
    font-weight: 300;
    font-stretch: normal;
    color: ${colors.blueCerulean};
    float: left;
    cursor: pointer;
`;
ViewAll.displayName = 'ViewAll';

export const CreateNew = styled.span`
    font-size: 12.5px;
    font-weight: 300;
    font-stretch: normal;
    color: ${colors.blueCerulean};
    float: right;
    cursor: pointer;
`;
CreateNew.displayName = 'CreateNew';

export const WorkspaceVulns = styled.span`
  color: ${colors.blueGrey};
  font-size: 12.5px;
  font-weight: 300;
  font-stretch: normal;
  padding-top: 1px;
  position: absolute;
  right: 20px;
`;
WorkspaceVulns.displayName = 'WorkspaceVulns';
