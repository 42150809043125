import React from 'react';
import { useDispatch } from 'react-redux';
import { markAllNotifications } from 'store/Notifications/actions';
import { Footer as Wrapper } from './styled';

const Footer = () => {
  const dispatch = useDispatch();
  return (
    /*eslint-disable */
    <Wrapper>
      <p onClick={ () => dispatch(markAllNotifications()) }>Delete All</p>
    </Wrapper>
    /* eslint-enable */
  );
};

export default Footer;
