import styled from 'styled-components';
import colors from 'Styles/colors';

export const Wrapper = styled.div`
  height: 100%;
  max-height: 100px;
  overflow: auto;
`;
Wrapper.displayName = 'Wrapper';

export const Item = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  color: ${colors.darkBlueGrey};
  overflow: hidden;
  white-space: nowrap;
  padding: 10px;
  display: flex;
  align-items: center;
  &:hover {
    background: ${colors.iceBlue};
    cursor: pointer;
  }
`;
Item.displayName = 'Item';
