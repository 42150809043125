import fetchApi from 'services/api/connector';

export const downloadReport = (workspace, payload, id) => fetchApi(`ws/${workspace}/reports/${id}/download`, payload);

export const getReportsList = (workspace, queryParam) => fetchApi(`ws/${workspace}/reports?${queryParam}`);

export const getPreviewFiltered = (workspace, queryParam, cancelTokenSource) => fetchApi(`ws/${workspace}/vulns/filter?q=${escape(JSON.stringify(queryParam))}`, undefined, undefined, cancelTokenSource);

export const getVulnsPreview = (workspace, queryParam) => fetchApi(`ws/${workspace}/vulns/filter?q=${escape(JSON.stringify(queryParam))}}`);

export const fetchTemplates = (workspace) => fetchApi(`ws/${workspace}/reports/listTemplates`);

export const createReport = (workspace, payload) => fetchApi(`ws/${workspace}/reports`, { method: 'POST', data: payload });

export const getReportDetail = (workspace, id) => fetchApi(`ws/${workspace}/reports/${id}`);

export const updateReport = (workspace, payload) => fetchApi(`ws/${workspace}/reports`, { method: 'PATCH', data: payload });

export const removeReport = (workspace, reportId) => fetchApi(`ws/${workspace}/reports/${reportId}`, { method: 'DELETE' });

export const getReportConfiguration = () => fetchApi('settings/executive_reports', { method: 'GET' });

export const saveReportConfiguration = (useMarkdown, borderWidth) => fetchApi('settings/executive_reports', { method: 'PATCH', data: { markdown: useMarkdown, border_size: borderWidth } });
