import styled from 'styled-components';
import colors from 'Styles/colors';
import { slideInLeft } from 'Styles/effects';

export const DetailWrapper = styled.div`
    display: flex;
    flex: 1 1 0px;
    flex-direction: column;
    padding-left: 15px;
    background-color: ${colors.white1};
    height: 100%;
`;
DetailWrapper.displayName = 'DetailWrapper';

export const Wrapper = styled.div.attrs(({ wrapperWidth }) => {
  let width = '55%';
  if (wrapperWidth) width = `${wrapperWidth}px`;
  return ({ style: { width } });
})`
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    z-index: 1;
    box-shadow: none;
    height: 100%;
    ${slideInLeft};
`;
Wrapper.displayName = 'Wrapper';

export const Resizer = styled.div`
    height: 100%;
    border-radius: 4px;
    width: 8px;
    margin-right: auto;
    background-color: ${colors.white1};
`;
Resizer.displayName = 'Resizer';

export const ResizerWrapper = styled.div`
    display: flex;
    height: 100%;
    cursor: col-resize;
    flex: 0 0 25px;
    background-color: ${colors.white1};
    &:hover{
        & > * {
            background-color: ${colors.light2};
        }
    }
    &:active{
        & > * {
            background-color: ${colors.light3};
        }
    }
`;
ResizerWrapper.displayName = 'ResizerWrapper';
