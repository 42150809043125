import styled from 'styled-components';
import get from 'lodash/get';
import colors from 'Styles/colors';

const severityMapColors = {
  critical: colors.warmPurple,
  high: colors.red3,
  info: colors.grey2,
  low: colors.green1,
  med: colors.orange1,
  unclasified: colors.grey4
};

const statusMapColors = {
  'open': colors.warmPurple,
  'risk-accepted': colors.green1,
  're-opened': colors.grey1,
  'closed': colors.grey1
};

export const Wrapper = styled.div`
  margin-top: 10px;
`;
Wrapper.displayName = 'Wrapper';

export default Wrapper;

export const Tag = styled.div`
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #bec8d2;
  background-color: #fafbfc;
  font-size: 11.5px;
  font-weight: 600;
  text-align: center;
  color: #63758d;
  display: inline-block;
  padding: 5px;
  margin-right: 9px;
  vertical-align: middle;
`;
Tag.displayName = 'Tag';

export const Status = styled.div`
  color: ${(props) => get(statusMapColors, [props.value], colors.grey1)};
  font-weight: bold;
  font-size: 12.5px;
  text-transform: capitalize;
  font-weight: 500;
`;
Status.displayName = 'Status';

export const Severity = styled.div`
  background: ${(props) => get(severityMapColors, [props.level], colors.grey1)};
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  width: 27px;
  line-height: 19px;
  border-radius: 2px;
  box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.13);
  font-size: 9px;
  font-weight: 900;
  border-radius: 4px;
  width: 24px;
  height: 18px;
  margin-bottom: 2px;
`;
Severity.displayName = 'Severity';

export const DateRow = styled.div`
  font-weight: 500;
`;
DateRow.displayName = 'DateRow';

export const RowName = styled.div`
  font-weight: 600;
  cursor: pointer;
  color: ${colors.dark2};
`;
RowName.displayName = 'RowName';
