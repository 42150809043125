import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendVulnToGitLab } from 'store/Settings/gitLabActions';
import get from 'lodash/get';
import first from 'lodash/first';
import { useIntl } from 'react-intl';
import {
  Wrapper, Title, Subtitle, Footer, Close, Save, Blue
} from './styled';

const GitLab = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const props = useSelector((state) => ({
    vulnsSelected: get(state, 'manage.vulnsSelected', [])
  }));

  const onSave = () => {
    dispatch(sendVulnToGitLab());
    onClose();
  };

  const getText = () => ((props.vulnsSelected.length === 1) ? first(props.vulnsSelected).name : `${props.vulnsSelected.length} vulnerabilities`);

  return (
    <Wrapper>
      <Title>{ intl.formatMessage({ id: 'sendToTool.gitlab.title' }) }</Title>
      <Subtitle>
        { intl.formatMessage({ id: 'sendToTool.gitlab.message.youAreSending' }) }
        <Blue>{ getText() }</Blue>
        { intl.formatMessage({ id: 'sendToTool.gitlab.message.toGitLab' }) }
      </Subtitle>
      <Footer>
        <Close onClick={ onClose }>{ intl.formatMessage({ id: 'sendToTool.gitlab.form.close' }) }</Close>
        <Save onClick={ onSave }>{ intl.formatMessage({ id: 'sendToTool.gitlab.form.save' }) }</Save>
      </Footer>
    </Wrapper>
  );
};

export default GitLab;
