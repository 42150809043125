import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectOrderedTemplates } from 'store/ExecutiveReportEditCreate/selectors';
import { Backdrop } from 'Common/Components/SearchBar/components/BasicFilter/styled';
import ActionItem from '../ActionItem';
import DropdownContainer from './styled';

const Dropdown = ({ onSelection, hideDropdown }) => {
  const orderedTemplates = useSelector(selectOrderedTemplates);

  return (
    <>
      <DropdownContainer>
        { orderedTemplates.map((t) => <ActionItem key={ t[1] } template={ t } onSelection={ onSelection } />) }
      </DropdownContainer>
      <Backdrop onClick={ hideDropdown } />
    </>
  );
};

Dropdown.propTypes = {
  onSelection: PropTypes.func.isRequired,
  hideDropdown: PropTypes.func.isRequired
};

export default Dropdown;
