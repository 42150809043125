import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCommandHistory } from 'Screens/Dashboard/actions/Actions';
import Card from 'Screens/Dashboard/components/ToolbarItems/Card';
import { selectCommandHistory, selectIsFetching } from 'store/Dashboard/selectors';
import { ButtonLink } from 'Common/Components/ButtonLink/styled';
import { Wrapper } from './styled';

const CommandHistory = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const history = useSelector(selectCommandHistory);
  const isFetching = useSelector(selectIsFetching);

  const viewMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    dispatch(getCommandHistory(page));
  }, [dispatch, page]);

  return (
    <Wrapper>
      { history.slice(0, history.length - 1).map((h) => {
        return (
          <Card
            key={ `${h._id}` }
            target={ `${h.command} ${h.params}` }
            user={ h.user }
            duration={ h.duration }
            date={ h.metadata.create_time }
          />
        );
      })}

      { isFetching && <Card isSkeleton /> }
      { history.length > (page * 15) && <ButtonLink isLoading={ isFetching } onClick={ viewMore }>Show more</ButtonLink> }
    </Wrapper>
  );
};

export default CommandHistory;
