import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'Common/Components/Inputs';
import {
  selectAuthenticated, selectProjects, selectError, selectMessage, selectProjectsSelected
} from 'store/Settings/jiraSelectors';
import {
  getJiraSettings, getJiraTemplate, sendVulnToJira, deleteProject, getJiraCacheProjects
} from 'store/Settings/jiraActions';
import { closeModal } from 'store/modals/actions';
import { MODAL_SEND_TO_JIRA } from 'store/modals/modals';
import { useIntl } from 'react-intl';
import first from 'lodash/first';
import { selectVulnsSelected } from 'store/Manage/selectors';
import FlexModal from 'Common/Components/FlexModal';
import Error from 'Common/Components/Error';
import Checkbox from 'Common/Components/Checkbox';
import {
  Wrapper, Title, Subtitle, Blue,
  ProjectWrapper, ProjectName, Configure,
  Header, CancelButton, ConfirmButton, CheckboxOption, Label, WrapperField, Input
} from './styled';
import ConfirmSend from './Confirm';

const Project = ({ data, isDone }) => {
  const dispatch = useDispatch();
  // const isDone = () => data.issue_types.some((issueType) => issueType.fields.some((field) => field.options.some((option) => option.required)));
  const handleClick = () => {
    dispatch(getJiraTemplate(data.value));
  };

  return (
    <ProjectWrapper>
      <ProjectName>{data.name}</ProjectName>
      <Configure isDone={ isDone } onClick={ handleClick } />
    </ProjectWrapper>
  );
};

const SetProject = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mergeVulns, setMergeVulns] = useState(false);
  const [issueName, setIssueName] = useState('');
  const dispatch = useDispatch();
  const authenticated = useSelector(selectAuthenticated);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    dispatch(getJiraSettings());
  }, [dispatch]);

  useEffect(() => {
    if (authenticated) setIndex(1);
  }, [authenticated]);

  const onClose = () => dispatch(closeModal(MODAL_SEND_TO_JIRA));
  const onSave = () => {
    if (authenticated) {
      dispatch(sendVulnToJira({
        password,
        username,
        issue_name: issueName,
        merge_vulns: mergeVulns
      }));
    } else dispatch(sendVulnToJira());
    onClose();
  };

  const content = () => {
    if (index === 0) {
      return (
        <ConfirmSend
          onClose={ onClose }
          onSave={ () => setIndex(1) }
          username={ username }
          setUsername={ setUsername }
          password={ password }
          setPassword={ setPassword }
        />
      );
    }
    return (
      <SendIssue
        onBack={ () => {
          setIndex(0);
          if (authenticated) onClose();
        } }
        onSave={ onSave }
        username={ username }
        password={ password }
        mergeVulns={ mergeVulns }
        setMergeVulns={ setMergeVulns }
        issueName={ issueName }
        setIssueName={ setIssueName }
      />
    );
  };

  return (
    <FlexModal z="9">
      <Wrapper height="705px">
        {content()}
      </Wrapper>
    </FlexModal>
  );
};

export default SetProject;

const SendIssue = ({
  onBack, onSave, username, password, mergeVulns, setMergeVulns, issueName, setIssueName
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [selectedProjects, setSelectedProjects] = useState([]);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const getText = () => ((vulnsSelected.length === 1) ? first(vulnsSelected).name : `${vulnsSelected.length} vulnerabilities`);
  const projects = useSelector(selectProjects);
  const projectsCompleted = useSelector(selectProjectsSelected);
  const onChange = (e, action) => {
    if (action && (action.action === 'pop-value' || action.action === 'remove-value')) {
      if (projectsCompleted.includes(action.removedValue.value)) dispatch(deleteProject(action.removedValue.value));
    }
    setSelectedProjects(e || []);
  };
  useEffect(() => {
    dispatch(getJiraCacheProjects({ username, password }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const error = useSelector(selectError);
  const message = useSelector(selectMessage);

  const toggleMerge = () => setMergeVulns(!mergeVulns);
  return (
    <>
      <Header>
        <Title>Send to Jira </Title>
        <CancelButton onClick={ onBack }>Back</CancelButton>
        <ConfirmButton disabled={ selectedProjects.length === 0 || !selectedProjects.every((project) => projectsCompleted.includes(project.value)) } onClick={ onSave }>Done</ConfirmButton>
      </Header>
      <Subtitle>
        { intl.formatMessage({ id: 'sendToTool.jira.message.youAreSending' }) }
        <Blue>{ getText() }</Blue>
        { intl.formatMessage({ id: 'sendToTool.jira.message.continue' }) }
      </Subtitle>
      { vulnsSelected.length > 1 &&
      (
      <>
        <CheckboxOption>
          <Checkbox onChange={ toggleMerge } state={ mergeVulns } />
          <Subtitle onClick={ toggleMerge }>Merge Vulns</Subtitle>
        </CheckboxOption>
        {
        mergeVulns && (
        <WrapperField>
          <Label>Issue Name</Label>
          <Input placeholder="My Issue" value={ issueName } onChange={ (e) => setIssueName(e.target.value) } />
        </WrapperField>
        )
      }
      </>
      )}

      {error && message ? <Error>{message}</Error> : null}

      <Subtitle>Select the project(s) to send the vulnerability:</Subtitle>
      <Dropdown isDisabled={ !projects.length } isMulti options={ projects } onChange={ onChange } isClearable={ false } />
      {selectedProjects.map((data) => <Project isDone={ projectsCompleted.includes(data.value) } data={ data } key={ `${data.id}-${data.name}` } />)}

    </>
  );
};
