import React, { useEffect, useState } from 'react';
import { Input } from 'Screens/ExecutiveReportEditCreate/components/ReportForm/components/Input/styled';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectInputField, selectParseError } from 'store/ExecutiveReportEditCreate/selectors';
import {
  clearParseError, setParseError,
  getVulnsPreview, setInputField
} from 'store/ExecutiveReportEditCreate/actions';
import { Wrapper, ParseErrorLabel } from './styled';
import CustomFilters from './CustomFilters';

const placeholder = 'e.g. {"and":[{"name": "confirmed","op": "==","val": "true"},{"name": "severity","op": "==","val": "high"}]}';
const inputType = 'advancedFilter';

const AdvancedFilter = ({ disabled, enableSection }) => {
  const dispatch = useDispatch();
  const [inputFilter, setInputFilter] = useState('');
  const storedFilter = useSelector((state) => selectInputField(state, inputType));
  const parseError = useSelector(selectParseError);
  const onChange = ({ target }) => setInputFilter(target.value);
  const onFocus = () => dispatch(clearParseError());
  const validateFilterSyntax = ({ target }) => {
    try {
      const queryToParse = `{"filters":[${target.value}]}`;
      JSON.parse(queryToParse);
      dispatch(setInputField(inputType, target.value));
      dispatch(getVulnsPreview());
    } catch (e) {
      dispatch(setParseError('Filter syntax error'));
      dispatch(setInputField(inputType, ''));
    }
  };

  useEffect(() => {
    if (disabled && !storedFilter) {
      setInputFilter('');
      dispatch(clearParseError());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, disabled]);

  useEffect(() => {
    if (storedFilter) {
      enableSection();
      setInputFilter(storedFilter);
    }
  }, [enableSection, storedFilter]);

  return (
    <Wrapper disabled={ disabled }>
      <Input
        onFocus={ onFocus }
        disabled={ disabled }
        placeholder={ placeholder }
        onChange={ onChange }
        onBlur={ validateFilterSyntax }
        value={ inputFilter }
        error={ !!parseError }
      />
      <ParseErrorLabel>
        { parseError }
      </ParseErrorLabel>
      <CustomFilters disabled={ disabled } />
    </Wrapper>
  );
};

AdvancedFilter.propTypes = {
  disabled: PropTypes.bool.isRequired,
  enableSection: PropTypes.func.isRequired
};

export default AdvancedFilter;
