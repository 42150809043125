import styled from 'styled-components';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';

export const Wrapper = styled.div`
  height: calc(100% - 74px);
  `;
Wrapper.displayName = 'Wrapper';

export const StyledTable = styled(CustomReactTable)`
  height: 100%;
  .rt-tbody .rt-td {
      & > * {
        overflow: visible;
      }
    }
  /* .rt-thead {
    z-index: 3 !important;
  } */
  .rt-table .rt-thead .rt-tr{
    height: 36px;
  }
  .rt-tbody .rt-tr{
    height: 38px;
  }
  
  .rt-tbody .rt-tr-group {
    &:hover {
      .actionButton{
        visibility: visible;
      }
    }
  }
  .actionButton {
    padding: 0px !important;
    visibility: hidden;
    overflow: visible;
  }

  .icon {
    height: 38px !important;
    padding: 0px !important;
  }

  .no-border-right{
    border-right: 0px !important;
  }

  .no-border-left{
    border-left: 0px !important;
  }
`;
CustomReactTable.displayName = 'CustomReactTable';

export const TableHeader = styled.div`
  color: #a6bbce;
  font-size: 10px;
  `;
TableHeader.displayName = 'TableHeader';
