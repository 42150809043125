import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsFetching, selectUserAgents, selectCloudAgents } from 'store/Agents/selectors';
import isEmpty from 'lodash/isEmpty';
import { getUserAgents } from 'store/Agents/actions';
import Wrapper from './styled';
import EmptyAgents from './components/EmptyAgents';
import UserAgents from './components/UserAgents';
// import CloudAgents from './components/CloudAgents';

const Grid = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectIsFetching);
  const userAgents = useSelector(selectUserAgents);
  const cloudAgents = useSelector(selectCloudAgents);
  const noAgents = isEmpty(userAgents) && isEmpty(cloudAgents) && !isFetching;

  // TODO ******** Enable once cloud agents are merged on black/dev ********

  // const isCloudAllowedByFeature = useSelector((state) => selectAllowedByFeature(state, FEATURE_CLOUD_AGENTS, PERMISSION_TYPES.READ));
  // const isCloudAllowedByRole = useSelector((state) => selectAllowedByRole(state, FEATURE_CLOUD_AGENTS, PERMISSION_TYPES.READ));
  // const isCloudAllowed = isCloudAllowedByFeature && isCloudAllowedByRole;

  useEffect(() => {
    dispatch(getUserAgents());
    // if (isCloudAllowed) dispatch(getCloudAgents());
  }, [dispatch]);

  if (noAgents) {
    return (
      <Wrapper>
        <EmptyAgents />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {/* { isCloudAllowed && <CloudAgents /> } */}
      <UserAgents />
    </Wrapper>
  );
};

export default Grid;
