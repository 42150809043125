import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedEntity, selectFilterError, selectAdvancedFilter, selectSaveEnabled
} from 'store/Filters/selectors';
import {
  setSelectedEntity, setAdvancedFilter, setFilterError, clearFilterError, setSaveEnabled, clearAdvancedFilter
} from 'store/Filters/actions';
import { SEARCH_BY_ENTITIES, ENTITIES } from 'store/Filters/entitiesConstants';
import get from 'lodash/get';
import { selectLocation } from 'store/Router/selectors';
import { trackEvent } from 'tracking/GA';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import DropdownOptions from '../BasicFilter/components/Dropdown';
import { Tag } from '../BasicFilter/components/FilterTags';
import { filterByInput, shouldRedirect, useFocus } from '../BasicFilter/functions';
import {
  DropdownContainer, Input, SearchInput,
  TagsContainer, Backdrop, SearchIcon, ClearIcon, SearchButton
} from '../BasicFilter/styled';

const AdvancedFilter = () => {
  const [advancedFilterValue, setAdvancedFilterValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputRef, setInputFocus, setInputBlur] = useFocus();
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);
  const currentLocationEntity = SEARCH_BY_ENTITIES.find((e) => location.pathname.includes(e.pathname));
  const selectedEntity = useSelector(getSelectedEntity);
  const selectedEntityLabel = get(ENTITIES, `${selectedEntity}.name.label`, '');
  const selectedEntityPathname = get(ENTITIES, `${selectedEntity}.pathname`, '');
  const searchFunction = get(ENTITIES, `${selectedEntity}.searchFunction`, null);
  const storedAdvancedFilter = useSelector((state) => selectAdvancedFilter(state, selectedEntity));
  const filterError = useSelector((state) => selectFilterError(selectedEntity, state));
  const filteredEntities = filterByInput(SEARCH_BY_ENTITIES, advancedFilterValue);
  const saveEnabled = useSelector(selectSaveEnabled);

  const trackSearch = () => {
    const trackAction = get(ACTIONS, `search.${selectedEntity}.avanced`, '');
    if (trackAction) dispatch(trackEvent(CATEGORIES.search, trackAction.name, trackAction.label));
  };

  useEffect(() => {
    setAdvancedFilterValue(storedAdvancedFilter);
  }, [storedAdvancedFilter]);

  useEffect(() => {
    if (saveEnabled) dispatch(setSaveEnabled(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, advancedFilterValue]);

  const onSelect = (entityName) => {
    dispatch(setSelectedEntity(entityName));
    setAdvancedFilterValue('');
    setInputFocus();
  };

  const validateFilterSyntax = () => {
    if (filterError) dispatch(clearFilterError(selectedEntity));
    try {
      const queryToParse = `{"filters":[${advancedFilterValue}]}`;
      JSON.parse(queryToParse);
      dispatch(shouldRedirect(selectedEntityPathname));
      dispatch(setAdvancedFilter(selectedEntity, advancedFilterValue));
      dispatch(setSaveEnabled(true));
      dispatch(searchFunction());
      trackSearch();
      setShowDropdown(false);
      setInputBlur();
    } catch (e) {
      dispatch(setFilterError(selectedEntity, 'Syntax error. Please try again. For further help check our documentation'));
    }
  };

  const onAdvancedKeyUp = (e) => {
    if (e.key === 'Enter') {
      if (selectedEntity && advancedFilterValue) validateFilterSyntax();
    } else if (e.key === 'Escape') {
      setInputBlur();
      setShowDropdown(false);
    }
  };

  const onAdvancedKeyDown = (e) => {
    if (e.key === 'Escape') {
      setAdvancedFilterValue('');
      dispatch(setSelectedEntity(''));
    } else if (e.key === 'Backspace') {
      if (!advancedFilterValue) {
        dispatch(setSelectedEntity(''));
        dispatch(setSaveEnabled(false));
      }
    }
  };

  const onDelete = () => {
    setAdvancedFilterValue('');
    setInputBlur();
    setShowDropdown(false);
    if (selectedEntity) {
      dispatch(clearFilterError(selectedEntity));
      dispatch(clearAdvancedFilter(selectedEntity));
      dispatch(searchFunction());
      dispatch(setSelectedEntity(''));
    }
  };

  return (
    <>
      <SearchInput>
        <TagsContainer>
          <Tag type="val" value={ selectedEntityLabel } showDelete={ !storedAdvancedFilter } onDelete={ onDelete } />
        </TagsContainer>
        <Input
          ref={ inputRef }
          value={ advancedFilterValue }
          type="text"
          placeholder={ currentLocationEntity.advancedPlaceholder }
          onChange={ ({ target }) => setAdvancedFilterValue(target.value) }
          onBlur={ ({ target }) => setAdvancedFilterValue(target.value) }
          onKeyDown={ onAdvancedKeyDown }
          onKeyUp={ onAdvancedKeyUp }
          onFocus={ () => setShowDropdown(true) }
        />
      </SearchInput>
      <DropdownContainer x={ 0 }>
        {showDropdown && !selectedEntity && <DropdownOptions defaultSelect options={ filteredEntities } onSelect={ (entity) => onSelect(entity.name.value) } /> }
      </DropdownContainer>
      { selectedEntityLabel && <ClearIcon onClick={ onDelete } /> }
      { showDropdown && <Backdrop onClick={ () => setShowDropdown(false) } /> }
      <SearchButton onClick={ () => { if (selectedEntity && advancedFilterValue) validateFilterSyntax(); } } label={ <SearchIcon /> } />
    </>
  );
};

export default AdvancedFilter;
