import React from 'react';
import CommonConfirmationDialog from 'Common/Components/ConfirmationDialog';
import { useSelector, useDispatch } from 'react-redux';
import { bulkUpdateVulns, bulkAddComment } from 'store/Manage/actions';
import {
  selectIsFetching, selectModalBulkUpdateValue,
  selectVulnsSelected, selectModalBulkUpdateField
} from 'store/Manage/selectors';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION, MODAL_MANAGE_BULK_UPDATE } from 'store/modals/modals';
import { closeModal } from 'store/modals/actions';
import selectModal from 'store/modals/selectors';

const BulkUpdateConfirmationDialog = () => {
  const showModal = useSelector((state) => selectModal(state, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
  const dispatch = useDispatch();
  const vulnsSelectedCount = useSelector(selectVulnsSelected).length;
  const isFetching = useSelector(selectIsFetching);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  const bulkUpdateField = useSelector(selectModalBulkUpdateField);
  const isComment = bulkUpdateField === 'comment';

  const handleClose = () => dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));

  const handleSubmit = () => {
    if (isComment) {
      handleClose();
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(bulkAddComment(bulkUpdateValue));
    } else {
      dispatch(bulkUpdateVulns(bulkUpdateValue));
    }
  };

  if (!showModal) return null;
  return (
    <CommonConfirmationDialog
      visible={ showModal }
      isFetching={ isFetching }
      title="Bulk Edit"
      handleSubmit={ handleSubmit }
      handleClose={ handleClose }
      count={ vulnsSelectedCount }
      upperCaseTitle="Vulnerabilities"
    />
  );
};
export default BulkUpdateConfirmationDialog;
