import styled from 'styled-components';
import { Row, Col } from 'Common/Components/Grid';
import colors from 'Styles/colors';
import { Box } from 'Screens/Dashboard/components/ToolbarItems/Common/Box/styled';

export const StyledRow = styled(Row)`
  flex-direction: ${(props) => (props.direction ? `${props.direction}` : 'auto')};
  ${({ marginBottom }) => marginBottom && `
    & > *:not(:last-child) {
      margin-bottom: 14px;
    }
  `}
  ${({ marginRight }) => marginRight && `
    & > *:not(:last-child) {
      margin-right: 14px;
    }
  `}
`;
StyledRow.displayName = 'StyledRow';

export const StyledCol = styled(Col)`
  ${({ marginBottom }) => marginBottom && `
    & > *:not(:last-child) {
      margin-bottom: 14px;
    }
  `}
  ${({ marginRight }) => marginRight && `
    & > *:not(:last-child) {
      margin-right: 14px;
    }
  `}
`;
StyledCol.displayName = 'StyledCol';

export const BoxLatestVulns = styled.div`
  border-radius: 3px;
  border: solid 1px ${colors.grey1};
  box-shadow: 0 0 20px 0 rgba(209, 218, 227, 0.97);
  padding: 22px;
  text-align: left;
  height: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top: 0;
`;
BoxLatestVulns.displayName = 'BoxLatestVulns';

export const BoxSummary = styled(Box)`
  background-color: ${colors.dark1};
  box-shadow: rgb(209 218 227 / 97%) 0px 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom: 0;
`;
BoxSummary.displayName = 'BoxSummary';

export const SummaryStyledCol = styled(StyledCol)`
  margin-bottom: 0px !important;
`;
SummaryStyledCol.displayName = 'SummaryStyledCol';
