import React from 'react';
import { useDispatch } from 'react-redux';
import { PropTypes } from 'prop-types';
import { WithPermission } from 'Common/Components/WithPermission';
import { PERMISSION_TYPES, FEATURE_USERS } from 'Hooks/usePermission/permissions';
import EditButton from 'Common/Components/EditButton';
import { showEditModal } from 'Screens/Users/actions/Actions';

const Edit = ({ currentBreakpoint, userSelected }) => {
  const dispatch = useDispatch();
  return (
    <WithPermission permission={ FEATURE_USERS } type={ PERMISSION_TYPES.UPDATE }>
      <EditButton editFunction={ () => dispatch(showEditModal()) } breakpoint={ currentBreakpoint } selectedEntities={ userSelected } display={ userSelected.length === 1 } />
    </WithPermission>
  );
};

Edit.propTypes = {
  currentBreakpoint: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  userSelected: PropTypes.array
};

Edit.defaultProps = {
  userSelected: []
};

export default Edit;
