import * as types from 'store/Manage/types';
import * as FileSaver from 'file-saver';
import api from 'services/api';
import { copy } from 'Common/Functions/Copy';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import find from 'lodash/find';
import { MOCK_VULN_ID } from 'Common/Components/Tour/helpers/mockVuln';
import { selectCurrentWorkspace, selectWorkspaceSelected } from 'store/Faraday/selectors';
import setTourFinished from 'store/Preferences/Tour/actions';
import {
  selectVulnDetailId,
  selectVulnsList,
  selectVulnDetail,
  selectUsesMockVulns,
  selectVulnsCount,
  selectLastSelected,
  selectVulnsSelected,
  selectShowDetail,
  selectModalBulkUpdateField,
  selectModalBulkUpdateValue,
  selectContextMenuXPos,
  selectContextMenuYPos
} from 'store/Manage/selectors';
import { getTags } from 'Screens/Tags/actions/Actions';
import { trackEvent } from 'tracking/GA';
import { CATEGORIES, ACTIONS } from 'tracking/GA/constants';
import { getData } from 'Screens/Workspaces/actions/Actions';
import {
  selectGroupByField, selectIsGrouping, selectRowsPerPage,
  selectQueryParam, selectAdvancedFilterQueryParam
} from 'store/Filters/selectors';
import { redirect } from 'store/Router/actions';
import { selectCsrfToken } from 'store/Sesion/selectors';
import { MODAL_MANAGE_BULK_UPDATE, MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { closeModal, openModal } from 'store/modals/actions';
import { setFilterError } from 'store/Filters/actions';
import { newGetVulns, setVulnsFilterError } from './filterActions';

export function setImagePreview (imagePreview) {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_IMAGE_PREVIEW, imagePreview
    });
  };
}

export function setSelectedComment (selectedComment) {
  return (dispatch) => {
    dispatch({ type: types.SET_SELECTED_COMMENT, selectedComment });
  };
}

const addDeleteController = (vulns, vulnList) => { // if all vulns are in vulnList then unselect then if not
  const allVulnsAreSelected = vulns.every((testVuln) => vulnList.some((vuln) => vuln._id === testVuln._id));

  if (allVulnsAreSelected) return [types.VULN_UNSELECTED, vulns];
  return [types.VULN_SELECTED, vulns];
};

const selectCalculator = (e, vuln, vulnList, areVulnsSelected) => (dispatch, getState) => {
  const pivot = selectLastSelected(getState());
  const index = vulnList.findIndex((el) => el._id === vuln._id);
  const vulnsSelected = selectVulnsSelected(getState());

  dispatch({ type: types.NEW_PIVOT, payload: index });
  if (e.shiftKey && pivot !== -1 && areVulnsSelected) { // if already had vuln selected and shift key is pressed
    const start = Math.min(pivot, index);
    const end = Math.max(pivot, index) + 1;
    const vulns = vulnList.slice(start, end);
    const [type, payload] = addDeleteController(vulns, vulnsSelected);
    return dispatch({ type, payload });
  }
  const [type, payload] = addDeleteController([vuln], vulnsSelected);
  return dispatch({ type, payload });
};

export const selectRow = (e, vuln) => (dispatch, getState) => {
  const allVulns = selectVulnsList(getState());
  const isGroupingBy = selectIsGrouping('vulns', getState());
  const vulnsSelected = selectVulnsSelected(getState());

  if (isGroupingBy) {
    const group = allVulns.find((g) => g.groupData && g.groupData.find((el) => el._id === vuln._id));
    if (!group) return; // si no hay groupData no se puede hacer calculos con las vulns
    const vulnGroup = group.groupData;
    const areVulnsSelected = vulnsSelected.length > 0 && vulnGroup.some((el) => el.id === vuln.id);
    dispatch(selectCalculator(e, vuln, vulnGroup, areVulnsSelected, vulnsSelected));
  } else {
    dispatch(selectCalculator(e, vuln, allVulns, vulnsSelected.length > 0));
  }
};

export const unSelectAll = () => (dispatch) => dispatch({ type: types.UNSELECT_ALL_VULN });

export const selectAllVulns = () => (dispatch, getState) => {
  const vulnsList = selectVulnsList(getState());
  const vulnsSelected = selectVulnsSelected(getState());
  const vulnCount = selectVulnsCount(getState());
  const pageSize = selectRowsPerPage('vulns', getState());

  if (vulnsSelected.length === vulnCount || vulnsSelected.length >= pageSize) return dispatch(unSelectAll());

  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

export const selectConfirmedVulns = () => (dispatch, getState) => {
  const state = getState();
  const vulnsList = selectVulnsList(state).filter((vuln) => (vuln.confirmed));

  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

export const selectNotConfirmedVulns = () => (dispatch, getState) => {
  const state = getState();
  const vulnsList = selectVulnsList(state).filter((vuln) => (!vuln.confirmed));
  return dispatch({ type: types.SELECT_ALL_VULN, vulnsList });
};

// Summary: Show confirmation modal when user delete vuln/s
export function showVulnModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_DELETE_CONFIRMATION_VULNS });
  };
}

// Summary: Hide confirmation modal when user delete vuln/s
export function hideVulnModalDelete () {
  return (dispatch) => {
    dispatch({ type: types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS });
  };
}

// Summary: reset state of manage screen
export function resetState () {
  return (dispatch) => {
    dispatch({ type: types.RESET_STATE_MANAGE });
  };
}

function exportCsv (result) {
  return async (_, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    let title = '';

    if (workspaceSelected) title = `SR-${workspaceSelected}`;
    else title = 'Vulnerability Model CSV';

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.csv';

    const data = new Blob([result], { type: fileType });
    FileSaver.saveAs(data, title + fileExtension);
  };
}

export function confirm (vuln, value) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.CONFIRMATION_CHANGE_START });
      const workspaceSelected = selectCurrentWorkspace(getState());
      const isGroupingBy = selectIsGrouping('vulns', getState());
      const vulnCopy = {
        ...vuln,
        confirmed: value
      };
      await api.manage.updateVuln(workspaceSelected, vulnCopy);
      dispatch({ type: types.CONFIRMATION_CHANGE_SUCCESS, vuln: vulnCopy, isGroupingBy });
    } catch (error) {
      dispatch({ type: types.CONFIRMATION_CHANGE_FAIL, error: error.message });
    }
  };
}

export function resetErrorValue () {
  return (dispatch) => {
    dispatch({ type: types.RESET_ERROR_VALUE });
  };
}

export function showManageLeftFilters (visible, breakpoint) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_MANAGE_LEFT_FILTERS, visible, breakpoint });
  };
}

export function redirectToVulnDetail (id) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/manage/${currentWorkspace}/${id}`));
  };
}

export function redirectToManage () {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    dispatch(redirect(`/manage/${currentWorkspace}`));
  };
}

export function setVulnerabilityDetailTab (vulnDetailSelectedTab) {
  return (dispatch) => {
    dispatch({ type: types.SET_VULNERABILITY_DETAIL_TAB, vulnDetailSelectedTab });
  };
}

export function showVulnerabilityDetail (id, vulnDetailSelectedTab) {
  return (dispatch, getState) => {
    const currentWorkspace = selectCurrentWorkspace(getState());
    if (id > 0) {
      dispatch(redirect(`/manage/${currentWorkspace}/${id}`));
      // Redirecting to manage/id calls loadVulnerabilityDetail, fetching the detail and showing it.
      dispatch(setVulnerabilityDetailTab(vulnDetailSelectedTab));
    }
  };
}

export function loadVulnerabilityDetail (id) {
  return async (dispatch, getState) => {
    if (id > 0) {
      const currentWorkspace = selectCurrentWorkspace(getState());
      try {
        const vuln = await api.manage.fetchById(currentWorkspace, id);
        dispatch({ type: types.SHOW_VULNERABILITY_DETAIL, vuln });
      } catch (e) {
        dispatch(redirectToManage());
      }
    }
  };
}

export function hideVulnerabilityDetail () {
  return (dispatch, getState) => {
    const showVulnDetail = selectShowDetail(getState());
    if (showVulnDetail) dispatch({ type: types.HIDE_VULNERABILITY_DETAIL });
  };
}

export function addEvidences (evidences, getEvidences) {
  return async (dispatch, getState) => {
    const id = selectVulnDetailId(getState());
    const csrfToken = selectCsrfToken(getState());
    const workspaceSelected = selectCurrentWorkspace(getState());

    const promises = evidences.map((evidence) => {
      const newEvidenceName = evidence.name.replace(/\s+/g, '');
      const renameEvidence = new File([evidence], newEvidenceName);
      const fd = new FormData();
      fd.append('csrf_token', csrfToken);
      fd.append('file', renameEvidence);
      return api.attachments.saveAttachments(workspaceSelected, id, fd);
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      // error
    }
    getEvidences();
  };
}

function vulnPreviewEditSuccedCallback (vulnBefore, vulnAfter) {
  return (dispatch, getState) => {
    const groupBy = selectGroupByField('vulns', getState());
    const vulnsList = selectVulnsList(getState());

    if (groupBy) {
      // se actualiza la tabla agrupada
      const indexGroupDataAfter = vulnsList.findIndex((x) => x[groupBy] === vulnAfter[groupBy]);
      const groupDataAfter = vulnsList[indexGroupDataAfter].groupData || [];
      const indexVulnAfter = groupDataAfter.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      if (indexVulnAfter < 0) { // no lo encontro, lo borro
        const indexGroupDataBefore = vulnsList.findIndex((x) => x[groupBy] === vulnBefore[groupBy]);
        const groupDataBefore = vulnsList[indexGroupDataBefore].groupData || {};
        const indexVulnBefore = groupDataBefore.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
        // lo quito del grupo.
        vulnsList[indexGroupDataBefore].groupData.splice(indexVulnBefore, 1);
        // eslint-disable-next-line no-negated-condition
        if (!vulnsList[indexGroupDataAfter].groupData) vulnsList[indexGroupDataAfter].count += 1;
        else vulnsList[indexGroupDataAfter].groupData.push(vulnAfter);
      } else {
        vulnsList[indexGroupDataAfter].groupData[indexVulnAfter] = vulnAfter;
      }
    } else {
      // se actualiza la tabla
      const index = vulnsList.findIndex((x) => vulnAfter._id === x.id || vulnAfter._id === x._id);
      vulnsList[index] = vulnAfter;
    }

    dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_SUCCESS, vuln: vulnAfter, vulnsList });
  };
}

function vulnPreviewEditErrorCallback () {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_FAILURE, error: 'An error has occurred' });
  };
}

export function updateVuln (vulnBefore, field, value) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const vulnAfter = { ...vulnBefore };
    set(vulnAfter, field, value);

    try {
      let newRefs = [];
      if (field === 'refs') {
        newRefs = value.map((ref) => ({ name: ref, type: 'other' }));
        await api.manage.updateVuln(workspaceSelected, { _id: vulnBefore._id, refs: newRefs });
      } else {
        await api.manage.updateVuln(workspaceSelected, { _id: vulnBefore._id, [field]: value });
      }

      dispatch(vulnPreviewEditSuccedCallback(vulnBefore, vulnAfter));
    } catch (error) {
      return dispatch(vulnPreviewEditErrorCallback());
    }
  };
}

export function selectGroup (index, selected) {
  return (dispatch) => {
    dispatch({ type: types.SELECT_GROUP_MANAGE, index, selected });
  };
}

export function selectVulnGroup (vuln, selected) {
  return (dispatch) => {
    dispatch({ type: types.SELECT_VULN_GROUP_MANAGE, vuln, selected });
  };
}

function getCommentsSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.GET_COMMENTS_SUCCESS,
      data
    });
  };
}

export function getComments (id) {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    try {
      dispatch({ type: types.GET_COMMENTS_REQUEST });
      const data = await api.manage.getComments(workspaceSelected, id);
      dispatch(getCommentsSucceedCallback(data));
    } catch (e) {
      dispatch({ type: types.GET_COMMENTS_FAIL, data: e.message });
    }
  };
}

function addCommentSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_COMMENT_SUCCESS,
      data
    });
  };
}

export function addComment (data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.ADD_COMMENT_REQUEST });
      const workspace = selectCurrentWorkspace(getState());
      const response = await api.manage.newComment(workspace, data);
      dispatch(addCommentSucceedCallback(response));
    } catch (e) {
      dispatch({ type: types.ADD_COMMENT_FAIL, data: e.message });
    }
  };
}

function removeCommentSucceedCallback (commentId) {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_COMMENT_SUCCESS,
      commentId
    });
  };
}

export function removeComment (commentId) {
  return async (dispatch, getState) => {
    const workspace = selectCurrentWorkspace(getState());
    try {
      dispatch({ type: types.REMOVE_COMMENT_REQUEST });
      await api.manage.removeComment(workspace, commentId);
      dispatch(removeCommentSucceedCallback(commentId));
    } catch (e) {
      dispatch({ type: types.REMOVE_COMMENT_FAIL, data: e.message });
    }
  };
}

function updateCommentSucceedCallback (data) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_COMMENT_SUCCESS,
      data
    });
  };
}

export function updateComment (data) {
  return async (dispatch, getState) => {
    const workspace = selectCurrentWorkspace(getState());
    try {
      dispatch({ type: types.UPDATE_COMMENT_REQUEST });
      const response = await api.manage.saveComment(workspace, data.id, data);
      dispatch(updateCommentSucceedCallback(response));
    } catch (e) {
      dispatch({ type: types.UPDATE_COMMENT_FAIL, data: e.message });
    }
  };
}

export function bulkAddComment () {
  return (dispatch, getState) => {
    const state = getState();
    const vulnsSelected = selectVulnsSelected(state);
    const text = selectModalBulkUpdateValue(state);
    const vulnsIds = vulnsSelected?.map((vuln) => vuln._id);

    vulnsIds.forEach((id) => {
      const data = {
        text,
        object_type: 'vulnerability',
        object_id: id,
        create_date: new Date()
      };
      dispatch(addComment(data));
    });
  };
}

export function setBulkUpdateField (field) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_SET_BULK_UPDATE_FIELD, field });
  };
}

export function setBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_SET_BULK_UPDATE_VALUE, value });
  };
}

export function addBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_ADD_BULK_UPDATE_VALUE, value });
  };
}

export function removeBulkUpdateValue (value) {
  return (dispatch) => {
    dispatch({ type: types.MANAGE_REMOVE_BULK_UPDATE_VALUE, value });
  };
}

export function refreshVulnsList (vulnsList, vulnsSelected, vulnDetail) {
  return (dispatch, getState) => {
    dispatch({
      type: types.REFRESH_VULNS, vulnsList, vulnsSelected, vulnDetail, vulnsCount: selectVulnsCount(getState())
    });
  };
}

const getImpactData = (value, selectedVulns) => {
  const keyNames = Object.keys(selectedVulns[0].impact);
  let impact = {};
  keyNames.forEach((imp) => {
    impact = {
      ...impact,
      [imp]: value.some((x) => x === imp)
    };
  });
  return { impact };
};

export function bulkUpdateVulns () {
  return async (dispatch, getState) => {
    const state = getState();

    dispatch({ type: types.CONFIRMATION_CHANGE_START });

    try {
      const vulnsList = selectVulnsList(state);
      const vulnsSelected = selectVulnsSelected(state);
      const vulnDetail = selectVulnDetail(state);
      const field = selectModalBulkUpdateField(state);
      const value = selectModalBulkUpdateValue(state);
      const workspaceSelected = selectCurrentWorkspace(state);
      const vulnIDs = vulnsSelected.map((v) => v._id);
      let data = {};

      if (field === 'references') data = { refs: value };
      else if (field === 'policy violations') data = { policyviolations: value };
      else if (field === 'description') data = { description: value, desc: value };
      else if (field === 'impact') data = getImpactData(value, vulnsSelected);
      else data = { [field]: value };

      const isDetailVulnSelected = vulnsSelected.some((selectedVuln) => !isEmpty(vulnDetail) && selectedVuln._id === vulnDetail._id);

      const updatedVulnDetail = isDetailVulnSelected ? { ...vulnDetail, ...data } : { ...vulnDetail };
      const payload = { ids: vulnIDs, ...data };

      await api.manage.updateVulns(workspaceSelected, payload);

      const updatedVulnsList = vulnsList.map((vuln) => {
        const isSelected = vulnsSelected.some((selectedVuln) => selectedVuln._id === vuln._id);
        if (isSelected) return { ...vuln, ...data };
        return vuln;
      });

      const updatedSelectedVulns = vulnsSelected.map((vuln) => ({ ...vuln, ...data }));

      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.MANAGE_BULK_UPDATE_FINISHED });
      dispatch(refreshVulnsList(updatedVulnsList, updatedSelectedVulns, updatedVulnDetail));
    } catch (e) {
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE));
      dispatch(closeModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
      dispatch({ type: types.MANAGE_VULN_ERROR, errorMessage: e.message || 'An error occured while updating vulns' });
    }
  };
}

export function saveTemplateFromVuln () {
  return (dispatch, getState) => {
    const vulnsSelected = selectVulnsSelected(getState());

    const save = async (template) => {
      const response = await api.knowledgeBase.createTemplate(template);
      return response;
    };

    const data = vulnsSelected.map((vuln) => {
      const template = {
        cwe: '',
        description: vuln.desc,
        desc: vuln.desc,
        data: vuln.data,
        exploitation: vuln.severity,
        name: vuln.name,
        references: vuln.refs.map(ref => ref.name),
        refs: vuln.refs.map(ref => ref.name),
        resolution: vuln.resolution,
        impact: vuln.impact,
        policyviolations: vuln.policyviolations,
        customfields: vuln.custom_fields,
        easeofresolution: vuln.easeofresolution,
        external_id: vuln.external_id,
        type: 'vulnerability_template'
      };
      return save(template);
    });

    Promise.allSettled(data).then((results) => {
      const templatesCreated = results ? results.filter((template) => template.status === 'fulfilled').map((t) => t.value) : [];
      const errors = results ? results.filter((template) => template.status === 'rejected').map((t) => t.reason.object) : [];
      dispatch({ type: types.SAVE_TEMPLATE_MANAGE, templatesCreated, errors });
    });
  };
}

export function getTemplatesMassiveUpdate () {
  return async (dispatch) => {
    dispatch({ type: types.GET_TEMPLATES_BULK_START });

    try {
      const data = await api.manage.getTemplates();
      dispatch({ type: types.GET_TEMPLATES_BULK_SUCCESS, data });
    } catch (e) {
      dispatch({ type: types.GET_TEMPLATES_BULK_FAIL });
    }
  };
}

export function setVulnsTags (tagsToAdd = [], tagsToRemove = []) {
  return async (dispatch, getState) => {
    dispatch({ type: types.SET_TAGS_REQUEST });
    try {
      const vulnsSelected = selectVulnsSelected(getState());
      const vulnsList = selectVulnsList(getState());
      const selectedVulnsIds = vulnsSelected.map((v) => (v._id));
      const workspace = getState().faraday.workspaceSelected;
      const data = {
        vulnerability_ids: selectedVulnsIds,
        tags_to_add: tagsToAdd,
        tags_to_remove: tagsToRemove
      };

      await api.manage.setVulnsTags(workspace, data);
      const newTags = tagsToAdd.filter((tag) => !tagsToRemove.includes(tag));
      selectedVulnsIds.forEach((id) => {
        const vuln = find(vulnsSelected, { _id: id });
        vuln.tags = [
          ...vuln.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTags
        ];

        const indexVulnsList = findIndex(vulnsList, { _id: vuln._id });
        if (indexVulnsList > -1 && !isEmpty(vulnsList)) vulnsList.splice(indexVulnsList, 1, vuln);

        const indexVulnsSelected = findIndex(vulnsSelected, { _id: id });
        if (indexVulnsList > -1 && !isEmpty(vulnsSelected)) vulnsSelected.splice(indexVulnsSelected, 1, vuln);
      });

      const vulnDetail = selectVulnDetail(getState());
      if (!!vulnDetail && selectedVulnsIds.includes(vulnDetail.id)) {
        vulnDetail.tags = [
          ...vulnDetail.tags.filter((tag) => !tagsToRemove.includes(tag)),
          ...newTags
        ];
      }

      dispatch({ type: types.SET_TAGS_SUCCESS });

      dispatch(refreshVulnsList(vulnsList, vulnsSelected, vulnDetail));
      dispatch(getTags());
    } catch (e) {
      dispatch({ type: types.SET_TAGS_FAIL, error: e });
    }
  };
}

export function addTag (tag) {
  return (dispatch) => {
    dispatch(setVulnsTags([tag], []));
  };
}

export function removeTag (tag) {
  return (dispatch) => {
    dispatch(setVulnsTags([], [tag]));
  };
}

export function showBulkUpdateModal (bulkUpdateField, bulkUpdateValue) {
  return (dispatch) => {
    dispatch(setBulkUpdateField(bulkUpdateField));
    dispatch(setBulkUpdateValue(bulkUpdateValue));
    dispatch(openModal(MODAL_MANAGE_BULK_UPDATE));
  };
}

export function onGetVulnsFail (errorMessage) {
  return (dispatch) => {
    dispatch({ type: types.SEARCH_VULNS_FAIL, data: errorMessage });
  };
}

export function removeVulnsTags (currentVuln, tagToRemove) {
  return async (dispatch, getState) => {
    dispatch({ type: types.SET_TAGS_REQUEST });
    try {
      if (currentVuln.tags.includes(tagToRemove)) {
        const state = getState();
        const vulnId = get(currentVuln, '_id', -1);
        const workspace = selectWorkspaceSelected(state);
        const data = {
          vulnerability_ids: [vulnId],
          tags_to_add: [],
          tags_to_remove: [tagToRemove]
        };
        await api.manage.setVulnsTags(workspace, data);

        const newTags = currentVuln.tags.filter((tag) => !tagToRemove.includes(tag));
        const newVuln = copy(currentVuln);
        newVuln.tags = newTags;

        const vulnsList = selectVulnsList(state);
        const newVulnsList = vulnsList.map((vuln) => {
          if (vuln._id === newVuln._id) return newVuln;
          return vuln;
        });

        dispatch({ type: types.UPDATE_VULN_MANAGE_PREVIEW_SUCCESS, vulnsList: newVulnsList, vuln: newVuln });
      }
    } catch (e) {
      dispatch({ type: types.SET_TAGS_FAIL, error: e });
    }
  };
}

export function getUsersList () {
  return async (dispatch) => {
    try {
      const response = await api.users.getUsersList();
      const mappedUsers = response.map((u) => ({ name: u.username }));
      return dispatch({ type: types.GET_USERS_SUCCESS, users: mappedUsers });
    } catch (e) {
      return dispatch({ type: '' });
    }
  };
}

// Summary: Delete vuln/s database
export function deleteSelectedVulns () {
  return async (dispatch, getState) => {
    const workspaceSelected = selectCurrentWorkspace(getState());
    const vulnsSelected = selectVulnsSelected(getState());
    const vulnIDs = vulnsSelected.map((vuln) => vuln._id);
    const groupByField = selectGroupByField('vulns', getState());
    const vulnsList = selectVulnsList(getState());

    dispatch({ type: types.HIDE_MODAL_DELETE_CONFIRMATION_VULNS_SUCCEED });
    dispatch({ type: types.DELETE_VULNS });

    try {
      await api.manage.deleteVulns(workspaceSelected, vulnIDs);
      vulnIDs.forEach((id) => dispatch(trackEvent(CATEGORIES.vuln, ACTIONS.deleteVuln.name, ACTIONS.deleteVuln.label, id)));
      dispatch(getData());
      if (groupByField) {
        const newVulnsList = vulnsList.map((group) => {
          const groupedData = get(group, 'groupData', null);
          if (groupedData) {
            const newGroupedData = groupedData.filter((vuln) => !vulnIDs.includes(vuln._id));
            return {
              ...group,
              count: newGroupedData.length,
              groupData: newGroupedData
            };
          }
          return group;
        });
        dispatch({ type: types.UPDATE_VULNS_LIST, vulnsList: newVulnsList });
      } else dispatch(newGetVulns());
      dispatch(getTags());
    } catch (e) {
      dispatch({ type: types.MANAGE_VULN_ERROR, errorMessage: e.message || 'An error occured while deleting vulns' });
    }
  };
}

export function removeMockVuln () {
  return (dispatch, getState) => {
    const vulnList = selectVulnsList(getState());
    const newVulnsList = vulnList.filter((vuln) => vuln._id !== MOCK_VULN_ID);
    const vulnsCount = newVulnsList.length > 0 ? newVulnsList.length - 1 : 0;
    dispatch({ type: types.REMOVE_MOCK_VULN, vulnsList: newVulnsList, vulnsCount });
  };
}

export function endTour () {
  return async (dispatch, getState) => {
    const usesMockVulns = selectUsesMockVulns(getState());
    if (usesMockVulns) dispatch(removeMockVuln());
    dispatch(setTourFinished(true));
  };
}

export function downloadVulns () {
  return async (dispatch, getState) => {
    const workspace = selectCurrentWorkspace(getState());

    const state = getState();
    let advancedFilterQueryParam = [];
    try {
      advancedFilterQueryParam = selectAdvancedFilterQueryParam(state, 'vulns', true);
    } catch (e) {
      dispatch(setFilterError('vulns', 'Syntax error. Please try again. For further help check our documentation'));
      return dispatch(setVulnsFilterError());
    }

    const hasAdvancedFilter = advancedFilterQueryParam.filters.length > 0;
    const standardQueryParam = selectQueryParam('vulns', state, true);
    const queryParam = hasAdvancedFilter ? advancedFilterQueryParam : standardQueryParam;

    try {
      const response = await api.manage.exportCSV(workspace, queryParam);
      dispatch(exportCsv(response));
    } catch (err) {
      dispatch({ type: types.EXPORT_CSV_MANAGE_FAILURE });
    }

    return dispatch({ type: types.EXPORT_CSV_MANAGE_SUCCESS });
  };
}

export function showContextMenu (show, XPos, YPos) {
  return (dispatch, getState) => {
    const state = getState();
    const currentXPos = selectContextMenuXPos(state);
    const currentYPos = selectContextMenuYPos(state);
    const newXPos = XPos ? (XPos + 1) : currentXPos;
    const newYPos = YPos ? (YPos + 1) : currentYPos;

    dispatch({
      type: types.SHOW_CONTEXT_MENU, show, contextMenuXPos: newXPos, contextMenuYPos: newYPos
    });
  };
}

export function showQuickPlanner (show) {
  return (dispatch) => {
    dispatch({ type: types.SHOW_QUICK_PLANNER, show });
  };
}

export function closeTemplateModalMessage () {
  return (dispatch) => {
    dispatch({ type: types.CLOSE_TEMPLATE_MODAL_MESSAGE });
  };
}
