import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';
import Tag from './styled';
import { SubTitle, SectionTitle } from '../../styled';
import { TagWrapper } from '../AddedTags/styled';

const NotAddedTags = ({
  addTag,
  newAddedTags,
  newNotAddedTags,
  notAddedTags
}) => {
  const intl = useIntl();
  const hasNotAddedTags = !isEmpty(newNotAddedTags);
  const emptyTagsText = isEmpty(notAddedTags) && isEmpty(newAddedTags) ? 'manage.tags.empty' : 'manage.tags.all_assigned';

  if (hasNotAddedTags) {
    return (
    <>
      <SectionTitle children={ intl.formatMessage({ id: 'manage.tags.inworkspace' }) } />
      <TagWrapper>
        { newNotAddedTags.map((tag) => (<Tag key={ `wtag_${tag}` } onClick={ () => addTag(tag) }>{tag}</Tag>)) }
      </TagWrapper>
    </>
    );
  }

  return (
    <>
      <SectionTitle children={ intl.formatMessage({ id: 'manage.tags.inworkspace' }) } />
      <SubTitle children={ intl.formatMessage({ id: emptyTagsText }) } />
    </>
  );
};

export default NotAddedTags;
