import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 21px;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.09px;
    color: #afb1bc;
    margin-bottom: 8px;
`;
Title.displayName = 'Title';

export const PlusIcon = styled.div`
    height: 25px;
    position: relative;
    right: 30px;
    top: 6px;
    width: 25px;    
    padding: 0px 4.49px;
    border-radius: 50%;
    cursor: pointer;

    &: hover {
        background-color: #90a9c0;
        
        svg {
            color: #fff!important;
        }
    }
`;
PlusIcon.displayName = 'PlusIcon';

export const InputWrapper = styled.div`
    display: inline-flex;
`;
InputWrapper.displayName = 'InputWrapper';

export const CustomInput = styled.input`
    width: ${(props) => (props.width ? `${props.width}` : '214px')};
    height: ${(props) => (props.height ? `${props.height}` : '34px')};    
    box-shadow: inset 0 -1px 0 0 #afafaf;
    background-color: #f5f7f9;
    border: none;    
    padding-left: 15px;
    padding-right: 42px;
    font-size: 14.5px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.48;
    letter-spacing: normal;
    color: #1c2243
    border-radius: 3px;

    &.full{
        background-color: white; 
    }

    &::placeholder {
        color: #afb1bc;
        font-size: 13.5px;
    }

    &:hover{
        border: 1px solid #afb1bc;        
    }

    &:focus{
        background-color: white;        

        &::placeholder {
            color: transparent;
        }
    }
`;
CustomInput.displayName = 'CustomInput';
