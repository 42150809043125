export const MODAL_MANAGE_BULK_UPDATE = 'MODAL_MANAGE_BULK_UPDATE';
export const MODAL_MANAGE_BULK_UPDATE_CONFIRMATION = 'MODAL_MANAGE_BULK_UPDATE_CONFIRMATION';
export const MODAL_RUN_USER_AGENT = 'modalRunAgent';
export const MODAL_RUN_CLOUD_AGENT = 'modalRunCloudAgent';
export const MODAL_UPGRADE_LICENSE = 'modalUpgradeLicense';
export const MODAL_AGENT_TOKEN = 'modalAgentToken';
export const MODAL_AGENT_CREATE = 'modalAgentCreate';
export const MODAL_SCHEDULE_CREATE_EDIT = 'modalScheduleCreateEdit';
export const MODAL_WORKSPACE_CREATE_EDIT = 'modalWorkspaceCreateEdit';
export const MODAL_EVIDENCE = 'MODAL_EVIDENCE';
export const MODAL_JIRA = 'MODAL_JIRA';
export const MODAL_JIRA_TEMPLATE = 'MODAL_JIRA_TEMPLATE';
export const MODAL_SEND_TO_JIRA = 'MODAL_SEND_TO_JIRA';
export const MODAL_RUN_PIPELINE = 'modalRunPipeline';
export const MODAL_SAVE_JOB = 'modalSaveJob';
export const MODAL_CREATE_SERVICE = 'MODAL_CREATE_SERVICE';
export const MODAL_ANALYTICS_CONFIRM_NAVIGATION = 'MODAL_ANALYTICS_CONFIRM_NAVIGATION';
export const MODAL_ANALYTICS_CONFIRM_DELETE = 'MODAL_ANALYTICS_CONFIRM_DELETE';
export const MODAL_ANALYTICS_NOTIFICATION_MESSAGE = 'MODAL_ANALYTICS_NOTIFICATION_MESSAGE';
export const MODAL_PROJECTS_CREATE_EDIT = 'MODAL_PROJECTS_CREATE_EDIT';
export const MODAL_PROJECTS_DELETE = 'MODAL_PROJECTS_DELETE';
export const MODAL_PROJECTS_SHOW_ERROR = 'MODAL_PROJECTS_SHOW_ERROR';
export const MODAL_TASKS_CREATE_EDIT = 'MODAL_TASKS_CREATE_EDIT';
export const MODAL_TASKS_DELETE = 'MODAL_TASKS_DELETE';
export const MODAL_TASKS_REDIRECT_TO_MANAGE = 'MODAL_TASKS_REDIRECT_TO_MANAGE';
export const MODAL_TASKS_COMMENT_DELETE = 'MODAL_TASKS_COMMENT_DELETE';
export const MODAL_REDIRECT_CONFIRMATION = 'MODAL_REDIRECT_CONFIRMATION';
export const MODAL_SCHEDULER_NO_WS_WARNING = 'MODAL_SCHEDULER_NO_WS_WARNING';
export const MODAL_APPLY_TEMPLATE = 'MODAL_APPLY_TEMPLATE';
export const MODAL_CUSTOM_ATTRIBUTES = 'MODAL_CUSTOM_ATTRIBUTES';
export const MODAL_SCHEDULER_OFFLINE_AGENT_WARNING = 'MODAL_SCHEDULER_OFFLINE_AGENT_WARNING';
export const MODAL_JOBS_DELETE = 'MODAL_JOBS_DELETE';
export const MODAL_UPGRADE_LICENSE_SIDEBAR = 'modalUpgradeLicenseSidebar';
export const MODAL_TOUR_FINISHED = 'MODAL_TOUR_FINISHED';
