import api from 'services/api';
import { showModalImportDashboard } from 'Screens/Dashboard/actions/Actions';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import { updateCurrentWorkspaceOnEdit as setFaradayWorkspace } from 'Screens/Faraday/actions/Actions';
import { workspaceIsInWorkspaceActivity, removeWorkspaceFromWorkspaceActivity } from 'Screens/Faraday/functions';
import { ACTIONS, CATEGORIES } from 'tracking/GA/constants';
import { trackEvent } from 'tracking/GA';
import { updatePreferences } from 'store/Preferences/actions';
import { selectWorkspaceActivity } from 'store/Preferences/workspace/selectors';
import get from 'lodash/get';
import { openModal, closeModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE, MODAL_WORKSPACE_CREATE_EDIT } from 'store/modals/modals';
import { selectMaxWorkspacesLimit } from 'store/Config/selector';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import {
  selectCurrentWsName,
  selectWsEditCreateName,
  selectWsEditCreateDescription,
  selectWsEditCreateStart,
  selectWsEditCreateEnd,
  selectWsEditCreateId,
  selectScope,
  selectWsEditActive,
  selectWsEditCustomer,
  selectWsEditReadonly,
  selectWsEditCreateMakePublic,
  selectWsEditCreateCurrentUsers
} from 'store/WorkspaceEditCreate/selectors';
import { redirect } from 'store/Router/actions';
import {
  setPageNumber, clearFilters,
  setFilter, setOrderBy
} from 'store/Filters/actions';
import { selectQueryParam } from 'store/Filters/selectors';
import { setError } from 'store/errors/actions';

export const SET_FIELD = 'SET_FIELD';
export const GET_DATA_WORKSPACE_START = 'GET_DATA_WORKSPACE_START';
export const GET_DATA_WORKSPACE_FAIL = 'GET_DATA_WORKSPACE_FAIL';
export const GET_DATA_WORKSPACE_SUCCESS = 'GET_DATA_WORKSPACE_SUCCESS';
export const WORKSPACE_SELECTED_FOR_WORKING = 'WORKSPACE_SELECTED_FOR_WORKING';
export const GET_WORKSPACES_FOR_WORKING_STARTED = 'GET_WORKSPACES_FOR_WORKING_STARTED';
export const GET_WORKSPACES_FOR_WORKING_SUCCESS = 'GET_WORKSPACES_FOR_WORKING_SUCCESS';
export const GET_WORKSPACES_FOR_WORKING_FAILURE = 'GET_WORKSPACES_FOR_WORKING_FAILURE';
export const WORKSPACE_CREATE_UPDATE_START = 'WORKSPACE_CREATE_UPDATE_START';
export const WORKSPACE_CREATE_UPDATE_SUCCESS = 'WORKSPACE_CREATE_UPDATE_SUCCESS';
export const WORKSPACE_ENABLE_DISABLE = 'WORKSPACE_ENABLE_DISABLE';
export const STOP_REDIRECT = 'STOP_REDIRECT';
export const WORKSPACE_SELECTED = 'WORKSPACE_SELECTED';
export const SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE = 'SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE';
export const HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE = 'HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE';
export const DELETE_WORKSPACES = 'DELETE_WORKSPACES';
export const HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED = 'HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED';
export const WORKSPACE_UPDATE_SUCCESS = 'WORKSPACE_UPDATE_SUCCESS';
export const SET_VISIBILITY_CREATE_MODAL_WORKSPACE = 'SET_VISIBILITY_CREATE_MODAL_WORKSPACE';
export const WORKSPACE_CREATION_ERROR = 'WORKSPACE_CREATION_ERROR';
export const SET_USERS_WORKSPACES = 'SET_USERS_WORKSPACES';
export const SET_WORKSPACE_FOR_EDIT = 'SET_WORKSPACE_FOR_EDIT';
export const WORKSPACE_MASSIVE_UPDATE_FAIL = 'WORKSPACE_MASSIVE_UPDATE_FAIL';
export const WORKSPACE_MASSIVE_UPDATE_SUCCESS = 'WORKSPACE_MASSIVE_UPDATE_SUCCESS';
export const MORE_OPTIONS_STATUS_CHANGE_WORKSPACE = 'MORE_OPTIONS_STATUS_CHANGE_WORKSPACE';
export const SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE = 'SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE';
export const TOGGLE_INACTIVE_FLAG = 'TOGGLE_INACTIVE_FLAG';
export const GET_DATA_WORKSPACES_SUCCESS = 'GET_DATA_WORKSPACES_SUCCESS';
export const WORKSPACE_SHOW_GRID_VIEW = 'WORKSPACE_SHOW_GRID_VIEW';
export const CURRENT_WORKSPACE_EDITED_UPDATE = 'CURRENT_WORKSPACE_EDITED_UPDATE';
export const SET_CURRENT_WORKSPACE_AS_INVALID = 'SET_CURRENT_WORKSPACE_AS_INVALID';
export const SET_SHOW_WORKSPACE_VALIDATOR = 'SET_SHOW_WORKSPACE_VALIDATOR';

export const RESET_STATE_WORKSPACE_CREATE_UPDATE = 'RESET_STATE_WORKSPACE_CREATE_UPDATE';
export const WORKSPACE_CREATE_UPDATE_FAIL = 'WORKSPACE_CREATE_UPDATE_FAIL';

export function resetCreationState () {
  return (dispatch) => {
    dispatch({
      type: RESET_STATE_WORKSPACE_CREATE_UPDATE
    });
  };
}

export function getWorkspaces () {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_STARTED });
      const data = await api.workspace.fetchWorkspaces({});
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_SUCCESS, data });
    } catch (e) {
      dispatch({ type: GET_WORKSPACES_FOR_WORKING_FAILURE, data: e.message || 'Failed to obtain workspaces.' });
    }
  };
}

// Summary: select a workspace for user to work
export function workspaceSelectedForWorking (workspace) {
  return (dispatch) => {
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace, redirect: true });
  };
}

export function stopRedirect () {
  return (dispatch) => {
    dispatch({ type: STOP_REDIRECT });
  };
}

export function getData () {
  return async (dispatch, getState) => {
    dispatch({ type: GET_DATA_WORKSPACE_START });
    const state = getState();
    const params = selectQueryParam('workspaces', state);
    try {
      const data = await api.workspace.fetchWorkspaces(params);
      return dispatch({ type: GET_DATA_WORKSPACES_SUCCESS, data });
    } catch (error) {
      return dispatch({ type: GET_DATA_WORKSPACE_FAIL, error });
    }
  };
}

export function enableDisable (ws, value) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const { workspaceList } = state.workspace;
      const currentWorkspace = selectCurrentWorkspace(getState());
      if (currentWorkspace !== ws) {
        const workspaces = await api.workspace.getActiveWorkspaces(); // obtengo el total de workspaces activos.
        const maxWsLimit = selectMaxWorkspacesLimit(getState());
        if (!value && get(workspaces, 'length', 0) >= maxWsLimit) {
          dispatch(openModal(MODAL_UPGRADE_LICENSE));
        } else {
          dispatch({ type: WORKSPACE_ENABLE_DISABLE });

          await api.workspace.updateWorkspace(ws, { active: !value });

          const newWs = workspaceList.filter((workspace) => workspace.name === ws)[0];
          newWs.active = !value;

          dispatch({ type: WORKSPACE_UPDATE_SUCCESS, ws: newWs });
          dispatch(getWorkspaces());
          dispatch(getData());
        }
      }
    } catch (e) {
      dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, data: 'An error has occurred.' });
    }
  };
}

export function changeReadOnly (ws, readonly) {
  return async (dispatch) => {
    try {
      dispatch({ type: WORKSPACE_ENABLE_DISABLE, ws });
      await api.workspace.updateWorkspace(ws, { readonly });
      const newWs = await api.workspace.getWsStats(ws);

      if (newWs) dispatch({ type: WORKSPACE_UPDATE_SUCCESS, ws: newWs });

      dispatch(getWorkspaces());
      dispatch(getData());
    } catch (e) {
      dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: 'An error has occurred' });
    }
  };
}

// Summary: Add workspace selected to state. // se usa para la tabla
export function selectWorkspace (vulnsSelectedInfo) {
  return (dispatch) => {
    dispatch({
      type: WORKSPACE_SELECTED, vulnsSelectedInfo
    });
  };
}

// Summary: delete workspace/s from database
export function deleteWorkspaceSelected () {
  return async (dispatch, getState) => {
    const state = getState();
    const workspacesSelected = selectWorkspacesSelected(state);
    const wsActivity = selectWorkspaceActivity(state);
    dispatch({ type: DELETE_WORKSPACES });

    // TODO: Modify backend, so it should receive an array and return message
    // when some workspaces could not be deleted. Then, add request.
    try {
      const promises = workspacesSelected.map((ws) => {
        const promise = api.workspace.deleteWorkspace(ws.name);
        dispatch(trackEvent(CATEGORIES.workspace, ACTIONS.deleteWorkspace.name, ACTIONS.deleteWorkspace.label, ws.id));
        return promise;
      });

      await Promise.all(promises);
      const newWsActivity = wsActivity.filter((ws) => workspacesSelected.every((selected) => ws !== selected.name));
      dispatch(updatePreferences({ workspaceActivity: newWsActivity }));
    } catch (e) {
      dispatch(setError(e?.message || 'There was an error, please try again'));
    }

    dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE_SUCCEED });
    await dispatch(getWorkspaces());
    await dispatch(getData());
  };
}

// Summary: Show confirmation modal when user delete workspace/s
export function showWorkspaceModalDelete () {
  return (dispatch) => {
    dispatch({ type: SHOW_MODAL_DELETE_CONFIRMATION_WORKSPACE });
  };
}

// Summary: Hide confirmation modal when user delete workspace/s
export function hideWorkspaceModalDelete () {
  return (dispatch) => {
    dispatch({ type: HIDE_MODAL_DELETE_CONFIRMATION_WORKSPACE });
  };
}

export function getUsers () {
  return async (dispatch) => {
    try {
      const users = await api.users.getUsers();
      const mappedUsers = users.rows.map((user) => user.doc);
      return dispatch({ type: SET_USERS_WORKSPACES, users: mappedUsers });
    } catch (e) {
      return dispatch({ type: '' });
    }
  };
}

export function createUpdateWorkspace () {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const currentWsName = selectCurrentWsName(state);
      const name = selectWsEditCreateName(state);
      const description = selectWsEditCreateDescription(state);
      const start = selectWsEditCreateStart(state);
      const end = selectWsEditCreateEnd(state);
      const id = selectWsEditCreateId(state);
      const scope = selectScope(state);
      const currentWorkspace = selectCurrentWorkspace(state);
      const active = selectWsEditActive(state);
      const customer = selectWsEditCustomer(state);
      const readonly = selectWsEditReadonly(state);
      const isPublic = selectWsEditCreateMakePublic(state);
      const currentUsers = selectWsEditCreateCurrentUsers(state);

      let wsActivity = selectWorkspaceActivity(state);

      dispatch({ type: WORKSPACE_CREATE_UPDATE_START });

      // eslint-disable-next-line no-useless-escape
      if (/^[a-z0-9][a-z0-9\_\$\(\)\+\-]*$/.test(name)) {
        let workspace = {
          name,
          description,
          scope,
          public: isPublic,
          users: currentUsers,
          duration: {
            start_date: start ? new Date(start).getTime() : '',
            end_date: end ? new Date(end).getTime() : ''
          }
        };

        if (id) {
          workspace = {
            ...workspace,
            id,
            active,
            customer,
            readonly
          };
        }

        if (id) {
          await api.workspace.updateWorkspace(currentWsName, workspace);
          dispatch(trackEvent(CATEGORIES.workspace, ACTIONS.editWorkspace.name, ACTIONS.editWorkspace.label, id));
        } else {
          const response = await api.workspace.createWorkspace(workspace);
          dispatch(trackEvent(CATEGORIES.workspace, ACTIONS.createWorkspace.name, ACTIONS.createWorkspace.label, response.id));
        }

        dispatch({ type: WORKSPACE_CREATE_UPDATE_SUCCESS });

        if (id && workspaceIsInWorkspaceActivity(wsActivity, currentWorkspace)) {
          wsActivity = removeWorkspaceFromWorkspaceActivity(wsActivity, currentWorkspace);
          dispatch(updatePreferences({ workspaceActivity: wsActivity }));
        }

        if (currentWsName === currentWorkspace && currentWsName !== name) {
          dispatch(setFaradayWorkspace(name, currentWorkspace));
        }

        dispatch(setVisibilityCreateModal(false));
        dispatch(getWorkspaces());
        dispatch(getUsers());
        // Remove when add pagination and filter functionality
        return dispatch(getData());
      }
      return dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: 'Workspace name can not contain white spaces, uppercase letters or special characters.' });
    } catch (e) {
      return dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function createWorkspaceFromSelector (users) {
  return async (dispatch, getState) => {
    const { name } = getState().workspaceEditCreate;
    const response = await dispatch(createUpdateWorkspace(users));

    if (!response.error) {
      await dispatch(getWorkspaces());
      dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: name });
      dispatch(redirect(`/feed/${name}`));
    }
    return dispatch({ type: '' });
  };
}

export function resetErrorMessage () {
  return (dispatch) => dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: '' });
}

export function setVisibilityCreateModal (value) {
  return async (dispatch, getState) => {
    const workspaces = await api.workspace.getActiveWorkspaces(); // obtengo el total de workspaces activos.
    const maxWsLimit = selectMaxWorkspacesLimit(getState());
    if (get(workspaces, 'length', 0) >= maxWsLimit) {
      dispatch(openModal(MODAL_UPGRADE_LICENSE));
    } else {
      dispatch({ type: SET_VISIBILITY_CREATE_MODAL_WORKSPACE, value });
      if (!value) dispatch({ type: RESET_STATE_WORKSPACE_CREATE_UPDATE, value });
      if (value) dispatch(openModal(MODAL_WORKSPACE_CREATE_EDIT));
      else dispatch(closeModal(MODAL_WORKSPACE_CREATE_EDIT));
    }
  };
}

export function showEditModal () {
  return async (dispatch, getState) => {
    const { workspacesSelected } = getState().workspace;
    try {
      const workspace = await api.workspace.getWsStats(workspacesSelected[0].name);
      dispatch(setVisibilityCreateModal(true));
      return dispatch({ type: SET_WORKSPACE_FOR_EDIT, workspace });
    } catch (e) {
      return dispatch({ type: WORKSPACE_CREATE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function massiveUpdateWorkspacesActive () {
  return async (dispatch, getState) => {
    const { workspacesSelected, workspaces } = getState().workspace;
    const currentWorkspace = selectCurrentWorkspace(getState());

    try {
      const workspacesUpdated = workspaces;
      const activeWorkspaces = await api.workspace.getActiveWorkspaces();
      const maxWsLimit = selectMaxWorkspacesLimit(getState());

      if (get(activeWorkspaces, 'length', 0) >= maxWsLimit) {
        dispatch(openModal(MODAL_UPGRADE_LICENSE));
      } else {
        workspacesSelected.forEach(async (ws) => {
          if (currentWorkspace !== ws.name) {
            const index = workspacesUpdated.findIndex((workspace) => workspace.id === ws.id);
            const wsCopy = ws;
            wsCopy.active = !wsCopy.active;
            workspacesUpdated[index] = wsCopy;

            if (wsCopy.active) {
              await api.workspace.updateWorkspace(wsCopy.name, { active: true });
            } else {
              await api.workspace.updateWorkspace(wsCopy.name, { active: false });
            }
          }
        });
      }
      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_SUCCESS, workspaces: workspacesUpdated });
    } catch (e) {
      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function moreStatusChange (value) {
  return (dispatch) => {
    dispatch({ type: MORE_OPTIONS_STATUS_CHANGE_WORKSPACE, value });
  };
}

export function setModalAssignUsersStatus (value) {
  return async (dispatch, getState) => {
    const { workspacesSelected, workspaces } = getState().workspace;

    try {
      const workspacesUpdated = workspaces;

      workspacesSelected.forEach(async (ws, indexP) => {
        const index = workspacesUpdated.findIndex((workspace) => workspace.id === ws.id);
        const wsCopy = ws;

        if (!ws.users) {
          const workspaceData = await api.workspace.getWsStats(ws.name);
          wsCopy.users = workspaceData.users;
          workspacesUpdated[index] = wsCopy;
        }

        if (indexP + 1 === workspacesSelected.length) {
          dispatch({ type: WORKSPACE_MASSIVE_UPDATE_SUCCESS, workspaces: workspacesUpdated });
          return dispatch({ type: SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE, value });
        }
        return dispatch({ type: '' });
      });

      return dispatch({ type: '' });
    } catch (e) {
      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function massiveUpdateUsers (selectedUsers) {
  return async (dispatch, getState) => {
    const { workspacesSelected } = getState().workspace;

    try {
      const promises = workspacesSelected.map((ws) => api.workspace.updateWorkspace(ws.name, { users: selectedUsers }));
      await Promise.all(promises);

      dispatch(getWorkspaces());
      dispatch(getData());
      return dispatch({ type: SET_STATUS_MODAL_ASSIGN_USERS_WORKSPACE, value: false });
    } catch (e) {
      return dispatch({ type: WORKSPACE_MASSIVE_UPDATE_FAIL, error: e.message ? e.message : 'An error has occurred.' });
    }
  };
}

export function toggleInactive () {
  return (dispatch) => {
    dispatch({ type: TOGGLE_INACTIVE_FLAG });
  };
}

export function workspaceSelectedFromTable (workspaceName, vulns) {
  return async (dispatch) => {
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: workspaceName });
    dispatch(redirect(`/feed/${workspaceName}`));

    if (vulns === 0) dispatch(showModalImportDashboard(true));
  };
}

export function setPage (page) {
  return (dispatch) => {
    dispatch(setPageNumber('workspaces', page));
    dispatch(getData());
  };
}

export function setWorkspaceFilter (string) {
  return (dispatch) => {
    const newFilter = { name: 'name', op: 'ilike', val: `%${string}%` };
    if (string) dispatch(setFilter('workspaces', newFilter));
    else dispatch(clearFilters('workspaces'));
    dispatch(getData());
  };
}

export function setOrderByWorkspaces (sorting) {
  return (dispatch) => {
    dispatch(setOrderBy('workspaces', sorting));
    dispatch(getData());
  };
}

export function toogleGridView (showGridView) {
  return (dispatch) => {
    dispatch({
      type: WORKSPACE_SHOW_GRID_VIEW, showGridView
    });
  };
}

export function setField (field, value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELD, field, value
    });
  };
}
