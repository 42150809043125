import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import ContextMenuButton from 'Common/Components/ContextMenuButton';
import SendToTool from 'Common/Components/SendToTool';
import { Send } from './styled';
import { selectShowDetail, selectVulnsSelected } from 'store/Manage/selectors';
import { selectIsAdmin } from 'store/Faraday/selectors';

const SendToToolsButton = () => {
  const isAdmin = useSelector(selectIsAdmin);
  const vulnsSelected = useSelector(selectVulnsSelected);
  const showVulnDetail = useSelector(selectShowDetail);

  if (!isAdmin) return null;
  return (
    <ContextMenuButton
      menu={ <SendToTool trigger="send_menu_manage" /> }
      trigger="send_menu_manage"
      display={ !isEmpty(vulnsSelected) && !showVulnDetail }
      icon={ <IconButton icon={ <Send /> } title="Send to Tools" /> }
    />
  );
};

export default SendToToolsButton;
