/* eslint-disable react/no-children-prop */
import useExpandable from 'Hooks/useExpandable';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectVulnDetailTags } from 'store/Manage/selectors';
import isEmpty from 'lodash/isEmpty';
import ExpandableWrapper from '../ExpandableWrapper';
import AddTagButton from './components/AddTagButton';
import TagItems from './components/TagItems';
import {
  Subtitle,
  Wrapper
} from './styled';

const TAB = 'general';
const ID = 'tags';

const Tags = () => {
  const intl = useIntl();
  const vulnTags = useSelector(selectVulnDetailTags);
  const hasTags = !isEmpty(vulnTags);
  const hasTagsLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.hasTagsSubtitle' });
  const noTagsLabel = intl.formatMessage({ id: 'manage.detail.tab.general.tags.noTagsSubtitle' });
  const isExpanded = useExpandable(TAB, ID, vulnTags);

  return (
    <ExpandableWrapper defaultValue={ isExpanded } id="tags" title={ intl.formatMessage({ id: 'manage.detail.tab.general.tags' }) }>
      <Wrapper>
        <Subtitle children={ hasTags ? hasTagsLabel : noTagsLabel } />
        <TagItems />
        <AddTagButton />
      </Wrapper>
    </ExpandableWrapper>
  );
};

export default Tags;
