import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ElementList from 'Screens/Analytics/components/CreateEdit/components/EditGraph/components/Settings/components/ElementList';
import { debounce } from 'lodash';
import {
  Wrapper, Search, Remove, WrapperSearch
} from './styled';

const ElementSearch = ({
  elementIndex, elementName, onSelectCallback, onRemoveCallback, elements, placeholder
}) => {
  const [search, setSearch] = useState(elementName);
  const [show, setShow] = useState();

  const onSelect = (value) => {
    onSelectCallback(elementIndex, value);
  };

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onRemove = () => {
    onRemoveCallback(elementIndex);
  };

  const onBlur = debounce(() => {
    if (search !== elementName) setSearch('');
    setShow(false);
  }, 300);

  const onFocus = () => {
    setShow(true);
  };

  useEffect(() => {
    if (search === elementName) setShow(false);
    else setShow(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Wrapper>
      <WrapperSearch>
        <Search onFocus={ onFocus } onBlur={ onBlur } placeholder={ placeholder } onChange={ onChange } value={ search } />
        <Remove onClick={ onRemove } />
      </WrapperSearch>
      {show && <ElementList elements={ elements.filter((item) => item.name.toLowerCase().startsWith(search)) } onClickCallback={ onSelect } />}
    </Wrapper>
  );
};

ElementSearch.propTypes = {
  elementIndex: PropTypes.number.isRequired,
  elementName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onRemoveCallback: PropTypes.func.isRequired,
  onSelectCallback: PropTypes.func.isRequired,
  elements: PropTypes.instanceOf(Array).isRequired // debe tener objectos con {name}
};

export default ElementSearch;
