import React from 'react';
import HelpIcon from 'Images/help.svg';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { setShowUserFeedback } from 'Screens/Faraday/actions/Actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectLicenseType } from 'store/Config/selector';

import {
  Wrapper, PrimaryTitle, SecondaryTitle, TertiaryTitle, Text, Row, Button, Logo, Container
} from '../../styled';

const Help = ({ onClose }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isPersonal = useSelector(selectLicenseType).includes('personal');
  const onShowingFeedback = () => {
    dispatch(setShowUserFeedback(true));
    onClose();
  };
  const link = {
    documentation: 'https://docs.faradaysec.com/',
    faqs: 'https://docs.faradaysec.com/FAQ/',
    troubleshooting: 'https://docs.faradaysec.com/Troubleshooting/',
    support: isPersonal ? 'mailto:helpdeskpersonal@infobytesec.zohodesk.com' : 'https://faradaysec.com/support/',
    home: 'https://faradaysec.com/'
  };

  return (
    <Wrapper maxWidth="465px">
      <Logo src={ HelpIcon } alt="Help" />
      <PrimaryTitle>
        {intl.formatMessage({ id: 'user.help.title' })}
      </PrimaryTitle>
      <SecondaryTitle>
        {intl.formatMessage({ id: 'user.help.secondary-title' })}
      </SecondaryTitle>
      <Container>
        <Row>
          <Text>
            {intl.formatMessage({ id: 'user.help.text.part1' })}
            {' '}
            <TertiaryTitle href={ link.documentation } target="_blank">{intl.formatMessage({ id: 'user.help.tertiary-title.documentation' })}</TertiaryTitle>
            <br />
            {intl.formatMessage({ id: 'user.help.text.part2' })}
            {' '}
            <TertiaryTitle href={ link.faqs } target="_blank">{intl.formatMessage({ id: 'user.help.tertiary-title.faqs' })}</TertiaryTitle>
            {' '}
            {intl.formatMessage({ id: 'user.help.text.part3' })}
            {' '}
            <TertiaryTitle href={ link.troubleshooting } target="_blank">{intl.formatMessage({ id: 'user.help.tertiary-title.troubleshooting' })}</TertiaryTitle>
            <br />
            <br />

            {intl.formatMessage({ id: 'user.help.text.part4' })}
            {' '}
            <TertiaryTitle href={ link.support } target="_blank">{intl.formatMessage({ id: 'user.help.tertiary-title.ticket' })}</TertiaryTitle>
            {' '}
            <br />
            <br />

            {intl.formatMessage({ id: 'user.help.text.part5' })}
            {' '}
            <TertiaryTitle onClick={ onShowingFeedback } target="_blank">{intl.formatMessage({ id: 'user.help.tertiary-title.leavingFeedback' })}</TertiaryTitle>
            {' '}
            <br />
            <br />

            {intl.formatMessage({ id: 'user.help.text.part6' })}
            {' '}
            <TertiaryTitle href={ link.home } target="_blank">{intl.formatMessage({ id: 'user.help.tertiary-title.faraday' })}</TertiaryTitle>
          </Text>
        </Row>
      </Container>
      <Button onClick={ onClose }>{intl.formatMessage({ id: 'user.buttton' })}</Button>
    </Wrapper>
  );
};

Help.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default Help;
