import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'Common/Components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSummary
} from 'Screens/Dashboard/actions/Actions';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import get from 'lodash/get';
import { CLOSED_FILTERS, CONFIRMED_FLAG_FILTERS, DASHBOARD_FILTERS } from 'store/Filters/constants';
import { isFilteringBy } from 'store/Filters/selectors';
import { redirect } from 'store/Router/actions';
import {
  StyledRow, Name, Title, Value, Empty, ClickableRow
} from './styled';
import { setFilter } from 'store/Filters/actions';
/* eslint-disable consistent-return */
const Summary = () => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const data = useSelector((state) => get(state, 'dashboard.tools[3].data', []));

  useEffect(() => {
    dispatch(getSummary());
  }, [dispatch, workspaceSelected]);

  const { confirmed } = CONFIRMED_FLAG_FILTERS;
  const { closed } = CLOSED_FILTERS;

  const isFilteringByConfirm = useSelector((state) => isFilteringBy(state, 'vulns', confirmed));
  const isFilteringByClosed = useSelector((state) => isFilteringBy(state, 'vulns', closed));

  const filterData = useMemo(() => {
    if (!data.stats) return;

    const parsedData = {
      hosts: data.stats.hosts,
      services: data.stats.services,
      web_vulns: data.stats.web_vulns,
      total_vulns: data.stats.total_vulns
    };

    if (isFilteringByConfirm && isFilteringByClosed) {
      return ({
        ...parsedData,
        total_vulns: data.stats.vulnerability_confirmed_and_not_closed_count,
        web_vulns: data.stats.vulnerability_web_confirmed_and_not_closed_count
      });
    }
    if (isFilteringByConfirm) {
      return ({
        ...parsedData,
        total_vulns: data.stats.confirmed_vulns,
        web_vulns: data.stats.vulnerability_web_confirmed_count
      });
    }
    if (isFilteringByClosed) {
      return {
        ...parsedData,
        total_vulns: data.stats.total_vulns - data.stats.closed_vulns,
        web_vulns: data.stats.web_vulns - data.stats.vulnerability_web_closed_count
      };
    }
    return parsedData;
  }, [data, isFilteringByConfirm, isFilteringByClosed]);

  const goToAssets = () => {
    dispatch(redirect(`/host/${workspaceSelected}`));
  };

  const goToManage = () => {
    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  const goToManageWebVulns = () => {
    dispatch(setFilter('vulns', DASHBOARD_FILTERS.vulnerabilitiesByType('vulnerability_web')));

    dispatch(redirect(`/manage/${workspaceSelected}`));
  };

  return (
    <ClickableRow >
      <Col>
        <Row onClick={ goToManage }>
          <Col><Title>Workspace Summary</Title></Col>
        </Row>
        { (data && data.stats)
          ? (
          <StyledRow>
            <Col onClick={ goToAssets }>
              <StyledRow><Value>{filterData.hosts}</Value></StyledRow>
              <StyledRow><Name>Assets</Name></StyledRow>
            </Col>

            <Col onClick={ goToAssets }>
              <StyledRow><Value>{filterData.services}</Value></StyledRow>
              <StyledRow><Name>Services</Name></StyledRow>
            </Col>

            <Col onClick={ goToManage } title="std + web">
              <StyledRow>
                <Value>{filterData.total_vulns || 0}</Value>
              </StyledRow>
              <StyledRow><Name>Total Vulnerabilities</Name></StyledRow>
            </Col>

            <Col onClick={ goToManageWebVulns } >
              <StyledRow>
                <Value>{filterData.web_vulns || 0}</Value>
              </StyledRow>
              <StyledRow><Name>Web Vulns</Name></StyledRow>
            </Col>

          </StyledRow>
            )
          : <Empty>There is no Vulnerabilities to show.</Empty> }
      </Col>
    </ClickableRow>
  );
};

export default Summary;
