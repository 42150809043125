/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-children-prop */
import React from 'react';
import { useSelector } from 'react-redux';
import DateFormatter from 'Common/Components/DateFormatter';
import get from 'lodash/get';
import { selectAllowedByRole } from 'Hooks/usePermission/selector';
import { FEATURE_REPORTING, PERMISSION_TYPES } from 'Hooks/usePermission/permissions';
import Skeleton from 'Common/Components/Skeleton';
import { PropTypes } from 'prop-types';
import ActionButton from './components/ActionButton';
import DownloadDropdown from './components/DownloadDropdown';
import {
  ColumnName, ColumnCreator, ColumnLastActivity,
  ColumnVulns, DeleteIcon, EditIcon,
  ProcessingIcon, WordIcon, PDFIcon,
  CloneIcon, WarningIcon, RetryLabel,
  WarningWrapper, VerticalLine
} from './styled';

const isProcessing = (rowInfo) => get(rowInfo, 'original.status', '') === 'processing';
const isCreated = (rowInfo) => get(rowInfo, 'original.status', '') === 'created';
const hasError = (rowInfo) => get(rowInfo, 'original.status', '') === 'error';
const isWord = (rowInfo) => get(rowInfo, 'original.template_name', '').includes('.doc');

const Warning = () => (
  <WarningWrapper>
    <VerticalLine />
    <WarningIcon />
  </WarningWrapper>
);

export const IconCell = ({ rowInfo, isFetching }) => {
  if (isFetching) return null;
  if (isProcessing(rowInfo)) return <ProcessingIcon />;
  if (hasError(rowInfo)) return <Warning />;
  if (isWord(rowInfo)) return <WordIcon />;
  return <PDFIcon />;
};

export const NameCell = ({ rowInfo, isFetching }) => {
  const name = get(rowInfo, 'original.name', '');
  const reportName = hasError(rowInfo) ? `"${name}" could not be generated.` : name;
  if (isFetching) return <Skeleton />;
  return <ColumnName isProcessing={ isProcessing(rowInfo) } hasError={ hasError(rowInfo) } children={ reportName } />;
};

export const VulnsCountCell = ({ rowInfo, isFetching }) => {
  if (isFetching) return <Skeleton />;
  const vulnsCount = get(rowInfo, 'original.vuln_count', '-');
  const shouldRender = isCreated(rowInfo);
  return shouldRender && <ColumnVulns children={ vulnsCount } />;
};

export const CreatorCell = ({ rowInfo, isFetching }) => {
  if (isFetching) return <Skeleton />;
  const owner = get(rowInfo, 'original.metadata.owner', '');
  const creator = get(rowInfo, 'original.metadata.creator', '');
  const shouldRender = isCreated(rowInfo);
  return shouldRender && <ColumnCreator isProcessing={ isProcessing(rowInfo) } children={ owner || creator } />;
};

export const LastActivityCell = ({ rowInfo, isFetching, cloneAction }) => {
  const date = get(rowInfo, 'original.metadata.update_time', '');
  const reportId = get(rowInfo, 'original.id', 0);
  const shouldRender = isCreated(rowInfo);
  if (isFetching) return <Skeleton />;
  if (hasError(rowInfo)) return <RetryLabel children="Retry" onClick={ () => cloneAction(reportId, true) } />;
  return shouldRender && (
  <ColumnLastActivity>
    <DateFormatter date={ date } />
  </ColumnLastActivity>
  );
};

export const DownloadCell = ({ rowInfo, isFetching }) => {
  const reportId = get(rowInfo, 'original.id', '');
  const reportName = get(rowInfo, 'original.name', '');
  const templateName = get(rowInfo, 'original.template_name', '');
  const shouldRender = isCreated(rowInfo) && !isFetching;
  return shouldRender && <DownloadDropdown reportId={ reportId } reportName={ reportName } template_name={ templateName } />;
};

export const CloneAndUpdateCell = ({ rowInfo, isFetching, cloneAction }) => {
  const reportId = get(rowInfo, 'original.id', 0);
  const canClone = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.CREATE));
  const shouldRender = isCreated(rowInfo) && !isFetching && canClone;
  return shouldRender && <ActionButton title="Clone and update" onClick={ () => cloneAction(reportId) } icon={ <CloneIcon /> } />;
};

export const CloneAndEditCell = ({ rowInfo, isFetching, editAction }) => {
  const reportId = get(rowInfo, 'original.id', 0);
  const canEdit = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.PATCH));
  const shouldRender = (isCreated(rowInfo) || hasError(rowInfo)) && !isFetching && canEdit;
  return shouldRender && <ActionButton title="Clone and edit" onClick={ () => editAction(reportId) } icon={ <EditIcon /> } />;
};

export const DeleteCell = ({ rowInfo, isFetching, deleteAction }) => {
  const reportId = get(rowInfo, 'original.id', 0);
  const canDelete = useSelector((state) => selectAllowedByRole(state, FEATURE_REPORTING, PERMISSION_TYPES.DELETE));
  const shouldRender = (isCreated(rowInfo) || hasError(rowInfo)) && !isFetching && canDelete;
  return shouldRender && <ActionButton title="Delete" onClick={ () => deleteAction(reportId) } icon={ <DeleteIcon /> } />;
};

IconCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
NameCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
VulnsCountCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
CreatorCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
LastActivityCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  cloneAction: PropTypes.func.isRequired
};
DownloadCell.propTypes = { rowInfo: PropTypes.object.isRequired, isFetching: PropTypes.bool.isRequired };
CloneAndUpdateCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  cloneAction: PropTypes.func.isRequired
};
CloneAndEditCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  editAction: PropTypes.func.isRequired
};

DeleteCell.propTypes = {
  rowInfo: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  deleteAction: PropTypes.func.isRequired
};
