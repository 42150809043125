import get from 'lodash/get';

export const selectWorkspaceSaved = (state) => get(state, 'workspaceEditCreate.workspaceSaved', false);
export const selectWsEditCreateName = (state) => get(state, 'workspaceEditCreate.name', '');
export const selectWsEditCreateDescription = (state) => get(state, 'workspaceEditCreate.description', '');
export const selectWsEditCreateStart = (state) => get(state, 'workspaceEditCreate.start', '');
export const selectWsEditCreateEnd = (state) => get(state, 'workspaceEditCreate.end', '');
export const selectWsEditCreateLocale = (state) => get(state, 'locale', '');
export const selectWsEditCreateErrorMessage = (state) => get(state, 'workspaceEditCreate.errorMessage', '');
export const selectWsEditCreateId = (state) => get(state, 'workspaceEditCreate.id');
export const selectCurrentWsName = (state) => get(state, 'workspaceEditCreate.currentName', '');
export const selectWsEditCreateMakePublic = (state) => get(state, 'workspaceEditCreate.makePublic', false);
export const selectWsEditCreateCurrentUsers = (state) => get(state, 'workspaceEditCreate.users', []);
export const selectIsFetching = (state) => get(state, 'workspaceEditCreate.isFetching', false);
export const selectScope = (state) => get(state, 'workspaceEditCreate.scope', []);
export const selectWsEditActive = (state) => get(state, 'workspaceEditCreate.active', false);
export const selectWsEditCustomer = (state) => get(state, 'workspaceEditCreate.customer', '');
export const selectWsEditReadonly = (state) => get(state, 'workspaceEditCreate.readonly', true);
