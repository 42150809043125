import LogOutForced from 'Common/Components/LogOutForced/index';
import Wizard from 'Screens/Wizard/components/Main';
import ForgotPassword from 'Screens/ForgotPassword';
import ResetPassword from 'Screens/ResetPassword';
import Schedule from 'Screens/Automation/Schedule';
import Agents from 'Screens/Automation/Agents';
import Host from 'Screens/Host';
import Dashboard from 'Screens/Dashboard/components/Dashboard/index';
import Pipelines from 'Screens/Automation/Pipelines';
import Jobs from 'Screens/Automation/Jobs';
import Analytics from 'Screens/Analytics';
import NewGraph from 'Screens/Analytics/components/CreateEdit/components/NewGraph';
import EditGraph from 'Screens/Analytics/components/CreateEdit/components/EditGraph';
import NotFound from '../Screens/Errores/NotFoundContainer'; // 404
import Login from '../Screens/Login/components/Login';
import KnowledgeBase from '../Screens/KnowledgeBase/components';
import Manage from '../Screens/Manage';
import Workspace from '../Screens/Workspaces';
import Users from '../Screens/Users/index';
import ExecutiveReport from '../Screens/ExecutiveReport/index';
import ExecutiveReportEditCreate from '../Screens/ExecutiveReportEditCreate/index';
import Projects from '../Screens/Planner/Projects/index';
import Swagger from 'Screens/Swagger';

const ApplicationRoutes = {
  Routes: [
    {
      path: '/automation/agents', exact: true, component: Agents, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/automation/schedule', exact: true, component: Schedule, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/login', exact: true, component: Login, isPrivate: false
    },
    {
      path: '/forgotpass', exact: true, component: ForgotPassword, isPrivate: false
    },
    {
      path: '/resetpass/:hash', exact: true, component: ResetPassword, isPrivate: false
    },
    {
      path: '/', exact: true, component: Workspace, isPrivate: true
    },
    {
      path: '/users', exact: true, component: Users, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/knowledge_base', exact: true, component: KnowledgeBase, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/manage/:ws/:id?', exact: false, component: Manage, isPrivate: true, requireWorkspace: true
    },
    {
      path: '/host/:ws/:id?', exact: true, component: Host, isPrivate: true, requireWorkspace: true
    },
    {
      path: '/workspaces', exact: true, component: Workspace, isPrivate: true
    },
    {
      path: '/report/:ws', exact: true, component: ExecutiveReport, isPrivate: true, requireWorkspace: true, edit: false
    },
    {
      path: '/report/:ws/new', exact: true, component: ExecutiveReportEditCreate, isPrivate: true, requireWorkspace: true, edit: false
    },
    {
      path: '/report/:ws/edit/:id', exact: true, component: ExecutiveReportEditCreate, isPrivate: true, requireWorkspace: true, edit: true
    },
    {
      path: '/feed/:ws', exact: true, component: Dashboard, isPrivate: true, requireWorkspace: true, edit: false
    },
    {
      path: '/wizard', exact: true, component: Wizard, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/wizard/force-first-scan', exact: true, component: Wizard, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/api-definitions', exact: true, component: Swagger, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/logout', exact: true, component: LogOutForced, isPrivate: true, requireWorkspace: false, edit: false
    },
    {
      path: '/404', exact: true, component: NotFound, isPrivate: false, requireWorkspace: false, edit: false
    },
    {
      path: '/automation/pipelines/new', exact: true, component: Pipelines, isPrivate: true, requireWorkspace: false, isNew: true
    },
    {
      path: '/automation/pipelines/:id?', exact: true, component: Pipelines, isPrivate: true, requireWorkspace: false, edit: false, isNew: false
    },
    {
      path: '/automation/jobs/new', exact: true, component: Jobs, isPrivate: true, requireWorkspace: false, isNew: true
    },
    {
      path: '/automation/jobs/new', exact: true, component: Jobs, isPrivate: true, requireWorkspace: false, isNew: true
    },
    {
      path: '/automation/jobs/:id?', exact: true, component: Jobs, isPrivate: true, requireWorkspace: false, isNew: false
    },
    {
      path: '/analytics', exact: true, component: Analytics, isPrivate: true, requireWorkspace: false
    },
    {
      path: '/analytics/new', exact: true, component: NewGraph, isPrivate: true, requireWorkspace: false, isNew: true
    },
    {
      path: '/analytics/:id', exact: true, component: EditGraph, isPrivate: true, requireWorkspace: false, edit: false, isNew: false
    },
    {
      path: '/planner', exact: true, component: Projects, isPrivate: true, requireWorkspace: false
    },
    { component: NotFound }
  ]
};

export default ApplicationRoutes;
