import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'Screens/ExecutiveReportEditCreate/components/ReportForm/components/Input/styled';
import { addFilter } from 'store/Filters/actions';
import { createFilter, eq } from 'store/Filters/constants';
import { PropTypes } from 'prop-types';
import { getVulnsPreview } from 'store/ExecutiveReportEditCreate/actions';
import { useIntl } from 'react-intl';
import {
  Wrapper, InputWrapper, PlusIcon, PlusIconWrapper
} from './styled';

const AssetsInput = ({ disabled }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const intl = useIntl();

  const addAsset = () => {
    if (inputValue) {
      const filter = createFilter('target', eq.name.value, inputValue);
      setInputValue('');
      dispatch(addFilter('reports', filter));
      dispatch(getVulnsPreview());
    }
  };

  const onKeyUp = ({ key }) => {
    if (key === 'Enter') addAsset();
  };

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          placeholder={ intl.formatMessage({ id: 'reports.editCreate.inputPlaceholder.assets' }) }
          onChange={ ({ target }) => setInputValue(target.value) }
          value={ inputValue }
          onFocus={ () => setIsFocused(true) }
          onBlur={ () => setIsFocused(false) }
          disabled={ disabled }
          onKeyUp={ onKeyUp }
        />
        <PlusIconWrapper
          isFocused={ isFocused }
          onClick={ addAsset }
          disabled={ disabled }
        >
          <PlusIcon />
        </PlusIconWrapper>
      </InputWrapper>
    </Wrapper>
  );
};

AssetsInput.propTypes = {
  disabled: PropTypes.bool
};

AssetsInput.defaultProps = {
  disabled: false
};
export default AssetsInput;
