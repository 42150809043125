import React from 'react';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { showContextMenu } from 'store/Manage/actions';
import { openModal } from 'store/modals/actions';
import { MODAL_APPLY_TEMPLATE } from 'store/modals/modals';
import { selectUserRole } from 'store/Faraday/selectors';
import { TemplateIcon } from './styled';

const ApplyTemplate = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);

  if (role === 'client' || role === 'asset_owner') return null;
  return (
    <MenuItem
      title="Apply Template"
      icon={ <TemplateIcon /> }
      onClick={ () => {
        dispatch(openModal(MODAL_APPLY_TEMPLATE));
        dispatch(showContextMenu(false));
      } }
    />
  );
};

export default ApplyTemplate;
