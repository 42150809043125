import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getGitLabSettings, TOOL_NAME } from 'store/Settings/gitLabActions';
import { getTemplates } from 'store/Settings/settingsActions';
import IssuesConfiguration from './components/IssuesConfiguration';
import Settings from './components/Settings';
import { Wrapper, Content } from './styled';

const GitLab = () => {
  const dispatch = useDispatch();

  // obtengo los datos del servidor
  useEffect(() => {
    dispatch(getTemplates(TOOL_NAME));
    dispatch(getGitLabSettings());
  }, [dispatch]);

  return (
    <Wrapper>
      <Content>
        <Settings />
        <IssuesConfiguration />
      </Content>
    </Wrapper>
  );
};
export default GitLab;
