import React from 'react';
import { PropTypes } from 'prop-types';
import {
  ContextMenu, MenuItem
} from 'react-contextmenu';
import {
  changeReadOnly, massiveUpdateWorkspacesActive, moreStatusChange, setModalAssignUsersStatus
} from 'Screens/Workspaces/actions/Actions';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Filters from 'Images/shape.svg';
import Edit from 'Images/icon-edit-enabled.svg';
import Tag from 'Images/icon-tag.svg';
import { MdKeyboardArrowRight as Arrow } from 'react-icons/md';
import { selectWorkspacesSelected } from 'store/Workspace/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import find from 'lodash/find';
import {
  MenuItemContent, Text, IconActiveInactive, Wrapper, ArrowIcon, IconEdit, SubMenuStatus, StatusText
} from './styled';

const ContextMenuWorkspaceMassiveUpdate = ({ trigger }) => {
  const dispatch = useDispatch();
  const workspacesSelected = useSelector((state) => selectWorkspacesSelected(state));
  const currentWorkspace = useSelector((state) => selectCurrentWorkspace(state));

  const setReadOnly = (value) => {
    if (workspacesSelected.length > 0) {
      workspacesSelected.forEach((ws) => {
        dispatch(changeReadOnly(ws.name, value));
      });
    }
  };

  return (
    <Wrapper>
      <ContextMenu id={ trigger } onHide={ () => dispatch(moreStatusChange(false)) } onShow={ () => moreStatusChange(true) }>
        { !find(workspacesSelected, { name: currentWorkspace }) && (
        <MenuItem onClick={ () => { dispatch(massiveUpdateWorkspacesActive()); } }>
          <MenuItemContent>
            <IconActiveInactive src={ Filters } />
            <Text><FormattedMessage id="workspaces.contextMenu.activeInactive" /></Text>
          </MenuItemContent>
        </MenuItem>
        ) }
        <SubMenuStatus title={ (
          <MenuItemContent>
            <IconEdit src={ Edit } />
            <Text><FormattedMessage id="workspaces.contextMenu.workspaceStatus" /></Text>
            <ArrowIcon><Arrow /></ArrowIcon>
          </MenuItemContent>
            ) }
        >
          <MenuItem onClick={ () => { setReadOnly(false); } }><StatusText value="editable"><FormattedMessage id="workspaces.table.columns.editable" /></StatusText></MenuItem>
          <MenuItem onClick={ () => { setReadOnly(true); } }><StatusText value="readOnly"><FormattedMessage id="workspaces.table.columns.readOnly" /></StatusText></MenuItem>
        </SubMenuStatus>
        <MenuItem onClick={ () => dispatch(setModalAssignUsersStatus(true)) }>
          <MenuItemContent>
            <IconActiveInactive src={ Tag } />
            <Text><FormattedMessage id="workspaces.contextMenu.assignUsers" /></Text>
          </MenuItemContent>
        </MenuItem>
      </ContextMenu>
    </Wrapper>
  );
};

ContextMenuWorkspaceMassiveUpdate.propTypes = {
  trigger: PropTypes.string.isRequired
};

export default ContextMenuWorkspaceMassiveUpdate;
