export default function matchField (field) {
  switch (field) {
    case 'host__os':
      return 'Host Os';

    case 'easeofresolution':
      return 'Ease of Resolution';

    case 'status_code':
      return 'Status Code';

    case 'pname':
      return 'Param Name';

    case 'policyviolations':
      return 'Policy Violations';

    case 'external_id':
      return 'External Id';

    case 'create_date':
      return 'Created';

    case 'creation_time':
      return 'Creation Time';

    case 'last_modified':
      return 'Last Modified';

    case 'open_services':
      return 'Open Services';

    case 'severity_counts':
      return 'Important Vulnerabilities';

    case 'service__name':
      return 'Service';

    case 'issuetracker':
      return 'Issue Tracker';
    case 'update_date':
      return 'Updated';

    default: {
      const fieldUpper = field.charAt(0).toUpperCase() + field.slice(1);
      return fieldUpper;
    }
  }
}
